import React, {useMemo} from 'react';
import {EtroStack} from '~/components';
import {EtroPaper} from '~/components/EtroPaper';
import {getUpdateList} from '../Updates/Updates';
import {UpdatesList} from '../Updates/UpdatesList';
import {EtroDivider} from '~/components/EtroDivider';
import {EtroTitle} from '~/components/EtroTitle';
import {useTranslation} from 'react-i18next';

export const HomeNews: React.FC = () => {
  const {t} = useTranslation();
  const update = useMemo(() => getUpdateList()[0], []);

  return (
    <EtroPaper>
      <EtroStack spacing="xs" sx={{width: '100%'}}>
        <EtroTitle order={1}>{t('latest news')}</EtroTitle>
        <EtroDivider />
        <EtroTitle order={2} align="left">
          {`${t('version update')} ${update.versionNumber}`}
        </EtroTitle>
        {update.announcement && update.announcement}
        <UpdatesList updates={update.updates} />
      </EtroStack>
    </EtroPaper>
  );
};
