import {
  NitroPayAdPlacementConfig,
  NitroPayAdPlacementConfigWithSize,
  NitroPayAdTypes
} from '~/types';
import {HEADER_HEIGHT} from '../Header';

interface CreateNitroPayAdParams {
  placementId: string;
  config: NitroPayAdPlacementConfig;
}

export const createNitroPayAd = async ({
  placementId,
  config
}: CreateNitroPayAdParams) => {
  if (window.nitroAds?.loaded) {
    return await window['nitroAds'].createAd(placementId, config);
  }

  return Promise.reject();
};

export const baseConfig: NitroPayAdPlacementConfig = {
  demo: process.env.REACT_APP_BASE_URL === 'http://localhost:8000/api/',
  refreshLimit: 0,
  refreshTime: 30,
  refreshVisibleOnly: true,
  renderVisibleOnly: false,
  report: {
    enabled: true,
    wording: 'Report Ad',
    position: 'bottom-right'
  }
};

export const nitroPayAdSizes = {
  billboard: [970, 250],
  halfPage: [300, 600],
  leaderboard: [728, 90],
  leaderboardLarge: [970, 90],
  mobileBanner: [320, 50],
  mobileBannerLarge: [320, 100],
  rectangleLarge: [336, 280],
  rectangleMedium: [300, 250],
  wideSkyscraper: [160, 600]
} as const;

export const nitroPayAdBreakpoints = {
  banner: '(min-width: 1025px)',
  desktop: '(min-width: 864px)',
  mobile: '(min-width: 320px) and (max-width: 863px)'
};

const mobileAnchorAd: NitroPayAdPlacementConfigWithSize = {
  format: 'anchor',
  anchor: 'bottom',
  anchorPersistClose: false,
  // Make sure banner is removed if resize
  mediaQuery: nitroPayAdBreakpoints.mobile,
  // Report doesn't show up properly on anchors
  report: {
    enabled: false
  },
  sizes: [nitroPayAdSizes.mobileBanner]
};

// Largest ad size should be first in list for container sizing.
export const nitroPayAdTypes: NitroPayAdTypes = {
  anchor: {
    desktop: {
      format: 'sticky-stack',
      stickyStackLimit: 15,
      stickyStackSpace: 1.25,
      stickyStackOffset: HEADER_HEIGHT ?? 50, // race condition with ad script
      sizes: [nitroPayAdSizes.wideSkyscraper],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: mobileAnchorAd
  },
  banner: {
    desktop: {
      sizes: [nitroPayAdSizes.leaderboard, nitroPayAdSizes.mobileBanner],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: {
      sizes: [nitroPayAdSizes.mobileBannerLarge, nitroPayAdSizes.mobileBanner]
    }
  },
  bannerMed: {
    desktop: {
      sizes: [
        nitroPayAdSizes.leaderboardLarge,
        nitroPayAdSizes.leaderboard,
        nitroPayAdSizes.mobileBannerLarge,
        nitroPayAdSizes.mobileBanner
      ],
      breakpoint: nitroPayAdBreakpoints.banner
    },
    mobile: {
      sizes: [nitroPayAdSizes.mobileBannerLarge, nitroPayAdSizes.mobileBanner]
    }
  },
  bannerLarge: {
    desktop: {
      sizes: [
        nitroPayAdSizes.billboard,
        nitroPayAdSizes.leaderboardLarge,
        nitroPayAdSizes.leaderboard,
        nitroPayAdSizes.rectangleMedium
      ],
      breakpoint: nitroPayAdBreakpoints.banner
    },
    mobile: {
      sizes: [nitroPayAdSizes.mobileBannerLarge, nitroPayAdSizes.mobileBanner]
    }
  },
  bannerSmall: {
    desktop: {
      sizes: [nitroPayAdSizes.leaderboard],
      breakpoint: nitroPayAdBreakpoints.banner
    },
    mobile: {sizes: [nitroPayAdSizes.mobileBanner]}
  },
  floating: {
    desktop: {format: 'floating', sizes: []},
    mobile: {format: 'floating', sizes: []}
  },
  rectangle: {
    desktop: {
      sizes: [nitroPayAdSizes.rectangleMedium],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: {sizes: [nitroPayAdSizes.rectangleMedium]}
  },
  rectangleLarge: {
    desktop: {
      sizes: [nitroPayAdSizes.rectangleLarge],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: {sizes: [nitroPayAdSizes.rectangleLarge]}
  },
  sidebar: {
    desktop: {
      sizes: [nitroPayAdSizes.wideSkyscraper],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: {sizes: [nitroPayAdSizes.rectangleMedium]}
  },
  sidebarLarge: {
    desktop: {
      sizes: [nitroPayAdSizes.halfPage],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: {sizes: [nitroPayAdSizes.rectangleMedium]}
  },
  sidebarAnchor: {
    desktop: {
      sizes: [nitroPayAdSizes.wideSkyscraper],
      breakpoint: nitroPayAdBreakpoints.desktop
    },
    mobile: mobileAnchorAd
  }
};
