import React from 'react';
import {EtroAvatar, EtroGroup, EtroText, RemoteIcon} from '~/components';
import {useSelector} from '~/hooks';
import {IconUser} from '@tabler/icons-react';
import {useMediaQueryClasses} from '~/hooks';

export const AccountAvatar: React.FC = () => {
  const {user} = useSelector(({auth}) => auth);
  const {username, discordAvatar, subscription} = user ?? {};
  const {classes} = useMediaQueryClasses();

  return (
    <EtroGroup spacing="xs">
      <EtroAvatar
        alt="account icon"
        color="etro"
        radius="xl"
        size={!!discordAvatar ? 'md' : 'sm'}
        src={
          discordAvatar && `${discordAvatar?.replace(/\.[^/.]+$/, '')}?size=256`
        }
        variant="filled"
      >
        {/* Fallback Icon */}
        <IconUser />
      </EtroAvatar>
      <EtroText size="sm" className={classes.hiddenMobile}>
        {username}
      </EtroText>
      {subscription?.isActive && (
        <RemoteIcon
          className={classes.hiddenMobile}
          iconPath={subscription.tier.iconPath}
          width={20}
          alt="subscription tier icon"
        />
      )}
    </EtroGroup>
  );
};
