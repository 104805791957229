import React, {useCallback} from 'react';
import {Header as MantineHeader} from '@mantine/core';
import {Link} from 'react-router-dom';
import {useToggle} from 'react-use';
import {
  EtroBox,
  EtroBurger,
  EtroButton,
  EtroGroup,
  EtroLogo,
  EtroText,
  LanguageMenu,
  LanguageSelect,
  RemoteSVG
} from '~/components';
import {NavbarDrawer} from './NavbarDrawer';
import {AccountMenu} from './AccountMenu';
import {useMediaQueryClasses, useMobileMediaQuery} from '~/hooks';
import {KofiButton} from '~/components/KofiButton';
import {DiscordInviteButton} from '~/components/DiscordButton/DiscordInviteButton';
import {NitroPayButton} from '../NitroPayButton';
import {useTranslation} from 'react-i18next';

export const HEADER_HEIGHT = 50;

export const Header: React.FC = () => {
  const [drawerOpened, toggleDrawer] = useToggle(false);
  const {t} = useTranslation();
  const isMobile = useMobileMediaQuery();
  const {classes} = useMediaQueryClasses();
  // Automatically close drawer if header is clicked
  const handleHeaderClick = useCallback(() => {
    if (drawerOpened) {
      toggleDrawer(false);
    }
  }, [drawerOpened, toggleDrawer]);

  return (
    <>
      <MantineHeader
        height={HEADER_HEIGHT}
        px={isMobile ? 'xs' : 'md'}
        mb={'xl'}
        onClick={handleHeaderClick}
      >
        <EtroGroup position="apart" sx={{height: '100%'}} noWrap spacing={'xs'}>
          <EtroBurger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
          <EtroGroup sx={{height: '100%'}} spacing={'xs'}>
            <EtroBox sx={{maxWidth: '90px'}}>
              <EtroLogo svgProps={{width: '100%'}} />
            </EtroBox>
            <EtroButton
              component={Link}
              leftIcon={
                <RemoteSVG iconPath="/ui/gearset.svg" className="etro-icon" />
              }
              size="md"
              to="/gearset"
              variant="subtle"
              className={classes.hiddenMobile}
            >
              <EtroText size="sm">{t('gearset')}</EtroText>
            </EtroButton>
            <EtroButton
              component={Link}
              leftIcon={
                <RemoteSVG iconPath="/ui/inventory.svg" className="etro-icon" />
              }
              size="md"
              to="/inventory"
              variant="subtle"
              className={classes.hiddenMobile}
            >
              <EtroText size="sm">{t('inventory')}</EtroText>
            </EtroButton>
          </EtroGroup>
          <EtroGroup spacing={'xs'} noWrap>
            <EtroGroup spacing={'xs'} className={classes.hiddenMobile}>
              <NitroPayButton minimal disableTooltip className="etro-no-pad" />
              <KofiButton minimal className="etro-no-pad" />
              {/* @ts-ignore */}
              <DiscordInviteButton
                className={'etro-button-hover-discord-fill etro-no-pad'}
                minimal
              />
            </EtroGroup>
            <LanguageSelect className={classes.hiddenMobile} />
            <LanguageMenu className={classes.hiddenDesktop} />
            <AccountMenu />
          </EtroGroup>
        </EtroGroup>
      </MantineHeader>
      <NavbarDrawer
        drawerOpened={drawerOpened}
        toggleDrawer={toggleDrawer}
        classes={classes}
      />
    </>
  );
};
