/*
  This acts as a middleware for the application. It intercepts any 
  Redux actions which have the following shape:
    read: { url: "gearsets/:id", method: "get" }
*/

import axios from 'axios';
import {safeGetItem} from '~/utils/localStorage';
import {DELETE, GET} from '../actions/types';
import i18next from 'i18next';
import queryStringParser from 'query-string';

var api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '',
  // timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const asyncTask = store => done => options => {
  const {method, url, payload} = options;
  const params = [GET, DELETE].includes(method) ? {params: payload} : payload;
  // const params = [GET, DELETE].includes(method) ? 'params' : 'data';

  const {
    auth: {token}
  } = store.getState();

  /* 
    Fall back to local storage if not found in Redux.
    This occurs in private mode and on the user request on load.
  */
  const JWT = token ? token : safeGetItem('token');

  // Only send token if it exists, otherwise decode errors occur on the backend
  // This can occur on first login
  if (JWT) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${JWT}`;
  }

  const language =
    i18next.resolvedLanguage ??
    safeGetItem('i18nextLng') ??
    queryStringParser.parse(window.location.search)?.lng;

  if (language) {
    axios.defaults.headers.common['Accept-Language'] = language;
  }

  return api[method](url, params).then(
    response => done(null, response),
    error => done(error, null)
  );
};
