import React, {useState, useEffect, useRef, useCallback} from 'react';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {startsWith} from 'lodash';
import {Intent, Toaster, Toast, Position} from '@blueprintjs/core';

const SaveWarningComponent = ({
  isAuthenticated,
  warningPath,
  message,
  userIsLoading
}) => {
  /*
  Dismiss and visible are separated otherwise the Effect will keep making toasts
  after the user closes it
  */
  const [warningDismissed, setWarningDismissed] = useState(false),
    [warningVisible, setWarningVisible] = useState(false),
    [toasts, setToasts] = useState([]),
    toasterRef = useRef(),
    location = useLocation();

  const hideWarning = useCallback(() => {
    setWarningVisible(false);
    setToasts([]);
    toasterRef.current.clear();
  }, [toasterRef]);

  const dismissWarning = () => {
    setWarningDismissed(true);
    hideWarning();
  };

  const warningToast = {
    key: 1,
    message,
    intent: Intent.WARNING,
    icon: 'warning-sign',
    timeout: 0,
    onDismiss: () => dismissWarning()
  };

  useEffect(() => {
    if (
      !isAuthenticated &&
      !warningDismissed &&
      !warningVisible &&
      startsWith(location.pathname, warningPath)
    ) {
      setWarningVisible(true);
      setToasts([warningToast]);
    }

    if (
      warningVisible &&
      (isAuthenticated || !startsWith(location.pathname, warningPath))
    ) {
      hideWarning();
    }
  }, [
    isAuthenticated,
    location,
    warningDismissed,
    warningVisible,
    setWarningVisible,
    warningPath,
    setToasts,
    warningToast,
    hideWarning
  ]);

  if (userIsLoading) {
    return null;
  }

  return (
    <>
      <Toaster position={Position.RIGHT_BOTTOM} ref={toasterRef}>
        {toasts.map(toast => (
          <Toast {...toast} />
        ))}
      </Toaster>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userIsLoading: state.auth.userIsLoading || state.auth.discordLoginIsLoading
  };
};

export const SaveWarning = connect(mapStateToProps)(SaveWarningComponent);
