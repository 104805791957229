/* eslint-disable no-unused-vars */
import {Key, ReactNode} from 'react';
import {NumberOperators, SelectOperators, StringOperators} from '~/types/enums';
import {SelectOption, SortingAlgorithmsUnion} from '~/types';
import {DataGridProps, Column} from 'react-data-grid';

export type Operators = NumberOperators | StringOperators | SelectOperators;

export interface EtroFilterColumn {
  columnKey: string;
  name: string;
  options?: SelectOption[];
  type: EtroDGColumnTypeUnion;
}

export interface EtroFilterState extends EtroFilterColumn {
  operator: Operators | null;
  value: EtroFilterStateValue;
}

export interface Filters {
  search: string;
  filterColumns: EtroFilterColumns;
  filterState: EtroFilterStates;
}

export type EtroFilterColumns = Record<string, EtroFilterColumn>;
export type EtroFilterStates = Record<string, EtroFilterState>;
export type EtroFilterStateValue = string;

export enum EtroDGColumnType {
  boolean = 'boolean',
  date = 'date',
  datetime = 'datetime',
  number = 'number',
  object = 'object',
  select = 'select',
  string = 'string',
  time = 'time'
}

export enum EtroDGSize {
  empty = '70px', // Size for Header + EmptyRowRenderer
  xs = '200px',
  sm = '400px',
  md = '600px',
  lg = '800px',
  xl = '1000px',
  fill = 'min-content'
}

export type EtroDGColumnTypeUnion = keyof typeof EtroDGColumnType;
export type EtroDGSizeUnion = keyof typeof EtroDGSize;

export type EtroDGColumn<R, SR = unknown> = Column<R, SR> & {
  filterable?: boolean;
  sortingAlgorithm?: SortingAlgorithmsUnion;
  type?: EtroDGColumnTypeUnion;
};

export interface EtroDataGridProps<R, SR = unknown, K extends Key = Key>
  extends DataGridProps<R, SR, K> {
  columns: readonly EtroDGColumn<R, SR>[];
  disableFiltering?: boolean;
  disableHeader?: boolean;
  headerActions?: ReactNode;
  noDataText?: string;
  size?: EtroDGSizeUnion;
  title?: ReactNode;
}

export type OperatorOption = {
  label: string | NumberOperators;
  value: Operators;
};

export type NumberFilter = (
  value: number | string | null,
  filterValue: number
) => boolean;

export type StringFilter = (
  value: string | null,
  filterValue: string
) => boolean;

export type ColumnOption = EtroFilterColumn & SelectOption;
