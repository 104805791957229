import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {EtroGroup, EtroStack, EtroText, EtroTitle} from '~/components';
import {useStyles} from './Footer.styles';
import {
  IconCode,
  IconCookie,
  IconGitMerge,
  IconMail,
  IconStack2,
  TablerIconsProps
} from '@tabler/icons-react';
import {useTranslation} from 'react-i18next';
import {TranslationKey} from '~/types';

interface FooterLinksProps {
  className?: string;
  onLinkClick?: () => void;
}

type FooterLinkGroups = {
  title: TranslationKey;
  links: {
    label: TranslationKey;
    to: string;
    openNewTab?: boolean;
    icon: React.ReactNode;
  }[];
}[];

const iconProps: TablerIconsProps = {
  // Blank className overrides default in index.scss
  className: '',
  size: '16px',
  stroke: '1.5'
};

const footerLinks: FooterLinkGroups = [
  {
    title: 'about',
    links: [
      {
        label: 'privacy',
        to: '/privacy',
        icon: <IconCookie {...iconProps} />
      },
      {
        label: 'contact',
        to: '/contact',
        icon: <IconMail {...iconProps} />
      }
    ]
  },
  {
    title: 'development',
    links: [
      {
        label: 'changelog',
        to: '/changelog',
        icon: <IconGitMerge {...iconProps} />
      },
      {
        label: 'api docs',
        to: `${process.env.REACT_APP_BASE_URL}docs`,
        openNewTab: true,
        icon: <IconCode {...iconProps} />
      },
      {
        label: 'embed',
        to: '/docs/embed',
        icon: <IconStack2 {...iconProps} />
      }
    ]
  }
];

export const FooterLinks: React.FC<FooterLinksProps> = ({
  className,
  onLinkClick
}) => {
  const {classes} = useStyles();
  const {t} = useTranslation();

  const groups = useMemo(() => {
    return footerLinks.map(group => {
      const links = group.links.map(({label, to, openNewTab, icon}, index) => {
        if (openNewTab) {
          return (
            <EtroText
              className={classes.link}
              color="dimmed"
              component={'a'}
              href={to}
              key={index}
              onClick={onLinkClick}
              rel={'noopener noreferrer'}
              size="xs"
              target={'_blank'}
            >
              {icon}
              {t(label)}
            </EtroText>
          );
        }

        return (
          <EtroText
            className={classes.link}
            color="dimmed"
            component={Link}
            onClick={onLinkClick}
            key={index}
            size="xs"
            to={to}
          >
            {icon}
            {t(label)}
          </EtroText>
        );
      });

      return (
        <EtroStack spacing={'xs'} align="flex-start" key={group.title}>
          <EtroTitle order={4}>{t(group.title)}</EtroTitle>
          {links}
        </EtroStack>
      );
    });
  }, [classes, onLinkClick, t]);

  return (
    <EtroGroup align={'flex-start'} spacing={'xl'} className={className}>
      {groups}
    </EtroGroup>
  );
};
