import React, {useLayoutEffect, useMemo, useState} from 'react';
import {
  NitroPayAdPlacementConfig,
  NitroPayAdPlacementConfigWithSize,
  NitroPayAdType
} from '~/types';
import {baseConfig, createNitroPayAd, nitroPayAdTypes} from './helpers';
import {useSelector} from '~/hooks';
import {EtroBox, EtroBoxProps} from '~/components';
// Use library because hooks are delayed and will load incorrectly
import {isMobile} from 'react-device-detect';

export * from './helpers';

export interface NitroPayAdProps {
  boxProps?: EtroBoxProps;
  config?: NitroPayAdPlacementConfig;
  placementId: string;
  type: NitroPayAdType;
}

export const NitroPayAd: React.FC<NitroPayAdProps> = ({
  config,
  placementId,
  type,
  boxProps = {}
}) => {
  const {subIsActive, loading} = useSelector(({auth}) => {
    return {
      subIsActive: auth.user?.subscription?.isActive,
      loading: auth.userIsLoading || auth.discordLoginIsLoading
    };
  });
  const [adLoaded, setAdLoaded] = useState(false);
  const nitroPayAdsLoaded = window.nitroAds?.loaded ?? false;
  const adConfig = useMemo(
    () => nitroPayAdTypes[type][isMobile ? 'mobile' : 'desktop'],
    [type]
  );
  const fullConfig: NitroPayAdPlacementConfigWithSize = useMemo(() => {
    return {
      ...baseConfig,
      ...adConfig,
      ...config
    };
  }, [adConfig, config]);
  const {format, sizes} = fullConfig;

  // layout effect so that sticky stack has appropriate space
  useLayoutEffect(() => {
    if (nitroPayAdsLoaded && !adLoaded && !subIsActive && !loading) {
      createNitroPayAd({
        placementId,
        config: fullConfig
      })
        .then(ad => {
          if (ad?.id) {
            setAdLoaded(true);
          }
        })
        .catch(() => {});
    }
  }, [
    fullConfig,
    adLoaded,
    config,
    placementId,
    nitroPayAdsLoaded,
    subIsActive,
    loading
  ]);

  if (type === 'floating') {
    return null;
  }

  return (
    <EtroBox
      {...boxProps}
      id={placementId}
      sx={{
        display:
          subIsActive || loading || (!adLoaded && type !== 'anchor')
            ? 'none'
            : undefined,
        width: type !== 'anchor' ? sizes[0][0] : undefined,
        height:
          format === 'sticky-stack'
            ? '100vh'
            : type !== 'anchor'
            ? // Add 20 for bottom margin
              sizes[0][1] + 20
            : undefined,
        ...boxProps.sx
      }}
    />
  );
};
