import React, {useCallback} from 'react';
import {TextInput, TextInputProps} from '@mantine/core';
import {EtroCloseButton} from '../EtroCloseButton';

export interface EtroTextInputProps extends TextInputProps {
  clearable?: boolean;
}

const defaultProps: Partial<EtroTextInputProps> = {
  labelProps: {size: 'sm'},
  size: 'xs'
};

export const EtroTextInput = React.forwardRef<
  HTMLInputElement,
  EtroTextInputProps
>(
  (
    {
      clearable,
      disabled,
      id,
      name,
      onChange,
      rightSection,
      size,
      value,
      ...rest
    },
    ref
  ) => {
    const onClearClick = useCallback(() => {
      const event = new Event('input', {bubbles: true});
      Object.defineProperty(event, 'target', {
        writable: false,
        value: {value: ''}
      });
      // @ts-expect-error missing certain event props
      onChange?.({target: {id, name, value: ''}});
    }, [id, name, onChange]);

    return (
      <TextInput
        ref={ref}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        rightSectionWidth={30}
        size={size}
        value={value}
        {...defaultProps}
        {...rest}
        rightSection={
          <>
            {rightSection}
            {clearable && (
              <EtroCloseButton
                disabled={disabled || !value}
                onClick={onClearClick}
                size={size}
                variant="subtle"
              />
            )}
          </>
        }
      />
    );
  }
);
