import React from 'react';
import {BaseDiscordButton} from './BaseDiscordButton';

export const DiscordInviteButton = ({
  text,
  className,
  minimal = false,
  outlined
}) => {
  return (
    <BaseDiscordButton
      outlined={outlined}
      className={className}
      link={`${process.env.REACT_APP_DISCORD_INV}`}
      text={text}
      minimal={minimal}
      iconProps={{
        className: 'etro-icon-small'
      }}
    />
  );
};
