import React from 'react';
import {createPolymorphicComponent, Text, TextProps} from '@mantine/core';

export interface EtroTextProps extends TextProps {}

const defaultProps: Partial<EtroTextProps> = {};

const _EtroText: React.FC<EtroTextProps> = props => {
  return <Text {...defaultProps} {...props} />;
};

export const EtroText = createPolymorphicComponent<'div', EtroTextProps>(
  _EtroText
);
