import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {
  Card,
  FormGroup,
  Button,
  Intent,
  Tooltip,
  Position,
  ButtonGroup
} from '@blueprintjs/core';
import {useCopyToClipboard, useToggle} from 'react-use';
import {InputWithError} from '../../components/Form/InputWithError';
import {SidebarTables} from './SidebarTables';
import {JobSelector} from '~/components/JobSelector/JobSelector';
import {ClanSelector} from '~/components/ClanSelector/ClanSelector';
import {PatchSelector} from '~/components/PatchSelector';
import {SplitButton} from '~/components/SplitButton';
import {onSuccess} from '~/components/AppToaster/AppToaster';
import {GearsetCloneDialog} from '../Home/GearsetCloneDialog';
import {GearsetImportDialog} from './GearsetImportDialog';
import {HelpDialog} from './HelpDialog';
import {EtroButton} from '~/components/EtroButton';
import {MateriaTotalsDialog} from './MateriaTotalsDialog';
import {EtroText} from '~/components/EtroText';
import {
  IconChevronsDown,
  IconChevronsRight,
  IconSum
} from '@tabler/icons-react';
import {useTranslation} from 'react-i18next';

const GearsetSidebarComponent = ({
  activeTab,
  additionalParams,
  allMateriaEditorVisible,
  currentJob,
  errors,
  gearset,
  gearsetId,
  handleChange,
  handleClearDialogToggle,
  handleJobChange,
  handleSubmit,
  isAuthenticated,
  isCraftOrGatherJob,
  levelBaseParams,
  loading,
  materiaTotals,
  params,
  setAdditionalParams,
  setFieldValue,
  toggleAllMateriaEditorVisible,
  touched,
  values
}) => {
  const {t} = useTranslation();
  // Destructure so memoize updates when needed.
  const {isOwner} = gearset;
  // eslint-disable-next-line no-unused-vars
  const [shareLink, copyShareLink] = useCopyToClipboard();
  const [cloneDialogVisible, toggleCloneDialog] = useToggle(false);
  const [importDialogVisible, toggleImportDialog] = useToggle(false);
  const [helpDialogVisible, toggleHelpDialog] = useState(false);
  const handleHelpDialogToggle = useCallback(
    () => toggleHelpDialog(!helpDialogVisible),
    [helpDialogVisible]
  );
  const [materiaTotalsDialogVisible, toggleMateriaTotalsDialog] = useState(
    false
  );
  const handleMateriaTotalsDialogToggle = useCallback(
    () => toggleMateriaTotalsDialog(!materiaTotalsDialogVisible),
    [materiaTotalsDialogVisible]
  );
  const splitButtonMenuOptions = useMemo(() => {
    return [
      {
        icon: 'floppy-disk',
        text: t('save'),
        disabled: !isOwner && gearsetId,
        onClick: handleSubmit,
        type: 'submit'
      },
      {
        icon: 'duplicate',
        text: t('clone'),
        onClick: toggleCloneDialog,
        type: 'button',
        disabled: !gearsetId
      },
      {
        icon: 'import',
        text: t('import'),
        onClick: toggleImportDialog,
        type: 'button'
      }
    ];
  }, [
    gearsetId,
    handleSubmit,
    isOwner,
    t,
    toggleCloneDialog,
    toggleImportDialog
  ]);
  // Default to clone and let the effect switch to save when needed
  const [currentOption, setCurrentOption] = useState(splitButtonMenuOptions[1]);

  // If the gearsetId changes (clone/import/load), automatically change to save action
  useEffect(() => {
    if (gearsetId && isOwner && currentOption.text !== 'Save') {
      setCurrentOption(splitButtonMenuOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gearsetId, isOwner]);

  // When starting a new GS or clearing reset to save action
  useEffect(() => {
    if (!gearsetId && currentOption.text === 'Clone') {
      setCurrentOption(splitButtonMenuOptions[0]);
    }
  }, [currentOption, gearsetId, splitButtonMenuOptions]);

  return (
    <>
      <Card className="etro-gearset-sidebar">
        {/* 
        Tooltips will continue to display at (0,0) if the tab is switched.
        Adding activeTab to hasErrors has a rendering delay and still shows the tooltip for a second.
       */}
        {activeTab && (
          <InputWithError
            id="name"
            value={values.name}
            errorContent={errors.name}
            hasErrors={errors.name && touched.name}
            label={t('name')}
            handleChange={handleChange}
          />
        )}
        {activeTab && (
          <Tooltip
            content={errors.job}
            isOpen={errors.job && touched.job}
            disabled={!(errors.job && touched.job)}
            intent={Intent.DANGER}
            position={Position.RIGHT}
            boundary={'viewport'}
            target={
              <JobSelector
                setFormValue={setFieldValue}
                additionalOnChange={handleJobChange}
                hasErrors={errors.job && touched.job}
              />
            }
          />
        )}
        <ClanSelector setFormValue={setFieldValue} />
        <PatchSelector
          value={values?.patch}
          setFormValue={setFieldValue}
          defaultId={gearset?.patch}
        />
        <FormGroup className="etro-gearset-buttons">
          <ButtonGroup fill className="etro-button-group-outlined">
            <Tooltip
              content={t('clear')}
              position={Position.TOP}
              boundary={'viewport'}
              intent={Intent.DANGER}
              hoverOpenDelay={0}
            >
              <Button
                className="etro-button-hover-danger-fill"
                icon="delete"
                outlined
                onClick={handleClearDialogToggle}
              />
            </Tooltip>
            <Tooltip
              content={t('share')}
              position={Position.TOP}
              boundary={'viewport'}
              intent={Intent.PRIMARY}
              disabled={!gearsetId}
              hoverOpenDelay={0}
            >
              <Button
                className="etro-button-hover-primary-fill"
                icon="link"
                outlined
                disabled={!gearsetId}
                onClick={() => {
                  copyShareLink(window.location.href);
                  onSuccess(
                    `${values.name} gearset link copied to clipboard.`,
                    Intent.PRIMARY,
                    'clipboard'
                  );
                }}
              />
            </Tooltip>
            <Tooltip
              content={t('help')}
              position={Position.TOP}
              boundary={'viewport'}
              intent={Intent.PRIMARY}
              hoverOpenDelay={0}
            >
              <Button
                className="etro-button-hover-primary-fill"
                icon="help"
                outlined
                onClick={handleHelpDialogToggle}
              />
            </Tooltip>
            <SplitButton
              intent={Intent.PRIMARY}
              disabled={!isAuthenticated}
              buttonProps={{
                loading,
                style: {minWidth: '90px', whiteSpace: 'nowrap'}
              }}
              menuOptions={splitButtonMenuOptions}
              option={currentOption}
              onChange={setCurrentOption}
            />
          </ButtonGroup>
        </FormGroup>
        <EtroText
          // Match BP label margin
          mb={5}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {t('materia')}
        </EtroText>
        <EtroButton.Group mb="xs">
          <EtroButton
            disabled={isEmpty(currentJob)}
            fullWidth
            leftIcon={<IconSum />}
            onClick={handleMateriaTotalsDialogToggle}
          >
            {t('total')}
          </EtroButton>
          <EtroButton
            disabled={isEmpty(currentJob)}
            fullWidth
            leftIcon={
              allMateriaEditorVisible ? (
                <IconChevronsDown />
              ) : (
                <IconChevronsRight />
              )
            }
            onClick={toggleAllMateriaEditorVisible}
          >
            {t('toggle all')}
          </EtroButton>
        </EtroButton.Group>
        {!isEmpty(params) && (
          <>
            <SidebarTables
              params={params}
              gearset={gearset}
              currentJob={currentJob}
              additionalParams={additionalParams}
              setAdditionalParams={setAdditionalParams}
              isCraftOrGatherJob={isCraftOrGatherJob}
              levelBaseParams={levelBaseParams}
            />
          </>
        )}
      </Card>
      {cloneDialogVisible && (
        <GearsetCloneDialog
          tableMode={false}
          isOpen={cloneDialogVisible}
          toggle={toggleCloneDialog}
          row={{
            id: gearsetId,
            name: values.name,
            job: currentJob.id
          }}
        />
      )}
      <GearsetImportDialog
        toggle={toggleImportDialog}
        visible={importDialogVisible}
      />
      <HelpDialog isOpen={helpDialogVisible} toggle={handleHelpDialogToggle} />
      <MateriaTotalsDialog
        materiaTotals={materiaTotals}
        isOpen={materiaTotalsDialogVisible}
        toggle={handleMateriaTotalsDialogToggle}
      />
    </>
  );
};

const actions = {};

const mapStateToProps = state => {
  return {
    currentJob: state.jobs.currentJob,
    isAuthenticated: state.auth.isAuthenticated,
    loading:
      state.gearsets.createIsLoading ||
      state.gearsets.updateIsLoading ||
      state.gearsets.cloneIsLoading
  };
};

export const GearsetSidebar = connect(
  mapStateToProps,
  actions
)(GearsetSidebarComponent);
