import React, {useEffect, useMemo, useRef} from 'react';
import {
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Position,
  Tooltip,
  ITooltipProps,
  IInputGroupProps,
  IFormGroupProps
} from '@blueprintjs/core';
import {FieldError} from 'react-hook-form';
import {useToggle} from 'react-use';
import {EtroBox, EtroText} from '~/components';

interface EtroInputProps extends Omit<IInputGroupProps, 'onChange'> {
  clearable?: boolean;
  formGroupProps?: Omit<IFormGroupProps, 'disabled' | 'label'> & {
    className?: string;
  };
  errors?:
    | ({message?: string} & Record<string, unknown>)
    | FieldError
    | undefined;
  inlineEdit?: boolean;
  label?: IFormGroupProps['label'];
  onChange?: (v: string) => void;
  tooltipProps?: Omit<ITooltipProps, 'disabled'>;
}

export const EtroInput: React.FC<EtroInputProps> = ({
  clearable,
  disabled,
  formGroupProps,
  onChange,
  errors,
  inlineEdit,
  intent,
  label,
  rightElement,
  tooltipProps,
  type,
  value,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [editing, toggleEditing] = useToggle(false);
  const _rightElement = useMemo(() => {
    if (!!rightElement) {
      return rightElement;
    } else if (clearable && onChange) {
      return <Button minimal icon="small-cross" onClick={() => onChange('')} />;
    }

    return undefined;
  }, [clearable, onChange, rightElement]);

  // Automatically focus input on mode change. Ensures onBlur is fired
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  return (
    <FormGroup {...formGroupProps} label={label} disabled={disabled}>
      <Tooltip
        position={Position.RIGHT}
        boundary={'viewport'}
        {...tooltipProps}
        content={errors?.message}
        isOpen={!!errors}
        disabled={!errors}
        intent={Intent.DANGER}
      >
        <>
          <InputGroup
            inputRef={inputRef}
            {...rest}
            value={value}
            onChange={
              onChange
                ? (e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e.target.value)
                : undefined
            }
            intent={!!errors ? Intent.DANGER : intent}
            disabled={disabled}
            rightElement={_rightElement}
            onBlur={() => {
              if (inlineEdit) {
                toggleEditing(false);
              }
            }}
            onClick={e => e.stopPropagation()}
            onKeyDown={({key}) => {
              if (key === 'Enter' && inlineEdit) {
                toggleEditing(false);
              }
            }}
            style={{display: editing || !inlineEdit ? 'inline-block' : 'none'}}
          />
          <EtroBox
            sx={theme => ({
              display: !editing && inlineEdit ? 'inline-flex' : 'none',
              // Match input height to reduce flexing
              minHeight: '30px',
              alignItems: 'center',
              '&:hover': {
                color: theme.colors.etro
              }
            })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              toggleEditing();
            }}
          >
            <EtroText sx={{paddingRight: '12px', lineHeight: 1}}>
              {value}
            </EtroText>
            <Icon icon="edit" />
          </EtroBox>
        </>
      </Tooltip>
    </FormGroup>
  );
};
