import {etroApi} from '.';
import {World} from '~/types';

const WORLD_BASE = 'world/';

const worldsApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readWorld: query<World, number>({
      query: id => `${WORLD_BASE}${id}`
    }),
    listWorlds: query<World[], void>({
      query: () => WORLD_BASE
    })
  })
});

export const {useReadWorldQuery, useListWorldsQuery} = worldsApi;
