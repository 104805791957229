import React, {useEffect, useCallback} from 'react';
import {useMantineTheme} from '@mantine/core';
import {useLocalStorage} from 'react-use';
import {userCreators} from '~/actions/user';
import {useSelector, useDispatch} from '~/hooks';
import {EtroSwitch, EtroSwitchProps} from '~/components';
import {IconMoonStars, IconSun} from '@tabler/icons-react';

const {setDarkMode} = userCreators;

export const ThemeSwitch: React.FC<EtroSwitchProps> = props => {
  const {darkMode} = useSelector(({user}) => user);
  const dispatch = useDispatch();
  const [userValue, setUserValue] = useLocalStorage('darkKey', true);
  const handleThemeToggle = useCallback(() => {
    setUserValue(!darkMode);
    dispatch(setDarkMode(!darkMode));
  }, [darkMode, dispatch, setUserValue]);
  const {white, black} = useMantineTheme();

  useEffect(() => {
    if (userValue === null) {
      setUserValue(darkMode);
    }

    dispatch(setDarkMode(userValue));
  }, [darkMode, dispatch, setUserValue, userValue]);

  return (
    <EtroSwitch
      checked={darkMode}
      size="md"
      onChange={handleThemeToggle}
      onLabel={<IconSun color={white} />}
      offLabel={<IconMoonStars color={black} />}
      {...props}
    />
  );
};
