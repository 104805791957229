import React from 'react';
import {Input, InputWrapperProps} from '@mantine/core';

export interface EtroInputWrapperProps extends InputWrapperProps {}

const defaultProps: Partial<EtroInputWrapperProps> = {};

export const EtroInputWrapper = React.forwardRef<
  HTMLDivElement,
  EtroInputWrapperProps
>((props, ref) => {
  return <Input.Wrapper ref={ref} {...defaultProps} {...props} />;
});
