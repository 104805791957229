import React from 'react';
import {Select, SelectProps} from '@mantine/core';

export interface EtroSelectProps extends SelectProps {}

const defaultProps: Partial<EtroSelectProps> = {
  clearable: true,
  nothingFound: 'No options',
  searchable: true,
  size: 'xs'
};

export const EtroSelect = React.forwardRef<HTMLInputElement, EtroSelectProps>(
  (props, ref) => {
    return <Select ref={ref} {...defaultProps} {...props} />;
  }
);
