import React, {useEffect, useMemo, ReactElement} from 'react';
import {useParams} from 'react-router-dom';
import {mapValues, isEmpty} from 'lodash';
import {useSelector, useDispatch, useMateriaMap} from '~/hooks';
import {gearsetsCreators, relicCreators} from '~/actions';
import {GearsetExpanded, Slot} from '~/types';
import {EmbedGearsetProps} from './types';
import {jobParamList, secondaryParamNameList} from '~/constants';
import {getSlotObjects} from '../Gearset/GearsetHelpers';

interface EmbedGearsetContainerProps {
  children: (props: EmbedGearsetProps) => ReactElement;
}

const TOTAL_PARAMS_FILTER = [
  'Food Values',
  'Medicine Values',
  'Materia Values',
  'Materia Totals',
  'Weapon Damage'
];

export const EmbedGearsetContainer: React.FC<EmbedGearsetContainerProps> = ({
  children
}) => {
  const {id: gearsetId} = useParams<{id: string}>();
  const dispatch = useDispatch();
  const materiaMap = useMateriaMap();
  const {readError, readResult, relics} = useSelector(({gearsets, relic}) => {
    const readResult = gearsets.readResult
      ? ({
          ...gearsets.readResult,
          totalParams: gearsets.readResult.totalParams?.filter(
            ({name}) => !TOTAL_PARAMS_FILTER.includes(name)
          )
        } as GearsetExpanded)
      : null;

    return {
      readError: gearsets.readError,
      readResult,
      relics:
        !!readResult && !isEmpty(readResult)
          ? mapValues(relic[readResult.job.abbrev], o => o?.[0])
          : {}
    };
  });
  const gearset: GearsetExpanded | null = useMemo(() => {
    if (!!readResult && !isEmpty(readResult)) {
      return {...readResult, ...relics};
    }

    return null;
  }, [readResult, relics]);
  const slots: Slot[] = useMemo(() => {
    if (!readResult) {
      return [];
    }

    return getSlotObjects(readResult.job) as Slot[];
  }, [readResult]);
  const params: {
    paramNames: string[];
    secondaryParams: string[];
  } = useMemo(() => {
    if (!readResult) {
      return {paramNames: [], secondaryParams: []};
    }

    const secondaryParams = secondaryParamNameList.concat(
      jobParamList[readResult.job.abbrev].secondary
    );

    return {
      paramNames: (jobParamList[readResult.job.abbrev].main as string[]).concat(
        secondaryParams
      ),
      secondaryParams
    };
  }, [readResult]);

  useEffect(() => {
    if (gearsetId) {
      dispatch(gearsetsCreators.read({expand: true}, {id: gearsetId}));
    }
  }, [dispatch, gearsetId]);

  useEffect(() => {
    if (readResult?.relics) {
      Object.values(readResult.relics).forEach(id =>
        dispatch(
          relicCreators.read({itemLevelSync: gearset?.itemLevelSync}, {id})
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, readResult?.relics]);

  return <>{children({gearset, materiaMap, ...params, readError, slots})}</>;
};
