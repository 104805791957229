import {createActions} from 'redux-arc';
import {POST, GET, PUT} from './types';

const authApiBase = 'auth/';
const userApiBase = 'auth/user/';

export const {types, creators: authCreators} = createActions('auth', {
  login: {url: `${authApiBase}login/`, method: POST},
  discordLogin: {url: `${authApiBase}discord/login/`, method: POST},
  logout: {url: `${authApiBase}logout/`, method: POST},
  user: {url: `${userApiBase}`, method: GET},
  userUpdate: {url: `${userApiBase}`, method: PUT},
  registration: {url: `${authApiBase}registration/`, method: POST},
  verifyEmail: {url: `${authApiBase}registration/verify-email/`, method: POST},
  passwordChange: {url: `${authApiBase}password/change/`, method: POST},
  passwordReset: {url: `${authApiBase}password/reset/confirm/`, method: POST},
  sendPasswordResetEmail: {url: `${authApiBase}password/reset/`, method: POST},
  sendVerificationEmail: {
    url: `${authApiBase}send-email-verification/`,
    method: POST
  },
  reset: null
});
