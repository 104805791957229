import {etroApi} from '.';
import {Region} from '~/types';

const REGION_BASE = 'region/';

const regionsApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readRegion: query<Region, number>({
      query: id => `${REGION_BASE}${id}`
    }),
    listRegions: query<Region[], void>({
      query: () => REGION_BASE
    })
  })
});

export const {useReadRegionQuery, useListRegionsQuery} = regionsApi;
