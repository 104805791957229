import React from 'react';
import {createPolymorphicComponent, Menu, MenuItemProps} from '@mantine/core';

const _EtroMenuItem: React.FC<MenuItemProps> = props => {
  return <Menu.Item {...props} />;
};

export const EtroMenuItem = createPolymorphicComponent<'button', MenuItemProps>(
  _EtroMenuItem
);
