import React from 'react';
import {FallbackProps} from 'react-error-boundary';
import {
  EtroAlert,
  EtroButton,
  EtroContainer,
  EtroStack,
  EtroText,
  EtroGroup
} from '~/components';
import {Link} from 'react-router-dom';
import {IconAlertCircle, IconHome, IconRefresh} from '@tabler/icons-react';

export const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  return (
    <EtroContainer>
      <EtroStack align={'center'} spacing={'xl'}>
        <EtroAlert
          title="Etro encountered an error."
          color="red.7"
          icon={<IconAlertCircle />}
        >
          <EtroText>
            {
              'The error has been automatically reported. Additionally, you can use the Discord link in the footer and report the following error to Ikuni:'
            }
          </EtroText>
          <EtroText component="pre" sx={{whiteSpace: 'pre-wrap'}}>
            {error?.message}
          </EtroText>
          <EtroGroup spacing={'xs'} position="center">
            <EtroButton
              component={Link}
              to="/"
              onClick={resetErrorBoundary}
              leftIcon={<IconHome />}
            >
              {'Home'}
            </EtroButton>
            <EtroButton
              component={Link}
              to="#"
              onClick={resetErrorBoundary}
              leftIcon={<IconRefresh />}
            >
              {'Refresh'}
            </EtroButton>
          </EtroGroup>
        </EtroAlert>
      </EtroStack>
    </EtroContainer>
  );
};
