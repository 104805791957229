import React from 'react';
import {Button, Intent, Tooltip, Position} from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';

export const SubmitWithError = props => {
  const {errorContent, hasErrors, icon, text, loading, className} = props;
  const {t} = useTranslation();

  return (
    <Tooltip
      content={<span>{errorContent}</span>}
      isOpen={hasErrors}
      disabled={!hasErrors}
      intent={Intent.DANGER}
      position={Position.RIGHT}
      target={
        <Button
          className={className ? className : 'etro-form-submit'}
          icon={icon}
          text={text ? text : t('submit')}
          type="submit"
          loading={loading}
          intent={Intent.PRIMARY}
        />
      }
    />
  );
};
