import React from 'react';
import {User} from '~/types';
import {NitroPayButton} from '~/components/NitroPayButton';
import {
  EtroGroup,
  EtroStack,
  EtroText,
  EtroTitle,
  RemoteIcon
} from '~/components';
import {IconCircleCheck, IconAlertCircle} from '@tabler/icons-react';
import {useMantineTheme} from '@mantine/core';
import {useTranslation} from 'react-i18next';

export interface AccountSubscriptionCardProps {
  subscription: User['subscription'];
}

const SubExpiration: React.FC<AccountSubscriptionCardProps> = ({
  subscription
}) => {
  const {i18n, t} = useTranslation();
  const {colors} = useMantineTheme();

  if (!subscription) {
    return null;
  }

  const {isActive} = subscription;

  return (
    <>
      <EtroText size="sm" mb={'xs'}>
        {t('expiration')}
      </EtroText>
      <EtroGroup spacing={'xs'} pb="sm">
        {isActive ? (
          <IconCircleCheck color={colors.green[9]} />
        ) : (
          <IconAlertCircle color={colors.red[9]} />
        )}
        <EtroText size="xs">
          {new Date(subscription.expiration).toLocaleTimeString(
            [i18n.resolvedLanguage],
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit'
            }
          )}
        </EtroText>
      </EtroGroup>
    </>
  );
};

export const AccountSubscriptionCard: React.FC<AccountSubscriptionCardProps> = ({
  subscription
}) => {
  const {t} = useTranslation();

  return (
    <div>
      <EtroTitle order={3} mb="sm" align="center">
        {t('subscription')}
      </EtroTitle>
      {!subscription || !subscription.isActive ? (
        <EtroStack spacing={0} pb="sm">
          <SubExpiration subscription={subscription} />
          <EtroText>{t('sub msg')}</EtroText>
        </EtroStack>
      ) : (
        <>
          <EtroStack spacing={0} align="center" pb="sm">
            <RemoteIcon
              iconPath={subscription.tier.iconPath}
              width={50}
              alt="subscription tier icon"
            />
            <EtroText>{subscription.tier.name}</EtroText>
          </EtroStack>
          <SubExpiration subscription={subscription} />
        </>
      )}
      <NitroPayButton
        outlined
        text={
          subscription?.isActive
            ? t('manage subscription')
            : t('add subscription')
        }
        disableTooltip
        className="etro-button-height"
      />
    </div>
  );
};
