import {etroApi} from '.';
import {DataCenter} from '~/types';

const DATACENTER_BASE = 'datacenter/';

const dataCentersApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readDataCenter: query<DataCenter, number>({
      query: id => `${DATACENTER_BASE}${id}`
    }),
    listDataCenters: query<DataCenter[], void>({
      query: () => DATACENTER_BASE
    })
  })
});

export const {useReadDataCenterQuery, useListDataCentersQuery} = dataCentersApi;
