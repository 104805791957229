import React, {useCallback, useEffect, useMemo} from 'react';
import {useLocalStorage, usePrevious, useToggle} from 'react-use';
import {Intent} from '@blueprintjs/core';
import {useLocation} from 'react-router';
import {ConfirmDialog} from '~/components/ConfirmDialog/ConfirmDialog';
import {getUpdateList} from '../Updates/Updates';
import {UpdatesList} from '../Updates/UpdatesList';
import {KofiButton} from '~/components/KofiButton';
import {NitroPayButton} from '~/components/NitroPayButton';
import {LinkButton} from '~/components/LinkButton/LinkButton';
import {EtroGroup} from '~/components';
import {AdBlockDetector} from '~/components/AdBlockDetector';
import {IconGitMerge} from '@tabler/icons-react';
const currentVersion = require('~/constants/version');

export const NewVersionDialog = () => {
  const [
      lastVersionViewed,
      setLastVersionViewed
    ] = useLocalStorage('lastVersionViewed', undefined, {raw: true}),
    // Have to have component state due to private browsers
    [dialogVisible, setDialogVisible] = useToggle(
      lastVersionViewed !== currentVersion
    ),
    onDialogClose = useCallback(() => {
      setLastVersionViewed(currentVersion);
      setDialogVisible(false);
    }, [setLastVersionViewed, setDialogVisible]);

  const location = useLocation();
  const prevLocation = usePrevious(location);
  const update = useMemo(() => getUpdateList()[0], []);

  useEffect(() => {
    // Automatically close if location changes from inline link inside updates
    if (prevLocation && location.pathname !== prevLocation.pathname) {
      onDialogClose();
    }
  }, [location.pathname, onDialogClose, prevLocation]);

  return (
    <ConfirmDialog
      confirmIcon={'tick'}
      confirmIntent={Intent.PRIMARY}
      disableCancel={true}
      icon={<IconGitMerge />}
      isOpen={dialogVisible}
      onConfirm={onDialogClose}
      title={`${currentVersion} Changelog`}
      toggle={onDialogClose}
      dialogStyle={{width: 'unset'}}
      confirmContent={
        <div className="etro-new-version-wrapper">
          <AdBlockDetector />
          {update.announcement && update.announcement}
          <UpdatesList updates={update.updates} />
          <EtroGroup position="center">
            <NitroPayButton
              showText
              outlined
              disableTooltip
              className="etro-button-height"
            />
            <KofiButton showText outlined className="etro-button-height" />
            <LinkButton
              className="etro-button-hover-primary-fill"
              to="/changelog"
              outlined
              icon={<IconGitMerge />}
              text="View Changelog"
              onClick={onDialogClose}
            />
          </EtroGroup>
        </div>
      }
    />
  );
};
