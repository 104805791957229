import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
  grid: {
    display: 'grid',
    gridGap: theme.spacing.xl,
    justifyContent: 'center',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, 450px)',
    '@media(min-width: 2560px)': {
      gridTemplateColumns: 'repeat(4, 450px)'
    },
    [theme.fn.smallerThan('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 450px))',
      '.etro-materia-item': {
        fontSize: '11px'
      },
      '.etro-gearset-card-stats': {
        fontSize: '11px'
      }
    }
  }
}));
