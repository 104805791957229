import React from 'react';
import {EtroGroup, EtroStack, EtroTitle} from '~/components';
import {RelicList} from './RelicList';
import {SaveWarning} from '~/components/AppToaster/SaveWarning';
import {NitroPayAd} from '~/components/NitroPayAd';
import {useMobileMediaQuery} from '~/hooks';
import {useTranslation} from 'react-i18next';

export const Inventory: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = useMobileMediaQuery();

  return (
    <>
      <EtroGroup
        align={'flex-start'}
        noWrap
        position="center"
        spacing={isMobile ? 0 : 'xl'}
      >
        <EtroStack align={'center'} spacing={'xl'} sx={{flex: 1}}>
          <NitroPayAd placementId="etro-ad-inventory-top" type="bannerLarge" />
          <EtroTitle color="etro" order={1} align="center">
            {t('relic')}
          </EtroTitle>
          <RelicList />
          <NitroPayAd
            placementId="etro-ad-inventory-bottom"
            type="banner"
            config={{renderVisibleOnly: true}}
          />
        </EtroStack>
        <NitroPayAd placementId="etro-ad-inventory-right" type="anchor" />
      </EtroGroup>
      <NitroPayAd placementId="etro-ad-inventory-floating" type="floating" />
      <SaveWarning
        warningPath={'/inventory'}
        message={'Please login to enable creating relics.'}
      />
    </>
  );
};
