import React from 'react';
import {Image, ImageProps} from '@mantine/core';

export interface EtroImageProps extends ImageProps {}

const defaultProps: Partial<EtroImageProps> = {};

export const EtroImage: React.FC<EtroImageProps> = props => {
  return <Image {...defaultProps} {...props} />;
};
