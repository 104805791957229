/* eslint-disable no-unused-vars */
// Enums cannot be exported from .d.ts files, so they live here and get imported separately.

export enum JobAbbrevEnum {
  ALC = 'ALC',
  ARM = 'ARM',
  AST = 'AST',
  BLM = 'BLM',
  BLU = 'BLU',
  BRD = 'BRD',
  BSM = 'BSM',
  BTN = 'BTN',
  CRP = 'CRP',
  CUL = 'CUL',
  DNC = 'DNC',
  DRG = 'DRG',
  DRK = 'DRK',
  FSH = 'FSH',
  GNB = 'GNB',
  GSM = 'GSM',
  LTW = 'LTW',
  MCH = 'MCH',
  MIN = 'MIN',
  MNK = 'MNK',
  NIN = 'NIN',
  PCT = 'PCT',
  PLD = 'PLD',
  RDM = 'RDM',
  RPR = 'RPR',
  SAM = 'SAM',
  SCH = 'SCH',
  SGE = 'SGE',
  SMN = 'SMN',
  VPR = 'VPR',
  WAR = 'WAR',
  WHM = 'WHM',
  WVR = 'WVR'
}

export enum SortingAlgorithms {
  stringCaseInsensitive = 'stringCaseInsensitive',
  stringCaseSensitive = 'stringCaseSensitive',
  number = 'number'
}

export enum NumberOperators {
  equal = '=',
  notEqual = '!=',
  gt = '>',
  gte = '>=',
  lt = '<',
  lte = '<=',
  empty = 'empty',
  notEmpty = 'notEmpty'
}

export enum StringOperators {
  contains = 'contains',
  equals = 'equals',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  empty = 'empty',
  notEmpty = 'notEmpty'
}

export enum SelectOperators {
  is = 'is',
  isNot = 'isNot',
  empty = 'empty',
  notEmpty = 'notEmpty'
}

export enum DNDTypes {
  action = 'action'
}
