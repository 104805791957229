import React from 'react';
import {Card} from '@blueprintjs/core';
import {EtroLogo} from '~/components';

export const EmbedNotFound: React.FC<{entity?: string}> = ({
  entity = 'Page'
}) => {
  return (
    <Card className="etro-embed-card-center">
      <EtroLogo showText openNewTab svgProps={{style: {maxWidth: '250px'}}} />
      <h2>{`${entity} Not Found`}</h2>
      <p>{`The ${entity.toLowerCase()} you're looking for does not exist.`}</p>
    </Card>
  );
};
