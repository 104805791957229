import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {RemoteIcon} from '~/components';

export const DateCellRenderer: FC<{value: string}> = ({value}) => {
  const {i18n} = useTranslation();

  return (
    <>
      {new Date(value).toLocaleTimeString([i18n.resolvedLanguage], {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      })}
    </>
  );
};

export const IconCellRenderer: FC<{
  height?: string;
  iconPath?: string;
  justify?: string;
  width?: string | number;
  value?: string | number;
}> = ({height = '28px', iconPath, justify, width = '28px', value}) => {
  if (!value && !iconPath) {
    return null;
  }

  return (
    <div className={`job-icon-cell${justify ? justify : ''}`}>
      <RemoteIcon
        alt={`${value}Icon`}
        height={height}
        width={width}
        iconPath={iconPath}
      />
      <span>{value}</span>
    </div>
  );
};
