import React from 'react';
import {Modal, ModalProps} from '@mantine/core';
import {EtroModalBody} from './EtroModalBody';
import {EtroModalCloseButton} from './EtroModalCloseButton';
import {EtroModalContent} from './EtroModalContent';
import {EtroModalHeader} from './EtroModalHeader';
import {EtroModalOverlay} from './EtroModalOverlay';
import {EtroModalRoot} from './EtroModalRoot';
import {EtroModalTitle} from './EtroModalTitle';

export interface EtroModalProps extends ModalProps {}
export type EtroModalComponents = {
  Body: typeof EtroModalBody;
  CloseButton: typeof EtroModalCloseButton;
  Content: typeof EtroModalContent;
  Header: typeof EtroModalHeader;
  Overlay: typeof EtroModalOverlay;
  Root: typeof EtroModalRoot;
  Title: typeof EtroModalTitle;
};

const defaultProps: Partial<EtroModalProps> = {};

export const EtroModal: React.FC<EtroModalProps> &
  EtroModalComponents = props => {
  return <Modal {...defaultProps} {...props} />;
};

EtroModal.Body = EtroModalBody;
EtroModal.CloseButton = EtroModalCloseButton;
EtroModal.Content = EtroModalContent;
EtroModal.Header = EtroModalHeader;
EtroModal.Overlay = EtroModalOverlay;
EtroModal.Root = EtroModalRoot;
EtroModal.Title = EtroModalTitle;
