import React, {useCallback, useMemo} from 'react';
import {RichTextEditor, RichTextEditorProps} from '@mantine/tiptap';
import {useEditor, Content, EditorEvents} from '@tiptap/react';
import {Color} from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import SubScript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import {useMantineTheme} from '@mantine/core';

export interface EtroRTEProps
  extends Omit<RichTextEditorProps, 'children' | 'editor' | 'onChange'> {
  onChange: (value: Content) => void;
  readOnly?: boolean;
  value: Content;
}

const defaultProps: Partial<EtroRTEProps> = {};

export const EtroRTE: React.FC<EtroRTEProps> = ({
  onChange,
  placeholder,
  readOnly,
  value,
  ...rest
}) => {
  const {colors} = useMantineTheme();
  const colorChoices = useMemo(() => {
    return Object.keys(colors)
      .filter(k => !['etroDark', 'etroLight'].includes(k))
      .map(k => colors[k][5]);
  }, [colors]);

  const onUpdate = useCallback(
    (props: EditorEvents['update']) => {
      const {editor} = props;
      const nextValue = editor.getHTML();

      // Avoid saving empty content
      onChange(nextValue === '<p></p>' ? null : nextValue);
    },
    [onChange]
  );
  const editor = useEditor(
    {
      content: value,
      editable: !readOnly,
      extensions: [
        Color,
        Highlight,
        Placeholder.configure({placeholder, showOnlyWhenEditable: false}),
        StarterKit,
        SubScript,
        Superscript,
        TextAlign.configure({types: ['heading', 'paragraph']}),
        TextStyle,
        Underline
      ],
      onUpdate
    },
    // only want value in dep array when readOnly otherwise editor will rerender onChange and lose focus
    [onUpdate, readOnly, readOnly && value]
  );

  return (
    <RichTextEditor {...defaultProps} {...rest} editor={editor}>
      {!readOnly && (
        <RichTextEditor.Toolbar sticky>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.ColorPicker colors={colorChoices} />
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.Superscript />
            <RichTextEditor.Subscript />
            <RichTextEditor.Highlight />
            <RichTextEditor.ClearFormatting />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Code />
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
            <RichTextEditor.H5 />
            <RichTextEditor.H6 />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  );
};
