import React, {useEffect} from 'react';
import {MantineProvider} from '@mantine/core';
import {defaultTheme} from '~/styles/theme';
import {useSelector} from '~/hooks';

export const ThemeProvider: React.FC = ({children}) => {
  const {darkMode} = useSelector(({user}) => user);

  useEffect(() => {
    document.body.className = darkMode ? 'bp3-dark' : 'bp3-body';
  }, [darkMode]);

  return (
    <MantineProvider
      theme={{
        colorScheme: darkMode === undefined || darkMode ? 'dark' : 'light',
        ...defaultTheme
      }}
    >
      {children}
    </MantineProvider>
  );
};
