import {etroApi} from '.';
import {OrderKey, PVPMatch, PVPMatchFilter} from '~/types';
import {deleteFromList} from './utils';

const PVPMATCH_BASE = 'pvpmatch/';
const sortKey = ['matchTimestamp'];
const orderKey: OrderKey = ['desc'];

const pvpMatchesApi = etroApi.injectEndpoints({
  endpoints: ({query, mutation}) => ({
    readPVPMatch: query<PVPMatch, string>({
      query: id => `${PVPMATCH_BASE}${id}`
    }),
    listPVPMatches: query<PVPMatch[], PVPMatchFilter | undefined>({
      query: params => {
        return {url: PVPMATCH_BASE, params};
      }
    }),
    deletePVPMatch: mutation<void, string>({
      query: id => {
        return {url: `${PVPMATCH_BASE}${id}`, method: 'DELETE'};
      },
      onQueryStarted: async (id, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;

          dispatch(
            pvpMatchesApi.util.updateQueryData(
              'listPVPMatches',
              undefined,
              draft => deleteFromList({draft, sortKey, id, orderKey})
            )
          );
        } catch {}
      }
    })
  })
});

export const {
  useDeletePVPMatchMutation,
  useLazyListPVPMatchesQuery,
  useLazyReadPVPMatchQuery,
  useListPVPMatchesQuery,
  useReadPVPMatchQuery
} = pvpMatchesApi;
