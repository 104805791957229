import {createReducers} from 'redux-arc';
import {orderBy} from 'lodash';
import {types} from '../actions/food';
import * as utils from './utils';
import {ORDER_KEY} from '~/constants';

export const INITIAL_STATE = {
  listResult: [],
  listIsLoading: false,
  listError: null,
  readResult: null,
  readIsLoading: false,
  readError: null,
  itemLevelRange: []
};

export const onFoodListResponse = (state, action) =>
  action.error
    ? {
        ...state,
        listIsLoading: false,
        listError:
          (action.payload.response && action.payload.response.data) || true,
        listResult: []
      }
    : {
        ...state,
        listIsLoading: false,
        listResult: orderBy(action.payload.data, ...ORDER_KEY),
        itemLevelRange: [
          action.payload.config.params.minItemLevel,
          action.payload.config.params.maxItemLevel
        ]
      };

const HANDLERS = {
  [types.READ.REQUEST]: utils.onReadRequest,
  [types.READ.RESPONSE]: utils.onReadResponse,
  [types.LIST.REQUEST]: utils.onListRequest,
  [types.LIST.RESPONSE]: onFoodListResponse
};

export const food = createReducers(INITIAL_STATE, HANDLERS);
