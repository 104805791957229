/*
  A button which is connected to the Router allowing linking.
  Props will be passed to Blueprint button which are needed
  Example:
  <LinkButton to="/test" text="Test" icon="home" />
  https://stackoverflow.com/questions/35963070/react-router-how-to-disable-a-link-if-its-active
*/

import React, {useMemo} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {startsWith} from 'lodash';
import {Button} from '@blueprintjs/core';

const LinkButtonComponent = props => {
  // Have to destructure staticContext or get React error.
  const {
      to,
      history,
      staticContext,
      overrideActive = false,
      openNewTab = false,
      ...rest
    } = props,
    isActive =
      to === history.location.pathname ||
      startsWith(history.location.pathname, to);

  const linkProps = useMemo(() => {
    if (openNewTab) {
      return {target: '_blank', rel: 'noopener noreferrer'};
    }

    return {};
  }, [openNewTab]);

  return (
    <>
      <Link to={isActive && !overrideActive ? '#' : to} {...linkProps}>
        <Button {...rest} />
      </Link>
    </>
  );
};

export const LinkButton = withRouter(LinkButtonComponent);
