import React from 'react';
import {Badge, BadgeProps, createPolymorphicComponent} from '@mantine/core';

export interface EtroBadgeProps extends BadgeProps {}

const defaultProps: Partial<EtroBadgeProps> = {};

const _EtroBadge: React.FC<EtroBadgeProps> = props => {
  return <Badge {...defaultProps} {...props} />;
};

export const EtroBadge = createPolymorphicComponent<'div', EtroBadgeProps>(
  _EtroBadge
);
