import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {Button, Intent, FormGroup, Callout} from '@blueprintjs/core';
import {useToggle} from 'react-use';
import {Formik} from 'formik';
import {withRouter} from 'react-router-dom';
import {ConfirmDialog} from '~/components/ConfirmDialog/ConfirmDialog';
import {InputWithError} from '~/components/Form/InputWithError';
import {onSuccess, onFailure} from '~/components/AppToaster/AppToaster';
import {EtroCheckbox} from '~/components/Form/EtroCheckbox';
import {EtroRadioGroup} from '~/components/Form/EtroRadioGroup';
import {
  importAriyalaSchema,
  importLodestoneSchema,
  importXivgearSchema
} from '~/utils/formValidationSchema';
import {gearsetsCreators} from '~/actions/gearsets';
import {useMobileMediaQuery} from '~/hooks';
import {useTranslation} from 'react-i18next';
import {EtroTextInput} from '~/components';

const ICON = 'import';
const IMPORT_TYPES = {
  lodestone: 'lodestone',
  ariyala: 'ariyala',
  xivgear: 'xivgear'
};
const radioOptions = [
  {label: 'XivGear', value: IMPORT_TYPES.xivgear},
  {label: 'Lodestone', value: IMPORT_TYPES.lodestone},
  {label: 'Ariyala', value: IMPORT_TYPES.ariyala}
];
const getValidationSchema = importType => {
  switch (importType) {
    case IMPORT_TYPES.ariyala:
      return importAriyalaSchema;
    case IMPORT_TYPES.lodestone:
      return importLodestoneSchema;
    case IMPORT_TYPES.xivgear:
    default:
      return importXivgearSchema;
  }
};
const DEFAULT_ERROR_MESSAGE = 'Error importing, please try again.';

export const GearsetImportDialogComponent = ({
  ariyalaImport,
  ariyalaImportError,
  history,
  importIsLoading,
  lodestoneImport,
  lodestoneImportError,
  resetImportError,
  tableMode,
  toggle: propsToggle,
  visible: propsVisible,
  xivgearImport,
  xivgearImportError
}) => {
  const [importType, setImportType] = useState(IMPORT_TYPES.xivgear),
    {t} = useTranslation(),
    [xivAPILoading, toggleXIVAPILoading] = useToggle(false),
    [dialogVisible, toggleDialogVisible] = useToggle(false),
    [openAfterImport, toggleOpenAfterImport] = useToggle(tableMode ?? false),
    isMobile = useMobileMediaQuery(),
    onImportSuccess = useCallback(
      (name, gearsetId) => {
        if (propsToggle) {
          propsToggle();
        } else {
          toggleDialogVisible();
        }
        onSuccess(`${name} imported.`);
        if (!tableMode || openAfterImport) {
          history.push(`/gearset/${gearsetId}`);
        }
      },
      [history, openAfterImport, propsToggle, tableMode, toggleDialogVisible]
    ),
    onXIVAPIError = useCallback(lodestoneData => {
      if (lodestoneData?.Error) {
        onFailure(lodestoneData?.Message ?? DEFAULT_ERROR_MESSAGE);
      }
    }, []),
    handleImportError = useCallback(
      error => {
        onFailure(error?.length ? error : DEFAULT_ERROR_MESSAGE);
        resetImportError();
      },
      [resetImportError]
    );

  // onSubmit will not display the proper error message.
  // Its always behind state updates by one.
  useEffect(() => {
    if (ariyalaImportError) {
      handleImportError(ariyalaImportError);
    } else if (lodestoneImportError) {
      handleImportError(lodestoneImportError);
    } else if (xivgearImportError) {
      handleImportError(xivgearImportError);
    }
  }, [
    ariyalaImportError,
    lodestoneImportError,
    xivgearImportError,
    handleImportError
  ]);

  return (
    <>
      {!propsToggle && (
        <FormGroup>
          <Button
            style={{whiteSpace: 'nowrap'}}
            icon={ICON}
            onClick={propsToggle ?? toggleDialogVisible}
            outlined={true}
            text={
              isMobile && tableMode
                ? undefined
                : `${t('import')}${tableMode ? '' : ' ' + t('gearset')}`
            }
            className={`etro-button-hover-primary-fill${
              tableMode ? ' etro-button-margin' : ' etro-full-width'
            }`}
          />
        </FormGroup>
      )}
      {(propsVisible || dialogVisible) && (
        <Formik
          initialValues={{
            name: '',
            ariyalaID: '',
            lodestoneID: '',
            xivgearURL: ''
          }}
          validationSchema={getValidationSchema(importType)}
          onSubmit={async values => {
            const {name, ariyalaID, lodestoneID, xivgearURL} = values;

            if (importType === IMPORT_TYPES.lodestone) {
              toggleXIVAPILoading();
              const lodestoneData = await fetch(
                `https://xivapi.com/character/${lodestoneID}`
              )
                .then(r => {
                  return r.json();
                })
                // Leave catch for safety
                .catch(error => {});

              toggleXIVAPILoading();
              if (lodestoneData?.Character) {
                const {Tribe, GearSet} = lodestoneData.Character;

                lodestoneImport({name, gearset: {Tribe, GearSet}}).then(r => {
                  if (r?.status === 201) {
                    onImportSuccess(name, r.data.id);
                  }
                });
              } else {
                onXIVAPIError(lodestoneData);
              }
            } else if (importType === IMPORT_TYPES.ariyala) {
              ariyalaImport({name, ariyalaID}).then(r => {
                if (r?.status === 201) {
                  onImportSuccess(name, r.data.id);
                }
              });
            } else if (importType === IMPORT_TYPES.xivgear) {
              xivgearImport({xivgearURL}).then(r => {
                if (r?.status === 201) {
                  const {name, id} = r.data[0];

                  onImportSuccess(name, id);
                }
              });
            }
          }}
        >
          {({errors, handleChange, handleSubmit, touched, values}) => (
            <ConfirmDialog
              confirmIcon={ICON}
              confirmIntent={Intent.PRIMARY}
              confirmText="Import"
              icon={ICON}
              isOpen={propsVisible ?? dialogVisible}
              loading={importIsLoading || xivAPILoading}
              onConfirm={handleSubmit}
              title="Import Gearset"
              toggle={propsToggle ?? toggleDialogVisible}
              disableConfirm={importType === IMPORT_TYPES.lodestone}
              confirmContent={
                <form onSubmit={handleSubmit} noValidate>
                  {!tableMode && importType !== IMPORT_TYPES.lodestone && (
                    <Callout
                      className="etro-dialog-callout"
                      intent={Intent.WARNING}
                    >
                      Importing a gearset will create and open a new gearset.
                      Save any changes to the current gearset if you want to
                      keep them.
                    </Callout>
                  )}
                  {importType === IMPORT_TYPES.lodestone && (
                    <Callout
                      className="etro-dialog-callout"
                      intent={Intent.DANGER}
                    >
                      Lodestone import has been temporarily disabled due to
                      server instability.
                    </Callout>
                  )}
                  <div className="etro-form-card">
                    <EtroRadioGroup
                      label="Import From"
                      options={radioOptions}
                      onChange={e => {
                        setImportType(e?.target?.value);
                      }}
                      selectedValue={importType}
                      requireValue={true}
                    />
                    {importType !== IMPORT_TYPES.xivgear && (
                      <InputWithError
                        id="name"
                        value={values.name}
                        errorContent={errors.name}
                        hasErrors={errors.name && touched.name}
                        label="Gearset Name"
                        handleChange={handleChange}
                      />
                    )}
                    {importType === IMPORT_TYPES.lodestone && (
                      <InputWithError
                        id="lodestoneID"
                        value={values.lodestoneID}
                        errorContent={errors.lodestoneID}
                        hasErrors={errors.lodestoneID && touched.lodestoneID}
                        label="Lodestone Character ID"
                        handleChange={handleChange}
                        leftElement={
                          <span className="etro-input-left-element">
                            {'na.finalfantasyxiv.com/lodestone/character/'}
                          </span>
                        }
                      />
                    )}
                    {importType === IMPORT_TYPES.ariyala && (
                      <InputWithError
                        id="ariyalaID"
                        value={values.ariyalaID}
                        errorContent={errors.ariyalaID}
                        hasErrors={errors.ariyalaID && touched.ariyalaID}
                        label="Ariyala Gearset ID"
                        handleChange={handleChange}
                        leftElement={
                          <span className="etro-input-left-element">
                            {'ffxiv.ariyala.com/'}
                          </span>
                        }
                      />
                    )}
                    {importType === IMPORT_TYPES.xivgear && (
                      <EtroTextInput
                        clearable
                        description="Generated URL for a full sheet (all sets imported) or individual set"
                        error={touched.xivgearURL && errors.xivgearURL}
                        id="xivgearURL"
                        label="XivGear Export URL"
                        onChange={handleChange}
                        pb="xs"
                        value={values.xivgearURL}
                      />
                    )}
                    {tableMode && (
                      <EtroCheckbox
                        label={'Open After Import'}
                        checked={openAfterImport}
                        onChange={toggleOpenAfterImport}
                      />
                    )}
                  </div>
                </form>
              }
            />
          )}
        </Formik>
      )}
    </>
  );
};

const actions = {
  ariyalaImport: gearsetsCreators.ariyalaImport,
  lodestoneImport: gearsetsCreators.lodestoneImport,
  xivgearImport: gearsetsCreators.xivgearImport,
  resetImportError: gearsetsCreators.resetImportError
};

const mapStateToProps = ({gearsets}) => {
  return {
    importIsLoading:
      gearsets.ariyalaImportIsLoading ||
      gearsets.lodestoneImportIsLoading ||
      gearsets.xivgearImportIsLoading,
    ariyalaImportError: gearsets.ariyalaImportError,
    lodestoneImportError: gearsets.lodestoneImportError,
    xivgearImportError: gearsets.xivgearImportError
  };
};

export const GearsetImportDialog = withRouter(
  connect(mapStateToProps, actions)(GearsetImportDialogComponent)
);
