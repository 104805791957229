import React, {FC} from 'react';
import {EtroText, EtroBox} from '~/components';
import {values} from 'lodash';
import {handleRingId} from '~/views/Gearset/GearsetHelpers';
import {paramNames} from '~/constants';
import {EmbedGearsetProps, Item} from '../types';
import {RemoteIcon} from '~/components/EtroIcon';

interface MateriaListProps
  extends Pick<EmbedGearsetProps, 'gearset' | 'materiaMap'> {
  row: Item;
}

export const MateriaList: FC<MateriaListProps> = ({
  row,
  gearset,
  materiaMap
}) => {
  if (
    'itemLevelSync' in row &&
    row.itemLevelSync &&
    row.itemLevelSync < row.itemLevel
  ) {
    return null;
  }

  return (
    <>
      {values(gearset?.materia?.[handleRingId(row.id, row.slotKey)]).map(
        (materiaId, i) => {
          const materia = materiaMap[materiaId];

          if (!materia) {
            return null;
          }

          return (
            <EtroBox
              key={`equipment${row.id}-${materiaId}-${i}`}
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <RemoteIcon
                iconPath={materia.iconPath}
                height={'20px'}
                alt={`${materia.name}Icon`}
              />
              <EtroText
                size="xs"
                color="dimmed"
                sx={{
                  padding: '4px 12px 4px 4px',
                  whiteSpace: 'nowrap'
                }}
              >{`${paramNames[materia.param as keyof typeof paramNames]} +${
                materia.paramValue
              }`}</EtroText>
            </EtroBox>
          );
        }
      )}
    </>
  );
};
