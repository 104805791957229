/*
  Reducer for authentication API
*/
import axios from 'axios';
import {createReducers} from 'redux-arc';
import {safeGetItem, safeSetItem, safeRemoveItem} from '~/utils/localStorage';
import {types} from '../actions/auth';
import {isEmpty} from 'lodash';

const authToken = 'token';

export const INITIAL_STATE = {
  isAuthenticated: false, // Has the user been authenticated by the backend
  user: null, // Object containing user information: username, email, etc
  token: null, // JWT token
  loginIsLoading: false,
  loginError: null,
  discordLoginIsLoading: false,
  discordLoginError: null,
  logoutIsLoading: false,
  logoutError: null,
  userIsLoading: false,
  userError: null,
  userUpdateIsLoading: false,
  userUpdateError: null,
  registrationIsLoading: false,
  registrationError: null,
  passwordChangeIsLoading: false,
  passwordChangeError: null,
  passwordResetIsLoading: false,
  passwordResetError: null,
  sendPasswordResetEmailIsLoading: false,
  sendPasswordResetEmailError: null,
  sendVerificationEmailIsLoading: false,
  sendVerificationEmailError: null,
  verifyEmailIsLoading: false,
  verifyEmailError: null
};

const initNitroPaySponsor = token => {
  if (token) {
    // This returns a promise if needed
    window['nitroSponsor'].init({
      token,
      successUrl: `${process.env.REACT_APP_NITROPAY_CB}true`,
      cancelUrl: `${process.env.REACT_APP_NITROPAY_CB}false`,
      product: process.env.REACT_APP_NITROPAY_PRODUCT
    });
  }
};

const mapAuthErrors = errors => {
  // Stop Django view errors from being mapped to Tooltips
  if (
    isEmpty(errors) ||
    errors.headers['content-type'] === 'text/plain; charset=utf-8' ||
    errors.headers['content-type'] === 'text/html'
  ) {
    return null;
  }

  return Object.keys(errors.data).map(field => {
    return {field, message: errors.data[field]};
  });
};

const removeToken = () => {
  safeRemoveItem(authToken);
  delete axios.defaults.headers.common['Authorization'];
};

const onReset = () => {
  removeToken();
  return INITIAL_STATE;
};

const onLoginRequest = state => {
  removeToken();

  return {
    ...INITIAL_STATE,
    loginIsLoading: true,
    loginError: null
  };
};

const onLoginResponse = (state, action) => {
  if (action.error) {
    removeToken();

    return {
      ...state,
      loginIsLoading: false,
      loginError: mapAuthErrors(action.payload.response)
    };
  }

  safeSetItem(authToken, action.payload.data.access_token);
  initNitroPaySponsor(action.payload.data.user.nitroPayToken);

  return {
    ...state,
    loginIsLoading: false,
    user: {...action.payload.data.user},
    isAuthenticated: true,
    token: action.payload.data.access_token
  };
};

const onDiscordLoginRequest = state => {
  removeToken();

  return {
    ...INITIAL_STATE,
    discordLoginIsLoading: true,
    discordLoginError: null
  };
};

const onDiscordLoginResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      discordLoginIsLoading: false,
      discordLoginError: mapAuthErrors(action.payload.response)
    };
  }

  safeSetItem(authToken, action.payload.data.access_token);
  initNitroPaySponsor(action.payload.data.user.nitroPayToken);

  return {
    ...state,
    discordLoginIsLoading: false,
    user: {...action.payload.data.user},
    isAuthenticated: true,
    token: action.payload.data.access_token
  };
};

const onLogoutRequest = state => ({
  ...state,
  logoutIsLoading: true,
  logoutError: null
});

const onLogoutResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      logoutIsLoading: false,
      logoutError: mapAuthErrors(action.payload.response)
    };
  }

  removeToken();
  safeRemoveItem('nitroSponsorCache');

  return {
    ...INITIAL_STATE,
    logoutIsLoading: false
  };
};

const onUserRequest = state => ({
  ...state,
  userIsLoading: true,
  userError: null
});

const onUserResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      userIsLoading: false,
      userError: mapAuthErrors(action.payload.response)
    };
  }

  const JWT = safeGetItem(authToken);
  initNitroPaySponsor(action.payload.data.nitroPayToken);

  return {
    ...state,
    userIsLoading: false,
    user: {...action.payload.data},
    isAuthenticated: true,
    token: JWT
  };
};

const onUserUpdateRequest = state => ({
  ...state,
  userUpdateIsLoading: true,
  userUpdateError: null
});

const onUserUpdateResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      userUpdateIsLoading: false,
      userUpdateError: action.payload.response?.data
    };
  }

  return {
    ...state,
    userUpdateIsLoading: false,
    user: {...action.payload.data}
  };
};

const onRegistrationRequest = state => ({
  ...state,
  registrationIsLoading: true,
  registrationError: null
});

const onRegistrationResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      registrationIsLoading: false,
      registrationError: mapAuthErrors(action.payload.response)
    };
  }

  return {
    ...state,
    registrationIsLoading: false
  };
};

const onVerifyEmailRequest = state => ({
  ...state,
  verifyEmailIsLoading: true,
  verifyEmailError: null
});

const onVerifyEmailResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      verifyEmailIsLoading: false,
      verifyEmailError: mapAuthErrors(action.payload.response)
    };
  }

  return {
    ...state,
    verifyEmailIsLoading: false
  };
};

const onPasswordChangeRequest = state => ({
  ...state,
  passwordChangeIsLoading: true,
  passwordChangeError: null
});

const onPasswordChangeResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      passwordChangeIsLoading: false,
      passwordChangeError: mapAuthErrors(action.payload.response)
    };
  }

  removeToken();
  return {
    ...INITIAL_STATE,
    passwordChangeIsLoading: false
  };
};

const onPasswordResetRequest = state => ({
  ...state,
  passwordResetIsLoading: true,
  passwordResetError: null
});

const onPasswordResetResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      passwordResetIsLoading: false,
      passwordResetError: mapAuthErrors(action.payload.response)
    };
  }

  return {
    ...INITIAL_STATE,
    passwordResetIsLoading: false
  };
};

const onSendPasswordResetEmailRequest = state => ({
  ...state,
  sendPasswordResetEmailIsLoading: true,
  sendPasswordResetEmailError: null
});

const onSendPasswordResetEmailResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      sendPasswordResetEmailIsLoading: false,
      sendPasswordResetEmailError: mapAuthErrors(action.payload.response)
    };
  }

  return {
    ...INITIAL_STATE,
    sendPasswordResetEmailIsLoading: false
  };
};

const onSendVerificationEmailRequest = state => ({
  ...state,
  sendVerificationEmailIsLoading: true,
  sendVerificationEmailError: null,
  loginError: null
});

const onSendVerificationEmailResponse = (state, action) => {
  if (action.error) {
    removeToken();
    return {
      ...state,
      sendVerificationEmailIsLoading: false,
      sendVerificationEmailError: mapAuthErrors(action.payload.response)
    };
  }

  return {
    ...INITIAL_STATE,
    sendVerificationEmailIsLoading: false
  };
};

const HANDLERS = {
  [types.RESET]: onReset,
  [types.LOGIN.REQUEST]: onLoginRequest,
  [types.LOGIN.RESPONSE]: onLoginResponse,
  [types.DISCORD_LOGIN.REQUEST]: onDiscordLoginRequest,
  [types.DISCORD_LOGIN.RESPONSE]: onDiscordLoginResponse,
  [types.LOGOUT.REQUEST]: onLogoutRequest,
  [types.LOGOUT.RESPONSE]: onLogoutResponse,
  [types.USER.REQUEST]: onUserRequest,
  [types.USER.RESPONSE]: onUserResponse,
  [types.USER_UPDATE.REQUEST]: onUserUpdateRequest,
  [types.USER_UPDATE.RESPONSE]: onUserUpdateResponse,
  [types.REGISTRATION.REQUEST]: onRegistrationRequest,
  [types.REGISTRATION.RESPONSE]: onRegistrationResponse,
  [types.VERIFY_EMAIL.REQUEST]: onVerifyEmailRequest,
  [types.VERIFY_EMAIL.RESPONSE]: onVerifyEmailResponse,
  [types.PASSWORD_CHANGE.REQUEST]: onPasswordChangeRequest,
  [types.PASSWORD_CHANGE.RESPONSE]: onPasswordChangeResponse,
  [types.PASSWORD_RESET.REQUEST]: onPasswordResetRequest,
  [types.PASSWORD_RESET.RESPONSE]: onPasswordResetResponse,
  [types.SEND_PASSWORD_RESET_EMAIL.REQUEST]: onSendPasswordResetEmailRequest,
  [types.SEND_PASSWORD_RESET_EMAIL.RESPONSE]: onSendPasswordResetEmailResponse,
  [types.SEND_VERIFICATION_EMAIL.REQUEST]: onSendVerificationEmailRequest,
  [types.SEND_VERIFICATION_EMAIL.RESPONSE]: onSendVerificationEmailResponse
};

export const auth = createReducers(INITIAL_STATE, HANDLERS);
