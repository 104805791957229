import React from 'react';
import {Group, GroupProps} from '@mantine/core';

export interface EtroGroupProps extends GroupProps {}

const defaultProps: Partial<EtroGroupProps> = {};

export const EtroGroup = React.forwardRef<HTMLDivElement, EtroGroupProps>(
  (props, ref) => {
    return <Group ref={ref} {...defaultProps} {...props} />;
  }
);
