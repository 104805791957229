import {MaybeDrafted} from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {differenceBy, sortBy, orderBy} from 'lodash';
import {OrderKey} from '~/types';

interface UtilArgs<T> {
  draft: MaybeDrafted<T & {id: string | number}>[];
  sortKey?: string | string[];
  orderKey?: OrderKey;
}

interface CreateArgs<T> extends UtilArgs<T> {
  created: T & {id: string | number};
}

interface UpdateArgs<T> extends UtilArgs<T> {
  updated: T & {id: string | number};
}

interface DeleteArgs<T> extends UtilArgs<T> {
  id: string | number;
}

const handleSort = <T>({draft, sortKey, orderKey}: UtilArgs<T>) => {
  if (orderKey) {
    return orderBy(draft, sortKey, orderKey);
  } else if (sortKey) {
    return sortBy(draft, sortKey);
  }

  return draft;
};

export const concatToList = <T>({
  created,
  draft,
  sortKey,
  orderKey
}: CreateArgs<T>) => {
  const result = draft.concat(created);

  return handleSort<T>({draft: result, sortKey, orderKey});
};

export const updateInList = <T>({
  draft,
  sortKey,
  updated,
  orderKey
}: UpdateArgs<T>) => {
  const result = draft.map(x => (x.id === updated.id ? updated : x));

  return handleSort<T>({draft: result, sortKey, orderKey});
};

export const deleteFromList = <T>({
  draft,
  id,
  sortKey,
  orderKey
}: DeleteArgs<T>) => {
  const result = differenceBy(draft, [{id}], 'id');

  return handleSort<T>({draft: result, sortKey, orderKey});
};
