import {createActions} from 'redux-arc';
import {GET} from './types';

const equipmentApiBase = 'equipment/';

export const {types, creators: equipmentCreators} = createActions('equipment', {
  read: {url: `${equipmentApiBase}:id/`, method: GET},
  list: {url: equipmentApiBase, method: GET},
  mergeReadResult: null
});
