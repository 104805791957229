import {etroApi} from '.';
import {Gearset} from '~/types';

const GEARSET_BASE = 'gearsets/';

const gearsetsApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    listBISGearsets: query<Gearset[], void>({
      query: () => `${GEARSET_BASE}bis/`
    })
  })
});

export const {useListBISGearsetsQuery} = gearsetsApi;
