import {AnyAction, Reducer} from 'redux';
import {
  Clan,
  EtroAPIFormErrors,
  Gearset,
  GearsetExpanded,
  Job,
  MateriaIdMap,
  MateriaSelectOptions,
  MaxPotions,
  Relic,
  User
} from '~/types';
import {INITIAL_STATE as authState, auth} from './auth';
import {INITIAL_STATE as clansState, clans} from './clans';
import {INITIAL_STATE as equipmentState, equipment} from './equipment';
import {INITIAL_STATE as foodState, food} from './food';
import {INITIAL_STATE as gearsetsState, gearsets} from './gearsets';
import {INITIAL_STATE as jobsState, jobs} from './jobs';
import {INITIAL_STATE as materiaState, materia} from './materia';
import {INITIAL_STATE as medicineState, medicine} from './medicine';
import {INITIAL_STATE as relicState, relic} from './relic';
import {INITIAL_STATE as userState, user} from './user';

/*
  Since redux-arc is not typed, deprecated and generates reducers,
  RTK cannot infer the types for the store state. These types are
  not perfect, but it at least gives autocomplete and types
  based on the initial state values without manually typing everything.
*/

type ReduxArcReducer<T> = Reducer<T, AnyAction>;
type Override<T, U> = ReduxArcReducer<Omit<T, keyof U> & U>;

interface ReduxArc {
  auth: Override<
    typeof authState,
    {
      user: User | null;
      passwordResetError: {
        field: string;
        message: string[];
      }[];
      userUpdateError: EtroAPIFormErrors;
    }
  >;
  clans: Override<
    typeof clansState,
    {currentClan: Clan | null; listResult: Clan[]}
  >;
  equipment: ReduxArcReducer<typeof equipmentState>;
  food: ReduxArcReducer<typeof foodState>;
  gearsets: Override<
    typeof gearsetsState,
    {
      gearset: Gearset;
      listResult: Gearset[];
      readResult: Gearset | GearsetExpanded | null;
    }
  >;
  jobs: Override<typeof jobsState, {currentJob: Job | null; listResult: Job[]}>;
  materia: Override<
    typeof materiaState,
    {materiaSelectOptions: MateriaSelectOptions; materiaIdMap: MateriaIdMap}
  >;
  medicine: Override<typeof medicineState, {maxPotions: MaxPotions}>;
  relic: Override<
    typeof relicState,
    {
      listResult: Relic[];
      ALC: Record<string, Relic[]>;
      ARM: Record<string, Relic[]>;
      AST: Record<string, Relic[]>;
      BLM: Record<string, Relic[]>;
      BLU: Record<string, Relic[]>;
      BRD: Record<string, Relic[]>;
      BSM: Record<string, Relic[]>;
      BTN: Record<string, Relic[]>;
      CRP: Record<string, Relic[]>;
      CUL: Record<string, Relic[]>;
      DNC: Record<string, Relic[]>;
      DRG: Record<string, Relic[]>;
      DRK: Record<string, Relic[]>;
      FSH: Record<string, Relic[]>;
      GNB: Record<string, Relic[]>;
      GSM: Record<string, Relic[]>;
      LTW: Record<string, Relic[]>;
      MCH: Record<string, Relic[]>;
      MIN: Record<string, Relic[]>;
      MNK: Record<string, Relic[]>;
      NIN: Record<string, Relic[]>;
      PCT: Record<string, Relic[]>;
      PLD: Record<string, Relic[]>;
      RDM: Record<string, Relic[]>;
      RPR: Record<string, Relic[]>;
      SAM: Record<string, Relic[]>;
      SCH: Record<string, Relic[]>;
      SGE: Record<string, Relic[]>;
      SMN: Record<string, Relic[]>;
      VPR: Record<string, Relic[]>;
      WAR: Record<string, Relic[]>;
      WHM: Record<string, Relic[]>;
      WVR: Record<string, Relic[]>;
    }
  >;
  user: ReduxArcReducer<typeof userState>;
}

export const reduxArcReducers: ReduxArc = {
  auth,
  clans,
  equipment,
  food,
  gearsets,
  jobs,
  materia,
  medicine,
  relic,
  user
};
