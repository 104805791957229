import {createActions} from 'redux-arc';
import {GET} from './types';

const jobsApiBase = 'jobs/';

export const {types, creators: jobsCreators} = createActions('jobs', {
  read: {url: `${jobsApiBase}:id/`, method: GET},
  list: {url: jobsApiBase, method: GET},
  setCurrentJob: null,
  resetCurrentJob: null
});
