import React, {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import Select, {components} from 'react-select';
import {isEmpty, isEqual} from 'lodash';
import {FormGroup} from '@blueprintjs/core';
import {usePrevious} from 'react-use';
import variables from '~/styles/variables.scss';
import {ParamSpan} from '~/views/Gearset/ParamSpan';
import {EtroText} from '~/components/EtroText';
import {iLevelSyncIcon} from '~/constants';
import {EtroGroup} from '../EtroGroup';
import {EtroStack} from '../EtroStack';
import {RemoteIcon} from '../EtroIcon';

export const {Option} = components;

export const IconOption = props => (
  <Option {...props}>
    <div className="etro-select-option">
      <div className="etro-select-option-icon">
        <img
          src={`${process.env.REACT_APP_ICON_BASE_URL}${props.data.iconPath}`}
          height="28px"
          alt={`${props.data.id}Icon`}
        />
      </div>
      <div className="etro-select-option-label">
        <span>{props.data.label}</span>
      </div>
    </div>
  </Option>
);

export const EquipmentOption = props => {
  const {data, isCraftOrGatherJob} = props;
  const {iconPath, id, label, itemLevelSync, itemLevel} = data;

  return (
    <Option {...props}>
      <EtroGroup noWrap align={'center'} spacing={0} mr={8}>
        <RemoteIcon
          iconPath={iconPath}
          height="28px"
          width="28px"
          alt={`${id}Icon`}
        />
        <EtroStack spacing={1} sx={{flex: 1}}>
          {/* Add ml here instead of mx on parent Group due to ParamSpan CSS */}
          <EtroGroup noWrap position="apart" ml={8}>
            <EtroText lineClamp={1}>{label}</EtroText>
            <EtroGroup
              noWrap
              spacing="xs"
              // minWidth otherwise long names can take ilevelsync space
              sx={{minWidth: itemLevelSync ? '68px' : undefined}}
            >
              {itemLevelSync && (
                // lineClamp because it will wrap between icon and number
                <EtroText lineClamp={1} size={'11px'} color="yellow.6">
                  {`${iLevelSyncIcon} ${itemLevelSync}`}
                </EtroText>
              )}
              <EtroText size={'11px'} color="dimmed">
                {`i${itemLevel}`}
              </EtroText>
            </EtroGroup>
          </EtroGroup>
          <ParamSpan
            isCraftOrGatherJob={isCraftOrGatherJob}
            className="etro-select-option-stats"
            item={data}
            foodValues={null}
            shrinkOverflow={true}
          />
        </EtroStack>
      </EtroGroup>
    </Option>
  );
};

const getCustomStyle = (etroStyles, useIcons, hasErrors) => {
  const {
      color,
      disabledColor,
      indicatorColor,
      disabledBGColor,
      backgroundColor
    } = etroStyles,
    {intentDanger, intentPrimary} = variables;

  return {
    menuPortal: provided => ({...provided, zIndex: 9999}),
    option: (provided, {isDisabled, isFocused}) => ({
      ...provided,
      ':active': {backgroundColor: backgroundColor},
      padding: 0,
      color: isDisabled ? disabledColor : color,
      backgroundColor: isDisabled ? disabledBGColor : backgroundColor,
      borderColor: isFocused
        ? intentPrimary
        : isDisabled
        ? disabledBGColor
        : backgroundColor,
      borderRadius: '10px',
      borderStyle: 'solid',
      borderWidth: '2px',
      paddingLeft: '6px',
      textAlign: 'left',
      cursor: isDisabled ? 'not-allowed' : 'pointer'
    }),
    control: (provided, {isDisabled}) => ({
      ...provided,
      ...etroStyles,
      minHeight: '1px',
      borderWidth: '1px',
      borderColor: hasErrors
        ? intentDanger
        : isDisabled
        ? disabledBGColor
        : backgroundColor,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': !isDisabled && {
        borderColor: intentPrimary
      }
    }),
    container: provided => ({...provided, pointerEvents: 'unset'}),
    input: provided => ({
      ...provided,
      marginLeft: '8px',
      color
    }),
    singleValue: provided => ({
      ...provided,
      ...etroStyles,
      paddingLeft: useIcons ? 0 : '8px'
    }),
    noOptionsMessage: provided => ({
      ...provided,
      ...etroStyles
    }),
    menu: provided => ({
      ...provided,
      marginTop: '2px',
      zIndex: 2
    }),
    menuList: provided => ({
      ...provided,
      backgroundColor,
      scrollbarWidth: 'thin'
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '1px',
      flexWrap: 0,
      minHeight: '42px'
    }),
    placeholder: provided => ({
      ...provided,
      marginLeft: '8px'
    }),
    clearIndicator: provided => ({
      ...provided,
      color
    }),
    dropdownIndicator: (provided, {isDisabled}) => ({
      ...provided,
      color: isDisabled ? disabledColor : color
    }),
    indicatorSeparator: (provided, {isDisabled}) => ({
      ...provided,
      backgroundColor: isDisabled ? disabledColor : indicatorColor
    })
  };
};

const getMappedOptions = options => {
  return isEmpty(options)
    ? []
    : options.map(x => {
        return {
          ...x,
          value: x.id,
          label: x.name
        };
      });
};

/**
 * @deprecated Use EtroSelect from index.
 */
export const EtroSelectComponent = props => {
  const {
      useIcons,
      darkMode,
      label,
      defaultId,
      options,
      onChange,
      preMappedOptions,
      hasErrors,
      value,
      createWatchId,
      updateWatch,
      resetWatch,
      formGroupProps
    } = props,
    [isDefaultSet, setDefault] = useState(false),
    previousDefaultId = usePrevious(defaultId),
    hasPremap = !isEmpty(preMappedOptions),
    etroStyles = useMemo(
      () => ({
        color: darkMode ? variables.darkText : variables.lightText,
        backgroundColor: darkMode
          ? variables.darkTertiary
          : variables.lightTertiary,
        indicatorColor: darkMode
          ? variables.darkIndicator
          : variables.lightIndicator,
        disabledColor: darkMode
          ? variables.darkTextDisabled
          : variables.lightTextDisabled,
        disabledBGColor: darkMode
          ? variables.darkDisabled
          : variables.lightDisabled
      }),
      [darkMode]
    ),
    customStyle = useMemo(
      () => getCustomStyle(etroStyles, useIcons, hasErrors),
      [etroStyles, useIcons, hasErrors]
    ),
    mappedOptions = useMemo(() => getMappedOptions(options), [options]);

  let defaultOption = null;

  if (defaultId && !isDefaultSet) {
    if (hasPremap) {
      defaultOption = preMappedOptions.find(
        x => x.id === defaultId || x.value === defaultId
      );
    } else if (!hasPremap && !isEmpty(mappedOptions)) {
      defaultOption = mappedOptions.find(x => x.id === defaultId);
    }
  }

  useEffect(() => {
    if (defaultOption && !isDefaultSet) {
      onChange(defaultOption);
      setDefault(true);
    }
  }, [isDefaultSet, setDefault, defaultOption, onChange]);

  // Reset defaultId if it changes
  useEffect(() => {
    if (previousDefaultId && !isEqual(previousDefaultId, defaultId)) {
      setDefault(false);
    }
  }, [defaultId, previousDefaultId]);

  // Automatically set a new option on creation
  useEffect(() => {
    if (createWatchId) {
      const newOption = mappedOptions.find(o => o.id === createWatchId);
      if (newOption && !isEqual(value, newOption)) {
        onChange(newOption);
        resetWatch();
      }
    }
  }, [value, createWatchId, onChange, mappedOptions, resetWatch]);

  /*
    If a value object is edited, the update does not completely fire
    because the id's are the same. For example, if the current value is
    a relic object, and the name/params are updated, this will not be
    reflected so we force an onChange event.
  */
  useEffect(() => {
    if (value && updateWatch) {
      const updatedOption = mappedOptions.find(o => o.id === value.id);
      if (!isEqual(value, updatedOption)) {
        onChange(updatedOption);
        resetWatch();
      }
    }
  }, [value, updateWatch, onChange, mappedOptions, resetWatch]);

  return (
    <FormGroup {...formGroupProps} label={label && label}>
      <Select
        components={
          useIcons ? {Option: IconOption, SingleValue: IconOption} : {}
        }
        menuPlacement={'auto'}
        {...props}
        options={hasPremap ? preMappedOptions : mappedOptions}
        styles={customStyle}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: variables.intentPrimary,
            primary75: etroStyles.backgroundColor,
            primary50: etroStyles.backgroundColor
          }
        })}
        // defaultMenuIsOpen
      />
    </FormGroup>
  );
};

const actions = {};

const mapStateToProps = (state, props) => {
  return {
    darkMode: state.user.darkMode
  };
};

export const EtroSelect = connect(
  mapStateToProps,
  actions
)(EtroSelectComponent);
