import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {EtroSelect, EtroSelectProps} from '~/components';
import {useClanList} from '~/hooks';

export interface ClanSelectProps extends Omit<EtroSelectProps, 'data'> {}

export const ClanSelect = React.forwardRef<HTMLInputElement, ClanSelectProps>(
  (props, ref) => {
    const {t} = useTranslation();
    const clanList = useClanList();
    const clanOptions: EtroSelectProps['data'] = useMemo(() => {
      return clanList.map(({id, name}) => ({value: `${id}`, label: name}));
    }, [clanList]);

    return (
      <EtroSelect
        ref={ref}
        label={t('clan')}
        placeholder="Select a clan..."
        {...props}
        data={clanOptions}
      />
    );
  }
);
