import React, {useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {IconSettings, IconLogout} from '@tabler/icons-react';
import {EtroButton, EtroGroup, EtroMenu} from '~/components';
import {authCreators} from '~/actions/auth';
import {gearsetsCreators} from '~/actions/gearsets';
import {relicCreators} from '~/actions/relic';
import {onSuccess} from '~/components/AppToaster/AppToaster';
import {ThemeSwitch} from './ThemeSwitch';
import {AccountAvatar} from './AccountAvatar';
import {useDispatch, useSelector} from '~/hooks';
import {ReduxArcResponse, Awaited} from '~/types';
import {AuthModal} from './AuthModal';
import {useTranslation} from 'react-i18next';

const {logout} = authCreators;
const {resetResults: resetGearsetResults} = gearsetsCreators;
const {resetResults: resetRelicResults} = relicCreators;

export const AccountMenu: React.FC = () => {
  const {isAuthenticated, userIsLoading} = useSelector(({auth}) => auth);
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(logout(null)).then((r: Awaited<ReduxArcResponse>) => {
      if (r?.status === 200) {
        history.push('/');
        dispatch(resetGearsetResults());
        dispatch(resetRelicResults());
        onSuccess('Logged out successfully.');
      }
    });
  }, [dispatch, history]);

  if (userIsLoading) {
    return null;
  }

  return (
    <>
      <EtroMenu keepMounted>
        <EtroMenu.Target>
          <EtroButton
            size="md"
            variant="subtle"
            // Use display: none instead of conditional render due to memory leak in Mantine menu when unmounted
            sx={{display: isAuthenticated ? undefined : 'none'}}
          >
            <AccountAvatar />
          </EtroButton>
        </EtroMenu.Target>
        <EtroMenu.Dropdown>
          <EtroMenu.Item component={Link} to="/account" icon={<IconSettings />}>
            {t('account')}
          </EtroMenu.Item>
          <EtroMenu.Item icon={<IconLogout />} onClick={handleLogout}>
            {t('logout')}
          </EtroMenu.Item>
          <EtroMenu.Divider />
          <EtroGroup px="xs">
            <ThemeSwitch />
          </EtroGroup>
        </EtroMenu.Dropdown>
      </EtroMenu>
      {!isAuthenticated && (
        <EtroGroup spacing={'xs'} noWrap>
          <ThemeSwitch />
          <AuthModal />
        </EtroGroup>
      )}
    </>
  );
};
