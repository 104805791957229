import React from 'react';
import {List, ListProps} from '@mantine/core';
import {EtroListItem} from './EtroListItem';

export interface EtroListProps extends ListProps {}

const defaultProps: Partial<EtroListProps> = {};

export const EtroList: React.FC<EtroListProps> & {
  Item: typeof EtroListItem;
} = props => {
  return <List {...defaultProps} {...props} />;
};

EtroList.Item = EtroListItem;
