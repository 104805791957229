import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '~/types';
import {safeGetItem} from '~/utils/localStorage';
import i18next from 'i18next';
import queryStringParser from 'query-string';

export const etroApi = createApi({
  reducerPath: 'etroApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL || '',
    prepareHeaders: (headers, {getState}) => {
      headers.set('Content-Type', 'application/json');

      const {
        auth: {token}
      } = getState() as RootState;

      /*
        Fall back to local storage if not found in Redux.
        This occurs in private mode and on the user request on load.
      */
      const JWT = token ? token : safeGetItem('token');

      if (JWT) {
        headers.set('Authorization', `Bearer ${JWT}`);
      }

      const language =
        i18next.resolvedLanguage ??
        safeGetItem('i18nextLng') ??
        queryStringParser.parse(window.location.search)?.lng;

      if (language) {
        headers.set('Accept-Language', language);
      }

      return headers;
    }
  }),
  endpoints: () => ({})
});
