import React, {useState, useCallback} from 'react';
import {
  EtroAlert,
  EtroButton,
  EtroCode,
  EtroContainer,
  EtroBox,
  EtroPrism,
  EtroStack,
  EtroTitle,
  EtroText
} from '~/components';
import {useMeasure, useToggle, useDebounce} from 'react-use';
import {NitroPayAd} from '~/components/NitroPayAd';
import {IconMoonStars, IconSun, IconWindowMaximize} from '@tabler/icons-react';
import {useMantineTheme} from '@mantine/core';
import {supportedLngs} from '~/i18n';
import {useTranslation} from 'react-i18next';

const IFRAME_TITLE = 'Etro Gearset';

// eslint-disable-next-line no-unused-vars
enum Themes {
  // eslint-disable-next-line no-unused-vars
  dark = 'Dark',
  // eslint-disable-next-line no-unused-vars
  light = 'Light'
}

export const EmbedDocs: React.FC = () => {
  const [theme, setTheme] = useState(Themes.dark);
  const [themeToggleDisabled, setThemeToggleDisabled] = useToggle(true);
  const {t} = useTranslation();
  const [ref, {width, height}] = useMeasure<HTMLIFrameElement>();
  const {breakpoints} = useMantineTheme();
  const toggleTheme = useCallback(() => {
    if (theme === Themes.dark) {
      setTheme(Themes.light);
    } else if (theme === Themes.light) {
      setTheme(Themes.dark);
    }
  }, [theme]);
  const handleThemeToggle = useCallback(() => {
    toggleTheme();
    setThemeToggleDisabled();
  }, [setThemeToggleDisabled, toggleTheme]);
  const iframeUrl = `${process.env.REACT_APP_EMBED_BASE_URL}${
    process.env.REACT_APP_EMBED_GS
  }${theme === Themes.light ? '?theme=light' : ''}`;

  const iFrameCodeBlock = `
// styles.css
#etro-iframe-container {
  min-width: 350px;
  width: 100%;
  max-width: 768px;
  min-height: 500px;
  
  iframe {
    width: 100%;
    height: 100%;
  }
}

// view.html
<div id="etro-iframe-container">
  <iframe 
    title="${IFRAME_TITLE}" 
    src="${iframeUrl}">
  </iframe>
</div>
`;

  useDebounce(
    () => {
      if (themeToggleDisabled) {
        setThemeToggleDisabled();
      }
    },
    5000,
    [themeToggleDisabled]
  );

  return (
    <EtroContainer>
      <EtroStack align={'center'} spacing={'xl'}>
        <NitroPayAd placementId="etro-ad-embed-doc-top" type="bannerLarge" />
        <EtroTitle color="etro" order={1} align="center">
          {t('embed')}
        </EtroTitle>
        <EtroStack
          align="stretch"
          sx={{textAlign: 'left', maxWidth: `${breakpoints.sm}`, width: '100%'}}
        >
          <EtroTitle order={3} align="center">
            {'Usage'}
          </EtroTitle>
          <EtroText>
            {'Etro gearsets can be embed into your site by adding an '}
            <EtroCode>{'iframe'}</EtroCode>
            {' element where the '}
            <EtroCode>{'src'}</EtroCode>
            {
              ' property points to the desired gearset using the embed specific URL. Specifically the URL schema is:'
            }
          </EtroText>
          <EtroCode
            sx={{alignSelf: 'start'}}
          >{`${process.env.REACT_APP_EMBED_BASE_URL}:gearsetId`}</EtroCode>
          <EtroTitle order={3} align="center">
            {'Language'}
          </EtroTitle>
          <EtroText>
            {
              'The language can be adjusted to fit the localization of your site by passing a '
            }
            <EtroCode>{'lng'}</EtroCode>
            {' query parameter with the '}
            <EtroCode>{'src'}</EtroCode>
            {' URL. Valid values are '}
            {supportedLngs.map((lng, i, {length}) => {
              return (
                <React.Fragment key={`lng${i}`}>
                  <EtroCode>{lng}</EtroCode>
                  {i + 1 === length ? '.' : ', '}
                </React.Fragment>
              );
            })}
            {
              " Note that the user's language setting, if it exists, will take precedence over the query param."
            }
          </EtroText>
          <EtroTitle order={3} align="center">
            {'Theming'}
          </EtroTitle>
          <EtroText>
            {
              'The theme can be adjusted to fit the style of your site by passing a '
            }
            <EtroCode>{'theme'}</EtroCode>
            {' query parameter with the '}
            <EtroCode>{'src'}</EtroCode>
            {
              " URL. Currently only dark (default) and light themes are supported. The code block below will update when changing the preview's theme to light."
            }
          </EtroText>
          <EtroTitle order={3} align="center">
            {'Responsive Content'}
          </EtroTitle>
          <EtroText>
            {'The content of the '}
            <EtroCode>{'iframe'}</EtroCode>
            {
              ' is responsive to viewport widths down to about 350px. It can go lower, but the Stats table will become difficult to use. It is easiest to constrain the '
            }
            <EtroCode>{'iframe'}</EtroCode>
            {' with a container like the preview below.'}
          </EtroText>
          <EtroTitle order={3} align="center">
            {'Preview'}
          </EtroTitle>
          <EtroText>
            {'The following code block is rendering the preview below.'}
          </EtroText>
          <EtroButton
            sx={{alignSelf: 'start'}}
            onClick={handleThemeToggle}
            leftIcon={theme === Themes.light ? <IconMoonStars /> : <IconSun />}
            disabled={themeToggleDisabled}
          >
            <EtroText>
              {`Preview ${
                theme === Themes.light ? Themes.dark : Themes.light
              } Theme`}
            </EtroText>
          </EtroButton>
          <EtroPrism
            language="typescript"
            sx={{
              maxWidth: `${breakpoints.sm}`,
              width: '100%'
            }}
          >
            {iFrameCodeBlock}
          </EtroPrism>
          <EtroAlert title="Resizable" icon={<IconWindowMaximize />}>
            <EtroText size="xs" mb="md">
              {'The '}
              <EtroCode>{'iframe'}</EtroCode>
              {' below can be resized by dragging the bottom right corner.'}
            </EtroText>
            <EtroText size="xs">
              {`Current Dimensions: ${width}px ${'\u2715'} ${height}px`}
            </EtroText>
          </EtroAlert>
          <EtroBox
            sx={{
              resize: 'both',
              overflow: 'hidden',
              minWidth: '358px',
              maxWidth: '100%',
              minHeight: '508px',
              height,
              padding: '0 4px 4px 0',
              iframe: {
                width: '100%',
                height: '100%'
              }
            }}
          >
            <iframe ref={ref} title={IFRAME_TITLE} src={iframeUrl} />
          </EtroBox>
        </EtroStack>
        <NitroPayAd
          placementId="etro-ad-embed-doc-bottom"
          type="bannerMed"
          config={{renderVisibleOnly: true}}
        />
      </EtroStack>
    </EtroContainer>
  );
};
