import React, {useMemo} from 'react';
import {EtroList, EtroText} from '~/components';
import {EmbedGearsetProps} from '../types';
import {GearsetBuffs} from '~/types';
import {buffTiers, fullCrafterBuffs, gathererBuffs} from '~/constants';
import {RemoteIcon} from '~/components/EtroIcon';
import {isEmpty} from 'lodash';

interface BuffsProps extends Pick<EmbedGearsetProps, 'gearset'> {}

type BuffOptions = {icon: string; label: string}[];

export const Buffs: React.FC<BuffsProps> = ({gearset}) => {
  const buffs = useMemo(() => {
    const options = gearset?.job.isCrafting ? fullCrafterBuffs : gathererBuffs;

    const _buffs = options.reduce((acc: BuffOptions, {label, icon, key}) => {
      const gsValue = gearset?.buffs?.[key as keyof GearsetBuffs];

      if (gsValue) {
        const tier = buffTiers.find(({value}) => value === gsValue);

        acc.push({icon, label: `${label}${tier ? ' ' + tier.label : ''}`});
      }

      return acc;
    }, []);

    return _buffs;
  }, [gearset]);

  if (isEmpty(buffs)) {
    return null;
  }

  return (
    <EtroList spacing="sm" size="sm" center sx={{marginTop: '12px'}}>
      {buffs.map(({icon, label}, i) => {
        return (
          <EtroList.Item
            key={`buff-${i}`}
            icon={
              <RemoteIcon
                iconPath={icon}
                alt={label}
                height={40}
                renderSkeleton
                // Make buff icons 40px width without distorting the image
                style={{padding: label !== 'Specialist' ? '0 5px' : undefined}}
              />
            }
          >
            <EtroText size="sm">{label}</EtroText>
          </EtroList.Item>
        );
      })}
    </EtroList>
  );
};
