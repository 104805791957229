import {useSelector} from '.';
import {JobAbbrevs, JobEquipment} from '~/types';

export const useJobEquipment = (jobAbbrev?: JobAbbrevs): JobEquipment => {
  const {equipment} = useSelector(state => {
    const _abbrev = jobAbbrev ?? state.jobs.currentJob?.abbrev;

    return {
      equipment: _abbrev ? state.equipment[_abbrev] : {}
    };
  });

  return equipment;
};
