import React, {useState, useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {Button, FormGroup} from '@blueprintjs/core';
import {useUnmount} from 'react-use';
import {relicCreators} from '~/actions/relic';
import {equipmentCreators} from '~/actions/equipment';
import {
  DateCellRenderer,
  EtroDataGrid,
  EtroDataGridAction,
  IconCellRenderer,
  SortingAlgorithms
} from '~/components/EtroDataGrid';
import {ConfirmDialog} from '~/components/ConfirmDialog/ConfirmDialog';
import {onSuccess, onFailure} from '~/components/AppToaster/AppToaster';
import {RelicForm} from './RelicForm';
import {useFetchWhenAuth, useMobileMediaQuery} from '~/hooks';
import {EtroPaper} from '~/components/EtroPaper';
import {useTranslation} from 'react-i18next';

const BASE_PARAM_COLUMN = {
  filterable: true,
  sortable: true,
  type: 'number'
};

const RelicListComponent = ({
  cloneRelic,
  deleteIsLoading,
  deleteRelic,
  isAuthenticated,
  listEquipment,
  listItemLevelSync,
  listRelic,
  relicList,
  relicListCached,
  resetRelicWatches
}) => {
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false),
    [formDialogVisible, setFormDialogVisible] = useState(false),
    toggleFormDialog = useCallback(
      () => setFormDialogVisible(!formDialogVisible),
      [formDialogVisible]
    ),
    toggleDeleteDialog = useCallback(
      () => setDeleteDialogVisible(!deleteDialogVisible),
      [deleteDialogVisible]
    ),
    [selectedRow, setSelectedRow] = useState(null),
    isMobile = useMobileMediaQuery();
  const {t} = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        filterable: true,
        frozen: true,
        key: 'jobName',
        name: t('job'),
        sortable: true,
        type: 'select',
        width: 90,
        sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
        formatter: ({row}) => (
          <IconCellRenderer
            iconPath={`/jobs/${row.jobName}.svg`}
            value={row.jobName}
          />
        )
      },
      {
        filterable: true,
        frozen: true,
        key: 'name',
        minWidth: 200,
        name: t('name'),
        resizable: true,
        sortable: true,
        type: 'string',
        formatter: ({row}) => (
          <IconCellRenderer
            iconPath={row.iconPath}
            value={row.name}
            justify="-start"
          />
        )
      },
      {
        filterable: true,
        name: t('base item'),
        key: 'baseItemName',
        minWidth: 200,
        resizable: true,
        sortable: true,
        type: 'string'
      },
      {
        key: 'lastUpdate',
        name: t('updated'),
        minWidth: 200,
        resizable: true,
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
        formatter: ({row}) => <DateCellRenderer value={row.lastUpdate} />
      },
      {
        key: 'level',
        name: t('level'),
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'itemLevel',
        name: t('item level'),
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'Main',
        name: 'Main',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'VIT',
        name: 'VIT',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'PIE',
        name: 'PIE',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'DH',
        name: 'DH',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'CRT',
        name: 'CRT',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'DET',
        name: 'DET',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'TEN',
        name: 'TEN',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'SKS',
        name: 'SKS',
        ...BASE_PARAM_COLUMN
      },
      {
        key: 'SPS',
        name: 'SPS',
        ...BASE_PARAM_COLUMN
      },
      {
        name: t('action'),
        key: 'id',
        sortable: false,
        width: 135,
        formatter: ({row}) => (
          <span className="gearset-list-actions">
            <EtroDataGridAction
              content={`${t('edit')} ${t('relic')}`}
              icon="edit"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRow(row);
                toggleFormDialog();
              }}
            />
            <EtroDataGridAction
              content={`${t('clone')} ${t('relic')}`}
              icon="duplicate"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                cloneRelic(null, {id: row.id}).then(r => {
                  if (r?.status === 201) {
                    onSuccess(`${row.name} cloned successfully.`);
                  } else {
                    onFailure(`Error cloning ${row.name}, please try again.`);
                  }
                });
              }}
            />
            <EtroDataGridAction
              content={`${t('delete')} ${t('relic')}`}
              icon="trash"
              fill="danger"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRow(row);
                toggleDeleteDialog();
              }}
            />
          </span>
        )
      }
    ];
  }, [cloneRelic, t, toggleDeleteDialog, toggleFormDialog]);

  const headerActions = useMemo(() => {
    if (!isAuthenticated) {
      return null;
    }

    return (
      <FormGroup>
        <Button
          className={'etro-button-hover-primary-fill'}
          icon={'plus'}
          text={isMobile ? undefined : t('create')}
          outlined={true}
          onClick={toggleFormDialog}
        />
      </FormGroup>
    );
  }, [isAuthenticated, isMobile, t, toggleFormDialog]);

  useFetchWhenAuth(() => {
    if (!relicListCached || !!listItemLevelSync) {
      listRelic();
    }
    listEquipment({canCustomize: true});
  });

  /* 
    Reset update results can cause the watches in GearsetCard 
    to fire and set the slot to undefined if the relic was 
    used in a gearset that is opened.
  */
  useUnmount(() => resetRelicWatches());

  return (
    <>
      <EtroPaper
        sx={{
          // Table will grow outside Stack due to hardcoded column sizes
          // Ad Width + Group Spacing + Sidebar + Main padding
          maxWidth: 'calc(100vw - 223px)',
          // NitroAd mobile breakpoints
          '@media (min-width: 320px) and (max-width: 863px)': {
            // Main Padding
            maxWidth: 'calc(100vw - 32px)'
          }
        }}
      >
        <EtroDataGrid
          columns={columns}
          headerActions={headerActions}
          noDataText={`No relics available.${
            isAuthenticated ? ' Click the create button above.' : ''
          }`}
          onRowClick={row => {
            setSelectedRow(row);
            toggleFormDialog();
          }}
          rows={relicList}
          size="md"
        />
      </EtroPaper>
      {deleteDialogVisible && (
        <ConfirmDialog
          isOpen={deleteDialogVisible}
          toggle={() => {
            toggleDeleteDialog();
            setSelectedRow(null);
          }}
          loading={deleteIsLoading}
          confirmIcon="trash"
          confirmContent={`Are you sure you want to delete ${selectedRow.name}?`}
          onConfirm={async () => {
            const {id, jobName, slotName} = selectedRow;

            await deleteRelic(null, {id, jobName, slotName}).then(r => {
              if (r?.status === 204) {
                onSuccess(`${selectedRow.name} deleted successfully.`);
              } else {
                onFailure(
                  `Error deleting ${selectedRow.name}, please try again.`
                );
              }
            });
          }}
        />
      )}
      {formDialogVisible && (
        <RelicForm
          isOpen={formDialogVisible}
          toggle={() => {
            toggleFormDialog();
            setSelectedRow(null);
          }}
          selectedRow={selectedRow}
        />
      )}
    </>
  );
};

const actions = {
  deleteRelic: relicCreators.delete,
  cloneRelic: relicCreators.clone,
  listRelic: relicCreators.list,
  listEquipment: equipmentCreators.list,
  resetRelicWatches: relicCreators.resetWatches
};

const mapStateToProps = state => {
  return {
    // Sharing GS with relic can put items from other users in listResult
    relicList: state.relic.listResult.filter(i => i.isOwner),
    listItemLevelSync: state.relic.listItemLevelSync,
    isAuthenticated: state.auth.isAuthenticated,
    deleteIsLoading: state.relic.deleteIsLoading,
    relicListCached: state.relic.listCached
  };
};

export const RelicList = connect(mapStateToProps, actions)(RelicListComponent);
