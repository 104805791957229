import React, {useState, useEffect, useRef, useMemo} from 'react';
import {connect} from 'react-redux';
import {
  Dialog,
  FormGroup,
  Button,
  Intent,
  Tooltip,
  Position,
  NumericInput
} from '@blueprintjs/core';
import {Formik} from 'formik';
import {InputWithError} from '../../components/Form/InputWithError';
import {pickBy, trimEnd, isEqual, orderBy} from 'lodash';
import {
  EtroSelect,
  EquipmentOption,
  IconOption
} from '~/components/EtroSelect/EtroSelect';
import {relicCreators} from '~/actions/relic';
import {iLevelSyncIcon, paramNames} from '~/constants';
import * as Yup from 'yup';
import {usePrevious} from 'react-use';
import {EtroAlert} from '~/components/EtroAlert';
import {EtroText} from '~/components/EtroText';
import {useTranslation} from 'react-i18next';

const nameSchema = Yup.string()
  .min(2, 'Minimum name length is 2 characters')
  .max(30, 'Maximum name length is 30 characters')
  .required('Item name is required');

const baseItemSchema = Yup.mixed().required('Base item is required');

const createParamSchema = paramMax => {
  const paramSchema = Yup.number()
    .nullable()
    .integer('Stat must be whole number');

  return paramMax
    ? paramSchema.max(paramMax, `The stat cap is ${paramMax}`)
    : paramSchema;
};

const paramSumSchema = maxParamSum => {
  if (maxParamSum) {
    return Yup.number().max(
      maxParamSum,
      `Maximum stat total is ${maxParamSum}`
    );
  }

  return undefined;
};

const createRelicSchema = currentBaseItem => {
  const maxParams = Object.keys(currentBaseItem?.maxParams ?? {})
    .filter(Number)
    .map(p => currentBaseItem?.maxParams[p]);

  return Yup.object().shape({
    name: nameSchema,
    baseItemId: baseItemSchema,
    paramSum: paramSumSchema(currentBaseItem?.maxParams.maxParamSum),
    param0Value: createParamSchema(maxParams[0]),
    param1Value: createParamSchema(maxParams[1]),
    param2Value: createParamSchema(maxParams[2]),
    param3Value: createParamSchema(maxParams[3]),
    param4Value: createParamSchema(maxParams[4]),
    param5Value: createParamSchema(maxParams[5])
  });
};

const INITIAL_STATE = {
  baseItemId: null,
  name: '',
  paramSum: 0,
  param0: null,
  param0Value: 0,
  param1: null,
  param1Value: 0,
  param2: null,
  param2Value: 0,
  param3: null,
  param3Value: 0,
  param4: null,
  param4Value: 0,
  param5: null,
  param5Value: 0
};

const ParamSum = ({
  errors,
  formRef,
  maxParamSum,
  submitCount,
  touched,
  values
}) => {
  const {t} = useTranslation();
  const paramSum = useMemo(
      () =>
        Object.keys(values)
          .filter(v => v.indexOf('Value') > -1)
          .reduce((acc, currentKey) => acc + values[currentKey], 0),
      [values]
    ),
    displayTooltip = useMemo(
      () => errors.paramSum && touched.paramSum && submitCount > 0,
      [errors.paramSum, touched.paramSum, submitCount]
    ),
    overcapClassname = useMemo(
      () => (paramSum > maxParamSum ? 'etro-materia-overcap' : undefined),
      [paramSum, maxParamSum]
    );

  useEffect(() => {
    if (paramSum !== values.paramSum) {
      formRef.current.setFieldValue('paramSum', paramSum);
    }
  }, [formRef, paramSum, values.paramSum]);

  return (
    <Tooltip
      content={errors.paramSum}
      isOpen={displayTooltip}
      disabled={!displayTooltip}
      intent={Intent.DANGER}
      position={Position.LEFT}
      boundary={'viewport'}
      target={
        <span className="etro-form-max-param-sum">
          <span>
            {`${t('total')}: `}
            <span className={overcapClassname}>{`${paramSum}${
              maxParamSum ? `/${maxParamSum}` : ''
            }`}</span>
          </span>
          {maxParamSum && (
            <span>
              {`${t('remaining')}: `}
              <span className={overcapClassname}>{`${
                maxParamSum - paramSum
              }`}</span>
            </span>
          )}
        </span>
      }
    />
  );
};

const RelicFormComponent = ({
  createRelic,
  defaultBaseItem,
  equipmentList,
  isOpen,
  itemLevelSync,
  listLoading,
  selectedRow,
  submitLoading,
  toggle,
  updateRelic
}) => {
  const formRef = useRef(),
    [currentBaseItem, setCurrentBaseItem] = useState(null),
    [updateStateInitialized, setUpdateStateInitialized] = useState(false),
    prevBaseItem = usePrevious(currentBaseItem);
  const {t} = useTranslation();

  // Setup params and reset form state on baseItem change
  useEffect(() => {
    if (currentBaseItem && !isEqual(prevBaseItem, currentBaseItem)) {
      let nextState = {};

      if (selectedRow && !updateStateInitialized) {
        Object.keys(currentBaseItem.maxParams).forEach((p, i) => {
          const paramString = `param${i}`,
            paramId = Number(p);

          let updateValue;

          for (var j = 0; j < 6; j++) {
            const searchString = `param${j}`;

            if (selectedRow[searchString] === paramId) {
              updateValue = selectedRow[`${searchString}Value`];
            }
          }

          nextState[paramString] = paramId;
          nextState[`param${i}Value`] = updateValue ?? 0;
        });

        setUpdateStateInitialized(true);
      } else {
        Object.keys(currentBaseItem.maxParams).forEach((p, i) => {
          nextState[`param${i}`] = Number(p);
          nextState[`param${i}Value`] = 0;
        });
      }

      formRef.current.resetForm({
        ...INITIAL_STATE,
        ...nextState,
        baseItemId: currentBaseItem.id,
        name: formRef.current.state.values.name
      });
    }
  }, [currentBaseItem, selectedRow, updateStateInitialized, prevBaseItem]);

  return (
    <Dialog
      title={
        selectedRow
          ? `${t('edit')} ${selectedRow.name}`
          : `${t('create')} ${t('relic')}`
      }
      isOpen={isOpen}
      onClose={toggle}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      backdropClassName={''}
    >
      <div className="etro-form-dialog-body">
        <div className="etro-form-card">
          <Formik
            ref={formRef}
            initialValues={
              selectedRow
                ? {
                    ...INITIAL_STATE,
                    baseItemId: selectedRow.baseItemId,
                    name: selectedRow.name
                  }
                : INITIAL_STATE
            }
            validationSchema={createRelicSchema(currentBaseItem)}
            onSubmit={values => {
              // Only submit param with paramValue > 0 and start from param0
              const {baseItemId, name} = values;
              let cleanedParams = {};

              const validValues = pickBy(values, (v, k) => {
                return v > 0 && k.includes('Value');
              });

              Object.keys(validValues).forEach((k, i) => {
                const paramKey = trimEnd(k, 'Value');

                cleanedParams[`param${i}`] = values[paramKey];
                cleanedParams[`param${i}Value`] = validValues[k];
              });

              const payload = {
                ...INITIAL_STATE,
                ...cleanedParams,
                baseItemId,
                name,
                // paramSum is in INITIAL_STATE, but don't need to send to backend
                paramSum: undefined,
                itemLevelSync
              };

              (selectedRow
                ? updateRelic(payload, {id: selectedRow.id})
                : createRelic(payload)
              ).then(() => toggle());
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              submitCount,
              touched,
              setFieldValue,
              values
            }) => (
              <>
                <form onSubmit={handleSubmit} noValidate>
                  {itemLevelSync && (
                    <EtroAlert
                      icon={iLevelSyncIcon}
                      title="Item Level Sync Disabled"
                      mb="xl"
                      color="etro.4"
                      variant="filled"
                    >
                      <EtroText size="xs">
                        {`While editing this relic, it's item level is set to the original value of ${defaultBaseItem.itemLevel}. Upon save, it will be synced to the gearset item level sync of ${itemLevelSync}.`}
                      </EtroText>
                    </EtroAlert>
                  )}
                  <Tooltip
                    content={errors.baseItemId}
                    isOpen={errors.baseItemId && touched.baseItemId}
                    disabled={!(errors.baseItemId && touched.baseItemId)}
                    intent={Intent.DANGER}
                    position={Position.RIGHT}
                    boundary={'viewport'}
                    target={
                      <EtroSelect
                        components={{
                          Option: EquipmentOption,
                          SingleValue: IconOption
                        }}
                        options={equipmentList}
                        isClearable={true}
                        useIcons={true}
                        placeholder={'Select a base item...'}
                        label={t('base item')}
                        hasErrors={errors.baseItemId && touched.baseItemId}
                        onChange={item => {
                          setFieldValue('baseItemId', item ? item.id : null);
                          setCurrentBaseItem(item);
                        }}
                        value={currentBaseItem}
                        defaultId={
                          selectedRow?.baseItemId || defaultBaseItem?.id
                        }
                        isDisabled={!!selectedRow || !!defaultBaseItem}
                        isLoading={listLoading}
                      />
                    }
                  />
                  <InputWithError
                    id="name"
                    value={values.name}
                    errorContent={errors.name}
                    hasErrors={errors.name && touched.name}
                    label={t('name')}
                    handleChange={handleChange}
                  />
                  <div className="etro-form-params">
                    {currentBaseItem &&
                      Object.keys(currentBaseItem.maxParams)
                        .filter(Number)
                        .map((p, i) => {
                          const formKey = `param${i}Value`;

                          return (
                            <Tooltip
                              key={`param${p}`}
                              content={errors[formKey]}
                              isOpen={errors[formKey] && touched[formKey]}
                              disabled={!(errors[formKey] && touched[formKey])}
                              intent={Intent.DANGER}
                              position={i % 2 ? Position.RIGHT : Position.LEFT}
                              boundary={'viewport'}
                              className={`etro-form-param${
                                i % 2 ? ' param-margin' : ''
                              }`}
                              target={
                                <FormGroup label={paramNames[p]}>
                                  <NumericInput
                                    min={0}
                                    max={currentBaseItem.maxParams[p]}
                                    fill={true}
                                    majorStepSize={10}
                                    stepSize={10}
                                    minorStepSize={null}
                                    // placeholder={currentBaseItem.maxParams[p]}
                                    value={values[formKey]}
                                    onValueChange={v =>
                                      setFieldValue(formKey, v)
                                    }
                                  />
                                </FormGroup>
                              }
                            />
                          );
                        })}
                  </div>
                  <div className="etro-form-dialog-actions">
                    <ParamSum
                      errors={errors}
                      formRef={formRef}
                      maxParamSum={currentBaseItem?.maxParams.maxParamSum}
                      submitCount={submitCount}
                      touched={touched}
                      values={values}
                    />
                    <span>
                      <Button
                        type="button"
                        icon="cross"
                        onClick={toggle}
                        text={t('cancel')}
                        disabled={submitLoading}
                        outlined={true}
                      />
                      <Button
                        type="submit"
                        icon={selectedRow ? 'updated' : 'plus'}
                        intent={Intent.PRIMARY}
                        text={selectedRow ? t('save') : t('create')}
                        loading={submitLoading}
                        outlined={true}
                      />
                    </span>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
};

const actions = {
  createRelic: relicCreators.create,
  updateRelic: relicCreators.update
};

const mapStateToProps = (state, props) => {
  const {defaultBaseItem} = props;

  return {
    equipmentList: defaultBaseItem
      ? [defaultBaseItem]
      : orderBy(
          state.equipment.listResult,
          ['itemLevel', 'name'],
          ['desc', 'asc']
        ),
    listLoading: state.equipment.listIsLoading,
    submitLoading: state.relic.createIsLoading || state.relic.updateIsLoading
  };
};

export const RelicForm = connect(mapStateToProps, actions)(RelicFormComponent);
