import React from 'react';
import {LoadingOverlay} from '@mantine/core';
import {useSelector} from '~/hooks';

export const EtroLoadingOverlay: React.FC = () => {
  const {loading} = useSelector(state => {
    return {
      loading:
        state.auth.userIsLoading ||
        state.auth.discordLoginIsLoading ||
        state.equipment.listIsLoading ||
        state.gearsets.readIsLoading ||
        state.gearsets.listIsLoading ||
        state.relic.readIsLoading ||
        state.relic.listIsLoading
    };
  });

  return (
    <LoadingOverlay
      exitTransitionDuration={1000}
      keepMounted
      loaderProps={{size: 'xl'}}
      // Makes the overlay always centered and over the entire viewport instead of based on view size
      sx={{position: 'fixed'}}
      transitionDuration={0}
      visible={loading}
    />
  );
};
