import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {Tab, Tabs, Tooltip, Icon, Position} from '@blueprintjs/core';
import {Formik} from 'formik';
import {isEmpty, mapValues} from 'lodash';
import {useHistory} from 'react-router';
import {GearsetSidebar} from './GearsetSidebar';
import {FilterSidebar} from './FilterSidebar';
import {CollapseDrawer} from '~/components/CollapseDrawer/CollapseDrawer';
import {gearsetsCreators} from '~/actions/gearsets';
import {currentPatch} from '~/constants';
import {gearsetSchema} from '~/utils/formValidationSchema';
import {onSuccess, onFailure} from '~/components/AppToaster/AppToaster';
import {HEADER_HEIGHT} from '~/components/Header';

const TAB_IDS = {
  close: 'close',
  form: 'form',
  filter: 'filter'
};

const TabTitle = ({activeTab, icon, tooltipContent}) => {
  return (
    <Tooltip
      position={Position.RIGHT}
      boundary={'viewport'}
      content={tooltipContent}
      targetClassName={activeTab ? undefined : 'etro-button-hover-primary-fill'}
    >
      <Icon icon={icon} />
    </Tooltip>
  );
};

const GearsetSidebarWrapperComponent = ({
  additionalParams,
  allMateriaEditorVisible,
  createGearset,
  formRef,
  gearset,
  gearsetId,
  handleClearDialogToggle,
  handleJobChange,
  isCraftOrGatherJob,
  itemLevelRange,
  levelBaseParams,
  listResult,
  materiaTierRange,
  materiaTotals,
  params,
  setAdditionalParams,
  setGearset,
  toggleAllMateriaEditorVisible,
  updateGearset
}) => {
  const [sidebarVisible, toggleSidebarVisible] = useState(true),
    [currentTab, setCurrentTab] = useState(TAB_IDS.form),
    history = useHistory(),
    onTabChange = useCallback(
      (nextTab, prevTab) => {
        if (nextTab === TAB_IDS.close) {
          // Use null so there is no indicator when sidebar is hidden
          setCurrentTab(null);
          toggleSidebarVisible(!sidebarVisible);
        } else {
          setCurrentTab(nextTab);
          // Always set visible incase the user clicks a different tab to open sidebar
          toggleSidebarVisible(true);
        }
      },
      [sidebarVisible]
    ),
    setTabClassName = useCallback(
      tabId => {
        return currentTab === tabId
          ? 'etro-sidebar-tab etro-sidebar-tab-active'
          : 'etro-sidebar-tab etro-sidebar-tab-inactive';
      },
      [currentTab]
    );

  useEffect(() => {
    if (sidebarVisible) {
      const d = document.querySelector('.etro-sidebar-tabs');
      if (d?.firstElementChild?.className === 'bp3-tab-list') {
        d.appendChild(d.firstElementChild);
      }
    }
  }, [sidebarVisible]);

  return (
    <Formik
      ref={formRef}
      initialValues={{
        name: '',
        job: null,
        clan: null,
        patch: currentPatch
      }}
      validationSchema={gearsetSchema}
      onSubmit={values => {
        const {name, patch} = values;
        let relics = {};

        const mappedGS = mapValues(gearset, (item, key) => {
          if (!item) {
            return null;
          } else if (typeof item.id === 'string') {
            relics = {
              ...relics,
              [key]: item.id
            };

            return null;
          }

          return item.id;
        });

        const payload = {
          ...mappedGS,
          ...values,
          minItemLevel: itemLevelRange[0],
          maxItemLevel: itemLevelRange[1],
          materia: gearset.materia,
          minMateriaTier: materiaTierRange[0],
          maxMateriaTier: materiaTierRange[1],
          buffs: gearset.buffs,
          relics: isEmpty(relics) ? null : relics,
          patch: patch?.value || null,
          notes: gearset.notes,
          level: gearset.level,
          itemLevelSync: gearset.itemLevelSync ?? null,
          partyBonus: gearset.partyBonus
        };

        const promise =
          gearsetId && gearset.isOwner
            ? updateGearset(payload, {
                id: gearsetId
              })
            : createGearset(payload);

        promise.then(r => {
          if (r && (r.status === 200 || r.status === 201)) {
            onSuccess(`${name} gearset saved successfully.`);

            // Set CreateID in URL
            if (!gearsetId) {
              history.replace(`/gearset/${r.data.id}`);
            }
            /* 
              Set isOwner because the createResult is not used in
              Gearset effect when the URL changes. It is not used
              because it causes a full reload when the gearset[equipmentId]
              changes from object to number to object.

              Set name so TitleHandler can update
            */
            setGearset({isOwner: true, name});
          } else {
            onFailure(`Error saving ${name} gearset, please try again.`);
          }
        });
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
        values
      }) => (
        <>
          <form
            onSubmit={handleSubmit}
            noValidate
            className="etro-gearset-sticky-sidebar "
            style={{top: HEADER_HEIGHT}}
          >
            <CollapseDrawer
              sidebarVisible={sidebarVisible}
              toggleSidebarVisible={toggleSidebarVisible}
              onTabChange={() => onTabChange(TAB_IDS.form)}
              currentTab={currentTab}
            >
              <Tabs
                className="etro-sidebar-tabs"
                vertical={true}
                animate={true}
                onChange={onTabChange}
                selectedTabId={currentTab}
              >
                <Tab
                  id={TAB_IDS.close}
                  className={setTabClassName(TAB_IDS.close)}
                  title={
                    sidebarVisible && (
                      <TabTitle
                        icon={'cross'}
                        activeTab={currentTab === TAB_IDS.close}
                        tooltipContent={'Hide Sidebar'}
                      />
                    )
                  }
                  panel={<></>}
                />
                <Tab
                  id={TAB_IDS.form}
                  className={setTabClassName(TAB_IDS.form)}
                  title={
                    <TabTitle
                      icon="form"
                      activeTab={currentTab === TAB_IDS.form}
                      tooltipContent={'Form & Tables'}
                    />
                  }
                  panel={
                    <GearsetSidebar
                      activeTab={currentTab === TAB_IDS.form}
                      additionalParams={additionalParams}
                      allMateriaEditorVisible={allMateriaEditorVisible}
                      errors={errors}
                      gearset={gearset}
                      gearsetId={gearsetId}
                      handleChange={handleChange}
                      handleClearDialogToggle={handleClearDialogToggle}
                      handleJobChange={handleJobChange}
                      handleSubmit={handleSubmit}
                      isCraftOrGatherJob={isCraftOrGatherJob}
                      levelBaseParams={levelBaseParams}
                      materiaTotals={materiaTotals}
                      params={params}
                      setAdditionalParams={setAdditionalParams}
                      setFieldValue={setFieldValue}
                      toggleAllMateriaEditorVisible={
                        toggleAllMateriaEditorVisible
                      }
                      touched={touched}
                      values={values}
                    />
                  }
                />
                <Tab
                  id={TAB_IDS.filter}
                  className={setTabClassName(TAB_IDS.filter)}
                  title={
                    <TabTitle
                      icon="cog"
                      activeTab={currentTab === TAB_IDS.filter}
                      tooltipContent={'Settings'}
                    />
                  }
                  panel={
                    <FilterSidebar
                      isCraftOrGatherJob={isCraftOrGatherJob}
                      listResult={listResult}
                    />
                  }
                />
              </Tabs>
            </CollapseDrawer>
          </form>
        </>
      )}
    </Formik>
  );
};

const actions = {
  createGearset: gearsetsCreators.create,
  updateGearset: gearsetsCreators.update,
  setGearset: gearsetsCreators.setGearset
};

const mapStateToProps = state => {
  return {
    itemLevelRange: state.gearsets.itemLevelRange,
    materiaTierRange: state.gearsets.materiaTierRange
  };
};

export const GearsetSidebarWrapper = connect(
  mapStateToProps,
  actions
)(GearsetSidebarWrapperComponent);
