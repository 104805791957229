import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Classes,
  Position,
  Tooltip,
  Intent,
  Card
} from '@blueprintjs/core';
import {authCreators} from '~/actions/auth';
import {Formik} from 'formik';
import {PopoverButton} from '~/components/PopoverButton/PopoverButton';
import {InputWithError} from '../../components/Form/InputWithError';
import {SubmitWithError} from '../../components/Form/SubmitWithError';
import {loginSchema, forgotPasswordSchema} from '~/utils/formValidationSchema';
import {AppToaster} from '~/components/AppToaster/AppToaster';
import {DiscordLoginButton} from '../../components/DiscordButton/DiscordLoginButton';
import {IconLogin, IconKey} from '@tabler/icons-react';
import {Translation} from 'react-i18next';

export class LoginComponent extends Component {
  state = {
    username: '',
    hasEmailVerificationError: false,
    resetPasswordEmail: null
  };

  componentDidUpdate = prevProps => {
    if (prevProps.serverErrors !== this.props.serverErrors) {
      if (!this.checkForEmailVerificationError() && this.form) {
        this.form.setErrors({serverErrors: this.props.serverErrors});
      }
    }
  };

  checkForEmailVerificationError = () => {
    const {serverErrors} = this.props;

    if (serverErrors) {
      const hasEmailVerificationError =
        serverErrors.findIndex(e => e === 'E-mail is not verified.') > -1;

      if (hasEmailVerificationError) {
        this.setState({hasEmailVerificationError: true});
      }
      return hasEmailVerificationError;
    }
    return false;
  };

  onSendVerificationEmail = () => {
    this.setState({hasEmailVerificationError: false});
    this.props
      .sendVerificationEmail({username: this.state.username})
      .then(r => r && this.onSendVerificationEmailSuccess(r.status));
  };

  onSendPasswordResetEmailSuccess = () => {
    AppToaster.show({
      message: 'Password reset email sent.',
      intent: Intent.SUCCESS,
      icon: 'envelope'
    });

    this.props.authDialogToggle();
  };

  onSendVerificationEmailSuccess = status => {
    const emailSent = status === 201;
    if (emailSent || status === 200) {
      AppToaster.show({
        message: emailSent
          ? 'Please confirm your email to complete registration.'
          : 'Email verified, please login.',
        intent: emailSent ? Intent.SUCCESS : Intent.PRIMARY,
        icon: emailSent ? 'envelope' : 'tick'
      });

      this.props.authDialogToggle();
    }
  };

  render() {
    return (
      <div className="etro-form-wrapper">
        <div className="etro-form-card-wrapper-dialog">
          <Card className="etro-form-card">
            <Formik
              ref={el => (this.form = el)}
              initialValues={{username: '', password: ''}}
              validationSchema={loginSchema}
              onSubmit={values => {
                this.props
                  .login({
                    username: values.username,
                    password: values.password
                  })
                  .then(r => {
                    if (r?.status === 200) {
                      this.props.authDialogToggle();
                    }
                  });

                this.setState({
                  username: values.username,
                  hasEmailVerificationError: false
                });
              }}
            >
              {({errors, handleChange, handleSubmit, touched}) => (
                <form className="etro-form" onSubmit={handleSubmit} noValidate>
                  <InputWithError
                    errorContent={errors.username}
                    hasErrors={errors.username && touched.username}
                    label={<Translation>{t => t('username')}</Translation>}
                    id="username"
                    handleChange={handleChange}
                  />
                  <InputWithError
                    errorContent={errors.password}
                    hasErrors={errors.password && touched.password}
                    label={<Translation>{t => t('password')}</Translation>}
                    handleChange={handleChange}
                    inputType="password"
                    id="password"
                  />
                  <SubmitWithError
                    errorContent={errors.serverErrors}
                    hasErrors={!!errors.serverErrors}
                    icon={<IconLogin />}
                    text={<Translation>{t => t('login')}</Translation>}
                    loading={this.props.loginIsLoading}
                  />
                </form>
              )}
            </Formik>
            <DiscordLoginButton
              text={<Translation>{t => `Discord ${t('login')}`}</Translation>}
            />
            <PopoverButton
              popoverProps={{position: Position.RIGHT, boundary: 'viewport'}}
              buttonProps={{
                text: <Translation>{t => t('forgot password')}</Translation>,
                icon: <IconKey />,
                className: 'etro-form-submit'
              }}
              content={
                <div className="etro-password-reset">
                  <Button
                    className={Classes.POPOVER_DISMISS}
                    id="etro-close-button"
                    icon="cross"
                    minimal={true}
                  />
                  <Formik
                    initialValues={{email: ''}}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={values => {
                      this.props
                        .sendPasswordResetEmail({email: values.email})
                        .then(r => {
                          if (r && r.status === 200) {
                            this.onSendPasswordResetEmailSuccess();
                          }
                        });
                    }}
                  >
                    {({errors, handleChange, handleSubmit, touched}) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <InputWithError
                          errorContent={errors.email}
                          hasErrors={errors.email && touched.email}
                          label={<Translation>{t => t('email')}</Translation>}
                          handleChange={handleChange}
                          inputType="email"
                          id="email"
                        />
                        <SubmitWithError
                          loading={this.props.sendPasswordResetEmailIsLoading}
                          text={<Translation>{t => t('submit')}</Translation>}
                        />
                      </form>
                    )}
                  </Formik>
                </div>
              }
            />
            {this.state.hasEmailVerificationError && (
              <Tooltip
                content={
                  <Translation>{t => t('email not verified')}</Translation>
                }
                isOpen={true}
                intent={Intent.DANGER}
                position={Position.BOTTOM}
                target={
                  <Button
                    className="etro-form-submit"
                    icon="envelope"
                    text={
                      <Translation>{t => t('send confirm email')}</Translation>
                    }
                    onClick={this.onSendVerificationEmail}
                  />
                }
              />
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export const handleAuthErrors = errorList => {
  if (errorList && errorList.length > 0) {
    return errorList.map(e => e.message[0].replace('This field', e.field));
  }
};

const actions = {
  login: authCreators.login,
  sendVerificationEmail: authCreators.sendVerificationEmail,
  sendPasswordResetEmail: authCreators.sendPasswordResetEmail
};

const mapStateToProps = state => {
  return {
    serverErrors: handleAuthErrors(state.auth.loginError),
    loginIsLoading: state.auth.loginIsLoading,
    sendPasswordResetEmailIsLoading: state.auth.sendPasswordResetEmailIsLoading
  };
};

export const Login = connect(mapStateToProps, actions)(LoginComponent);
