/*
  The 'Page Not Found' page
*/

import React from 'react';

export const NotFound = () => {
  return (
    <div>
      <h2>Page Not Found</h2>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
};
