import {useEffect} from 'react';
import {isEmpty} from 'lodash';
import {useSelector, useDispatch} from '.';
import {Job, Medicine, Food} from '~/types';
import {foodCreators, materiaCreators, medicineCreators} from '~/actions';
import {
  craftMedicineItemLevelRange,
  foodItemLevelRange,
  jobParamList,
  paramIds
} from '~/constants';

export const useMedicineList = (job?: Job | null): Medicine[] => {
  const {currentJob, listError, listIsLoading, listResult} = useSelector(
    state => {
      const {listError, listIsLoading, listResult} = state.medicine;

      return {
        currentJob: state.jobs.currentJob,
        listError,
        listIsLoading,
        listResult
      };
    }
  );
  const dispatch = useDispatch();
  const isCrafting = job?.isCrafting ?? currentJob?.isCrafting ?? false;

  useEffect(() => {
    if (isCrafting && isEmpty(listResult) && !listIsLoading && !listError) {
      dispatch(medicineCreators.list(craftMedicineItemLevelRange));
    }
  }, [dispatch, isCrafting, listError, listIsLoading, listResult]);

  return listResult;
};

export const useJobPotion = (job: Job | null) => {
  const {listError, listIsLoading, maxPotions} = useSelector(
    ({medicine}) => medicine
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(maxPotions) && !listError && !listIsLoading) {
      dispatch(medicineCreators.list(craftMedicineItemLevelRange));
    }
  }, [dispatch, listError, listIsLoading, maxPotions]);

  if (job && !isEmpty(maxPotions)) {
    const jobMainParamName = jobParamList[job.abbrev].main[0];
    const jobMainParamId = Number(paramIds[jobMainParamName]);

    return maxPotions[jobMainParamId];
  }

  return null;
};

export const useFoodList = (): Food[] => {
  const {listError, listIsLoading, listResult} = useSelector(({food}) => food);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(listResult) && !listIsLoading && !listError) {
      dispatch(foodCreators.list(foodItemLevelRange));
    }
  }, [dispatch, listError, listIsLoading, listResult]);

  return listResult;
};

export const useMateriaOptions = () => {
  const {
    listError,
    listIsLoading,
    listResult,
    materiaSelectOptions
  } = useSelector(({materia}) => materia);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(listResult) && !listIsLoading && !listError) {
      dispatch(materiaCreators.list());
    }
  }, [dispatch, listError, listIsLoading, listResult]);

  return materiaSelectOptions;
};

export const useMateriaMap = () => {
  const {listError, listIsLoading, listResult, materiaIdMap} = useSelector(
    ({materia}) => materia
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(listResult) && !listIsLoading && !listError) {
      dispatch(materiaCreators.list());
    }
  }, [dispatch, listError, listIsLoading, listResult]);

  return materiaIdMap;
};
