import React, {useState, useMemo} from 'react';
import {
  Label,
  Button,
  Collapse,
  Position,
  Tooltip,
  Intent
} from '@blueprintjs/core';
import {EtroDataGrid, SortingAlgorithms} from '~/components/EtroDataGrid';
import {EtroBox} from '~/components/EtroBox';
import {useTranslation} from 'react-i18next';
import {IconInfoCircleFilled} from '@tabler/icons-react';
import {EtroGroup} from '~/components';

export const AdditionalStatsTable = ({additionalParams}) => {
  const [tableVisible, toggleTableVisible] = useState(true);
  const {t} = useTranslation();
  const columns = useMemo(
    () => [
      {
        name: t('stat'),
        key: 'name',
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
        formatter: ({row}) => {
          const {name, helpText} = row;

          return helpText ? (
            <Tooltip
              content={helpText}
              position={Position.TOP}
              boundary={'viewport'}
              intent={Intent.PRIMARY}
            >
              <EtroGroup noWrap spacing={'xs'} position={'center'}>
                {name}
                <IconInfoCircleFilled />
              </EtroGroup>
            </Tooltip>
          ) : (
            name
          );
        }
      },
      {
        name: t('value'),
        key: 'value',
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.number,
        formatter: ({row}) => (
          <span>{`${row.value}${row.units ? row.units : ''}`}</span>
        )
      }
    ],
    [t]
  );

  return (
    <div className="etro-param-table-wrapper">
      <div className="etro-param-table-label">
        <Label>{t('additional stats')}</Label>
        <div className="table-collapse-button">
          <Tooltip
            content={tableVisible ? t('hide table') : t('show table')}
            position={Position.TOP}
            boundary={'viewport'}
          >
            <Button
              minimal={true}
              className="etro-button-hover-primary-fill"
              icon={
                tableVisible ? 'double-chevron-down' : 'double-chevron-right'
              }
              onClick={() => toggleTableVisible(!tableVisible)}
            />
          </Tooltip>
        </div>
      </div>
      <Collapse isOpen={tableVisible} keepChildrenMounted={true}>
        <EtroBox
          sx={{
            '.rdg': {
              gridTemplateColumns: `auto 70px !important`
            }
          }}
        >
          <EtroDataGrid
            disableHeader
            disableFiltering
            className="etro-param-table"
            rows={additionalParams}
            columns={columns}
            size="fill"
          />
        </EtroBox>
      </Collapse>
    </div>
  );
};
