import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
  link: {
    display: 'inline-flex',
    gap: '8px',
    alignItems: 'center',
    // important is required to override BP styles on anchor
    color:
      theme.colorScheme === 'dark'
        ? `${theme.colors.dark[2]} !important`
        : undefined,
    '&:hover': {
      color: 'inherit !important'
    }
  },
  logo: {
    [theme.fn.smallerThan('sm')]: {
      alignItems: 'center'
    }
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${
      // Match Divider colors
      theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4]
    }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column'
    }
  },
  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs
    }
  },
  navbarLinks: {
    justifyContent: 'space-evenly'
  }
}));
