import React, {useCallback} from 'react';
import {useToggle} from 'react-use';
import {SubmitHandler, useForm, useController} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  ClanSelect,
  EtroButton,
  EtroGroup,
  EtroInputWrapper,
  EtroRangeSlider,
  EtroStack,
  EtroText,
  EtroTextInput,
  EtroTitle,
  openEtroConfirmModal,
  showEtroFailureNotification,
  showEtroSuccessNotification
} from '~/components';
import {IconKey, IconDeviceFloppy, IconRefreshAlert} from '@tabler/icons-react';
import {PasswordChangeDialog} from './PasswordChangeDialog';
import {User} from '~/types';
import {accountSettingsSchema} from '~/utils/formValidationSchema';
import {authCreators} from '~/actions/auth';
import {useDispatch, useSelector} from '~/hooks';
import {ReduxArcResponse, Awaited} from '~/types';
import {EtroRadioGroup} from '~/components/Form/EtroRadioGroup';
import {
  minItemLevel,
  maxItemLevel,
  partyBonusOptions,
  defaultMinItemLevel
} from '~/constants';
import {isEqual} from 'lodash';
import {useTranslation} from 'react-i18next';

const {userUpdate} = authCreators;
const _defaultItemLevelRange: [number, number] = [
  defaultMinItemLevel,
  maxItemLevel
];

type AccountSettingsFormFields = Pick<
  User,
  'defaultClan' | 'defaultItemLevelRange' | 'defaultPartyBonus' | 'username'
>;

export const AccountSettingsCard: React.FC<{user: User}> = ({user}) => {
  const {userUpdateIsLoading, userUpdateError} = useSelector(({auth}) => auth);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [passwordChangeDialogVisible, togglePasswordChangeDialog] = useToggle(
    false
  );

  const {
    defaultClan,
    defaultItemLevelRange,
    defaultPartyBonus,
    discordAvatar,
    email,
    username
  } = user;

  const {
    control,
    formState,
    handleSubmit,
    reset,
    getValues
  } = useForm<AccountSettingsFormFields>({
    resolver: yupResolver(accountSettingsSchema),
    defaultValues: {
      defaultClan,
      defaultItemLevelRange,
      defaultPartyBonus,
      username
    }
  });

  const handleUserUpdate: SubmitHandler<AccountSettingsFormFields> = useCallback(
    data => {
      const payload = {
        ...data,
        defaultItemLevelRange: isEqual(
          data.defaultItemLevelRange,
          _defaultItemLevelRange
        )
          ? null
          : data.defaultItemLevelRange
      };

      dispatch(userUpdate(payload)).then((r: Awaited<ReduxArcResponse>) => {
        if (r?.status === 200) {
          reset(payload);
          showEtroSuccessNotification({
            message: 'Account settings saved successfully.'
          });
        } else {
          showEtroFailureNotification({
            message: 'Error saving account settings.'
          });
        }
      });
    },
    [dispatch, reset]
  );

  const {
    field: usernameField,
    fieldState: {error: usernameError}
  } = useController({name: 'username', control});

  const {
    field: clanField,
    fieldState: {error: clanError}
  } = useController({name: 'defaultClan', control});

  const {field: partyBonusField} = useController({
    name: 'defaultPartyBonus',
    control
  });

  const {
    field: itemLevelRangeField,
    fieldState: {error: itemLevelRangeError}
  } = useController({
    name: 'defaultItemLevelRange',
    control
  });

  const openConfirmReset = () =>
    openEtroConfirmModal({
      title: 'Reset Settings',
      children: (
        <EtroText>
          {'Are you sure you want to reset the settings to default values?'}
        </EtroText>
      ),
      onConfirm: () =>
        handleUserUpdate({
          defaultClan: null,
          defaultItemLevelRange: null,
          defaultPartyBonus: null,
          username
        })
    });

  return (
    <>
      <form onSubmit={handleSubmit(handleUserUpdate)}>
        <EtroTitle order={3} mb="sm" align="center">
          {t('settings')}
        </EtroTitle>
        <EtroStack spacing={'md'}>
          <EtroTextInput
            label={t('provider')}
            defaultValue={discordAvatar ? 'Discord' : 'Etro'}
            disabled
          />
          <EtroTextInput label={t('email')} defaultValue={email} disabled />
          <EtroTextInput
            label={t('username')}
            {...usernameField}
            error={usernameError?.message || userUpdateError?.username}
          />
          {!discordAvatar && (
            <EtroInputWrapper label={t('password')}>
              <div>
                <EtroButton
                  fullWidth
                  leftIcon={<IconKey />}
                  onClick={togglePasswordChangeDialog}
                  disabled={!!discordAvatar}
                >
                  <EtroText size="sm">{t('change password')}</EtroText>
                </EtroButton>
              </div>
            </EtroInputWrapper>
          )}
          <ClanSelect
            label={t('default clan')}
            {...clanField}
            defaultValue={defaultClan ? `${defaultClan}` : null}
            error={clanError?.message}
            value={clanField.value ? `${clanField.value}` : null}
          />
          <EtroRangeSlider
            defaultValue={_defaultItemLevelRange}
            markAmount={9}
            max={maxItemLevel}
            min={minItemLevel}
            onChange={itemLevelRangeField.onChange}
            step={5}
            value={itemLevelRangeField.value ?? _defaultItemLevelRange}
            wrapperProps={{
              label: t('default item level range'),
              error: itemLevelRangeError?.message
            }}
          />
          <EtroRadioGroup
            label={t('default party bonus')}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              partyBonusField.onChange(
                e?.currentTarget ? Number(e.currentTarget.value) : null
              )
            }
            options={partyBonusOptions}
            selectedValue={partyBonusField.value ?? undefined}
          />
          <EtroGroup grow spacing="md">
            <EtroButton
              color={'red'}
              leftIcon={<IconRefreshAlert />}
              disabled={
                userUpdateIsLoading ||
                isEqual(getValues(), {
                  defaultClan: null,
                  defaultItemLevelRange: null,
                  defaultPartyBonus: null,
                  username
                })
              }
              onClick={openConfirmReset}
            >
              {t('reset settings')}
            </EtroButton>
            <EtroButton
              type="submit"
              loading={userUpdateIsLoading}
              disabled={!formState.isDirty}
              leftIcon={<IconDeviceFloppy />}
            >
              {t('save settings')}
            </EtroButton>
          </EtroGroup>
        </EtroStack>
      </form>
      <PasswordChangeDialog
        isOpen={passwordChangeDialogVisible}
        toggle={togglePasswordChangeDialog}
      />
    </>
  );
};
