import React from 'react';
import {createPolymorphicComponent, Anchor, AnchorProps} from '@mantine/core';

export interface EtroAnchorProps extends AnchorProps {}

const defaultProps: Partial<EtroAnchorProps> = {};

const _EtroAnchor: React.FC<EtroAnchorProps> = props => {
  return <Anchor {...defaultProps} {...props} />;
};

export const EtroAnchor = createPolymorphicComponent<'a', EtroAnchorProps>(
  _EtroAnchor
);
