import React, {useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {EtroSelect} from '~/components/EtroSelect/EtroSelect';
import {clansCreators} from '~/actions/clans';
import {useTranslation} from 'react-i18next';

const ClanSelectorComponent = ({
  currentClan,
  defaultId,
  listClans,
  listError,
  listIsLoading,
  listResult,
  onChange: propsOnChange,
  setCurrentClan,
  setFormValue,
  userDefaultClan,
  value: propsValue
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (isEmpty(listResult) && !listIsLoading && !listError) {
      listClans();
    }
  }, [listResult, listClans, listIsLoading, listError]);

  const onChange = useCallback(
    clan => {
      if (currentClan?.id !== clan?.id) {
        setCurrentClan(clan);
      }

      if (setFormValue) {
        setFormValue('clan', clan ? clan.id : null);
      }

      if (propsOnChange) {
        propsOnChange(clan);
      }
    },
    [currentClan, propsOnChange, setCurrentClan, setFormValue]
  );

  return (
    <EtroSelect
      defaultId={defaultId ?? userDefaultClan}
      isClearable={true}
      isLoading={listIsLoading}
      label={t('clan')}
      menuPortalTarget={document.body}
      onChange={clan => {
        onChange(clan);
      }}
      options={listResult}
      placeholder="Select a clan..."
      value={propsValue ?? currentClan}
    />
  );
};

const actions = {
  setCurrentClan: clansCreators.setCurrentClan,
  listClans: clansCreators.list
};

const mapStateToProps = state => {
  return {
    currentClan: state.clans.currentClan,
    listIsLoading: state.clans.listIsLoading,
    listResult: state.clans.listResult,
    listError: state.clans.listError,
    userDefaultClan: state.auth.user?.defaultClan
  };
};

/**
 * @deprecated Use ClanSelect.
 */
export const ClanSelector = connect(
  mapStateToProps,
  actions
)(ClanSelectorComponent);
