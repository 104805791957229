import {etroApi} from '.';
import {Territory, TerritoryFilter} from '~/types';

const TERRITORY_BASE = 'territory/';

const territoryApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readTerritory: query<Territory, number>({
      query: id => `${TERRITORY_BASE}${id}`
    }),
    listTerritories: query<Territory[], TerritoryFilter>({
      query: params => {
        return {url: `${TERRITORY_BASE}`, params};
      }
    })
  })
});

export const {useReadTerritoryQuery, useListTerritoriesQuery} = territoryApi;
