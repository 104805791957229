import {createReducers} from 'redux-arc';
import {sortBy} from 'lodash';
import {types} from '../actions/clans';
import * as utils from './utils';

export const INITIAL_STATE = {
  currentClan: null,
  listResult: [],
  listIsLoading: false,
  listError: null,
  readResult: null,
  readIsLoading: false,
  readError: null
};

const onSetCurrentClan = (state, action) => ({
  ...state,
  currentClan: action.payload
});

const onResetCurrentClan = state => {
  return {
    ...state,
    currentClan: null
  };
};

const onClanListResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      listIsLoading: false,
      listError:
        (action.payload.response && action.payload.response.data) || true,
      listResult: []
    };
  }

  return {
    ...state,
    listIsLoading: false,
    listResult: sortBy(action.payload.data, ['name'])
  };
};

const HANDLERS = {
  [types.READ.REQUEST]: utils.onReadRequest,
  [types.READ.RESPONSE]: utils.onReadResponse,
  [types.LIST.REQUEST]: utils.onListRequest,
  [types.LIST.RESPONSE]: onClanListResponse,
  [types.SET_CURRENT_CLAN]: onSetCurrentClan,
  [types.RESET_CURRENT_CLAN]: onResetCurrentClan
};

export const clans = createReducers(INITIAL_STATE, HANDLERS);
