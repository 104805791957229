/* A button which triggers a popover with
   a content prop being what is rendered
   inside the popover
*/

import React from 'react';
import {
  Button,
  Popover,
  Position,
  IButtonProps,
  IPopoverProps
} from '@blueprintjs/core';

interface PopoverButtonProps {
  popoverProps: Omit<IPopoverProps, 'content'>;
  buttonProps: IButtonProps & React.HTMLAttributes<HTMLElement>;
  content: IPopoverProps['content'];
  disabled?: boolean;
}

export const PopoverButton: React.FC<PopoverButtonProps> = ({
  popoverProps,
  buttonProps,
  content,
  disabled = false
}) => {
  return (
    <Popover
      {...popoverProps}
      disabled={disabled}
      content={content}
      position={
        popoverProps && popoverProps.position
          ? popoverProps.position
          : Position.BOTTOM
      }
      target={<Button {...buttonProps} disabled={disabled} />}
    />
  );
};
