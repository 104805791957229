import React from 'react';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {authCreators} from '~/actions/auth';
import {safeSetItem} from '~/utils/localStorage';
import {BaseDiscordButton} from './BaseDiscordButton';

export const DiscordLoginButtonComponent = ({text, reset}) => {
  const location = useLocation();

  return (
    <BaseDiscordButton
      className={'etro-form-submit etro-discord-button-fill'}
      link={`${process.env.REACT_APP_DISCORD_CB}`}
      text={text}
      iconProps={{
        className: 'etro-icon-small'
      }}
      // Remove any auth state/tokens before redirecting off site or it can cause stale state to persist
      // if the user was on a different account before when they return.
      onClick={() => {
        safeSetItem('discordCallbackFrom', location.pathname);
        reset();
      }}
    />
  );
};

const actions = {
  reset: authCreators.reset
};

export const DiscordLoginButton = connect(
  null,
  actions
)(DiscordLoginButtonComponent);
