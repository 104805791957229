import React from 'react';
import {FormGroup, Icon} from '@blueprintjs/core';

export const EtroFormGroup = ({
  label,
  inline,
  icon,
  children,
  contentClassName
}) => {
  return (
    <FormGroup
      className="etro-form-group-wrapper"
      inline={inline}
      contentClassName={contentClassName}
      label={
        <div className="etro-form-group-label">
          {icon && (
            <Icon
              icon={
                icon.includes('/i/') ? (
                  <img
                    src={`${process.env.REACT_APP_ICON_BASE_URL}${icon}`}
                    alt={`${label}-icon`}
                  />
                ) : (
                  icon
                )
              }
            />
          )}
          {label}
        </div>
      }
    >
      {children}
    </FormGroup>
  );
};
