import React, {useEffect} from 'react';
import {Radio, RadioGroup} from '@blueprintjs/core';
import {EtroFormGroup} from './EtroFormGroup';

export const EtroRadioGroup = props => {
  const {
    label,
    options,
    icon,
    inline,
    onChange,
    selectedValue,
    requireValue,
    disabled
  } = props;

  /* 
    This is the easiest way to remove the BP radio button itself
    since its essentially a span nested in the input
  */
  useEffect(() => {
    Array.from(
      document.getElementsByClassName('bp3-control-indicator')
    ).forEach(element => {
      const {parentNode} = element;

      if (parentNode?.classList?.contains('bp3-radio')) {
        parentNode.removeChild(element);
      }
    });
  }, [props]);

  /* 
    RadioGroup supports its own label, but it messes with the CSS
    because of the HTML label elements, BP and classNames. So, it is
    much easier to just wrap it in FormGroup
  */
  return (
    <EtroFormGroup inline={inline} label={label} icon={icon}>
      <RadioGroup
        className={'etro-radio-group'}
        onChange={onChange}
        selectedValue={selectedValue}
        disabled={disabled}
      >
        {options?.map(o => {
          const {label, value} = o;
          return (
            <Radio
              className={
                value === selectedValue
                  ? 'etro-radio etro-radio-checked'
                  : 'etro-radio'
              }
              label={label}
              value={value}
              key={`${label}-etro-radio-group-option-${value}`}
              onClick={e => {
                if (
                  !requireValue &&
                  `${e.currentTarget.value}` === `${selectedValue}`
                ) {
                  onChange(undefined);
                }
              }}
            />
          );
        })}
      </RadioGroup>
    </EtroFormGroup>
  );
};
