import React, {useState, useEffect} from 'react';
import {Position, Tooltip, Button, Drawer} from '@blueprintjs/core';
import {useWindowSize} from 'react-use';
import {EtroAffix} from '~/components';
import {HEADER_HEIGHT} from '../Header';
import {IconLayoutSidebarLeftExpand} from '@tabler/icons-react';

export const CollapseDrawer = ({
  children,
  sidebarVisible,
  toggleSidebarVisible,
  onTabChange,
  currentTab
}) => {
  const [autoHideSidebar, setAutoHideSidebar] = useState(false),
    {width} = useWindowSize();

  useEffect(() => {
    // sidebarWidth + tabButtons + cardWidth + 3% "marginRight"
    const minWidth = 816 + width * 0.03;
    if (width <= minWidth) {
      setAutoHideSidebar(true);
    } else if (width > minWidth) {
      setAutoHideSidebar(false);
    }
  }, [width, setAutoHideSidebar, onTabChange]);

  useEffect(() => {
    toggleSidebarVisible(!autoHideSidebar);
  }, [autoHideSidebar, toggleSidebarVisible]);

  /* 
    When expanding past minWidth, the currentTab can still be null but
    sidebarVisible can be true. This causes the state to get out of sync
    and the sidebar is not actually visible, but the close button is there.
  */
  useEffect(() => {
    if (!autoHideSidebar && sidebarVisible && !currentTab) {
      onTabChange();
    }
  });

  return (
    <>
      {autoHideSidebar ? (
        <>
          {/* zIndex is so it is below the drawer */}
          <EtroAffix position={{top: HEADER_HEIGHT}} zIndex={199}>
            <Tooltip
              position={Position.RIGHT}
              boundary={'viewport'}
              content={'Show Sidebar'}
            >
              <Button
                large={true}
                icon={<IconLayoutSidebarLeftExpand />}
                onClick={onTabChange}
                className="etro-button-hover-primary-fill"
              />
            </Tooltip>
          </EtroAffix>
          <Drawer
            className={'etro-sidebar-drawer'}
            isOpen={sidebarVisible}
            position={Position.LEFT}
            lazy={false}
            transitionDuration={100}
          >
            {children}
          </Drawer>
        </>
      ) : (
        children
      )}
    </>
  );
};
