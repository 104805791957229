import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {Button} from '@blueprintjs/core';
import {EtroBox} from '~/components';
import {pick} from 'lodash';
import {useDebounce} from 'react-use';
import {
  useDGFilterContext,
  ColumnOption,
  getOperatorsByFilterType,
  EtroFilterStateValue,
  nonInputFilters
} from '.';
import {EtroSelect} from '../EtroSelect/EtroSelect';
import {EtroInput} from '../EtroInput';
import {EtroDGColumnType} from './types';

interface FilterProps {
  columnOptions: ColumnOption[];
  handleRemoveFilter: (id: string) => void;
  id: string;
  removeDisabled: boolean;
}

const FORM_GROUP_STYLE: React.CSSProperties = {
  maxWidth: '150px',
  paddingRight: '8px'
};

export const Filter: React.FC<FilterProps> = ({
  columnOptions,
  handleRemoveFilter,
  id,
  removeDisabled
}) => {
  const {filters, setFilterState} = useDGFilterContext();
  const filter = filters.filterState[id];
  const [internalValue, setInternalValue] = useState(filter.value ?? '');
  const isSelectFilter = useMemo(() => {
    return filter.type === EtroDGColumnType.select;
  }, [filter.type]);
  const operatorOptions = useMemo(() => getOperatorsByFilterType(filter.type), [
    filter
  ]);
  const currentColumn = useMemo(() => {
    return columnOptions.find(({columnKey}) => columnKey === filter.columnKey);
  }, [columnOptions, filter]);
  const currentOperator = useMemo(() => {
    return operatorOptions.find(({value}) => value === filter.operator);
  }, [filter, operatorOptions]);
  const onColumnChange = useCallback(
    (column: ColumnOption) => {
      setFilterState(id, {
        ...pick(column, ['columnKey', 'name', 'type']),
        // Don't reset operator if the options stay the same
        operator: column.type === filter.type ? filter.operator : null,
        value: ''
      });
    },
    [filter, id, setFilterState]
  );
  const onOperatorChange = useCallback(
    (operator: typeof operatorOptions[0]) => {
      setFilterState(id, {
        ...filter,
        operator: operator.value
      });
    },
    [filter, id, setFilterState]
  );
  const onValueChange = useCallback(
    (value: EtroFilterStateValue) => {
      setFilterState(id, {...filter, value});
    },
    [filter, id, setFilterState]
  );
  const valueFieldDisabled = useMemo(
    () => (filter.operator ? nonInputFilters.includes(filter.operator) : false),
    [filter]
  );
  const valueOptions = useMemo(() => {
    if (isSelectFilter) {
      return filters.filterColumns[filter.columnKey].options;
    }
  }, [filter.columnKey, filters.filterColumns, isSelectFilter]);
  const currentValueOption = useMemo(() => {
    if (isSelectFilter) {
      return valueOptions?.find(({value}) => value === filter.value);
    }
  }, [filter.value, isSelectFilter, valueOptions]);

  // Debounce the value input so table isn't changing while the user is still typing
  useDebounce(
    () => {
      if (internalValue !== filter.value) {
        onValueChange(internalValue);
      }
    },
    250,
    [internalValue]
  );

  // Keep internal in sync with events (clear button) which change the context
  useEffect(
    () => {
      setInternalValue(filter?.value ?? '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter?.value]
  );

  return (
    <EtroBox
      sx={{
        display: 'flex',
        textAlign: 'left',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
    >
      <EtroSelect
        className="etro-select-small"
        classNamePrefix="etro-select-small"
        formGroupProps={{style: FORM_GROUP_STYLE}}
        label="Column"
        onChange={onColumnChange}
        preMappedOptions={columnOptions}
        value={currentColumn}
      />
      <EtroSelect
        className="etro-select-small"
        classNamePrefix="etro-select-small"
        formGroupProps={{style: FORM_GROUP_STYLE}}
        label="Operator"
        onChange={onOperatorChange}
        preMappedOptions={operatorOptions}
        // Pass null when undefined other can show stale state on clear
        value={currentOperator ?? null}
      />
      {isSelectFilter ? (
        <EtroSelect
          className="etro-select-small"
          classNamePrefix="etro-select-small"
          isDisabled={valueFieldDisabled}
          formGroupProps={{style: FORM_GROUP_STYLE}}
          label="Value"
          onChange={({value}: {value: string}) => onValueChange(value)}
          preMappedOptions={valueOptions}
          value={currentValueOption ?? null}
        />
      ) : (
        <EtroInput
          clearable
          disabled={valueFieldDisabled}
          formGroupProps={{style: FORM_GROUP_STYLE}}
          label="Value"
          onChange={setInternalValue}
          value={internalValue}
        />
      )}
      <Button
        className={'etro-dg-filter-remove'}
        icon="small-cross"
        onClick={() => handleRemoveFilter(id)}
        minimal
        disabled={removeDisabled}
      />
    </EtroBox>
  );
};
