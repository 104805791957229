import React from 'react';
import {EtroDataGridProps} from '.';
import {FilteringToolbar} from './FilteringToolbar';
import {EtroTitle} from '~/components';

export interface EtroDataGridHeaderProps<T>
  extends Pick<
    EtroDataGridProps<T>,
    'disableFiltering' | 'headerActions' | 'title'
  > {
  rowLength: number;
}

export function EtroDataGridHeader<T>({
  disableFiltering,
  headerActions,
  rowLength,
  title
}: EtroDataGridHeaderProps<T>) {
  return (
    <>
      {title && (
        <EtroTitle order={2} mb="xs">
          {title}
        </EtroTitle>
      )}
      <div className="etro-dg-header">
        <div className="etro-dg-header-actions">{headerActions}</div>
        <div className="etro-dg-header-filters">
          {!disableFiltering && (
            <FilteringToolbar<T> title={title} rowLength={rowLength} />
          )}
        </div>
      </div>
    </>
  );
}
