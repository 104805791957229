import React from 'react';
import {Stack, StackProps} from '@mantine/core';

export interface EtroStackProps extends StackProps {}

const defaultProps: Partial<EtroStackProps> = {};

export const EtroStack: React.FC<EtroStackProps> = props => {
  return <Stack {...defaultProps} {...props} />;
};
