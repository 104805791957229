import React from 'react';
import {useToggle} from 'react-use';
import {EtroButton, EtroModal, EtroTabs, EtroText} from '~/components';
import {Login} from '~/views/Login/Login';
import {Registration} from '~/views/Registration/Registration';
import {IconLogin} from '@tabler/icons-react';
import {useTranslation} from 'react-i18next';

export const AuthModal: React.FC = () => {
  const [visible, toggleVisible] = useToggle(false);
  const {t} = useTranslation();

  return (
    <>
      <EtroButton
        leftIcon={<IconLogin />}
        onClick={toggleVisible}
        size="md"
        variant="subtle"
      >
        <EtroText size="sm">{t('login')}</EtroText>
      </EtroButton>
      <EtroModal
        centered
        closeOnEscape
        onClose={toggleVisible}
        opened={visible}
        size={500}
        title={`${t('login to')} Etro`}
      >
        <EtroTabs defaultValue={'login'} keepMounted={false}>
          <EtroTabs.List>
            <EtroTabs.Tab value="login">{t('login')}</EtroTabs.Tab>
            <EtroTabs.Tab value="registration">{t('register')}</EtroTabs.Tab>
          </EtroTabs.List>
          <EtroTabs.Panel value="login">
            <Login authDialogToggle={toggleVisible} />
          </EtroTabs.Panel>
          <EtroTabs.Panel value="registration">
            <Registration authDialogToggle={toggleVisible} />
          </EtroTabs.Panel>
        </EtroTabs>
      </EtroModal>
    </>
  );
};
