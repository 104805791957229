import React from 'react';
import {createPolymorphicComponent, Paper, PaperProps} from '@mantine/core';

export interface EtroPaperProps extends PaperProps {}

// Match BP
const defaultProps: Partial<EtroPaperProps> = {
  p: 'lg',
  radius: 'sm'
};

const _EtroPaper: React.FC<EtroPaperProps> = props => {
  return <Paper {...defaultProps} {...props} />;
};

export const EtroPaper = createPolymorphicComponent<'div', EtroPaperProps>(
  _EtroPaper
);
