import React, {useState, useMemo} from 'react';
import {Intent} from '@blueprintjs/core';
import {useCopyToClipboard, useToggle} from 'react-use';
import {useListBISGearsetsQuery} from '~/api';
import {
  EtroDataGrid,
  EtroDataGridAction,
  EtroDGColumn,
  IconCellRenderer,
  SortingAlgorithms,
  useLinkRowRenderer
} from '~/components/EtroDataGrid';
import {Gearset} from '~/types';
import {currentPatch} from '~/constants';
import {onSuccess} from '~/components/AppToaster/AppToaster';
import {GearsetCloneDialog} from './GearsetCloneDialog';
import {EtroPaper} from '~/components/EtroPaper';
import {useSelector} from '~/hooks';
import {useTranslation} from 'react-i18next';

export const GearsetBISList: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<Gearset | null>(null);
  const {isAuthenticated} = useSelector(({auth}) => {
    return {isAuthenticated: auth.isAuthenticated};
  });
  const [, copyShareLink] = useCopyToClipboard();
  const [cloneDialogVisible, toggleCloneDialog] = useToggle(false);
  const {t} = useTranslation();
  const {data: bisGearsets} = useListBISGearsetsQuery();
  const linkRowRenderer = useLinkRowRenderer<Gearset>('/gearset/', 'id');
  const columns: readonly EtroDGColumn<Gearset>[] = useMemo(() => {
    return [
      {
        frozen: true,
        key: 'jobAbbrev',
        name: t('job'),
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
        filterable: true,
        type: 'select',
        formatter: ({row}) => (
          <IconCellRenderer iconPath={row.jobIconPath} value={row.jobAbbrev} />
        )
      },
      {
        filterable: true,
        frozen: true,
        key: 'name',
        name: t('name'),
        resizable: true,
        sortable: true,
        type: 'string'
      },
      {
        key: 'id',
        name: t('action'),
        formatter: ({row}) => (
          <span className="gearset-list-actions">
            <EtroDataGridAction
              content={`${t('copy')} ${t('gearset')} ${t('link')}`}
              icon="link"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                copyShareLink(`${window.location.href}gearset/${row.id}`);
                onSuccess(
                  `${row.name} gearset link copied to clipboard.`,
                  Intent.PRIMARY,
                  'clipboard'
                );
              }}
            />
            {isAuthenticated && (
              <EtroDataGridAction
                content={`${t('clone')} ${t('gearset')}`}
                icon="duplicate"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedRow(row);
                  toggleCloneDialog(true);
                }}
              />
            )}
          </span>
        )
      }
    ];
  }, [copyShareLink, isAuthenticated, t, toggleCloneDialog]);

  return (
    <>
      <EtroPaper
        sx={{
          '.rdg': {
            /* 
              Force column widths because rdg column calc can't handle scrollbar
              when inside of Home layout. Maybe fixes itself with more columns?
             */
            gridTemplateColumns: `90px auto 100px !important`
          }
        }}
      >
        <EtroDataGrid<Gearset>
          {...linkRowRenderer}
          columns={columns}
          noDataText={'No gearsets available.'}
          rows={bisGearsets ?? []}
          title={`Best In Slot ${t('gearset')} (${currentPatch.value.toFixed(
            1
          )})`}
        />
      </EtroPaper>
      {cloneDialogVisible && (
        <GearsetCloneDialog
          tableMode={true}
          isOpen={cloneDialogVisible}
          toggle={() => {
            toggleCloneDialog(!cloneDialogVisible);
            setSelectedRow(null);
          }}
          row={selectedRow}
        />
      )}
    </>
  );
};
