import {orderBy} from 'lodash';
import {createReducers} from 'redux-arc';
import {types} from '../actions/medicine';
import * as utils from './utils';
import {hqPotCooldownDeltaS, paramNames, ORDER_KEY} from '~/constants';

export const INITIAL_STATE = {
  listResult: [],
  listIsLoading: false,
  listError: null,
  maxPotions: {},
  readResult: null,
  readIsLoading: false,
  readError: null
};

const getMaxPotions = listResult => {
  return listResult.reduce((acc, item) => {
    const {cooldownS, param0, maxHQ0, valueHQ0} = item;

    if (acc[param0] && acc[param0].maxHQ0 > maxHQ0) {
      return acc;
    }

    const effect = `${paramNames[param0]} +${valueHQ0}% (Max ${maxHQ0})`;
    acc[param0] = {...item, cooldownS: cooldownS - hqPotCooldownDeltaS, effect};

    return acc;
  }, {});
};

const onMedicineListResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      listIsLoading: false,
      listError:
        (action.payload.response && action.payload.response.data) || true,
      listResult: []
    };
  }

  const listResult = orderBy(action.payload.data, ...ORDER_KEY);

  return {
    ...state,
    listIsLoading: false,
    listResult,
    itemLevelRange: [
      action.payload.config.params.minItemLevel,
      action.payload.config.params.maxItemLevel
    ],
    maxPotions: getMaxPotions(listResult)
  };
};

const HANDLERS = {
  [types.READ.REQUEST]: utils.onReadRequest,
  [types.READ.RESPONSE]: utils.onReadResponse,
  [types.LIST.REQUEST]: utils.onListRequest,
  [types.LIST.RESPONSE]: onMedicineListResponse
};

export const medicine = createReducers(INITIAL_STATE, HANDLERS);
