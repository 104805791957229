export const safeGetItem = (key, raw = true) => {
  try {
    return raw
      ? localStorage.getItem(key)
      : JSON.parse(localStorage.getItem(key));
  } catch {
    return null;
  }
};

export const safeSetItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch {
    return false;
  }
};

export const safeRemoveItem = key => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
};
