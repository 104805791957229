import {etroApi} from '.';
import {PVPTeam} from '~/types';

const PVPTEAM_BASE = 'pvpteam/';

const pvpTeamsApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readPVPTeam: query<PVPTeam, number>({
      query: id => `${PVPTEAM_BASE}${id}`
    }),
    listPVPTeams: query<PVPTeam[], void>({
      query: () => PVPTEAM_BASE
    })
  })
});

export const {useReadPVPTeamQuery, useListPVPTeamsQuery} = pvpTeamsApi;
