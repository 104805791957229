import React, {useRef, useEffect} from 'react';
import {Formik} from 'formik';
import {isEmpty} from 'lodash';
import {useHistory, useParams} from 'react-router-dom';
import {authCreators} from '~/actions/auth';
import {InputWithError} from '../../components/Form/InputWithError';
import {SubmitWithError} from '../../components/Form/SubmitWithError';
import {resetPasswordSchema} from '~/utils/formValidationSchema';
import {AppToaster} from '~/components/AppToaster/AppToaster';
import {EtroContainer, EtroStack, EtroTitle, NitroPayAd} from '~/components';
import {useMantineTheme} from '@mantine/core';
import {useDispatch, useSelector} from '~/hooks';
import {ReduxArcResponse, Awaited} from '~/types';

type PasswordResetFormFields = {
  password: string;
  passwordConfirm: string;
};
type PasswordResetUrlParams = {uid: string; token: string};

const onSuccess = () => {
  AppToaster.show({
    message: 'Password reset complete, please login.',
    intent: 'success',
    icon: 'tick'
  });
};

const onFailure = () => {
  AppToaster.show({
    message: 'Invalid password reset link.',
    intent: 'danger',
    icon: 'issue'
  });
};

const {passwordReset} = authCreators;

export const PasswordReset: React.FC = () => {
  const {passwordResetError, passwordResetIsLoading} = useSelector(
    ({auth}) => auth
  );
  const {breakpoints} = useMantineTheme();
  const formRef = useRef<Formik<PasswordResetFormFields>>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const {uid, token} = useParams<PasswordResetUrlParams>();

  useEffect(() => {
    if (
      !!passwordResetError &&
      !isEmpty(passwordResetError) &&
      !!formRef.current
    ) {
      passwordResetError.forEach(({field, message}) => {
        if (field === 'new_password1' || field === 'new_password2') {
          formRef.current?.setFieldError(
            'password',
            // @ts-ignore setFieldError accepts a string[], Formik type wrong
            message
          );
        } else if (field === 'uid' || field === 'token') {
          onFailure();
          history.push('/');
        }
      });
    }
  }, [history, passwordResetError]);

  return (
    <EtroContainer>
      <EtroStack align={'center'} spacing={'xl'}>
        <NitroPayAd placementId="etro-ad-pw-reset-top" type="bannerLarge" />
        <EtroTitle color="etro" order={1} align="center">
          {'Reset Password'}
        </EtroTitle>
        <EtroStack
          spacing={'xl'}
          sx={{maxWidth: breakpoints.xs, textAlign: 'left', width: '100%'}}
        >
          <Formik
            ref={formRef}
            initialValues={{password: '', passwordConfirm: ''}}
            validationSchema={resetPasswordSchema}
            onSubmit={values => {
              const {password, passwordConfirm} = values;
              dispatch(
                passwordReset({
                  new_password1: password,
                  new_password2: passwordConfirm,
                  uid,
                  token
                })
              ).then((r: Awaited<ReduxArcResponse>) => {
                if (r?.status === 200) {
                  onSuccess();
                  history.push('/');
                }
              });
            }}
          >
            {({errors, handleChange, handleSubmit, touched}) => (
              <form onSubmit={handleSubmit} noValidate>
                {/* @ts-ignore missing props*/}
                <InputWithError
                  errorContent={errors.password}
                  hasErrors={errors.password && touched.password}
                  label="Password"
                  handleChange={handleChange}
                  inputType="password"
                />
                {/* @ts-ignore missing props*/}
                <InputWithError
                  errorContent={errors.passwordConfirm}
                  hasErrors={errors.passwordConfirm && touched.passwordConfirm}
                  label="Confirm Password"
                  handleChange={handleChange}
                  inputType="password"
                  id="passwordConfirm"
                />
                <SubmitWithError loading={passwordResetIsLoading} />
              </form>
            )}
          </Formik>
        </EtroStack>
        <NitroPayAd placementId="etro-ad-pw-reset-bottom" type="bannerMed" />
      </EtroStack>
    </EtroContainer>
  );
};
