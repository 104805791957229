import React, {useEffect} from 'react';
import {EtroBox} from '~/components';
import queryStringParser from 'query-string';
import {useLocation} from 'react-router';
import {useSelector, useDispatch} from '~/hooks';
import {userCreators} from '~/actions';

/* 
  Use a nested theme provider so that we don't have to worry about local storage access
  which the default theme provider is using due to Redux not being available.
*/
export const EmbedWrapper: React.FC = ({children}) => {
  const dispatch = useDispatch();
  const {darkMode} = useSelector(({user}) => {
    return {darkMode: user.darkMode};
  });
  const location = useLocation();
  const queryParams = queryStringParser.parse(location.search);

  useEffect(() => {
    if (queryParams?.theme) {
      const {theme} = queryParams;

      if (theme === 'light' && darkMode) {
        dispatch(userCreators.setDarkMode(false));
      }
    } else if (!darkMode) {
      dispatch(userCreators.setDarkMode(true));
    }
  }, [darkMode, dispatch, queryParams]);

  document.body.className = darkMode ? 'bp3-dark' : 'bp3-body';

  return (
    <EtroBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '20px'
      }}
    >
      {children}
    </EtroBox>
  );
};
