import React from 'react';
import {Dialog, DialogProps} from '@mantine/core';

export interface EtroDialogProps extends DialogProps {}

const defaultProps: Partial<EtroDialogProps> = {};

export const EtroDialog: React.FC<EtroDialogProps> = props => {
  return <Dialog {...defaultProps} {...props} />;
};
