import React, {useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {EtroSelect} from '~/components/EtroSelect/EtroSelect';
import {jobsCreators} from '~/actions/jobs';
import {getSimilarJobs} from '~/constants';
import {useTranslation} from 'react-i18next';

const JobSelectorComponent = ({
  additionalOnChange,
  currentJob,
  defaultId,
  hasErrors,
  listError,
  listIsLoading,
  listJobs,
  listResult,
  onChange: propsOnChange,
  setCurrentJob,
  setFormValue,
  value: propsValue
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (isEmpty(listResult) && !listIsLoading && !listError) {
      listJobs();
    }
  }, [listResult, listJobs, listIsLoading, listError]);

  const onChange = useCallback(
    job => {
      if (currentJob?.id !== job?.id) {
        setCurrentJob(job);
      }

      if (setFormValue) {
        setFormValue('job', job ? job.id : null);
      }
    },
    [setCurrentJob, setFormValue, currentJob]
  );

  return (
    <EtroSelect
      value={propsValue ?? currentJob}
      onChange={job => {
        if (propsOnChange) {
          return propsOnChange(job);
        }

        onChange(job);
        additionalOnChange?.(job);
      }}
      defaultId={defaultId}
      options={listResult}
      isLoading={listIsLoading}
      useIcons={true}
      label={t('job')}
      placeholder="Select a job..."
      menuPortalTarget={document.body}
      hasErrors={hasErrors}
    />
  );
};

const actions = {
  setCurrentJob: jobsCreators.setCurrentJob,
  listJobs: jobsCreators.list
};

const mapStateToProps = (state, props) => {
  const {currentJob, listResult} = state.jobs;
  const {value, filterSimilarJobs, battleOnly} = props;
  const filterByJob = value ?? currentJob;
  let filteredJobs = listResult;

  if (filterSimilarJobs && filterByJob) {
    if (filterByJob.isCrafting) {
      filteredJobs = listResult.filter(j => j.isCrafting);
    } else if (filterByJob.isGathering) {
      filteredJobs = listResult.filter(j => j.isGathering);
    } else {
      const similarJobs = getSimilarJobs(filterByJob.abbrev);
      filteredJobs = listResult.filter(({abbrev}) =>
        similarJobs.includes(abbrev)
      );
    }
  } else if (battleOnly) {
    filteredJobs = listResult.filter(j => !j.isCrafting && !j.isGathering);
  }

  return {
    currentJob,
    listIsLoading: state.jobs.listIsLoading,
    listResult: filteredJobs,
    listError: state.jobs.listError
  };
};

export const JobSelector = connect(
  mapStateToProps,
  actions
)(JobSelectorComponent);
