import React, {useCallback} from 'react';
import {
  EtroSelect,
  EtroSelectProps,
  EtroMenu,
  EtroButton,
  EtroText,
  EtroMenuProps
} from '~/components';
import {useTranslation} from 'react-i18next';
import {IconLanguage} from '@tabler/icons-react';

export interface LanguageSelectProps
  extends Omit<EtroSelectProps, 'data' | 'value' | 'onChange'> {}

export interface LanguageMenuProps extends EtroMenuProps {
  className?: string;
}

const languageOptions = [
  {value: 'de', label: 'Deutsch'},
  {value: 'en', label: 'English'},
  {value: 'fr', label: 'Français'},
  {value: 'ja', label: '日本語'}
];

export const LanguageSelect = React.forwardRef<
  HTMLInputElement,
  LanguageSelectProps
>((props, ref) => {
  const {i18n} = useTranslation();
  const _onChange: NonNullable<
    EtroSelectProps['onChange']
  > = useCallback(
    value =>
      i18n
        .changeLanguage(value ?? undefined)
        .then(() => window.location.reload()),
    [i18n]
  );

  return (
    <EtroSelect
      clearable={false}
      icon={<IconLanguage />}
      placeholder="Select a language..."
      ref={ref}
      searchable={false}
      sx={{maxWidth: '120px'}}
      {...props}
      data={languageOptions}
      onChange={_onChange}
      value={i18n.resolvedLanguage}
    />
  );
});

export const LanguageMenu: React.FC<LanguageMenuProps> = props => {
  const {i18n} = useTranslation();
  const _onChange: NonNullable<
    EtroSelectProps['onChange']
  > = useCallback(
    value =>
      i18n
        .changeLanguage(value ?? undefined)
        .then(() => window.location.reload()),
    [i18n]
  );

  return (
    <EtroMenu {...props}>
      <EtroMenu.Target>
        <EtroButton size="md" leftIcon={<IconLanguage />} variant="subtle">
          <EtroText size="sm">{i18n.resolvedLanguage.toUpperCase()}</EtroText>
        </EtroButton>
      </EtroMenu.Target>
      <EtroMenu.Dropdown>
        {languageOptions.map(({label, value}) => (
          <EtroMenu.Item key={value} onClick={() => _onChange(value)}>
            {label}
          </EtroMenu.Item>
        ))}
      </EtroMenu.Dropdown>
    </EtroMenu>
  );
};
