import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {Button, Position} from '@blueprintjs/core';
import {EtroBadge, EtroBox} from '~/components';
import {v4 as uuid} from 'uuid';
import {omit} from 'lodash';
import {useDGFilterContext, Filter} from '.';
import {PopoverButton} from '~/components/PopoverButton/PopoverButton';
import {useTranslation} from 'react-i18next';

export const FilteringPopover: React.FC = () => {
  const {
    clearFilters,
    filterCount,
    filters,
    removeFilter,
    setFilterState
  } = useDGFilterContext();
  const [filterIds, setFilterIds] = useState<string[]>([uuid()]);
  const {t} = useTranslation();
  const [columnOptions, firstColumn] = useMemo(() => {
    return [
      Object.values(filters.filterColumns).map(column => {
        return {
          ...omit(column, 'options'),
          value: column.columnKey,
          label: column.name
        };
      }),
      Object.values(filters.filterColumns)[0]
    ];
  }, [filters.filterColumns]);
  const handleAddFilter = useCallback(() => {
    const newId = uuid();
    setFilterIds(filterIds.concat(newId));
    setFilterState(newId, {
      ...omit(firstColumn, 'options'),
      operator: null,
      value: ''
    });
  }, [filterIds, firstColumn, setFilterState]);
  const handleClearFilters = useCallback(() => {
    clearFilters({
      filterState: {[filterIds[0]]: {...firstColumn, operator: null, value: ''}}
    });
    setFilterIds([filterIds[0]]);
  }, [clearFilters, filterIds, firstColumn]);
  const handleRemoveFilter = useCallback(
    (removeId: string) => {
      removeFilter(removeId);
      setFilterIds(filterIds.filter(id => id !== removeId));
    },
    [filterIds, removeFilter]
  );
  const removeDisabled = useMemo(() => filterIds.length < 2, [
    filterIds.length
  ]);
  const popoverContent = useMemo(() => {
    return (
      <EtroBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '6px',
          width: '30vw',
          minWidth: '400px',
          maxWidth: '500px'
        }}
      >
        {/* Always show 1 filter */}
        <Filter
          columnOptions={columnOptions}
          handleRemoveFilter={handleRemoveFilter}
          id={filterIds[0]}
          removeDisabled={removeDisabled}
        />
        {filterIds.slice(1).map(id => (
          <Filter
            columnOptions={columnOptions}
            handleRemoveFilter={handleRemoveFilter}
            id={id}
            key={id}
            removeDisabled={removeDisabled}
          />
        ))}
        <EtroBox
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '6px'
          }}
        >
          <Button
            className={'etro-button-hover-primary-fill'}
            outlined
            icon="filter-list"
            text="Add"
            onClick={handleAddFilter}
          />
          <Button
            className={'etro-button-hover-danger-fill'}
            outlined
            icon="filter-remove"
            onClick={handleClearFilters}
            text="Clear"
          />
        </EtroBox>
      </EtroBox>
    );
  }, [
    columnOptions,
    filterIds,
    handleAddFilter,
    handleClearFilters,
    handleRemoveFilter,
    removeDisabled
  ]);

  /* 
    Cannot handleAddFilter on mount because context/callbacks will not
    setup state properly for the first filter.
  */
  useEffect(() => {
    if (
      filterIds.length === 1 &&
      !Object.keys(filters.filterState).includes(filterIds[0])
    ) {
      setFilterState(filterIds[0], {
        ...omit(firstColumn, 'options'),
        operator: null,
        value: ''
      });
    }
  }, [filterIds, filters.filterState, firstColumn, setFilterState]);

  return (
    <EtroBox
      mb="xs"
      sx={{
        // relative allows absolute position of badge within this box
        position: 'relative'
      }}
    >
      {!!filterCount && (
        <EtroBadge
          size="md"
          variant="filled"
          sx={{
            position: 'absolute',
            top: '-0.8em',
            left: '-1.2em'
          }}
        >
          {filterCount}
        </EtroBadge>
      )}
      <PopoverButton
        content={popoverContent}
        buttonProps={{
          className: 'etro-button-hover-primary-fill etro-button-margin',
          outlined: true,
          icon: 'filter',
          text: t('filter'),
          style: {whiteSpace: 'nowrap'}
        }}
        popoverProps={{position: Position.TOP_LEFT, boundary: 'viewport'}}
      />
    </EtroBox>
  );
};
