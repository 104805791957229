import React from 'react';
import {Box, BoxProps, createPolymorphicComponent} from '@mantine/core';

export interface EtroBoxProps
  extends BoxProps,
    Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {}

const defaultProps: Partial<EtroBoxProps> = {};

const _EtroBox = React.forwardRef<HTMLDivElement, EtroBoxProps>(
  (props, ref) => {
    return <Box ref={ref} {...defaultProps} {...props} />;
  }
);

export const EtroBox = createPolymorphicComponent<'div', EtroBoxProps>(
  _EtroBox
);
