import React from 'react';
import {Switch, SwitchProps} from '@mantine/core';
import {EtroSwitchGroup} from './EtroSwitchGroup';

export interface EtroSwitchProps extends SwitchProps {}

const defaultProps: Partial<EtroSwitchProps> = {};

export const EtroSwitch: React.FC<EtroSwitchProps> & {
  Group: typeof EtroSwitchGroup;
} = props => {
  return <Switch {...defaultProps} {...props} />;
};

EtroSwitch.Group = EtroSwitchGroup;
