import {SortingAlgorithmsUnion} from '~/types';
import {SortingAlgorithms} from '~/types/enums';
import {EtroDGColumnType, EtroDGColumnTypeUnion} from '.';

export {SortingAlgorithms};

export const stringCaseInsensitive = (a: string | null, b: string | null) => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  return a.toLowerCase().localeCompare(b.toLowerCase());
};

const stringCaseSensitive = (a: string | null, b: string | null) => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  return a.localeCompare(b);
};

const number = (a: number | null, b: number | null) => {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  return a - b;
};

const notImplemented = () => {
  return 0;
};

export const getSortByName = (name: SortingAlgorithmsUnion) => {
  switch (name) {
    case SortingAlgorithms.stringCaseInsensitive:
      return stringCaseInsensitive;
    case SortingAlgorithms.stringCaseSensitive:
      return stringCaseSensitive;
    case SortingAlgorithms.number:
      return number;
    default:
      return notImplemented;
  }
};

export const getSortByType = (type: EtroDGColumnTypeUnion) => {
  switch (type) {
    case EtroDGColumnType.string:
      return stringCaseInsensitive;
    case EtroDGColumnType.number:
      return number;
    default:
      return notImplemented;
  }
};
