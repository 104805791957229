import React from 'react';
import {Footer as MantineFooter} from '@mantine/core';
import {
  EtroContainer,
  EtroGroup,
  EtroLogo,
  EtroStack,
  EtroText
} from '~/components';
import {KofiButton} from '~/components/KofiButton';
import {DiscordInviteButton} from '~/components/DiscordButton/DiscordInviteButton';
import {NitroPayButton} from '../NitroPayButton';
import {FooterLinks} from './FooterLinks';
import {useStyles} from './Footer.styles';
import {useMediaQueryClasses} from '~/hooks';
const currentVersion = require('~/constants/version');

export const FOOTER_HEIGHT = 186;

export const Footer = () => {
  const {classes} = useStyles();
  const {classes: mediaClasses} = useMediaQueryClasses();

  return (
    <MantineFooter height={FOOTER_HEIGHT} mt="xl" py="md">
      <EtroContainer px="sm" className={classes.inner}>
        <EtroStack align={'flex-start'} className={classes.logo}>
          <EtroLogo
            disableLink
            showText
            svgProps={{
              width: '100%',
              style: {width: '125px'}
            }}
          />
        </EtroStack>
        <FooterLinks className={mediaClasses.hiddenMobile} />
      </EtroContainer>
      <EtroContainer px="sm" mt="sm" pt="sm" className={classes.afterFooter}>
        <EtroText size="xs" color="dimmed">
          {`${currentVersion} by Ikuni Kuni of Gilgamesh.`}
        </EtroText>
        <span className="etro-ccpa-link" data-ccpa-link="1"></span>
        <EtroGroup
          spacing={'xs'}
          className={classes.social}
          position="right"
          noWrap
        >
          <NitroPayButton minimal disableTooltip className="etro-no-pad" />
          <KofiButton minimal className="etro-no-pad" />
          {/* @ts-ignore */}
          <DiscordInviteButton
            className={'etro-button-hover-discord-fill etro-no-pad'}
            minimal
          />
        </EtroGroup>
      </EtroContainer>
    </MantineFooter>
  );
};
