import React, {FC} from 'react';
import {EtroText} from '~/components';
import {EmbedGearsetProps, Item} from '../types';

interface RelicParamsProps extends Pick<EmbedGearsetProps, 'secondaryParams'> {
  row: Item;
}

export const RelicParams: FC<RelicParamsProps> = ({row, secondaryParams}) => {
  return (
    <>
      {secondaryParams?.map((paramName, i) => {
        if (!row[paramName as keyof typeof row]) {
          return null;
        }

        return (
          <EtroText
            key={`${row.name}-relic-param-${i}`}
            size="xs"
            color="dimmed"
            sx={{
              padding: '4px 12px 4px 0',
              whiteSpace: 'nowrap'
            }}
          >{`${paramName} +${row[paramName as keyof typeof row]}`}</EtroText>
        );
      })}
    </>
  );
};
