import {etroApi} from '.';
import {Trait, ActionTraitFilter} from '~/types';

const TRAIT_BASE = 'traits/';

const traitsApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readTrait: query<Trait, number>({
      query: id => `${TRAIT_BASE}${id}`
    }),
    listTraits: query<Trait[], ActionTraitFilter>({
      query: params => {
        return {url: `${TRAIT_BASE}`, params};
      }
    })
  })
});

export const {useReadTraitQuery, useListTraitsQuery} = traitsApi;
