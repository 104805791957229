import React, {useState, useMemo} from 'react';
import {
  useSelector,
  useDispatch,
  useFetchWhenAuth,
  useMobileMediaQuery
} from '~/hooks';
import {Intent, Button, FormGroup} from '@blueprintjs/core';
import {useCopyToClipboard, useToggle} from 'react-use';
import {useHistory} from 'react-router-dom';
import {gearsetsCreators} from '~/actions/gearsets';
import {ConfirmDialog} from '~/components/ConfirmDialog/ConfirmDialog';
import {onSuccess, onFailure} from '~/components/AppToaster/AppToaster';
import {GearsetCloneDialog} from './GearsetCloneDialog';
import {GearsetImportDialog} from '~/views/Gearset/GearsetImportDialog';
import {
  DateCellRenderer,
  EtroDataGrid,
  EtroDataGridAction,
  EtroDGColumn,
  IconCellRenderer,
  SortingAlgorithms,
  useLinkRowRenderer
} from '~/components/EtroDataGrid';
import {Gearset} from '~/types';
import {AxiosResponse} from 'axios';
import {EtroPaper} from '~/components/EtroPaper';
import {useTranslation} from 'react-i18next';

export const GearsetList: React.FC = () => {
  const {
    deleteIsLoading,
    gearsetListCached,
    isAuthenticated,
    listResult
  } = useSelector(state => {
    return {
      deleteIsLoading: state.gearsets.deleteIsLoading,
      gearsetListCached: state.gearsets.listCached,
      isAuthenticated: state.auth.isAuthenticated,
      listResult: state.gearsets.listResult?.filter(i => i.isOwner) ?? []
    };
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const linkRowRenderer = useLinkRowRenderer<Gearset>('/gearset/', 'id');
  const isMobile = useMobileMediaQuery();
  const [deleteDialogVisible, toggleDeleteDialog] = useState(false),
    [cloneDialogVisible, toggleCloneDialog] = useToggle(false),
    [selectedRow, setSelectedRow] = useState<Gearset | null>(null),
    // eslint-disable-next-line no-unused-vars
    [shareLink, copyShareLink] = useCopyToClipboard(),
    columns: readonly EtroDGColumn<Gearset>[] = useMemo(() => {
      return [
        {
          frozen: true,
          key: 'jobAbbrev',
          name: t('job'),
          sortable: true,
          sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
          filterable: true,
          type: 'select',
          formatter: ({row}) => (
            <IconCellRenderer
              iconPath={row.jobIconPath}
              value={row.jobAbbrev}
            />
          )
        },
        {
          filterable: true,
          frozen: true,
          key: 'name',
          name: t('name'),
          resizable: true,
          sortable: true,
          type: 'string'
        },
        {
          filterable: true,
          key: 'patch',
          name: t('patch'),
          sortable: true,
          type: 'number'
        },
        {
          key: 'lastUpdate',
          name: t('updated'),
          resizable: true,
          sortable: true,
          sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
          formatter: ({row}) => <DateCellRenderer value={row.lastUpdate} />
        },
        {
          key: 'id',
          name: t('action'),
          formatter: ({row}) => (
            <span className="gearset-list-actions">
              <EtroDataGridAction
                content={`${t('edit')} ${t('gearset')}`}
                icon="edit"
              />
              <EtroDataGridAction
                content={`${t('copy')} ${t('gearset')} ${t('link')}`}
                icon="link"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyShareLink(`${window.location.href}gearset/${row.id}`);
                  onSuccess(
                    `${row.name} gearset link copied to clipboard.`,
                    Intent.PRIMARY,
                    'clipboard'
                  );
                }}
              />
              <EtroDataGridAction
                content={`${t('clone')} ${t('gearset')}`}
                icon="duplicate"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedRow(row);
                  toggleCloneDialog(true);
                }}
              />
              <EtroDataGridAction
                content={`${t('delete')} ${t('gearset')}`}
                icon="trash"
                fill="danger"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedRow(row);
                  toggleDeleteDialog(!deleteDialogVisible);
                }}
              />
            </span>
          )
        }
      ];
    }, [copyShareLink, deleteDialogVisible, t, toggleCloneDialog]);

  const headerActions = useMemo(() => {
    if (!isAuthenticated) {
      return null;
    }

    return (
      <>
        <FormGroup>
          <Button
            className={'etro-button-hover-primary-fill'}
            icon={'plus'}
            text={isMobile ? undefined : t('create')}
            outlined={true}
            onClick={() => history.push(`/gearset`)}
          />
        </FormGroup>
        <GearsetImportDialog
          // Requires TS conversion or bunch of default props and logic changes
          // @ts-ignore
          tableMode={true}
        />
      </>
    );
  }, [history, isAuthenticated, isMobile, t]);

  useFetchWhenAuth(() => {
    if (!gearsetListCached) {
      dispatch(gearsetsCreators.list());
    }
  });

  return (
    <>
      <EtroPaper
        sx={{
          '.rdg': {
            /* 
              Force column widths because rdg column calc can't handle scrollbar
              when inside of Home layout. Maybe fixes itself with more columns?
             */
            gridTemplateColumns: '90px auto 60px auto 180px !important'
          }
        }}
      >
        <EtroDataGrid<Gearset>
          {...linkRowRenderer}
          columns={columns}
          headerActions={headerActions}
          noDataText={`No gearsets available.${
            isAuthenticated ? ' Click the create button above.' : ''
          }`}
          rows={listResult}
          title={t('your gearsets')}
        />
      </EtroPaper>
      {deleteDialogVisible && selectedRow && (
        <ConfirmDialog
          isOpen={deleteDialogVisible}
          toggle={() => {
            toggleDeleteDialog(!deleteDialogVisible);
            setSelectedRow(null);
          }}
          loading={deleteIsLoading}
          confirmIcon="trash"
          confirmContent={`Are you sure you want to delete ${selectedRow.name} gearset?`}
          onConfirm={async () => {
            await dispatch(
              gearsetsCreators.delete(null, {id: selectedRow.id})
            ).then((r: AxiosResponse) => {
              if (r && r.status === 204) {
                onSuccess(`${selectedRow.name} gearset deleted successfully.`);
              } else {
                onFailure(
                  `Error deleting ${selectedRow.name} gearset, please try again.`
                );
              }
            });
          }}
        />
      )}
      {cloneDialogVisible && (
        <GearsetCloneDialog
          tableMode={true}
          isOpen={cloneDialogVisible}
          toggle={() => {
            toggleCloneDialog(!cloneDialogVisible);
            setSelectedRow(null);
          }}
          row={selectedRow}
        />
      )}
    </>
  );
};
