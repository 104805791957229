import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import {isEmpty, isEqual} from 'lodash';
import {Intent, Card} from '@blueprintjs/core';
import {authCreators} from '~/actions/auth';
import {InputWithError} from '../../components/Form/InputWithError';
import {SubmitWithError} from '../../components/Form/SubmitWithError';
import {registrationSchema} from '~/utils/formValidationSchema';
import {AppToaster} from '~/components/AppToaster/AppToaster';
import {DiscordLoginButton} from '../../components/DiscordButton/DiscordLoginButton';
import {IconUserPlus} from '@tabler/icons-react';
import {Translation} from 'react-i18next';

export class RegistrationComponent extends Component {
  componentDidUpdate = prevProps => {
    const {serverErrors} = this.props;
    if (prevProps.serverErrors !== serverErrors) {
      if (!isEmpty(serverErrors) && this.form) {
        serverErrors.map(e =>
          this.form.setFieldError(
            isEqual(`${e.field}`, 'password1') ? 'password' : `${e.field}`,
            e.message
          )
        );
      }
    }
  };

  onSuccess = () => {
    AppToaster.show({
      message: 'Please confirm your email to complete registration.',
      intent: Intent.SUCCESS,
      icon: 'envelope'
    });

    this.props.authDialogToggle();
  };

  render() {
    return (
      <div className="etro-form-wrapper">
        <div className="etro-form-card-wrapper-dialog">
          <Card className="etro-form-card">
            <Formik
              ref={el => (this.form = el)}
              initialValues={{
                username: '',
                password: '',
                passwordConfirm: '',
                email: ''
              }}
              validationSchema={registrationSchema}
              onSubmit={values => {
                const {username, password, passwordConfirm, email} = values;
                this.props
                  .registration({
                    username,
                    password1: password,
                    password2: passwordConfirm,
                    email
                  })
                  .then(r => {
                    if (r && r.status === 201) {
                      this.onSuccess();
                    }
                  });
              }}
            >
              {({errors, handleChange, handleSubmit, touched}) => (
                <form className="etro-form" onSubmit={handleSubmit} noValidate>
                  <InputWithError
                    errorContent={errors.username}
                    hasErrors={errors.username && touched.username}
                    label={<Translation>{t => t('username')}</Translation>}
                    handleChange={handleChange}
                    id="username"
                  />
                  <InputWithError
                    errorContent={errors.email}
                    hasErrors={errors.email && touched.email}
                    label={<Translation>{t => t('email')}</Translation>}
                    handleChange={handleChange}
                    id="email"
                  />
                  <InputWithError
                    errorContent={errors.password}
                    hasErrors={errors.password && touched.password}
                    label={<Translation>{t => t('password')}</Translation>}
                    handleChange={handleChange}
                    inputType="password"
                    id="password"
                  />
                  <InputWithError
                    errorContent={errors.passwordConfirm}
                    hasErrors={
                      errors.passwordConfirm && touched.passwordConfirm
                    }
                    label={
                      <Translation>{t => t('confirm password')}</Translation>
                    }
                    handleChange={handleChange}
                    inputType="password"
                    id="passwordConfirm"
                  />
                  <SubmitWithError
                    errorContent={errors.serverErrors}
                    hasErrors={!!errors.serverErrors}
                    icon={<IconUserPlus />}
                    text={<Translation>{t => t('register')}</Translation>}
                    loading={this.props.loading}
                  />
                </form>
              )}
            </Formik>
            <DiscordLoginButton
              text={
                <Translation>{t => `Discord ${t('register')}`}</Translation>
              }
            />
          </Card>
        </div>
      </div>
    );
  }
}

const actions = {registration: authCreators.registration};

const mapStateToProps = state => {
  return {
    serverErrors: state.auth.registrationError,
    loading: state.auth.registrationIsLoading
  };
};

export const Registration = connect(
  mapStateToProps,
  actions
)(RegistrationComponent);
