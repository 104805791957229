import {differenceBy, sortBy, uniqBy} from 'lodash';

export const onReadRequest = state => ({
  ...state,
  readIsLoading: true,
  readError: null
});

export const onReadResponse = (state, action, sortKey, handler) => {
  if (action.error) {
    return {
      ...state,
      readIsLoading: false,
      readError:
        (action.payload.response && action.payload.response.data) || true,
      readResult: null
    };
  }

  const handledData = handler
    ? handler(action.payload.data)
    : action.payload.data;

  return {
    ...state,
    readIsLoading: false,
    readResult: handledData,
    listResult: uniqBy(
      sortBy(state.listResult.concat(handledData), sortKey),
      'id'
    )
  };
};

export const onListRequest = state => ({
  ...state,
  listCached: false,
  listIsLoading: true,
  listError: null
});

export const onListResponse = (state, action) =>
  action.error
    ? {
        ...state,
        listCached: false,
        listIsLoading: false,
        listError:
          (action.payload.response && action.payload.response.data) || true,
        listResult: []
      }
    : {
        ...state,
        listCached: true,
        listIsLoading: false,
        listResult: action.payload.data ?? []
      };

export const onCreateRequest = state => ({
  ...state,
  createIsLoading: true,
  createError: null
});

export const onCreateResponse = (state, action, sortKey, handler) => {
  if (action.error) {
    return {
      ...state,
      createIsLoading: false,
      createError: action.payload.response,
      createResult: null
    };
  }

  const handledData = handler
    ? handler(action.payload.data)
    : action.payload.data;

  return {
    ...state,
    createIsLoading: false,
    createResult: handledData,
    listResult: sortBy([...state.listResult, handledData], sortKey)
  };
};

export const onUpdateRequest = state => ({
  ...state,
  updateIsLoading: true,
  updateError: null
});

export const onUpdateResponse = (state, action, sortKey, handler) => {
  if (action.error) {
    return {
      ...state,
      updateIsLoading: false,
      updateError:
        (action.payload.response && action.payload.response.data) || true,
      updateResult: null
    };
  }

  const handledData = handler
    ? handler(action.payload.data)
    : action.payload.data;

  return {
    ...state,
    updateIsLoading: false,
    updateResult: handledData,
    listResult: sortBy(
      state.listResult.map(x => (x.id === action.meta.id ? handledData : x)),
      sortKey
    )
  };
};

export const onDeleteRequest = state => ({
  ...state,
  deleteIsLoading: true,
  deleteError: null
});

export const onDeleteResponse = (state, action) =>
  action.error
    ? {
        ...state,
        deleteIsLoading: false,
        deleteError:
          (action.payload.response && action.payload.response.data) || true,
        deleteResult: null
      }
    : {
        ...state,
        deleteIsLoading: false,
        deleteResult: action.payload.data,
        listResult: differenceBy(state.listResult, [{id: action.meta.id}], 'id')
      };

export const onCloneRequest = state => ({
  ...state,
  cloneIsLoading: true,
  cloneError: null
});

export const onCloneResponse = (state, action, sortKey, handler) => {
  if (action.error) {
    return {
      ...state,
      cloneIsLoading: false,
      cloneError: action.payload.response,
      cloneResult: null
    };
  }

  const handledData = handler
    ? handler(action.payload.data)
    : action.payload.data;

  return {
    ...state,
    cloneIsLoading: false,
    cloneResult: handledData,
    listResult: sortBy([...state.listResult, handledData], sortKey)
  };
};

export const onResetResults = state => {
  return {
    ...state,
    cloneResult: null,
    createResult: null,
    importResult: null,
    listCached: false,
    listResult: [],
    readResult: null,
    updateResult: null
  };
};

export const onGenericRequest = (state, key) => ({
  ...state,
  [`${key}IsLoading`]: true,
  [`${key}Error`]: null
});

export const mapFieldError = (slice, fieldName, replaceText = 'This field') => {
  return slice?.replace(replaceText, fieldName) ?? true;
};
