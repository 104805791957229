import {createActions} from 'redux-arc';
import {GET, POST, PUT, DELETE} from './types';

const relicApiBase = 'relic/';

export const {types, creators: relicCreators} = createActions('relic', {
  read: {url: `${relicApiBase}:id/`, method: GET},
  list: {url: relicApiBase, method: GET},
  create: {url: relicApiBase, method: POST},
  update: {url: `${relicApiBase}:id/`, method: PUT},
  delete: {url: `${relicApiBase}:id/`, method: DELETE},
  clone: {url: `${relicApiBase}:id/clone/`, method: GET},
  resetResults: null,
  resetWatches: null
});
