import React, {useMemo} from 'react';
import {RangeSlider, RangeSliderProps} from '@mantine/core';
import {EtroInputWrapper, EtroInputWrapperProps} from '../EtroInputWrapper';

export interface EtroRangeSliderProps extends RangeSliderProps {
  markAmount?: number;
  wrapperProps?: Omit<EtroInputWrapperProps, 'children'>;
}

const defaultProps: Partial<EtroRangeSliderProps> = {
  labelAlwaysOn: true,
  minRange: 0,
  size: 'sm',
  thumbSize: 16
};

// https://stackoverflow.com/a/18953446/7049826
const roundToStep = (x: number, step: number) => {
  return Math.ceil(x / step) * step;
};

export const EtroRangeSlider = React.forwardRef<
  HTMLDivElement,
  EtroRangeSliderProps
>(({markAmount, wrapperProps, ...rest}, ref) => {
  const _marks: EtroRangeSliderProps['marks'] = useMemo(() => {
    const {marks, min, max, step} = rest;

    if (marks) {
      return marks;
    }

    if (!min || !max || !markAmount || !step) {
      return undefined;
    }

    if (markAmount <= 2) {
      return [
        {value: min, label: `${min}`},
        {value: max, label: `${max}`}
      ];
    }

    // Subtract 1 to ensure always have a max mark
    const amountPerMark = (max - min) / (markAmount - 1);
    const markStep = roundToStep(amountPerMark, step);
    const _marks: EtroRangeSliderProps['marks'] = [];

    let nextMark = min;
    for (let i = 0; i < markAmount; i++) {
      if (nextMark > max) {
        nextMark = max;
      }
      _marks.push({value: nextMark, label: `${nextMark}`});

      nextMark += markStep;
    }

    return _marks;
  }, [markAmount, rest]);

  return (
    <EtroInputWrapper {...wrapperProps}>
      <RangeSlider
        ref={ref}
        // marks are position: absolute, so create extra space for them
        mb={!!_marks ? 'lg' : undefined}
        {...defaultProps}
        {...rest}
        marks={_marks}
      />
    </EtroInputWrapper>
  );
});
