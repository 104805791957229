import React from 'react';
import {CloseButton, CloseButtonProps} from '@mantine/core';

export interface EtroCloseButtonProps extends CloseButtonProps {}

const defaultProps: Partial<EtroCloseButtonProps> = {};

export const EtroCloseButton: React.FC<EtroCloseButtonProps> = props => {
  return <CloseButton {...defaultProps} {...props} />;
};
