import React from 'react';
import {
  Button,
  Tooltip,
  Position,
  ITooltipProps,
  IButtonProps,
  Intent
} from '@blueprintjs/core';

interface EtroDataGridActionProps {
  content: ITooltipProps['content'];
  icon: IButtonProps['icon'];
  onClick?: IButtonProps['onClick'];
  fill?: Intent;
}

export const EtroDataGridAction: React.FC<EtroDataGridActionProps> = ({
  content,
  icon,
  onClick,
  fill = 'primary'
}) => {
  return (
    <Tooltip
      content={content}
      position={Position.TOP}
      boundary={'viewport'}
      targetClassName={'etro-button-popover-target'}
    >
      <Button
        className={`etro-button-hover-${fill}-fill etro-no-pad`}
        minimal={true}
        icon={icon}
        onClick={onClick}
      />
    </Tooltip>
  );
};
