import {createActions} from 'redux-arc';
import {GET, POST, PUT, DELETE} from './types';

const gearsetsApiBase = 'gearsets/';
const importApiBase = 'import/';

export const {types, creators: gearsetsCreators} = createActions('gearsets', {
  read: {url: `${gearsetsApiBase}:id/`, method: GET},
  list: {url: gearsetsApiBase, method: GET},
  create: {url: gearsetsApiBase, method: POST},
  update: {url: `${gearsetsApiBase}:id/`, method: PUT},
  delete: {url: `${gearsetsApiBase}:id/`, method: DELETE},
  clone: {url: `${gearsetsApiBase}:id/clone/`, method: GET},
  setGearset: null,
  setGearsetMateria: null,
  resetGearset: null,
  resetResults: null,
  filterItemLevel: null,
  ariyalaImport: {url: `${importApiBase}ariyala/`, method: POST},
  lodestoneImport: {url: `${importApiBase}lodestone/`, method: POST},
  xivgearImport: {url: `${importApiBase}xivgear/`, method: POST},
  resetImportError: null,
  setItemLevelRange: null,
  setMateriaTierRange: null,
  setItemLevelSync: null
});
