import React, {useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dialog, Intent, Card} from '@blueprintjs/core';
import {Formik} from 'formik';
import {isEmpty} from 'lodash';
import {authCreators} from '~/actions/auth';
import {gearsetsCreators} from '~/actions/gearsets';
import {relicCreators} from '~/actions/relic';
import {InputWithError} from '../../components/Form/InputWithError';
import {SubmitWithError} from '../../components/Form/SubmitWithError';
import {resetPasswordSchema} from '~/utils/formValidationSchema';
import {AppToaster} from '~/components/AppToaster/AppToaster';
import {IconKey} from '@tabler/icons-react';
import {useTranslation} from 'react-i18next';

const onSuccess = () => {
  AppToaster.show({
    message: 'Password change complete, please login again.',
    intent: Intent.SUCCESS,
    icon: 'tick'
  });
};

export const PasswordChangeDialogComponent = ({
  passwordChange,
  isOpen,
  toggle,
  serverErrors,
  loading,
  resetGearsetResults,
  resetRelicResults
}) => {
  const formRef = useRef();
  const {t} = useTranslation();

  useEffect(() => {
    if (!isEmpty(serverErrors) && formRef) {
      serverErrors.map(e =>
        formRef.current.setFieldError('password', e.message)
      );
    }
  }, [serverErrors]);

  return (
    <Dialog
      title={t('change password')}
      icon={<IconKey />}
      isOpen={isOpen}
      onClose={toggle}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      backdropClassName={''}
    >
      <div className="etro-form-wrapper">
        <div className="etro-form-card-wrapper-dialog">
          <Card className="etro-form-card">
            <Formik
              ref={formRef}
              initialValues={{password: '', passwordConfirm: ''}}
              validationSchema={resetPasswordSchema}
              onSubmit={values => {
                const {password, passwordConfirm} = values;
                passwordChange({
                  new_password1: password,
                  new_password2: passwordConfirm
                }).then(r => {
                  if (r && r.status === 200) {
                    resetGearsetResults();
                    resetRelicResults();
                    onSuccess();
                  }
                });
              }}
            >
              {({errors, handleChange, handleSubmit, touched}) => (
                <form className="etro-form" onSubmit={handleSubmit} noValidate>
                  <InputWithError
                    errorContent={errors.password}
                    hasErrors={errors.password && touched.password}
                    label={t('password')}
                    handleChange={handleChange}
                    inputType="password"
                    id="password"
                  />
                  <InputWithError
                    errorContent={errors.passwordConfirm}
                    hasErrors={
                      errors.passwordConfirm && touched.passwordConfirm
                    }
                    label={t('confirm password')}
                    handleChange={handleChange}
                    inputType="password"
                    id="passwordConfirm"
                  />
                  <SubmitWithError loading={loading} />
                </form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </Dialog>
  );
};

const actions = {
  passwordChange: authCreators.passwordChange,
  resetGearsetResults: gearsetsCreators.resetResults,
  resetRelicResults: relicCreators.resetResults
};

const mapStateToProps = state => {
  return {
    serverErrors: state.auth.passwordChangeError,
    loading: state.auth.passwordChangeIsLoading
  };
};

export const PasswordChangeDialog = connect(
  mapStateToProps,
  actions
)(PasswordChangeDialogComponent);
