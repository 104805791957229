import React, {useEffect} from 'react';
import {useToggle} from 'react-use';
import {Callout} from '@blueprintjs/core';
import {useDetectAdBlock} from 'adblock-detect-react';
import {useSelector} from '~/hooks';
import {EtroBox, EtroText} from '~/components';

export const AdBlockDetector: React.FC = () => {
  const [visible, setVisible] = useToggle(false);
  const adBlockDetected = useDetectAdBlock();
  const {subIsActive, loading} = useSelector(({auth}) => {
    return {
      subIsActive: auth.user?.subscription?.isActive,
      loading: auth.userIsLoading || auth.discordLoginIsLoading
    };
  });

  useEffect(() => {
    if (adBlockDetected && !loading && !subIsActive) {
      setVisible(true);
    }
  }, [adBlockDetected, loading, setVisible, subIsActive]);

  if (!visible) {
    return null;
  }

  return (
    <EtroBox sx={{maxWidth: '500px', alignSelf: 'center'}}>
      <Callout
        title="Please consider disabling your Ad Blocker"
        icon="disable"
        intent="primary"
      >
        <EtroText size="xs">
          We know ads can be annoying, but Etro is a free tool with high server
          costs which are mostly offset by ad revenue. If you prefer an ad free
          experience, please become a subscriber.
        </EtroText>
      </Callout>
    </EtroBox>
  );
};
