import {useEffect} from 'react';
import {isEmpty} from 'lodash';
import {useSelector, useDispatch} from '~/hooks';
import {clansCreators} from '~/actions';

export const useClanList = () => {
  const {listError, listIsLoading, listResult} = useSelector(
    ({clans}) => clans
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(listResult) && !listIsLoading && !listError) {
      dispatch(clansCreators.list());
    }
  }, [dispatch, listError, listIsLoading, listResult]);

  return listResult;
};
