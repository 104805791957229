import React from 'react';
import {Tabs, TabsProps} from '@mantine/core';
import {EtroTabsList} from './EtroTabsList';
import {EtroTabsPanel} from './EtroTabsPanel';
import {EtroTabsTab} from './EtroTabsTab';

export interface EtroTabsProps extends TabsProps {}
export type EtroTabsComponents = {
  List: typeof EtroTabsList;
  Panel: typeof EtroTabsPanel;
  Tab: typeof EtroTabsTab;
};

const defaultProps: Partial<EtroTabsProps> = {};

export const EtroTabs: React.FC<EtroTabsProps> & EtroTabsComponents = ({
  children,
  ...props
}) => {
  return (
    <Tabs {...defaultProps} {...props}>
      {children}
    </Tabs>
  );
};

EtroTabs.List = EtroTabsList;
EtroTabs.Panel = EtroTabsPanel;
EtroTabs.Tab = EtroTabsTab;
