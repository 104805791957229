import React from 'react';
import {Checkbox} from '@blueprintjs/core';
import {EtroFormGroup} from './EtroFormGroup';

export const EtroCheckbox = ({
  inline,
  label,
  icon,
  onChange,
  checked,
  disabled
}) => {
  return (
    <EtroFormGroup
      inline={inline}
      label={label}
      icon={icon}
      contentClassName={'etro-checkbox-wrapper'}
    >
      <Checkbox onChange={onChange} checked={checked} disabled={disabled} />
    </EtroFormGroup>
  );
};
