import {string, number, ref, mixed, object} from 'yup';

const usernameSchema = string()
  .min(2, 'Minimum username length is 2 characters')
  .max(40, 'Maximum username length is 40 characters')
  .required('Username is required');

const passwordSchema = string()
  .min(8, 'Minimum password length is 8 characters')
  .required('Password is required');

const passwordConfirmSchema = string()
  .oneOf([ref('password')], 'Passwords do not match')
  .required('Password confirmation is required');

const emailSchema = string()
  .email('Invalid email address')
  .required('Email is required');

const gearsetNameSchema = string()
  .min(2, 'Minimum gearset name length is 2 characters')
  .max(30, 'Maximum gearset name length is 30 characters')
  .required('Gearset name is required');

const ariyalaIdSchema = string()
  .min(4, 'Minimum Ariyala Gearset ID length is 4 characters')
  .max(6, 'Maximum Ariyala Gearset ID length is 6 characters')
  .required('Ariyala Gearset ID is required');

const lodestoneIdSchema = number()
  .typeError('Lodestone Character ID must be a number')
  .required('Lodestone Character ID is required');

const xivgearURLSchema = string()
  .required('xivgear URL is required')
  .url('Not a valid XivGear URL')
  .test('is-valid-xivgear-url', 'Not a valid XivGear URL', value => {
    if (!value) return false;
    if (!value.startsWith('https://xivgear.app/')) return false;
    const uuidPart = value.split('?page=sl%7C')[1];
    if (!uuidPart) return false;
    return string().uuid().isValidSync(uuidPart);
  });

const gearsetJobSchema = mixed().required('Job is required');

export const loginSchema = object().shape({
  username: usernameSchema,
  password: passwordSchema
});

export const forgotPasswordSchema = object().shape({
  email: emailSchema
});

export const registrationSchema = object().shape({
  username: usernameSchema,
  password: passwordSchema,
  passwordConfirm: passwordConfirmSchema,
  email: emailSchema
});

export const resetPasswordSchema = object().shape({
  password: passwordSchema,
  passwordConfirm: passwordConfirmSchema
});

export const gearsetSchema = object().shape({
  name: gearsetNameSchema,
  job: gearsetJobSchema
});

export const importAriyalaSchema = object().shape({
  name: gearsetNameSchema,
  ariyalaID: ariyalaIdSchema
});

export const importLodestoneSchema = object().shape({
  name: gearsetNameSchema,
  lodestoneID: lodestoneIdSchema
});

export const accountSettingsSchema = object({
  username: usernameSchema
});

export const importXivgearSchema = object().shape({
  xivgearURL: xivgearURLSchema
});
