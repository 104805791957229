import React, {useState, useCallback, useEffect} from 'react';
import {Intent, Callout} from '@blueprintjs/core';
import {ConfirmDialog} from '~/components/ConfirmDialog/ConfirmDialog';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useToggle} from 'react-use';
import {gearsetsCreators} from '~/actions/gearsets';
import {onSuccess, onFailure} from '~/components/AppToaster/AppToaster';
import {JobSelector} from '~/components/JobSelector/JobSelector';
import {EtroCheckbox} from '~/components/Form/EtroCheckbox';

const GearsetCloneDialogComponent = ({
  cloneGearset,
  cloneIsLoading,
  history,
  isOpen,
  jobListResult,
  row,
  tableMode,
  toggle
}) => {
  const {name, job, id} = row,
    [openAfterClone, toggleOpenAfterClone] = useToggle(tableMode ?? false);
  const [cloneToJob, setCloneToJob] = useState(job);
  const handleCloneToJobChange = useCallback(
    nextJob => setCloneToJob(nextJob),
    [setCloneToJob]
  );

  // job is only the id, the select needs full object
  useEffect(() => {
    handleCloneToJobChange(jobListResult.find(j => j.id === job));
  }, [job, jobListResult, handleCloneToJobChange]);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      toggle={toggle}
      loading={cloneIsLoading}
      title={`Clone ${name} Gearset`}
      confirmIntent={Intent.PRIMARY}
      icon="duplicate"
      confirmText="Clone"
      confirmIcon="duplicate"
      onConfirm={async () => {
        await cloneGearset(
          {
            job: cloneToJob.id !== job ? cloneToJob.id : undefined
          },
          {
            id
          }
        ).then(r => {
          if (r?.status === 201) {
            // Force toggle first otherwise memory leak
            if (tableMode) {
              toggle();
            }
            onSuccess(`${name} gearset cloned successfully.`);
            if (!tableMode || openAfterClone) {
              history.push(`/gearset/${r.data.id}`);
            }
          } else {
            onFailure(`Error cloning ${name} gearset, please try again.`);
          }
        });
      }}
      confirmContent={
        <>
          {!tableMode && (
            <Callout className="etro-dialog-callout" intent={Intent.WARNING}>
              Cloning this gearset will create and open a new gearset. Save any
              changes to the current gearset if you want to keep them.
            </Callout>
          )}
          <div className="etro-form-card">
            <JobSelector
              defaultId={job}
              filterSimilarJobs={true}
              onChange={handleCloneToJobChange}
              value={cloneToJob}
            />
            {tableMode && (
              <EtroCheckbox
                label={'Open After Clone?'}
                checked={openAfterClone}
                onChange={toggleOpenAfterClone}
              />
            )}
          </div>
        </>
      }
    />
  );
};

const actions = {
  cloneGearset: gearsetsCreators.clone
};

const mapStateToProps = (state, props) => {
  return {
    cloneIsLoading: state.gearsets.cloneIsLoading,
    jobListResult: state.jobs.listResult
  };
};

export const GearsetCloneDialog = withRouter(
  connect(mapStateToProps, actions)(GearsetCloneDialogComponent)
);
