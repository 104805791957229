import React, {useState, useEffect} from 'react';
import {isEmpty, values, omitBy} from 'lodash';
import {EtroRadioGroup} from '~/components/Form/EtroRadioGroup';
import {EtroCheckbox} from '~/components/Form/EtroCheckbox';
import {sumObjectsByProperty} from './GearsetHelpers';
import {
  buffTiers,
  crafterBuffs,
  gathererBuffs,
  crafterBuffParams,
  gathererBuffParams,
  specialist
} from '~/constants';

export const Buffs = ({currentJob, selectedItem, readResult, onChange}) => {
  const [defaultSet, setDefault] = useState(false),
    [currentParams, setCurrentParms] = useState({}),
    buffs = currentJob?.isCrafting ? crafterBuffs : gathererBuffs,
    buffParams = currentJob?.isCrafting
      ? crafterBuffParams
      : gathererBuffParams,
    _onChange = (key, value, params) => {
      // Can't just keep summing onChange because then params are
      // not removed on uncheck or changing tiers of same buff.
      const newParams = {...currentParams, [key]: params};
      setCurrentParms(newParams);

      const combinedParams = sumObjectsByProperty(...values(newParams)),
        cleanedItem = omitBy(selectedItem, (v, k) => k.startsWith('param'));

      let totalParams = {};
      Object.entries(combinedParams).forEach(([key, value], index) => {
        totalParams[`param${index}`] = parseInt(key);
        totalParams[`param${index}Value`] = value;
      });

      onChange({...cleanedItem, [key]: value, ...totalParams});
    };

  useEffect(() => {
    if (!defaultSet && !isEmpty(readResult)) {
      const buffKeys = Object.keys(buffParams),
        defaultParams = buffKeys.reduce((acc, key) => {
          if (readResult.hasOwnProperty(key)) {
            acc[key] =
              key === 'specialist'
                ? buffParams[key]
                : buffParams[key][readResult[key]];
          }

          return acc;
        }, {});

      setCurrentParms(defaultParams);
      onChange(readResult);
      setDefault(true);
    }
  }, [readResult, defaultSet, onChange, buffParams]);

  return (
    <div className="etro-buffs-wrapper">
      {currentJob?.isCrafting && (
        <EtroCheckbox
          label={specialist.label}
          icon={specialist.icon}
          inline={true}
          onChange={e => {
            const {checked} = e.currentTarget;
            _onChange(
              specialist.key,
              checked,
              checked ? crafterBuffParams.specialist : {}
            );
          }}
          checked={selectedItem?.specialist ?? false}
        />
      )}
      {buffs.map((b, i) => {
        const {label, icon, key} = b;
        return (
          <EtroRadioGroup
            key={`buffs-${label}`}
            label={label}
            icon={icon}
            inline={true}
            options={buffTiers}
            onChange={e => {
              const value = e?.currentTarget?.value ?? 0;
              _onChange(key, value, buffParams[key][value]);
            }}
            selectedValue={selectedItem?.[key]}
          />
        );
      })}
    </div>
  );
};
