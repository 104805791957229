import React from 'react';
import {Avatar} from '@mantine/core';

// Not exported
type AvatarGroupProps = React.ComponentProps<typeof Avatar.Group>;

export const EtroAvatarGroup: React.FC<AvatarGroupProps> = ({
  children,
  ...rest
}) => {
  return <Avatar.Group {...rest}>{children}</Avatar.Group>;
};
