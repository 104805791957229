import React, {useMemo} from 'react';
import {EtroImage, EtroImageProps, EtroSkeleton} from '~/components';

interface RemoteIconProps extends EtroImageProps {
  iconPath: string | null | undefined;
  renderSkeleton?: boolean;
}

export const RemoteIcon: React.FC<RemoteIconProps> = ({
  height,
  iconPath,
  renderSkeleton,
  width,
  ...rest
}) => {
  const placeholder = useMemo(() => {
    if (!iconPath) {
      if (renderSkeleton) {
        return <EtroSkeleton height={height} width={width} />;
      }

      return undefined;
    }
  }, [height, iconPath, renderSkeleton, width]);

  return (
    <EtroImage
      placeholder={placeholder}
      src={`${process.env.REACT_APP_ICON_BASE_URL}${iconPath}`}
      {...rest}
      height={height}
      width={width}
    />
  );
};
