import React, {useMemo, useState} from 'react';
import {
  Label,
  Button,
  Collapse,
  Tooltip,
  Position,
  Intent
} from '@blueprintjs/core';
import {EtroDataGrid, SortingAlgorithms} from '~/components/EtroDataGrid';
import {EtroBox} from '~/components/EtroBox';
import {useTranslation} from 'react-i18next';

export const StatsAndTiersTable = ({rows, isCraftOrGatherJob}) => {
  const [tableVisible, toggleTableVisible] = useState(true);
  const {t} = useTranslation();
  const columns = useMemo(() => {
    let baseColumns = [
      {
        name: t('stat'),
        key: 'name',
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
        formatter: ({row}) => {
          const content = (
            <span className={row.onExactTier ? 'etro-exact-tier' : ''}>
              {row.name}
            </span>
          );

          return row.helpText ? (
            <Tooltip
              content={row.helpText}
              position={Position.BOTTOM_RIGHT}
              boundary={'viewport'}
              intent={Intent.PRIMARY}
            >
              {content}
            </Tooltip>
          ) : (
            content
          );
        }
      },
      {
        name: t('total'),
        key: 'value',
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.number
      }
    ];

    if (!isCraftOrGatherJob) {
      baseColumns = baseColumns.concat([
        {
          name: t('previous'),
          key: 'previous',
          sortable: true,
          sortingAlgorithm: SortingAlgorithms.number
        },
        {
          name: t('next'),
          key: 'next',
          sortable: true,
          sortingAlgorithm: SortingAlgorithms.number
        }
      ]);
    }

    return baseColumns;
  }, [isCraftOrGatherJob, t]);

  return (
    <div className="etro-param-table-wrapper">
      <div className="etro-param-table-label">
        <Label>{!isCraftOrGatherJob ? t('stat and tier') : t('stat')}</Label>
        <div className="table-collapse-button">
          <Tooltip
            content={tableVisible ? t('hide table') : t('show table')}
            position={Position.TOP}
            boundary={'viewport'}
          >
            <Button
              minimal={true}
              className="etro-button-hover-primary-fill"
              icon={
                tableVisible ? 'double-chevron-down' : 'double-chevron-right'
              }
              onClick={() => toggleTableVisible(!tableVisible)}
            />
          </Tooltip>
        </div>
      </div>
      <Collapse isOpen={tableVisible} keepChildrenMounted={true}>
        <EtroBox
          sx={{
            '.rdg': {
              gridTemplateColumns: `minmax(90px, auto) repeat(${
                columns.length - 1
              }, 65px) !important`
            }
          }}
        >
          <EtroDataGrid
            disableHeader
            disableFiltering
            className="etro-param-table"
            rows={rows}
            columns={columns}
            size="fill"
            // Disable due to columns changing
            enableVirtualization={false}
          />
        </EtroBox>
      </Collapse>
    </div>
  );
};
