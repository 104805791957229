import React, {useMemo} from 'react';
import {useMantineTheme} from '@mantine/core';
import {Link} from 'react-router-dom';
import {RemoteSVG, RemoteSVGProps} from '~/components';

interface EtroLogoProps {
  disableLink?: boolean;
  openNewTab?: boolean;
  showText?: boolean;
  svgProps?: Omit<RemoteSVGProps, 'src' | 'iconPath'>;
}

export const EtroLogo: React.FC<EtroLogoProps> = ({
  disableLink = false,
  openNewTab = false,
  showText = false,
  svgProps
}) => {
  const theme = useMantineTheme();
  const _svgProps: RemoteSVGProps = useMemo(() => {
    return {
      alt: 'Etro Logo',
      fill: theme.fn.primaryColor(),
      className: '',
      ...svgProps,
      iconPath: showText ? '/ui/etroicon-text.svg' : '/ui/etroicon.svg',
      style: {
        zIndex: 1,
        ...svgProps?.style
      }
    };
  }, [showText, svgProps, theme.fn]);

  return (
    <Link
      to={disableLink ? '#' : '/'}
      style={{pointerEvents: disableLink ? 'none' : undefined}}
      target={openNewTab ? '_blank' : undefined}
      rel={openNewTab ? 'noopener noreferrer' : undefined}
    >
      <RemoteSVG {..._svgProps} />
    </Link>
  );
};
