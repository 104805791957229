import React from 'react';
import {EtroList, EtroStack, EtroTitle} from '~/components';
import {Update, PartialPick} from '~/types';

export interface UpdatesListProps
  extends PartialPick<Update, 'versionNumber'> {}

export const UpdatesList: React.FC<UpdatesListProps> = ({
  versionNumber,
  updates,
  announcement
}) => {
  return (
    <EtroStack spacing={0} mt={versionNumber ? 'lg' : undefined}>
      {versionNumber && (
        <EtroTitle order={3} color="etro" align="left">
          {versionNumber}
        </EtroTitle>
      )}
      {announcement && announcement}
      <EtroList size="sm" sx={{textAlign: 'left'}}>
        {updates.map((x, i) => (
          // pr to help contain text, otherwise will extend outside container
          <EtroList.Item pr="xs" key={`${versionNumber}-${i}`}>
            {typeof x === 'function' ? x() : x}
          </EtroList.Item>
        ))}
      </EtroList>
    </EtroStack>
  );
};
