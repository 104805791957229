import {createReducers} from 'redux-arc';
import {types} from '~/actions/user';

export const INITIAL_STATE = {
  darkMode: true
};

const onSetDarkMode = (state, action) => ({
  ...state,
  darkMode: action.payload
});

const HANDLERS = {
  [types.SET_DARK_MODE]: onSetDarkMode
};

export const user = createReducers(INITIAL_STATE, HANDLERS);
