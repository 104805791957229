import React from 'react';
import {useSelector} from '~/hooks';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import {safeGetItem} from '~/utils/localStorage';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

// Creates a route only accessible when a user has logged in.
// PrivateRoute has the same API as regular Route by passing
// @rest props through
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const {isAuthenticated, userIsLoading} = useSelector(({auth}) => auth);
  const storageToken = safeGetItem('token');

  return (
    <Route
      {...rest} // Since there is no path prop, it will always render
      render={props => {
        if (
          /* 
            If there is a token and the app is initially loading
            assume that the token is valid and wait for auth/user to finish.
            If the token is invalid after auth/user then it is removed,
            and the Redirect will be hit.
          */
          (!!storageToken && !isAuthenticated && !userIsLoading) ||
          isAuthenticated ||
          userIsLoading
        ) {
          return <Component {...props} />;
        }

        return <Redirect to={{pathname: '/', state: {from: props.location}}} />;
      }}
    />
  );
};
