import React from 'react';
import {
  FormGroup,
  InputGroup,
  Intent,
  Tooltip,
  Position
} from '@blueprintjs/core';
import {lowerCase} from 'lodash';

/**
 * @deprecated Use EtroInput. This component is for legacy Formik forms.
 */
export const InputWithError = ({
  disabled,
  errorContent,
  handleChange,
  hasErrors,
  id,
  inputType,
  label,
  leftElement,
  value
}) => {
  return (
    <FormGroup label={label}>
      <Tooltip
        content={errorContent}
        isOpen={hasErrors}
        disabled={!hasErrors}
        intent={Intent.DANGER}
        position={Position.RIGHT}
        boundary={'viewport'}
        target={
          <InputGroup
            id={id ? id : lowerCase(label)}
            type={inputType ? inputType : null}
            onChange={handleChange}
            intent={hasErrors ? Intent.DANGER : null}
            value={value}
            leftElement={leftElement}
            disabled={disabled}
          />
        }
      />
    </FormGroup>
  );
};
