import React from 'react';
import {Menu, MenuProps} from '@mantine/core';
import {EtroMenuDivider} from './EtroMenuDivider';
import {EtroMenuDropdown} from './EtroMenuDropdown';
import {EtroMenuItem} from './EtroMenuItem';
import {EtroMenuLabel} from './EtroMenuLabel';
import {EtroMenuTarget} from './EtroMenuTarget';

export interface EtroMenuProps extends MenuProps {}
export type EtroMenuComponents = {
  Divider: typeof EtroMenuDivider;
  Dropdown: typeof EtroMenuDropdown;
  Item: typeof EtroMenuItem;
  Label: typeof EtroMenuLabel;
  Target: typeof EtroMenuTarget;
};

const defaultProps: Partial<EtroMenuProps> = {
  arrowPosition: 'center',
  arrowSize: 14,
  position: 'bottom-end',
  shadow: 'md',
  withArrow: true
};

export const EtroMenu: React.FC<EtroMenuProps> & EtroMenuComponents = props => {
  return <Menu {...defaultProps} {...props} />;
};

EtroMenu.Divider = EtroMenuDivider;
EtroMenu.Dropdown = EtroMenuDropdown;
EtroMenu.Item = EtroMenuItem;
EtroMenu.Label = EtroMenuLabel;
EtroMenu.Target = EtroMenuTarget;
