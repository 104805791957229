import {useState, useEffect} from 'react';
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook
} from 'react-redux';
import type {AppDispatch, RootState} from '../store';

export * from './clan';
export * from './equipment';
export * from './item';
export * from './jobs';
export * from './styles';

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export function useFetchWhenAuth(callback: () => void) {
  const [fetched, setFetched] = useState(false),
    isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !fetched) {
      callback();
      setFetched(true);
    }

    if (!isAuthenticated && fetched) {
      setFetched(false);
    }
  }, [callback, fetched, isAuthenticated]);
}
