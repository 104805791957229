import React from 'react';
import {KofiButton} from '~/components/KofiButton';
import {NitroPayButton} from '~/components/NitroPayButton';
import {EtroGroup, EtroStack, EtroText} from '~/components';
import {DiscordInviteButton} from '~/components/DiscordButton/DiscordInviteButton';
import {EtroPaper} from '~/components/EtroPaper';
import {EtroDivider} from '~/components/EtroDivider';
import {EtroTitle} from '~/components/EtroTitle';
import {useTranslation} from 'react-i18next';

export const About = () => {
  const {t} = useTranslation();

  return (
    <EtroPaper>
      <EtroStack spacing="xs" sx={{width: '100%'}}>
        <EtroTitle order={1}>{t('welcome to etro')}</EtroTitle>
        <EtroDivider />
        <EtroText size="sm">{t('about etro')}</EtroText>
        <EtroText size="sm" mb="sm">
          {t('sub msg')}
        </EtroText>
        <EtroGroup position="center">
          <NitroPayButton
            showText
            outlined
            disableTooltip
            className="etro-button-height"
          />
          <KofiButton
            showText={true}
            outlined={true}
            className="etro-button-height"
          />
          <DiscordInviteButton
            text={'Discord'}
            outlined
            className={'etro-button-hover-discord-fill etro-button-height'}
          />
        </EtroGroup>
        <EtroStack spacing={0} mt="sm">
          <EtroText size="xs" color="dimmed" italic>
            {`FINAL FANTASY XIV ©2010 - ${new Date().getFullYear()} SQUARE ENIX CO., LTD.`}
          </EtroText>
          <EtroText size="xs" color="dimmed" italic>
            {
              'FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd. All material used under license.'
            }
          </EtroText>
          <EtroText size="xs" color="dimmed" italic>
            {'Etro is not affiliated with SQUARE ENIX CO., LTD.'}
          </EtroText>
        </EtroStack>
        <span className="etro-ccpa-link" data-ccpa-link="1"></span>
      </EtroStack>
    </EtroPaper>
  );
};
