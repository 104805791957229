import React from 'react';
import {Avatar, AvatarProps, createPolymorphicComponent} from '@mantine/core';
import {EtroAvatarGroup} from './EtroAvatarGroup';

export interface EtroAvatarProps extends AvatarProps {}

const defaultProps: Partial<EtroAvatarProps> = {};

const _EtroAvatar: React.FC<EtroAvatarProps> & {
  Group: typeof EtroAvatarGroup;
} = props => {
  return <Avatar {...defaultProps} {...props} />;
};

_EtroAvatar.Group = EtroAvatarGroup;

export const EtroAvatar = createPolymorphicComponent<'div', EtroAvatarProps>(
  _EtroAvatar
);
