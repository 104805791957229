import React from 'react';
import {AnchorButton} from '@blueprintjs/core';
import {RemoteSVG} from '~/components';

export const BaseDiscordButton = ({
  onClick,
  text,
  link,
  className,
  minimal = false,
  iconProps,
  outlined
}) => {
  return (
    <AnchorButton
      className={className}
      href={link}
      text={text}
      minimal={minimal}
      icon={
        <RemoteSVG
          iconPath="/ui/discordlogo.svg"
          className="etro-icon-xs"
          {...iconProps}
        />
      }
      onClick={onClick}
      outlined={outlined}
    />
  );
};
