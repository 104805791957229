import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {authCreators} from '~/actions/auth';
import {Redirect} from 'react-router-dom';
import {Intent} from '@blueprintjs/core';
import {AppToaster} from '~/components/AppToaster/AppToaster';

const onSuccess = () => {
  AppToaster.show({
    message: 'Email verified, please login.',
    intent: Intent.SUCCESS,
    icon: 'tick'
  });
};

const onFailure = () => {
  AppToaster.show({
    message: 'Invalid email verification link.',
    intent: Intent.DANGER,
    icon: 'issue'
  });
};

export const VerifyEmailComponent = ({
  match,
  verifyEmail,
  isAuthenticated,
  verifyEmailIsLoading,
  verifyEmailError
}) => {
  const key = match.params.key;

  useEffect(() => {
    if (!isAuthenticated && !verifyEmailIsLoading && !verifyEmailError) {
      verifyEmail({key}).then(r =>
        r && r.status === 200 ? onSuccess() : onFailure()
      );
    }
  }, [
    verifyEmail,
    key,
    isAuthenticated,
    verifyEmailError,
    verifyEmailIsLoading
  ]);

  return <Redirect to="/" />;
};

const actions = {verifyEmail: authCreators.verifyEmail};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    verifyEmailIsLoading: state.auth.verifyEmailIsLoading,
    verifyEmailError: state.auth.verifyEmailError
  };
};

export const VerifyEmail = connect(
  mapStateToProps,
  actions
)(VerifyEmailComponent);
