import React from 'react';
import {Skeleton, SkeletonProps} from '@mantine/core';

export interface EtroSkeletonProps extends SkeletonProps {}

const defaultProps: Partial<EtroSkeletonProps> = {};

export const EtroSkeleton: React.FC<EtroSkeletonProps> = props => {
  return <Skeleton {...defaultProps} {...props} />;
};
