import React from 'react';
import {Affix, AffixProps} from '@mantine/core';

export interface EtroAffixProps extends AffixProps {}

const defaultProps: Partial<EtroAffixProps> = {};

export const EtroAffix: React.FC<EtroAffixProps> = props => {
  return <Affix {...defaultProps} {...props} />;
};
