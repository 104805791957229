import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {EtroSelect} from '~/components/EtroSelect/EtroSelect';
import {Patch} from '~/types';

const patchOptions = [
  {value: 7.0, label: '7.0 - Dawntrail'},
  {value: 6.5, label: '6.5 - Growing Light'},
  {value: 6.4, label: '6.4 - The Dark Throne'},
  {value: 6.3, label: '6.3 - Gods Revel, Lands Tremble'},
  {value: 6.2, label: '6.2 - Buried Memory'},
  {value: 6.1, label: '6.1 - Newfound Adventure'},
  {value: 6.0, label: '6.0 - Endwalker'},
  {value: 5.5, label: '5.5 - Death Unto Dawn'},
  {value: 5.4, label: '5.4 - Futures Rewritten'},
  {value: 5.3, label: '5.3 - Reflections in Crystal'},
  {value: 5.2, label: '5.2 - Echoes of a Fallen Star'},
  {value: 5.1, label: '5.1 - Vows of Virtue, Deeds of Cruelty'},
  {value: 5.0, label: '5.0 - Shadowbringers'},
  {value: 4.5, label: '4.5 - A Requiem for Heroes'},
  {value: 4.4, label: '4.4 - Prelude In Violet'},
  {value: 4.3, label: '4.3 - Under The Moonlight'},
  {value: 4.2, label: '4.2 - Rise of a New Sun'},
  {value: 4.1, label: '4.1 - The Legend Returns'},
  {value: 4.0, label: '4.0 - Stormblood'},
  {value: 3.5, label: '3.5 - The Far Edge Of Fate'},
  {value: 3.4, label: '3.4 - Soul Surrender'},
  {value: 3.3, label: '3.3 - Revenge Of The Horde'},
  {value: 3.2, label: '3.2 - The Gears Of Change'},
  {value: 3.1, label: '3.1 - As Goes Light, So Goes Darkness'},
  {value: 3.0, label: '3.0 - Heavensward'},
  {value: 2.5, label: '2.5 - Before The Fall'},
  {value: 2.4, label: '2.4 - Dreams Of Ice'},
  {value: 2.3, label: '2.3 - Defenders Of Eorzea'},
  {value: 2.2, label: '2.2 - Through The Maelstrom'},
  {value: 2.1, label: '2.1 - A Realm Awoken'},
  {value: 2.0, label: '2.0 - A Realm Reborn'}
];

export const currentPatch = patchOptions[0];

interface PatchSelectorProps {
  defaultId?: number;
  onChange?: any;
  setFormValue?: (field: string, value: string) => void;
  value: Patch | number | null;
}

export const PatchSelector: React.FC<PatchSelectorProps> = ({
  defaultId,
  onChange: propsOnChange,
  setFormValue,
  value
}) => {
  const {t} = useTranslation();
  const onChange = useCallback(
    patch => {
      return setFormValue?.('patch', patch);
    },
    [setFormValue]
  );

  /*
    Have to handle null outside Etro select because it breaks Job/Clan Selectors defaultId due
    to render cycle and how setting defaults is handled.
  */
  useEffect(() => {
    if (defaultId === null) {
      onChange(defaultId);
    }
  }, [defaultId, onChange]);

  return (
    <EtroSelect
      value={value}
      onChange={(patch: Patch) => {
        if (propsOnChange) {
          return propsOnChange(patch);
        }

        onChange(patch);
      }}
      defaultId={defaultId}
      label={t('patch')}
      placeholder="Select a patch..."
      isClearable={true}
      preMappedOptions={patchOptions}
      menuPortalTarget={document.body}
    />
  );
};
