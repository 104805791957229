import React from 'react';
import SVG, {Props} from 'react-inlinesvg';

// Require src or iconPath
export type RemoteSVGProps = Omit<Props, 'src'> &
  ({src: Props['src']; iconPath?: never} | {iconPath: string; src?: never});

export const RemoteSVG: React.FC<RemoteSVGProps> = ({
  src,
  iconPath,
  ...props
}) => {
  return (
    <SVG
      className="etro-icon-small"
      {...props}
      src={!!src ? src : `${process.env.REACT_APP_ICON_BASE_URL}${iconPath}`}
    />
  );
};
