import {
  isEmpty,
  mapKeys,
  pick,
  reduce,
  sortBy,
  mapValues,
  values,
  round,
  ceil
} from 'lodash';
import {
  paramNames,
  paramSortOrder,
  paramIds,
  jobParamList,
  baseParams,
  secondaryParamNameList,
  tankJobList,
  healerJobList,
  baseGCD,
  physDamageId,
  magDamageId,
  battleSlotObjects,
  pldSlotObjects,
  craftSlotObjects,
  gatherSlotObjects,
  craftGatherFoodItemLevelRange,
  battleFoodItemLevelRange,
  defaultMaxLevel,
  baseParamLevels,
  partyBonus
} from '~/constants';

export const sumObjectsByProperty = (...objs) => {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) {
        a[k] = (a[k] || 0) + b[k];
      }
    }
    return a;
  }, {});
};

export const getLevelBaseParams = (job, level) => {
  if (isEmpty(job) || !level || !baseParamLevels.includes(level)) {
    return baseParams[defaultMaxLevel];
  }

  return baseParams[level];
};

export const handleRingId = (selectedItemId, slotKey) => {
  if (slotKey === 'fingerL') {
    return `${selectedItemId}L`;
  } else if (slotKey === 'fingerR') {
    return `${selectedItemId}R`;
  }

  return selectedItemId;
};

export const getMateriaObjects = (gearset, itemId, materia) => {
  if (isEmpty(gearset) || isEmpty(gearset.materia)) {
    return [];
  }

  return values(gearset.materia[itemId])
    .map(materiaId => materia.find(x => x.id === materiaId))
    .filter(Boolean);
};

// Sum params with the same Id for an item's list of materia objects
export const sumMateriaListParams = materiaList => {
  if (isEmpty(materiaList)) {
    return {};
  }

  return materiaList.reduce((sum, materia) => {
    var id = materia.param,
      value = materia.paramValue;
    if (sum[id]) {
      sum[id] += value;
    } else {
      sum[id] = value;
    }
    return sum;
  }, {});
};

export const getEquipmentParams = item => {
  let itemParams = {};

  if (isEmpty(item)) {
    return itemParams;
  }

  for (var i = 0; i < 11; i++) {
    var paramId = item[`param${i}`];
    // Don't need to check anymore if receive null paramId
    if (paramId) {
      itemParams[paramId] = item[`param${i}Value`];
    } else {
      break;
    }
  }

  return itemParams;
};

export const getEquipmentNamedParams = item => {
  let itemParams = {};

  if (isEmpty(item)) {
    return itemParams;
  }

  for (var i = 0; i < 11; i++) {
    var paramId = item[`param${i}`];
    // Don't need to check anymore if receive null paramId
    if (paramId) {
      itemParams[paramNames[paramId]] = item[`param${i}Value`];
    } else {
      break;
    }
  }

  return itemParams;
};

const calculateMateriaTotals = (gearset, materia, materiaParamSum) => {
  if (isEmpty(gearset.materia) || isEmpty(materia)) {
    return [];
  }

  // Iterate through gs slots
  // materiaTotals is {materiaId: total, materiaId2: total2, ...}
  const materiaTotals = Object.keys(gearset).reduce((acc, slot) => {
    // The slot can have null after clearing
    if (
      gearset[slot] &&
      !gearset[slot].isFood &&
      !gearset[slot].itemLevelSync
    ) {
      const itemId = handleRingId(gearset[slot].id, slot);

      // Check if item is in gs.materia, if not skip item
      if (gearset.materia[itemId]) {
        Object.keys(gearset.materia[itemId]).reduce((sum, materiaSlot) => {
          const materiaId = gearset.materia[itemId][materiaSlot];

          return acc[materiaId] ? (acc[materiaId] += 1) : (acc[materiaId] = 1);
        }, {});
      }
    }
    return acc;
  }, {});

  const materiaTotalObjects = [];

  // Combine materia object with total for table/future use
  // We have to use calculated sum because some materia may not get full value
  for (const k in materiaTotals) {
    const materiaObj = materia.find(({id}) => id === Number(k));

    if (materiaObj) {
      materiaTotalObjects.push({
        ...materiaObj,
        total: materiaTotals[k]
        // sum: materiaParamSum[materiaObj.param]
      });
    }
  }

  return sortBy(materiaTotalObjects, 'name');
};

const calculateMateriaParams = (gearset, materia) => {
  if (isEmpty(gearset.materia)) {
    return {};
  }

  // Iterate through gs slots
  const materiaParams = Object.keys(gearset).reduce((acc, slot) => {
    // The slot can have null after clearing
    if (
      gearset[slot] &&
      !gearset[slot].isFood &&
      (!gearset[slot].itemLevelSync ||
        gearset[slot].itemLevel <= gearset[slot].itemLevelSync)
    ) {
      const itemId = handleRingId(gearset[slot].id, slot);

      // Check if item is in gs.materia, if not skip item
      if (gearset.materia[itemId]) {
        // Iterate through item's materia IDs and get paramId and total value
        const itemMateriaParams = sumMateriaListParams(
          getMateriaObjects(gearset, itemId, materia)
        );

        // Get the item's params and max params
        const itemParams = getEquipmentParams(gearset[slot]),
          itemMaxes = gearset[slot].maxParams;

        for (var paramId in itemMateriaParams) {
          /* 
            If item has param take Min((max - existing, materiaSum)).
            Else Min(max, materiaSum)

            Taking Min() guarentees that we're never over the cap
          */
          const materiaValue = itemParams[paramId]
            ? Math.min(
                itemMaxes[paramId] - itemParams[paramId],
                itemMateriaParams[paramId]
              )
            : Math.min(itemMaxes[paramId], itemMateriaParams[paramId]);

          // Add to accumulator for all items
          if (acc[paramId]) {
            acc[paramId] += materiaValue;
          } else {
            acc[paramId] = materiaValue;
          }
        }
      }
    }
    return acc;
  }, {});

  return materiaParams;
};

const getRelativeFoodParamValue = (paramValue, foodMax, foodValue) => {
  // max > percent -> return percent, max < percent -> return max
  return Math.min(foodMax, Math.floor(paramValue * (foodValue * 0.01)));
};

const calculateFoodParams = (baseSumParams, food, levelBaseParams) => {
  let totalParams = {...baseSumParams},
    foodValues = {};

  for (let i = 0; i < 3; i++) {
    let paramId = food[`param${i}`];

    if (paramId) {
      if (food[`isRelative${i}`]) {
        let foodParamValue = null;

        if (totalParams[paramId]) {
          foodParamValue = getRelativeFoodParamValue(
            totalParams[paramId],
            food[`maxHQ${i}`],
            food[`valueHQ${i}`]
          );
          totalParams[paramId] = totalParams[paramId] + foodParamValue;
        } else {
          const baseParamValue = levelBaseParams[paramNames[paramId]];

          foodParamValue = getRelativeFoodParamValue(
            baseParamValue,
            food[`maxHQ${i}`],
            food[`valueHQ${i}`]
          );
          totalParams[paramId] = baseParamValue + foodParamValue;
        }

        foodValues[paramId] = foodParamValue;
      } else {
        totalParams[paramId] = totalParams[paramId]
          ? totalParams[paramId] + food[`valueHQ${i}`]
          : levelBaseParams[paramNames[paramId]] + food[`valueHQ${i}`];
      }
    } else {
      break;
    }
  }

  return [totalParams, foodValues];
};

const handleDefense = (item, acc, param_key, param_id) => {
  if (item[param_key]) {
    if (acc[param_id]) {
      acc[param_id] += item[param_key];
    } else {
      acc[param_id] = item[param_key];
    }
  }

  return;
};

export const calculatePartyBonus = ({params, job, gearsetPartyBonus}) => {
  if (
    isEmpty(params) ||
    isEmpty(job) ||
    gearsetPartyBonus === partyBonus['Off']
  ) {
    return params;
  }

  const bonusParams = jobParamList[job.abbrev].main.reduce((acc, paramName) => {
    const id = paramIds[paramName];

    acc[id] = Math.floor(params[id] * gearsetPartyBonus);

    return acc;
  }, {});

  return {...params, ...bonusParams};
};

export const calculateParamTotals = (
  gearset,
  jobClanBaseSum,
  materia,
  isCraftOrGatherJob,
  levelBaseParams,
  currentJob
) => {
  const params = Object.keys(gearset).reduce((acc, slot) => {
    // The slot can have null after clearing
    if (gearset[slot] && !gearset[slot].isFood && !gearset[slot].isMedicine) {
      if (!isCraftOrGatherJob) {
        handleDefense(gearset[slot], acc, 'defensePhys', paramIds['DEF']);
        handleDefense(gearset[slot], acc, 'defenseMag', paramIds['MDEF']);
      }

      for (var i = 0; i < 11; i++) {
        var paramId = gearset[slot][`param${i}`];
        // Don't need to check anymore if receive null paramId
        if (paramId) {
          if (!(isCraftOrGatherJob && paramId === Number(paramIds.VIT))) {
            if (acc[paramId]) {
              acc[paramId] += gearset[slot][`param${i}Value`];
            } else {
              acc[paramId] = gearset[slot][`param${i}Value`];
            }
          }
        } else {
          break;
        }
      }
    }
    return acc;
  }, {});

  const baseSumParams = !isEmpty(params)
      ? sumObjectsByProperty(params, jobClanBaseSum)
      : jobClanBaseSum,
    materiaParamSum = calculateMateriaParams(gearset, materia),
    materiaTotals = calculateMateriaTotals(gearset, materia, materiaParamSum),
    materiaBaseSum = sumObjectsByProperty(materiaParamSum, baseSumParams);

  const partyBonusBaseSum = calculatePartyBonus({
    params: materiaBaseSum,
    job: currentJob,
    gearsetPartyBonus: gearset.partyBonus
  });

  let totalParams = null,
    foodValues = null,
    medicineValues = null;

  if (gearset['food']) {
    const paramList = calculateFoodParams(
      partyBonusBaseSum,
      gearset['food'],
      levelBaseParams
    );
    totalParams = paramList[0];
    foodValues = paramList[1];
  }

  if (gearset['medicine']) {
    const paramList = calculateFoodParams(
      totalParams ?? partyBonusBaseSum,
      gearset['medicine'],
      levelBaseParams
    );
    totalParams = paramList[0];
    medicineValues = paramList[1];
  }

  if (!gearset['food'] && !gearset['medicine']) {
    totalParams = partyBonusBaseSum;
  }

  const mappedTotalParams = sortBy(
    Object.keys(totalParams).map(x => {
      return {id: x, name: paramNames[x], value: totalParams[x]};
    }),
    [
      x => {
        return paramSortOrder[x.id];
      }
    ]
  );

  return [mappedTotalParams, foodValues, materiaTotals, medicineValues];
};

const getSecondaryParamNameList = job => {
  if (!isEmpty(job)) {
    return job.isCrafting || job.isGathering
      ? [...jobParamList[job.abbrev].secondary]
      : [...jobParamList[job.abbrev].secondary, ...secondaryParamNameList];
  } else {
    return [];
  }
};

export const checkIfTank = jobAbbrev => {
  return tankJobList.includes(jobAbbrev);
};

export const checkIfHealer = jobAbbrev => {
  return healerJobList.includes(jobAbbrev);
};

const getSecondaryParamIds = currentJob => {
  if (isEmpty(currentJob)) {
    return [];
  }

  return getSecondaryParamNameList(currentJob).map(x => paramIds[x]);
};

const modifyBaseParams = (jobParams, levelBaseParams) => {
  let modifiedParams = {};

  for (var k in jobParams) {
    modifiedParams[k] = Math.floor((levelBaseParams[k] * jobParams[k]) / 100);
  }

  return modifiedParams;
};

export const sumJobClanBase = (job, clan, levelBaseParams) => {
  // sumObjs will sum things like Id and Name also, so pick out the params by name
  // then map the param names to their respective Id

  return !isEmpty(job)
    ? mapKeys(
        sumObjectsByProperty(
          modifyBaseParams(
            pick(job, jobParamList[job.abbrev].main),
            levelBaseParams
          ),
          pick(clan, jobParamList[job.abbrev].main),
          pick(levelBaseParams, getSecondaryParamNameList(job))
        ),
        (v, k) => paramIds[k]
      )
    : {};
};

export const calculateItemLevel = (gearset, job, isCraftOrGatherJob) => {
  let sum = reduce(
    gearset,
    (sum, v) => {
      sum += v && v.itemLevel && !v.isFood && !v.isMedicine ? v.itemLevel : 0;
      return sum;
    },
    0
  );

  if (!isCraftOrGatherJob && job && job.abbrev !== 'PLD') {
    sum += gearset.weapon ? gearset.weapon.itemLevel : 0;
  }

  // Vermilion Cloaks count as head as well
  if ([24856, 24855].includes(gearset.body?.id)) {
    sum += gearset.body.itemLevel;
  }

  return Math.floor((isNaN(sum) ? 0 : sum) / 12);
};

export const mapMateria = materia => {
  if (isEmpty(materia)) {
    return null;
  }

  let mappedItems = {};

  for (var item in materia) {
    mappedItems[item] = mapValues(materia[item], 'id');
  }

  return mappedItems;
};

export const filterMateriaOptions = (
  currentJob,
  materiaOptions,
  materiaTierRange
) => {
  if (isEmpty(currentJob)) {
    return [];
  }

  const secondaryParamIds = getSecondaryParamIds(currentJob);

  return materiaOptions.filter(
    x =>
      secondaryParamIds.includes(`${x.param}`) &&
      x.tier >= materiaTierRange[0] &&
      x.tier <= materiaTierRange[1]
  );
};

export const filterOptionBySecondary = (
  currentJob,
  options,
  isFood = false,
  currentFoodId = null
) => {
  if (isEmpty(currentJob)) {
    return [];
  }

  const itemLevelRange =
    currentJob.isCrafting || currentJob.isGathering
      ? craftGatherFoodItemLevelRange
      : battleFoodItemLevelRange;

  // Need to add VIT paramId also
  const secondaryParamIds = isFood
    ? [...getSecondaryParamIds(currentJob), '3']
    : getSecondaryParamIds(currentJob);

  return options.filter(x => {
    // Make sure old food outside ilevel range is always displayed.
    if (!!currentFoodId && x.id === currentFoodId) {
      return true;
    }

    for (let i = 0; i < 3; i++) {
      const paramId = x[`param${i}`];
      // Food can have only 1-2 stats
      if (paramId && !secondaryParamIds.includes(`${paramId}`)) {
        return false;
      }
    }

    if (
      isFood &&
      (x.itemLevel < itemLevelRange.min || x.itemLevel > itemLevelRange.max)
    ) {
      return false;
    }

    return true;
  });
};

export const getParam = (params, name) => {
  const paramId = paramIds[name];

  return params.find(x => x.id === paramId);
};

const makeTierObject = (p, currentValue, prevTier, nextTier) => {
  return {
    ...p,
    previous: prevTier - currentValue,
    next: nextTier - currentValue,
    onExactTier: currentValue === prevTier
  };
};

export const getWeaponDMGObject = item => {
  if (isEmpty(item)) {
    return null;
  }

  // BLU only jobCategory
  // BLU weapon has damagePhys > damageMag, but want MAG
  return item.jobCategory === 129 || item.damagePhys < item.damageMag
    ? {
        id: magDamageId,
        name: 'Weapon Damage',
        value: item.damageMag
      }
    : {
        id: physDamageId,
        name: 'Weapon Damage',
        value: item.damagePhys
      };
};

const dhScalar = 550;

const dhTierHelper = (multiplier, levelBaseParams) => {
  return Math.ceil(
    (multiplier * 10 * levelBaseParams.levelDiv) / dhScalar + levelBaseParams.DH
  );
};

const calcDHTiers = (p, currentMultiplier, levelBaseParams) => {
  const currentValue = p.value,
    prevTier = dhTierHelper(currentMultiplier, levelBaseParams),
    nextTier = dhTierHelper(currentMultiplier + 0.1, levelBaseParams);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcDirectHitRate = (DH, levelBaseParams) => {
  return !isEmpty(DH)
    ? Math.floor(
        (dhScalar * (DH.value - levelBaseParams.DH)) / levelBaseParams.levelDiv
      ) / 10
    : 0;
};

const crtScalar = 200;

export const calcCriticalHitRate = (CRT, levelBaseParams) => {
  return !isEmpty(CRT)
    ? Math.floor(
        (crtScalar * (CRT.value - levelBaseParams.CRT)) /
          levelBaseParams.levelDiv +
          50
      ) / 10
    : 5;
};

const crtTierHelper = (multiplier, levelBaseParams) => {
  return Math.ceil(
    ((multiplier * 1000 - 1400) * levelBaseParams.levelDiv) / crtScalar +
      levelBaseParams.CRT
  );
};

const calcCRTTiers = (p, currentMultiplier, levelBaseParams) => {
  const currentValue = p.value,
    prevTier = crtTierHelper(currentMultiplier, levelBaseParams),
    nextTier = crtTierHelper(currentMultiplier + 0.001, levelBaseParams);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcCriticalMultiplier = (CRT, levelBaseParams) => {
  return !isEmpty(CRT)
    ? Math.floor(
        (crtScalar * (CRT.value - levelBaseParams.CRT)) /
          levelBaseParams.levelDiv +
          1400
      ) / 1000
    : 1.4;
};

const detScalar = 140;

const detTierHelper = (multiplier, levelBaseParams) => {
  return Math.ceil(
    ((multiplier * 1000 - 1000) * levelBaseParams.levelDiv) / detScalar +
      levelBaseParams.DET
  );
};

const calcDETTiers = (p, currentMultiplier, levelBaseParams) => {
  const currentValue = p.value,
    prevTier = detTierHelper(currentMultiplier, levelBaseParams),
    nextTier = detTierHelper(currentMultiplier + 0.001, levelBaseParams);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcDetMultiplier = (DET, levelBaseParams) => {
  return !isEmpty(DET)
    ? Math.floor(
        (detScalar * (DET.value - levelBaseParams.DET)) /
          levelBaseParams.levelDiv +
          1000
      ) / 1000
    : 1;
};

const tenDamageScalar = 112;
const tenMitigationScalar = 200;

const tenTierHelper = (multiplier, levelBaseParams) => {
  return Math.ceil(
    ((multiplier * 1000 - 1000) * levelBaseParams.levelDiv) / tenDamageScalar +
      levelBaseParams.TEN
  );
};

const calcTENTiers = (p, currentMultiplier, levelBaseParams) => {
  const currentValue = p.value,
    prevTier = tenTierHelper(currentMultiplier, levelBaseParams),
    nextTier = tenTierHelper(currentMultiplier + 0.001, levelBaseParams);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcTenDamageMultiplier = (TEN, levelBaseParams) => {
  return !isEmpty(TEN)
    ? Math.floor(
        (tenDamageScalar * (TEN.value - levelBaseParams.TEN)) /
          levelBaseParams.levelDiv +
          1000
      ) / 1000
    : 1;
};

export const calcTenMitigation = (TEN, levelBaseParams) => {
  if (isEmpty(TEN)) {
    return {value: 0, converted: 0};
  }

  const tenMitigation =
    (1000 -
      Math.floor(
        (tenMitigationScalar * (TEN.value - levelBaseParams.TEN)) /
          levelBaseParams.levelDiv
      )) /
    1000;

  return {
    value: tenMitigation,
    converted: round((1 - tenMitigation) * 100, 2)
  };
};

const spdScalar = 130;

const spdTierHelper = (multiplier, baseSpeed, levelBaseParams) => {
  return Math.ceil(
    ((multiplier * 1000 - 1000) * levelBaseParams.levelDiv) / spdScalar +
      baseSpeed
  );
};

const calcSPDTiers = (p, currentMultiplier, usesSKS, levelBaseParams) => {
  const baseSpeed = usesSKS ? levelBaseParams.SKS : levelBaseParams.SPS,
    currentValue = p.value,
    prevTier = spdTierHelper(currentMultiplier, baseSpeed, levelBaseParams),
    nextTier = spdTierHelper(
      currentMultiplier + 0.001,
      baseSpeed,
      levelBaseParams
    );

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcSpeedMultiplier = (SPD, usesSKS, levelBaseParams) => {
  const baseSpeed = usesSKS ? levelBaseParams.SKS : levelBaseParams.SPS;

  return !isEmpty(SPD)
    ? Math.floor(
        (spdScalar * (SPD.value - baseSpeed)) / levelBaseParams.levelDiv + 1000
      ) / 1000
    : 1;
};

/* 
  GCD func is taken from Universal GCD Calc 5.35, since there were issues with my calc.
  These consts are kept in for consistancy sake, it is not worth trying to optimize and remove.
  Plus there could be buffs in the future which are used in the same places.
*/
const arrow = 0,
  feyWind = 0,
  selfBuff2 = 0,
  RoF = 100,
  umbralAstral3 = 100;
const gcdHelper = (
  SPD,
  baseSpeed,
  levelBaseParams,
  modifier = 0,
  haste = 0
) => {
  return (
    Math.floor(
      (Math.floor(
        (Math.floor(
          (Math.floor(
            ((1000 -
              Math.floor(
                (spdScalar * (SPD - baseSpeed)) / levelBaseParams.levelDiv
              )) *
              baseGCD) /
              1000
          ) *
            Math.floor(
              ((Math.floor(
                (Math.floor(((100 - arrow) * (100 - modifier)) / 100) *
                  (100 - haste)) /
                  100
              ) -
                feyWind) *
                (selfBuff2 - 100)) /
                100
            )) /
            -100
        ) *
          RoF) /
          1000
      ) *
        umbralAstral3) /
        100
    ) / 100 // Added to taken func. Converts from MS to S.
  );
};

export const getGCDMod = (job, level = defaultMaxLevel) => {
  if (isEmpty(job) || !jobParamList[job.abbrev].gcdMod) {
    return undefined;
  }

  const modifier = jobParamList[job.abbrev].gcdMod;

  if (typeof modifier === 'object') {
    return modifier[level];
  }

  return modifier;
};

export const calcGCD = (SPD, usesSKS, job, levelBaseParams, level) => {
  const baseSpeed = usesSKS ? levelBaseParams.SKS : levelBaseParams.SPS,
    modifier = getGCDMod(job, level);

  if (!isEmpty(SPD)) {
    const gcd = gcdHelper(SPD.value, baseSpeed, levelBaseParams),
      modGCD = modifier
        ? gcdHelper(SPD.value, baseSpeed, levelBaseParams, modifier)
        : null;

    return {gcd, modGCD};
  }

  return baseGCD / 1000;
};

export const calcGCDTiers = (tiers, gcds, SPD, job, level) => {
  const {gcd, modGCD} = gcds,
    {gcdTiers, modGCDTiers} = tiers,
    nextIndex = gcdTiers.findIndex(x => x > SPD),
    nextModIndex = modGCD && modGCDTiers.findIndex(x => x > SPD),
    jobParams = jobParamList[job.abbrev],
    gcdMod = getGCDMod(job, level),
    {gcdModName, gcdModNameLong} = jobParams;

  return [
    makeTierObject(
      {name: 'GCD', value: gcd},
      SPD,
      gcdTiers[nextIndex - 1],
      gcdTiers[nextIndex]
    ),
    modGCD &&
      makeTierObject(
        {
          name: `GCD (${gcdModName})`,
          value: modGCD,
          helpText: `GCD modified by ${
            gcdModNameLong || gcdModName
          } (-${gcdMod}%).`
        },
        SPD,
        modGCDTiers[nextModIndex - 1],
        modGCDTiers[nextModIndex]
      )
  ].filter(Boolean);
};

export const calcAllGCDTiers = (usesSKS, job, levelBaseParams, level) => {
  const baseSpeed = usesSKS ? levelBaseParams.SKS : levelBaseParams.SPS,
    modifier = getGCDMod(job, level);

  let gcdTiers = [],
    gcdTarget = 2.5,
    modGCDTiers = modifier ? [] : null,
    modGCDTarget = modifier
      ? gcdHelper(baseSpeed, baseSpeed, levelBaseParams, modifier)
      : null,
    currentMultiplier = 1,
    nextTier;

  while (gcdTarget >= 1.5) {
    // Find the next tier for the SPD multiplier and update for next loop
    nextTier = spdTierHelper(currentMultiplier, baseSpeed, levelBaseParams);
    currentMultiplier = ceil(currentMultiplier + 0.001, 3);

    // Find what the GCD would be at the next tier
    const nextTierGCD = gcdHelper(nextTier, baseSpeed, levelBaseParams);

    // If we find a tier which changes to the next GCD, save the result and update target
    if (gcdTarget === nextTierGCD) {
      gcdTiers.push(nextTier);
      gcdTarget = Number((gcdTarget - 0.01).toFixed(2));
    }

    if (modifier) {
      const nextTierModGCD = gcdHelper(
        nextTier,
        baseSpeed,
        levelBaseParams,
        modifier
      );

      if (modGCDTarget === nextTierModGCD) {
        modGCDTiers.push(nextTier);
        modGCDTarget = Number((modGCDTarget - 0.01).toFixed(2));
      }
    }
  }

  return {gcdTiers, modGCDTiers};
};

const pieScalar = 150;

const pieTierHelper = (multiplier, levelBaseParams) => {
  return (
    Math.ceil(
      ((multiplier - levelBaseParams.mpRefresh) * levelBaseParams.levelDiv) /
        pieScalar
    ) + levelBaseParams.PIE
  );
};

const calcPIETiers = (p, currentRefresh, levelBaseParams) => {
  const currentValue = p.value,
    prevTier = pieTierHelper(currentRefresh, levelBaseParams),
    nextTier = pieTierHelper(currentRefresh + 1, levelBaseParams);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcMPRefresh = (PIE, levelBaseParams) => {
  return !isEmpty(PIE)
    ? Math.floor(
        (pieScalar * (PIE.value - levelBaseParams.PIE)) /
          levelBaseParams.levelDiv
      ) + levelBaseParams.mpRefresh
    : levelBaseParams.mpRefresh;
};

export const calcHP = (VIT, job, isTank, levelBaseParams) => {
  const {HP, hpScalar, hpScalarTank, VIT: baseVIT} = levelBaseParams;
  const scalar = isTank ? hpScalarTank : hpScalar;

  return !isEmpty(VIT)
    ? Math.floor((HP * job.HP) / 100) +
        Math.floor((VIT.value - baseVIT) * scalar)
    : HP;
};

const defScalar = 15;

const defTierHelper = (multiplier, levelBaseParams) => {
  return Math.ceil(
    (-1 * ((multiplier * 1000 - 1000) * levelBaseParams.levelDiv)) / defScalar
  );
};

const calcDefTiers = (p, currentMultiplier, levelBaseParams) => {
  const currentValue = p.value,
    prevTier = defTierHelper(currentMultiplier.value, levelBaseParams),
    nextTier = defTierHelper(currentMultiplier.value - 0.001, levelBaseParams);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcDefMitigation = (DEF, levelBaseParams) => {
  if (isEmpty(DEF)) {
    return levelBaseParams.DEF;
  }

  const defMitigation =
    (1000 - Math.floor((defScalar * DEF.value) / levelBaseParams.levelDiv)) /
    1000;

  return {
    value: defMitigation,
    converted: round((1 - defMitigation) * 1000, 2)
  };
};

export const calcMDefMitigation = (MDEF, levelBaseParams) => {
  if (isEmpty(MDEF)) {
    return levelBaseParams.MDEF;
  }

  const mdefMitigation =
    (1000 - Math.floor((defScalar * MDEF.value) / levelBaseParams.levelDiv)) /
    1000;

  return {
    value: mdefMitigation,
    converted: round((1 - mdefMitigation) * 1000, 2)
  };
};

const mainStatTierHelper = (multiplier, base, scalar) => {
  return Math.ceil((100 * multiplier - 100) / (scalar / base)) + base;
};

const calcMainStatTiers = (p, currentMultiplier, levelBaseParams, isTank) => {
  const scalar = isTank
    ? levelBaseParams.mainScalarTank
    : levelBaseParams.mainScalar;
  const base = levelBaseParams[p.name];

  const currentValue = p.value,
    prevTier = mainStatTierHelper(currentMultiplier, base, scalar),
    nextTier = mainStatTierHelper(currentMultiplier + 0.01, base, scalar);

  return makeTierObject(p, currentValue, prevTier, nextTier);
};

export const calcMainStatMultiplier = (main, levelBaseParams, isTank) => {
  if (isEmpty(main)) {
    return null;
  }

  const scalar = isTank
    ? levelBaseParams.mainScalarTank
    : levelBaseParams.mainScalar;
  const base = levelBaseParams[main.name];

  return (100 + Math.floor(((main.value - base) * scalar) / base)) / 100;
};

export const calcWeaponDMGMultiplier = ({WD, job, levelBaseParams}) => {
  if (!WD || isEmpty(job)) {
    return 1;
  }

  const mainStatName = jobParamList[job.abbrev].main[0];
  const mainStatBase = levelBaseParams[mainStatName];
  const jobMod = job[mainStatName];

  return (WD + Math.floor((mainStatBase * jobMod) / 1000)) / 100;
};

const expectedDMGPotency = 100;
export const calcExpectedDMG = ({dmg, main, crt, det, dh, ten, trait}) => {
  return round(
    expectedDMGPotency * dmg * main * det * crt * dh * ten * trait,
    2
  );
};

export const convertMultiplier = multiplier => {
  return round(multiplier * 100, 2);
};

export const calcExpectedCRTMod = ({criticalHitRate, crtMultiplier}) => {
  return 1 + (criticalHitRate / 100) * (crtMultiplier - 1);
};

// Potency increase from a DH
const dhMultiplier = 1.25;
export const calcExpectedDHMod = ({directHitRate}) => {
  return 1 + (directHitRate / 100) * (dhMultiplier - 1);
};

export const calculateTiers = (
  params,
  multipliers,
  usesSKS,
  levelBaseParams,
  isTank
) => {
  if (isEmpty(params)) {
    return [];
  }
  const {
    crtMultiplier,
    detMultiplier,
    directHitRate,
    mpRefresh,
    spdMultiplier,
    tenMultiplier,
    defMitigation,
    mdefMitigation,
    mainStatMultiplier,
    vitMultiplier
  } = multipliers;

  return params.map(p => {
    switch (p.id) {
      case paramIds.DET:
        return calcDETTiers(p, detMultiplier, levelBaseParams);
      case paramIds.CRT:
        return calcCRTTiers(p, crtMultiplier, levelBaseParams);
      case paramIds.SPS:
      case paramIds.SKS:
        return calcSPDTiers(p, spdMultiplier, usesSKS, levelBaseParams);
      case paramIds.DH:
        return calcDHTiers(p, directHitRate, levelBaseParams);
      case paramIds.TEN:
        return calcTENTiers(p, tenMultiplier, levelBaseParams);
      case paramIds.PIE:
        return calcPIETiers(p, mpRefresh, levelBaseParams);
      case paramIds.DEF:
        return calcDefTiers(p, defMitigation, levelBaseParams);
      case paramIds.MDEF:
        return calcDefTiers(p, mdefMitigation, levelBaseParams);
      case paramIds.VIT:
        return calcMainStatTiers(p, vitMultiplier, levelBaseParams, isTank);
      case paramIds.STR:
      case paramIds.DEX:
      case paramIds.INT:
      case paramIds.MND:
        return calcMainStatTiers(
          p,
          mainStatMultiplier,
          levelBaseParams,
          isTank
        );
      default:
        return p;
    }
  });
};

export const getSlotObjects = currentJob => {
  if (isEmpty(currentJob)) {
    return battleSlotObjects;
  } else if (currentJob.abbrev === 'PLD') {
    return pldSlotObjects;
  } else if (currentJob.isCrafting) {
    return craftSlotObjects;
  } else if (currentJob.isGathering) {
    return gatherSlotObjects;
  } else {
    return battleSlotObjects;
  }
};
