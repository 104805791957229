import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  EtroAnchor,
  EtroBox,
  EtroContainer,
  EtroList,
  EtroTable,
  EtroText,
  EtroTitle
} from '~/components';
import {ConsentBanner} from '~/components/ConsentBanner';

const UPDATED_DATE = 'July 30, 2022';
const INFORMATION_COLLECTED = [
  {category: 'A. Identifiers', collected: 'YES'},
  {
    category:
      'B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))',
    collected: 'YES'
  },
  {
    category:
      'C. Protected classification characteristics under California or federal law',
    collected: 'NO'
  },
  {category: 'D. Commercial information.', collected: 'YES'},
  {category: 'E. Biometric information.', collected: 'NO'},
  {
    category: 'F. Internet or other similar network activity.',
    collected: 'YES'
  },
  {category: 'G. Geolocation data.', collected: 'YES'},
  {category: 'H. Sensory data.', collected: 'NO'},
  {
    category: 'I. Professional or employment-related information.',
    collected: 'NO'
  },
  {
    category: 'J. Inferences drawn from other personal information.',
    collected: 'YES'
  }
];

interface PrivacySectionProps {
  text?: string[];
  title: string;
}

const PrivacySection: React.FC<PrivacySectionProps> = ({
  children,
  text,
  title
}) => {
  return (
    <EtroBox
      mt="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
      }}
    >
      <EtroTitle mb="md" color="etro" order={2}>
        {title}
      </EtroTitle>
      {text?.map((t, i) => (
        <EtroText key={`${title}-text-${i}`} size="sm" mb="sm">
          {t}
        </EtroText>
      ))}
      {children}
    </EtroBox>
  );
};

export const Privacy: React.FC = () => {
  const {t} = useTranslation();
  const informationCollectedRows = useMemo(() => {
    return INFORMATION_COLLECTED.map(x => (
      <tr key={x.category}>
        <td>{x.category}</td>
        <td>{x.collected}</td>
      </tr>
    ));
  }, []);

  return (
    <>
      {/* xl padding due to lists overflowing X on mobile */}
      <EtroContainer px="xl">
        <EtroTitle color="etro" order={1} align="center">
          {t('privacy policy')}
        </EtroTitle>
        <EtroText size="sm" color="dimmed" align="center" mb="xl">
          {`Last Updated ${UPDATED_DATE}`}
        </EtroText>
        <EtroText size="sm" align="left" mb="sm">
          {`This Privacy Policy is effective immediately for users that sign up for accounts on or after ${UPDATED_DATE} and will become effective on ${UPDATED_DATE} for users that already have accounts.`}
        </EtroText>
        <EtroText size="sm" align="left">
          {
            'This Privacy Policy describes our policies on the collection, use, and disclosure of information about you in connection with your use of our services, including those offered through our website, applications, and emails (collectively, the "Service"). The terms "we", "us", and "our" refer to Etro. When you use the Service, you consent to our collection, use, and disclosure of information about you as described in this Privacy Policy.'
          }
        </EtroText>
        <PrivacySection
          title="Information Collection And Use"
          text={[
            'We may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your username, your email address, your browser and device, including your browser type, device type, IP address, user agent string, operating system, mobile carrier, and identifiers assigned to your browser or device. We also collect information about your visit to our Service, such as the websites you visit immediately prior to visiting our Service, access times, and the specific pages viewed.',
            'If you create an account, we may store and use the information you provide during that process. We may publicly display your username. You can modify some of the information associated with your account through your account settings. When you sign up for an account or use certain features, you are opting to receive messages from us. You can manage some of your messaging preferences through your account settings, but note that you cannot opt out of receiving certain administrative, transactional, or legal messages from us. We may also track your actions in response to the messages you receive from us or through the Service, such as whether you deleted, opened, or forwarded such messages. We may also store information that you provide through communications to emails and other electronic messages us.'
          ]}
        >
          <EtroText size="sm" mb="sm">
            {
              'Your contributions to the Service are intended for public consumption and are therefore viewable by the public. You can limit the public nature of some of these activities through your account settings. In accordance with '
            }
            <EtroAnchor
              size="sm"
              className="etro-inline-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.na.square-enix.com/rule.php?id=5382&tag=users_en"
            >
              {"Square Enix's User Agreement"}
            </EtroAnchor>
            {
              ', characters, character names, character data, virtual items, character profile information are owned by the game company or its licensors. This information is not protected as personal information, and may be displayed on the site.'
            }
          </EtroText>
          <EtroText size="sm">
            {
              'If you would like more information about this practice and to know your choices about not having this information used, '
            }
            <EtroAnchor
              size="sm"
              className="etro-inline-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://optout.networkadvertising.org/?c=1#!%2F"
            >
              {'click here'}
            </EtroAnchor>
            {'.'}
          </EtroText>
        </PrivacySection>
        <PrivacySection
          title="Sharing of Information"
          text={[
            'We may share information about you as follows or as otherwise described in this Privacy Policy:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>
              {
                'With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process;'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of the Service or others;'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'Between and among the Service and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership; and'
              }
            </EtroList.Item>
            <EtroList.Item>
              {'With your consent or at your direction.'}
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
        <PrivacySection
          title="Cookies"
          text={[
            'We use cookies to store information for your convenience. We also may use third party advertisements on the Service. Some of these advertisers may use technology such as cookies and web beacons when they advertise on the Service, which will also send these advertisers information including your IP address, your ISP , the browser you used to visit our site, and in some cases, whether you have Flash installed. You can choose to disable or selectively turn off our cookies or third-party cookies in your browser setting. However, this can greatly affect how you are able to interact with the Service.'
          ]}
        />
        <PrivacySection
          title="Security"
          text={[
            "The Service's infrastructure and organization is built with data protection and privacy in mind, focusing on a secure deployment of services, secure storage of data, secure transmission between services, and safe operation by administrators. However, no method of transmission over the Internet or method of storage, is 100% secure and we cannot guarantee your personal information's absolute security."
          ]}
        />
        <PrivacySection
          title="Changes to this Privacy Policy"
          text={[
            'We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications.'
          ]}
        />
        <PrivacySection title="Contact">
          <EtroText size="sm">
            {
              'You may update, correct, or delete information about you at any time by emailing us at '
            }
            <EtroAnchor
              size="sm"
              className="etro-inline-link"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:support@etro.gg"
            >
              {'support@etro.gg'}
            </EtroAnchor>
            {
              '. Note that we may retain certain information as required by law or for legitimate business purposes authorized under this Privacy Policy.'
            }
          </EtroText>
        </PrivacySection>
        <EtroBox mt="xl" sx={{textAlign: 'left'}}>
          <EtroTitle mb="lg" color="etro" order={1} align="center">
            {'For California Residents: CCPA Information'}
          </EtroTitle>
          <span className="etro-ccpa-link" data-ccpa-link="1"></span>
          <EtroText size="sm" mt="sm">
            {
              'This Privacy Notice for California Residents supplements the information contained elsewhere in this Privacy Notice and applies solely to all visitors, users, and others who reside in the State of California (“consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this notice.'
            }
          </EtroText>
        </EtroBox>
        <PrivacySection
          title="Rights and Choices"
          text={[
            'The CCPA provides consumers located in the state of California with certain rights regarding their personal information and data. The following section describes those rights and explains how to exercise them:'
          ]}
        />
        <PrivacySection
          title="Access to Specific Information and Data Portability Rights"
          text={[
            'You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (as described in the section “Exercising Access, Data Portability, and Deletion Rights”), we will disclose to you:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>
              {'The categories of personal information we collected about you.'}
            </EtroList.Item>
            <EtroList.Item>
              {
                'The categories of sources for the personal information we collected about you.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'Our business or commercial purpose for collecting or selling that personal information.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'The categories of third parties with whom we share that personal information.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'The specific pieces of personal information we collected about you (also called data portability request)'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'If we sold or disclose your personal information for a business purpose, two separate lists disclosing:'
              }
              <EtroList withPadding size="sm">
                <EtroList.Item>
                  {
                    'Sales, identifying the personal information categories that each category of recipient purchased, and'
                  }
                </EtroList.Item>
                <EtroList.Item>
                  {
                    'Disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained'
                  }
                </EtroList.Item>
              </EtroList>
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
        <PrivacySection
          title="Non-Discrimination"
          text={[
            "Any CCPA-permitted financial incentive we offer will reasonably relate to your value and contain written terms that describe the program's material aspects. We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:"
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>{'Deny you goods or services.'}</EtroList.Item>
            <EtroList.Item>
              {
                'Charge you different prices or rates for goods or services, including through granting discounts or imposing penalties.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {'Provide you a different level or quality of goods or services.'}
            </EtroList.Item>
            <EtroList.Item>
              {
                'Suggest that you may receive a different price or rate for goods or services or a different level of quality of goods or services.'
              }
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
        <PrivacySection
          title="Exercising Access, Data Portability, and Deletion Rights"
          text={[
            'To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request through the Contact section above.',
            'Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.',
            'You may only make a verifiable consumer request for access of data portability twice within a 12-month period.',
            'We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.',
            'Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.',
            'The verifiable consumer request must:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>
              {
                'Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.'
              }
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
        <PrivacySection
          title="Information We Collect"
          text={[
            'Our websites, emails (with your consent, where required by law), and other products, services and platforms collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). In particular, our websites, apps, emails, and other products, services and platforms may have collected the following categories of personal information from its consumers within the last twelve (12) months:'
          ]}
        >
          <EtroTable highlightOnHover mb="md">
            <thead>
              <tr>
                <th>Category</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>{informationCollectedRows}</tbody>
          </EtroTable>
          <EtroBox>
            <EtroText size="sm">
              {'Personal information does not include:'}
            </EtroText>
            <EtroList withPadding size="sm">
              <EtroList.Item>
                {'Publicly available information from government records.'}
              </EtroList.Item>
              <EtroList.Item>
                {'Deidentified or aggregated consumer information.'}
              </EtroList.Item>
              <EtroList.Item>
                {"Information excluded from the CCPA's scope, like:"}
                <EtroList withPadding size="sm">
                  <EtroList.Item>
                    {
                      'health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;'
                    }
                  </EtroList.Item>
                  <EtroList.Item>
                    {
                      "personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy."
                    }
                  </EtroList.Item>
                </EtroList>
              </EtroList.Item>
            </EtroList>
          </EtroBox>
        </PrivacySection>
        <PrivacySection
          title="Use of Personal Information"
          text={[
            'We may use or disclose the personal information we collect for one or more of the following business purposes:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>
              {
                'To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a newsletter or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we or our third-party service providers will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product or service orders and requests.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To provide, support, personalize, and develop our websites, emails, and other products, services and platforms.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To create, maintain, customize, and secure your account with us.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To process your requests, purchases, transactions, and payments and prevent transactional fraud.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To provide you with support and to respond to your inquiries, including investigating and addressing your concerns and monitoring and improving our responses.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To personalize your website, apps, emails, or other product, service or platform experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our websites, apps, emails, and other products, services and platforms.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To help maintain the safety, security, and integrity of our websites, apps, emails, and other products, services and platforms, databases and other technology assets, and business.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'For testing, research, analysis, and product development, including to develop and improve our websites, apps, emails, and other products, services and platforms.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'As described to you when collecting your personal information or as otherwise set forth in the CCPA.'
              }
            </EtroList.Item>
            <EtroList.Item>
              {
                'To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our website users is among the assets transferred.'
              }
            </EtroList.Item>
          </EtroList>
          <EtroText size="sm" mt="sm">
            {
              'We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.'
            }
          </EtroText>
        </PrivacySection>
        <PrivacySection
          title="Sharing Personal Information"
          text={[
            "We may disclose your personal information to a third-party for a business purpose or sell your personal information, subject to your right to opt-out of those sales (see 'Sales of Personal Information' below). When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. The CCPA prohibits third parties who purchase the personal information we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of further sales (see 'Sales of Personal Information' below).",
            'We may share your personal information with the following categories of third parties:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>{'Subsidiaries and affiliates.'}</EtroList.Item>
            <EtroList.Item>
              {'Contractors and service providers.'}
            </EtroList.Item>
            <EtroList.Item>{'Data aggregators.'}</EtroList.Item>
            <EtroList.Item>
              {
                'Third parties with whom we partner to offer products and services to you.'
              }
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
        <PrivacySection
          title="Disclosures of Personal Information for a Business Purpose"
          text={[
            'In the preceding twelve (12) months, third parties have disclosed the following categories of personal information for a business purpose:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>{'[Category A: Identifiers.]'}</EtroList.Item>
            <EtroList.Item>
              {
                '[Category B: California Customer Records personal information categories.]'
              }
            </EtroList.Item>
            <EtroList.Item>
              {'[Category F: Internet or other similar network activity.]'}
            </EtroList.Item>
            <EtroList.Item>{'[Category G: Geolocation Data.]'}</EtroList.Item>
            <EtroList.Item>
              {
                '[Category K: Inferences drawn from other personal information.]'
              }
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
        <PrivacySection
          title="Sales of Personal Information"
          text={[
            'In the preceding twelve (12) months, we have not sold any personal information.'
          ]}
        />
        <PrivacySection
          title="Partnered Third Party Privacy Policies"
          text={[
            'The Service partners with various third parties, their respective privacy policies can be reviewed at the following links:'
          ]}
        >
          <EtroList withPadding size="sm">
            <EtroList.Item>
              <EtroAnchor
                size="sm"
                className="etro-inline-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://nitropay.com/privacy"
              >
                {'NitroPay'}
              </EtroAnchor>
            </EtroList.Item>
            <EtroList.Item>
              <EtroAnchor
                size="sm"
                className="etro-inline-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy"
              >
                {'Google Analytics'}
              </EtroAnchor>
            </EtroList.Item>
          </EtroList>
        </PrivacySection>
      </EtroContainer>
      <ConsentBanner />
    </>
  );
};
