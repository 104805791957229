import React from 'react';
import {sortBy, isEmpty} from 'lodash';
import {getWeaponDMGObject} from './GearsetHelpers';
import {paramNames, paramSortOrder} from '~/constants';

const handleConsumable = (i, paramId, item, values) => {
  const itemValue = item[`valueHQ${i}`],
    itemMax = item[`maxHQ${i}`];

  if (item[`isRelative${i}`]) {
    if (!isEmpty(values)) {
      const currentValue = values[paramId];

      return [
        `${itemValue}% (${currentValue}/${itemMax})`,
        currentValue >= itemMax
      ];
    } else {
      return [`${itemValue}% (Max ${itemMax})`, false];
    }
  } else {
    return [`${itemValue}`, false];
  }
};

export const ParamSpan = ({
  isCraftOrGatherJob,
  className,
  item,
  foodValues,
  medicineValues,
  shrinkOverflow = false
}) => {
  if (item) {
    let params = [];

    for (let i = 0; i < 11; i++) {
      let paramId = item[`param${i}`];
      // Don't need to check anymore if receive null paramId
      if (paramId) {
        // Hide VIT on some older gathering items
        if (
          !(isCraftOrGatherJob && paramId === 3) ||
          item.isFood ||
          item.isMedicine
        ) {
          let value,
            foodCapped = false;

          if (item.isFood) {
            const result = handleConsumable(i, paramId, item, foodValues);
            value = result[0];
            foodCapped = result[1];
          } else if (item.isMedicine) {
            const result = handleConsumable(i, paramId, item, medicineValues);
            value = result[0];
            foodCapped = result[1];
          } else {
            value = item[`param${i}Value`];
          }

          params.push({
            id: paramId,
            value,
            foodCapped
          });
        }
      } else {
        break;
      }
    }

    if (item.weapon && !isCraftOrGatherJob) {
      params.push(getWeaponDMGObject(item));
    }

    const paramOverflow = shrinkOverflow && params.length > 6;

    return (
      <span
        className={`${className}${paramOverflow ? ' etro-small-param' : ''}`}
      >
        {sortBy(params, [
          x => {
            return paramSortOrder[x.id];
          }
        ]).map((p, i) => (
          <span
            key={i}
            className={p.foodCapped ? 'etro-food-capped' : undefined}
          >
            {`${paramNames[p.id]} +${p.value}`}
          </span>
        ))}
      </span>
    );
  } else {
    return <span className={className} />;
  }
};
