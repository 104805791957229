/*
  Checks user's local storage for JWT and validates if exists.
  Allows the user to stay logged in through tab close and refresh.
*/
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {safeGetItem} from '~/utils/localStorage';
import {authCreators} from '~/actions/auth';

export const ValidateTokenComponent = ({isAuthenticated, getUser}) => {
  const userToken = safeGetItem('token');

  useEffect(() => {
    if (userToken && !isAuthenticated) {
      getUser();
    }
  }, [userToken, getUser, isAuthenticated]);

  return null;
};

const actions = {getUser: authCreators.user};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
};

export const ValidateToken = connect(
  mapStateToProps,
  actions
)(ValidateTokenComponent);
