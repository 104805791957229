import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {EtroStack} from '~/components';
import {paramNames, materiaMeldRate} from '~/constants';
import {
  EtroSelect,
  Option,
  IconOption
} from '~/components/EtroSelect/EtroSelect';
import {gearsetsCreators} from '~/actions/gearsets';
import {handleRingId} from './GearsetHelpers';

const MateriaOption = props => (
  <Option {...props}>
    <div className="etro-select-option">
      <div className="etro-select-option-icon">
        <img
          src={`${process.env.REACT_APP_ICON_BASE_URL}${props.data.iconPath}`}
          height="28px"
          alt={`${props.data.id}Icon`}
        />
      </div>
      <div className="etro-select-option-text-materia">
        <span className="etro-select-option-label-materia">
          {props.data.label}
        </span>
        <EtroStack align={'flex-end'} justify={'flex-start'} spacing={0}>
          <span className="etro-select-option-ilevel">
            {`${paramNames[props.data.param]} +${props.data.paramValue}`}
          </span>
          <span className="etro-select-option-stats-meld-rate">
            {`${props.data.meldRate}%`}
          </span>
        </EtroStack>
      </div>
    </div>
  </Option>
);

const MateriaSelectorComponent = ({
  listIsLoading,
  filteredMateriaOptions,
  selectedItem,
  selectedItemId,
  selectedMateria,
  materiaSlot,
  setGearsetMateria,
  defaultId,
  totalParams
}) => {
  const optionsWithMeldRate = useMemo(() => {
    return filteredMateriaOptions.reduce((acc, materia) => {
      const {param, tier} = materia;
      // materiaSlotCount is only the 100% slots
      const {advancedMelding, materiaSlotCount, maxParams} = selectedItem;

      const meldRate =
        !advancedMelding || materiaSlot <= materiaSlotCount
          ? 100
          : materiaMeldRate[tier][materiaSlot - materiaSlotCount];

      // Filter out any materia that is a wasted meld
      if (meldRate === 0 || totalParams[param] >= maxParams[param]) {
        return acc;
      }

      acc.push({
        ...materia,
        meldRate
      });

      return acc;
    }, []);
  }, [filteredMateriaOptions, materiaSlot, selectedItem, totalParams]);

  const onChange = materia => {
    setGearsetMateria({
      itemId: selectedItemId,
      slot: materiaSlot,
      materia: materia ? materia.id : null
    });
  };

  const label = (
    <span className="etro-selector-label">
      <h4>{`Slot ${materiaSlot}`}</h4>
      {selectedMateria &&
        `${paramNames[selectedMateria.param]} +${selectedMateria.paramValue}`}
    </span>
  );

  return (
    <EtroSelect
      menuPortalTarget={document.body}
      value={selectedMateria}
      components={{Option: MateriaOption, SingleValue: IconOption}}
      onChange={materia => {
        onChange(materia);
      }}
      defaultId={defaultId}
      preMappedOptions={optionsWithMeldRate}
      isLoading={listIsLoading}
      useIcons={true}
      isClearable={true}
      label={label}
      placeholder="Select a materia..."
      menuPlacement={'bottom'}
    />
  );
};

const actions = {
  setGearsetMateria: gearsetsCreators.setGearsetMateria
};

const mapStateToProps = (state, props) => {
  const {selectedItem, materiaSlot, slotKey} = props,
    selectedItemId = handleRingId(selectedItem.id, slotKey),
    gearsetMateria = state.gearsets.gearset.materia,
    readResult = state.gearsets.readResult && state.gearsets.readResult.materia,
    materiaSelectOptions = state.materia.materiaSelectOptions,
    selectedMateriaId =
      (!isEmpty(gearsetMateria) &&
        gearsetMateria[selectedItemId] &&
        gearsetMateria[selectedItemId][materiaSlot]) ||
      null,
    selectedMateria = selectedMateriaId
      ? materiaSelectOptions.find(x => x.id === selectedMateriaId)
      : null,
    defaultId =
      (!isEmpty(readResult) &&
        readResult[selectedItemId] &&
        readResult[selectedItemId][materiaSlot]) ||
      null;

  return {
    selectedMateria,
    defaultId,
    listIsLoading: state.materia.listIsLoading,
    listError: state.materia.listError,
    selectedItemId
  };
};

export const MateriaSelector = connect(
  mapStateToProps,
  actions
)(MateriaSelectorComponent);
