import React from 'react';
import {ScrollArea, ScrollAreaProps} from '@mantine/core';

export interface EtroScrollAreaProps extends ScrollAreaProps {}

const defaultProps: Partial<EtroScrollAreaProps> = {
  px: 'sm',
  scrollbarSize: 7
};

export const EtroScrollArea: React.FC<EtroScrollAreaProps> = props => {
  return <ScrollArea {...defaultProps} {...props} />;
};
