import React from 'react';
import {AnchorButton, IButtonProps, Tooltip} from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';
import {RemoteIcon} from '~/components';
import {useSelector} from '~/hooks';

interface NitroPayButtonProps extends IButtonProps {
  disableTooltip?: boolean;
  showText?: boolean;
}

export const NitroPayButton: React.FC<NitroPayButtonProps> = ({
  className,
  disableTooltip,
  minimal,
  outlined,
  showText = false,
  text
}) => {
  const {t} = useTranslation();
  const {darkMode, isAuthenticated, nitroPayToken} = useSelector(
    ({auth, user}) => {
      return {
        darkMode: user.darkMode,
        isAuthenticated: auth.isAuthenticated,
        nitroPayToken: auth.user?.nitroPayToken
      };
    }
  );

  return (
    <Tooltip
      disabled={disableTooltip && isAuthenticated}
      content={`${isAuthenticated ? '' : t('login to') + ' '}${t('subscribe')}`}
      intent={!isAuthenticated ? 'danger' : undefined}
      boundary="viewport"
      position="top"
    >
      <AnchorButton
        className={`etro-full-width etro-button-hover-primary-fill ${
          className ? className : ''
        }`}
        outlined={outlined}
        minimal={minimal}
        intent={outlined ? 'primary' : undefined}
        icon={
          <RemoteIcon
            iconPath={
              darkMode ? '/ui/nitroPayWhite.png' : '/ui/nitroPayBlack.png'
            }
            className="etro-icon-small"
            alt="nitro pay icon"
            width={24}
          />
        }
        text={text ? text : showText ? t('subscribe') : undefined}
        disabled={!isAuthenticated}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://sponsor.nitrocnct.com/product/${process.env.REACT_APP_NITROPAY_PRODUCT}?token=${nitroPayToken}&cancelUrl=${process.env.REACT_APP_NITROPAY_CB}false&successUrl=${process.env.REACT_APP_NITROPAY_CB}true`}
      />
    </Tooltip>
  );
};
