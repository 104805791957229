import {createReducers} from 'redux-arc';
import {differenceBy, sortBy, uniqBy} from 'lodash';
import {types} from '../actions/relic';
import {
  onReadRequest,
  onListRequest,
  onCreateRequest,
  onCreateResponse,
  onCloneRequest,
  onCloneResponse,
  onUpdateRequest,
  onUpdateResponse,
  onDeleteRequest,
  onDeleteResponse,
  onResetResults
} from './utils';
import {mainParamNameListNoVIT, paramNames} from '~/constants';

export const INITIAL_STATE = {
  cloneResult: null,
  cloneIsLoading: false,
  cloneError: null,
  createResult: null,
  createIsLoading: false,
  createError: null,
  deleteResult: null,
  deleteIsLoading: false,
  deleteError: null,
  listCached: false,
  listItemLevelSync: null,
  listResult: [],
  listIsLoading: false,
  listError: null,
  readResult: null,
  readIsLoading: false,
  readError: null,
  updateResult: null,
  updateIsLoading: false,
  updateError: null,
  ALC: {},
  ARM: {},
  AST: {},
  BLM: {},
  BLU: {},
  BRD: {},
  BSM: {},
  BTN: {},
  CRP: {},
  CUL: {},
  DNC: {},
  DRG: {},
  DRK: {},
  FSH: {},
  GNB: {},
  GSM: {},
  LTW: {},
  MCH: {},
  MIN: {},
  MNK: {},
  NIN: {},
  PCT: {},
  PLD: {},
  RDM: {},
  RPR: {},
  SAM: {},
  SCH: {},
  SGE: {},
  SMN: {},
  VPR: {},
  WAR: {},
  WHM: {},
  WVR: {}
};

const SORT_KEY = ['jobName', 'name'],
  UNIQUE_KEY = 'id';

const groupByJobAndSlot = ceArr => {
  return ceArr.reduce((acc, item) => {
    const {jobName, slotName} = item;

    if (acc[jobName]) {
      if (acc[jobName][slotName]) {
        acc[jobName][slotName].push(item);
      } else {
        acc[jobName][slotName] = [item];
      }
    } else {
      acc[jobName] = {[slotName]: [item]};
    }

    return acc;
  }, {});
};

export const combineProps = item => {
  const {baseItem} = item;

  let combinedParams = {
      Main: null,
      VIT: null,
      PIE: null,
      TEN: null,
      DH: null,
      CRT: null,
      DET: null,
      SKS: null,
      SPS: null
    },
    currentParamId = 0;

  const extractItemParams = tempItem => {
    for (var i = 0; i < 6; i++) {
      var paramString = `param${i}`,
        paramId = tempItem[paramString];
      // Don't need to check anymore if receive null paramId
      if (paramId) {
        const paramValue = tempItem[`param${i}Value`];

        combinedParams[`param${currentParamId}`] = tempItem[paramString];
        combinedParams[`param${currentParamId}Value`] = paramValue;
        currentParamId++;

        const paramName = paramNames[paramId];
        if (mainParamNameListNoVIT.includes(paramName)) {
          combinedParams.Main = paramValue;
        } else {
          combinedParams[paramName] = paramValue;
        }
      } else {
        break;
      }
    }
  };

  extractItemParams(baseItem);
  extractItemParams(item);

  return {
    ...baseItem,
    ...item,
    baseItemName: baseItem.name,
    baseItem: undefined,
    ...combinedParams
  };
};

const onRelicListResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      listCached: false,
      listItemLevelSync: null,
      listIsLoading: false,
      listError:
        (action.payload.response && action.payload.response.data) || true,
      listResult: []
    };
  }

  const listResult = sortBy(
    uniqBy(
      [...action.payload.data.map(i => combineProps(i)), ...state.listResult],
      UNIQUE_KEY
    ),
    SORT_KEY
  );

  return {
    ...state,
    listCached: true,
    listItemLevelSync: action.payload.config?.params?.itemLevelSync ?? null,
    listIsLoading: false,
    listResult,
    ...groupByJobAndSlot(listResult)
  };
};

const onRelicReadResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      readIsLoading: false,
      readError:
        (action.payload.response && action.payload.response.data) || true,
      readResult: null
    };
  }

  const newItem = combineProps(action.payload.data),
    {jobName, slotName} = newItem;

  return {
    ...state,
    readIsLoading: false,
    readResult: newItem,
    listResult: sortBy(
      uniqBy([newItem, ...state.listResult], UNIQUE_KEY),
      SORT_KEY
    ),
    [jobName]: {
      ...state[jobName],
      [slotName]: uniqBy(
        [newItem, ...(state[jobName][slotName] ?? [])],
        UNIQUE_KEY
      )
    }
  };
};

// Create and Clone handler
const ccHandler = (state, action) => {
  if (action.error) {
    return state;
  }

  const newItem = state.listResult.find(x => x.id === action.payload.data.id),
    {jobName, slotName} = newItem;

  return {
    ...state,
    [jobName]: {
      ...state[jobName],
      [slotName]: [...(state[jobName][slotName] ?? []), newItem]
    }
  };
};

const updateResponseHandler = (state, action) => {
  if (action.error) {
    return state;
  }

  const updatedItem = state.listResult.find(x => x.id === action.meta.id),
    {id, jobName, slotName} = updatedItem;

  return {
    ...state,
    [jobName]: {
      ...state[jobName],
      [slotName]: state[jobName][slotName].map(x =>
        x.id === id ? updatedItem : x
      )
    }
  };
};

const deleteResponseHandler = (state, action) => {
  if (action.error) {
    return state;
  }

  const {id, jobName, slotName} = action.meta;

  return {
    ...state,
    [jobName]: {
      ...state[jobName],
      [slotName]: differenceBy(state[jobName][slotName], [{id}], UNIQUE_KEY)
    }
  };
};

const onResetWatches = state => {
  return {...state, createResult: null, updateResult: null};
};

const HANDLERS = {
  [types.READ.REQUEST]: onReadRequest,
  [types.READ.RESPONSE]: onRelicReadResponse,
  [types.LIST.REQUEST]: onListRequest,
  [types.LIST.RESPONSE]: onRelicListResponse,
  [types.CREATE.REQUEST]: onCreateRequest,
  [types.CREATE.RESPONSE]: (state, action) =>
    ccHandler(onCreateResponse(state, action, SORT_KEY, combineProps), action),
  [types.CLONE.REQUEST]: onCloneRequest,
  [types.CLONE.RESPONSE]: (state, action) =>
    ccHandler(onCloneResponse(state, action, SORT_KEY, combineProps), action),
  [types.UPDATE.REQUEST]: onUpdateRequest,
  [types.UPDATE.RESPONSE]: (state, action) =>
    updateResponseHandler(
      onUpdateResponse(state, action, SORT_KEY, combineProps),
      action
    ),
  [types.DELETE.REQUEST]: onDeleteRequest,
  [types.DELETE.RESPONSE]: (state, action) =>
    deleteResponseHandler(onDeleteResponse(state, action), action),
  [types.RESET_RESULTS]: onResetResults,
  [types.RESET_WATCHES]: onResetWatches
};

export const relic = createReducers(INITIAL_STATE, HANDLERS);
