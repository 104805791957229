import {createReducers} from 'redux-arc';
import {orderBy, uniqBy} from 'lodash';
import {types} from '../actions/equipment';
import * as utils from './utils';
import {jobList, ORDER_KEY} from '~/constants';

export const INITIAL_STATE = {
  listResult: [],
  listIsLoading: false,
  listError: null,
  readResult: null,
  readIsLoading: false,
  readError: null,
  ALC: {},
  ARM: {},
  AST: {},
  BLM: {},
  BLU: {},
  BRD: {},
  BSM: {},
  BTN: {},
  CRP: {},
  CUL: {},
  DNC: {},
  DRG: {},
  DRK: {},
  FSH: {},
  GNB: {},
  GSM: {},
  LTW: {},
  MCH: {},
  MIN: {},
  MNK: {},
  NIN: {},
  PCT: {},
  PLD: {},
  RDM: {},
  RPR: {},
  SAM: {},
  SCH: {},
  SGE: {},
  SMN: {},
  VPR: {},
  WAR: {},
  WHM: {},
  WVR: {}
};

const UNIQUE_KEY = 'id';

const groupBySlotCategory = equipmentArr => {
  return equipmentArr.reduce((acc, x) => {
    var key = x.slotName;

    if (acc[key]) {
      acc[key].push(x);
    } else {
      acc[key] = [x];
    }

    return acc;
  }, {});
};

const onJobEquipmentListResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      listIsLoading: false,
      listError:
        (action.payload.response && action.payload.response.data) || true,
      listResult: []
    };
  }

  const jobAbbrev =
      action.payload.config &&
      action.payload.config.params &&
      Object.keys(action.payload.config.params)[0],
    validJob = jobList.includes(jobAbbrev);

  return validJob
    ? {
        ...state,
        listIsLoading: false,
        [jobAbbrev]: {
          itemLevelRange: [
            action.payload.config.params.minItemLevel,
            action.payload.config.params.maxItemLevel
          ],
          itemLevelSync: action.payload.config.params.itemLevelSync,
          ...groupBySlotCategory(orderBy(action.payload.data, ...ORDER_KEY))
        }
      }
    : {
        ...state,
        listIsLoading: false,
        listResult: orderBy(action.payload.data, ...ORDER_KEY)
      };
};

const mergeReadToList = (state, data, jobAbbrev) => {
  const {slotName} = data;

  return {
    ...state[jobAbbrev],
    [slotName]: orderBy(
      uniqBy([data, ...(state[jobAbbrev]?.[slotName] || [])], UNIQUE_KEY),
      ...ORDER_KEY
    )
  };
};

const onJobEquipmentReadResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      readIsLoading: false,
      readResult: null,
      readError: action.payload.response?.data || true
    };
  }

  const {data} = action.payload,
    jobAbbrev = action.meta.jobAbbrev ?? data.jobName;

  return {
    ...state,
    readIsLoading: false,
    readResult: {...data, jobAbbrev},
    listResult: orderBy(
      uniqBy([data, ...state.listResult], UNIQUE_KEY),
      ...ORDER_KEY
    ),
    [jobAbbrev]: mergeReadToList(state, data, jobAbbrev)
  };
};

const onMergeReadResult = state => {
  const {jobAbbrev} = state.readResult;

  return {
    ...state,
    [jobAbbrev]: mergeReadToList(state, state.readResult, jobAbbrev)
  };
};

const HANDLERS = {
  [types.READ.REQUEST]: utils.onReadRequest,
  [types.READ.RESPONSE]: onJobEquipmentReadResponse,
  [types.LIST.REQUEST]: utils.onListRequest,
  [types.LIST.RESPONSE]: onJobEquipmentListResponse,
  [types.MERGE_READ_RESULT]: onMergeReadResult
};

export const equipment = createReducers(INITIAL_STATE, HANDLERS);
