import React from 'react';
import {Dialog, Intent, Button} from '@blueprintjs/core';
import {EtroAlert, EtroBox, EtroStack, EtroText, EtroTitle} from '~/components';
import {InlineMath, BlockMath} from 'react-katex';
import {DiscordInviteButton} from '~/components/DiscordButton/DiscordInviteButton';
import {IconAlertTriangleFilled} from '@tabler/icons-react';

const HelpDialogSection = ({children, title}) => {
  return (
    <EtroStack spacing={'sm'}>
      {title && (
        <EtroTitle order={3} color="etro" align="center">
          {title}
        </EtroTitle>
      )}
      {children}
    </EtroStack>
  );
};

export const HelpDialog = ({isOpen, toggle}) => {
  return (
    <Dialog
      title={'Gearset Help'}
      icon={'help'}
      isOpen={isOpen}
      onClose={toggle}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      backdropClassName={''}
    >
      <EtroStack m="md">
        <HelpDialogSection title={'Stat Tiers'}>
          <EtroText size="sm">
            In FFXIV, stats are tiered. For example, assume DET has tiers at 100
            and 200, which give 101% and 102% multipliers respectively. If the
            gearset had 150 DET, the multiplier would be 101% and it would
            effectively have 50 points of wasted DET. Not until the gearset had
            200 DET or more, would it be at the 102% multiplier. The actual
            tiers are much smaller in practice. While using Etro, if the "Prev"
            column has a 0, this means that stat is at an exact tier and the
            stat name will turn green.
          </EtroText>
        </HelpDialogSection>
        <HelpDialogSection title={'100p Action (Expected)'}>
          <EtroAlert icon={<IconAlertTriangleFilled />} color="etro.4">
            <EtroText size="sm">
              Due to rotational changes and job intricacies, it is invalid to
              compare this value between vastly different GCDs .
            </EtroText>
          </EtroAlert>
          <EtroText size="sm">
            Expected damage from a 100 potency action with normalized CRT and DH
            values. SKS, SPS, self buffs and raid buffs are NOT taken into
            account.
          </EtroText>
          <EtroText size="sm">
            CRT and DH multipliers are normalized by the following equation:
          </EtroText>
          <BlockMath math={`1 + \\frac{\\text{R}}{{100}}(\\text{M} - 1)`} />
          <EtroText size="sm">
            Where <InlineMath math={`\\text{R}`} /> is the DH or CRT rate and{' '}
            <InlineMath math={`\\text{M}`} /> is their respective multiplier.
          </EtroText>
        </HelpDialogSection>
        <HelpDialogSection title={'Further Questions?'}>
          <DiscordInviteButton
            text={'Join Discord'}
            outlined
            className={'etro-button-hover-discord-fill etro-button-height'}
          />
        </HelpDialogSection>
      </EtroStack>
      <EtroBox mx="md" sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          onClick={toggle}
          intent={Intent.PRIMARY}
          text="Ok"
          outlined={true}
        />
      </EtroBox>
    </Dialog>
  );
};
