import React from 'react';
import {Container, ContainerProps} from '@mantine/core';

export interface EtroContainerProps extends ContainerProps {}

const defaultProps: Partial<EtroContainerProps> = {};

export const EtroContainer: React.FC<EtroContainerProps> = props => {
  return <Container {...defaultProps} {...props} />;
};
