import React from 'react';
import {Alert, AlertProps} from '@mantine/core';

export interface EtroAlertProps extends AlertProps {}

const defaultProps: Partial<EtroAlertProps> = {
  color: 'etro.4'
};

export const EtroAlert: React.FC<EtroAlertProps> = ({children, ...rest}) => {
  return (
    <Alert {...defaultProps} {...rest}>
      {children}
    </Alert>
  );
};
