import React from 'react';
import {
  EtroContainer,
  EtroSimpleGrid,
  EtroStack,
  EtroTitle
} from '~/components';
import {AccountSubscriptionCard} from './AccountSubscriptionCard';
import {NitroPayAd} from '~/components/NitroPayAd';
import {useSelector} from '~/hooks';
import {useMantineTheme} from '@mantine/core';
import {AccountSettingsCard} from './AccountSettingsCard';
import {useTranslation} from 'react-i18next';

export const Account: React.FC = () => {
  const {user} = useSelector(({auth}) => auth);
  const {t} = useTranslation();
  const {breakpoints} = useMantineTheme();

  if (!!user) {
    return (
      <>
        <EtroContainer sx={{flexGrow: 1, maxWidth: '1080px'}}>
          <EtroStack align={'center'} spacing={'xl'}>
            <NitroPayAd placementId="etro-ad-account-top" type="bannerLarge" />
            <EtroTitle color="etro" order={1} align="center">
              {t('account')}
            </EtroTitle>
            <EtroSimpleGrid
              sx={{width: '100%', textAlign: 'left'}}
              cols={2}
              spacing="xl"
              breakpoints={[{maxWidth: breakpoints.sm, cols: 1}]}
            >
              <AccountSettingsCard user={user} />
              <AccountSubscriptionCard subscription={user.subscription} />
            </EtroSimpleGrid>
            <NitroPayAd placementId="etro-ad-account-bottom" type="bannerMed" />
          </EtroStack>
        </EtroContainer>
        <NitroPayAd placementId="etro-ad-account-floating" type="floating" />
      </>
    );
  }

  // PrivateRoute will handle Redirect.
  return null;
};
