import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button} from '@blueprintjs/core';
import {orderBy} from 'lodash';
import {
  EtroSelect,
  EquipmentOption,
  IconOption
} from '~/components/EtroSelect/EtroSelect';
import {Buffs} from './Buffs';
import {MateriaCard} from './MateriaCard';
import {ParamSpan} from './ParamSpan';
import {gearsetsCreators} from '~/actions/gearsets';
import {relicCreators} from '~/actions/relic';
import {filterOptionBySecondary} from './GearsetHelpers';
import {RelicForm} from '../Inventory/RelicForm';
import {specialEquipment, ORDER_KEY, iLevelSyncIcon} from '~/constants';
import {EtroGroup} from '~/components/EtroGroup';
import {EtroTitle} from '~/components/EtroTitle';
import {EtroText} from '~/components/EtroText';
import {EtroDivider, EtroPaper} from '~/components';
import {RemoteSVG} from '~/components/EtroIcon/RemoteSVG';
import {useMobileMediaQuery} from '~/hooks';
import {useTranslation} from 'react-i18next';
import parse from 'html-react-parser';

export const GearsetCardComponent = ({
  allMateriaEditorVisible,
  currentJob,
  filteredMateriaOptions,
  foodValues,
  gearset,
  isAuthenticated,
  isCraftOrGatherJob,
  medicineValues,
  options,
  readRelic,
  relicCreateResult,
  relicUpdateResult,
  relicReadResult,
  resetRelicWatches,
  setGearset,
  slot
}) => {
  const {t} = useTranslation();
  const slotKey = slot.gearsetKey ? slot.gearsetKey : slot.equipKey,
    selectedItem = gearset[slotKey],
    // When loading a GS, the slots are equipment ID (number), relic ID (string), or buffs (object)
    defaultId = gearset[slotKey],
    [relicFormDialogVisible, setRelicFormDialogVisible] = useState(false),
    toggleRelicFormDialog = useCallback(
      () => setRelicFormDialogVisible(!relicFormDialogVisible),
      [relicFormDialogVisible]
    ),
    [relicItem, setRelicItem] = useState(null),
    [baseRelicItem, setBaseRelicItem] = useState(null),
    onChange = useCallback(
      item => {
        setGearset({[slotKey]: item});
      },
      [setGearset, slotKey]
    ),
    createWatchId = useMemo(
      () =>
        relicCreateResult &&
        relicCreateResult?.slotName === slot.equipKey &&
        relicCreateResult.id,
      [relicCreateResult, slot.equipKey]
    ),
    updateWatch = useMemo(
      () => relicUpdateResult && relicUpdateResult?.slotName === slot.equipKey,
      [relicUpdateResult, slot.equipKey]
    );

  const isMobile = useMobileMediaQuery();

  const handleRelicEditOnClick = useCallback(() => {
    setBaseRelicItem(options.find(x => x.id === selectedItem.baseItemId));

    if (gearset.itemLevelSync) {
      readRelic(null, {id: selectedItem.id, skipSetGearset: true}).then(() => {
        // Cannot setRelic readResult because it isn't updated yet
        toggleRelicFormDialog();
      });
    } else {
      setRelicItem(selectedItem);
      toggleRelicFormDialog();
    }
  }, [
    gearset.itemLevelSync,
    options,
    readRelic,
    selectedItem,
    toggleRelicFormDialog
  ]);

  useEffect(() => {
    if (
      gearset.itemLevelSync &&
      relicFormDialogVisible &&
      relicReadResult &&
      relicReadResult.id === selectedItem.id
    ) {
      setRelicItem(relicReadResult);
    }
  }, [
    gearset.itemLevelSync,
    relicFormDialogVisible,
    relicReadResult,
    selectedItem
  ]);

  return (
    <>
      {/* div stops Paper from growing when no item selected, but another card in row has item */}
      <div>
        <EtroPaper p={isMobile ? 'xs' : 'lg'}>
          <EtroGroup position="apart" align={'flex-end'} mb={'xs'}>
            <EtroGroup position="left" align={'flex-end'} spacing={'xs'}>
              <RemoteSVG
                iconPath={`/slots/${
                  slot.equipKey ? slot.equipKey : slot.gearsetKey
                }.svg`}
              />
              <EtroTitle order={3}>{slot.name}</EtroTitle>
            </EtroGroup>
            <EtroGroup position="right" align={'flex-end'} spacing="xs">
              {selectedItem?.itemLevelSync && (
                <EtroText size="xs" color="yellow.6">
                  {`${iLevelSyncIcon} ${selectedItem.itemLevelSync}`}
                </EtroText>
              )}
              {selectedItem?.itemLevel && (
                <EtroText size="xs">{`i${selectedItem.itemLevel}`}</EtroText>
              )}
            </EtroGroup>
          </EtroGroup>
          {slotKey !== 'buffs' ? (
            <EtroSelect
              value={selectedItem}
              components={{
                Option: props =>
                  EquipmentOption({...props, isCraftOrGatherJob}),
                SingleValue: IconOption
              }}
              onChange={item => {
                onChange(item);
              }}
              defaultId={defaultId}
              options={options}
              isClearable={true}
              useIcons={true}
              placeholder={null}
              noOptionsMessage={() =>
                'No items, select a job or adjust the item level range.'
              }
              createWatchId={createWatchId}
              updateWatch={updateWatch}
              resetWatch={resetRelicWatches}
            />
          ) : (
            <Buffs
              currentJob={currentJob}
              selectedItem={selectedItem}
              onChange={onChange}
              readResult={defaultId}
            />
          )}
          {selectedItem?.description &&
            !selectedItem.isFood &&
            !selectedItem.isMedicine && (
              <>
                <EtroText size={'xs'} align="left" inline>
                  {parse(selectedItem.description)}
                </EtroText>
                <EtroDivider my="xs" />
              </>
            )}
          <ParamSpan
            isCraftOrGatherJob={isCraftOrGatherJob}
            className="etro-gearset-card-stats"
            item={selectedItem}
            foodValues={foodValues}
            medicineValues={medicineValues}
            shrinkOverflow={true}
          />
          {selectedItem?.canCustomize &&
            !selectedItem?.baseItemId &&
            isAuthenticated && (
              <Button
                className="etro-gearset-card-button"
                text={`${t('create')} ${selectedItem.name}`}
                outlined={true}
                icon="plus"
                onClick={() => {
                  setBaseRelicItem(selectedItem);
                  toggleRelicFormDialog();
                }}
              />
            )}
          {selectedItem?.canCustomize &&
            selectedItem?.isOwner &&
            isAuthenticated && (
              <Button
                className="etro-gearset-card-button"
                text={`${t('edit')} ${selectedItem.name}`}
                outlined={true}
                icon="edit"
                onClick={handleRelicEditOnClick}
              />
            )}
          {selectedItem?.materiaSlotCount > 0 && (
            <MateriaCard
              allMateriaEditorVisible={allMateriaEditorVisible}
              currentJob={currentJob}
              filteredMateriaOptions={filteredMateriaOptions}
              selectedItem={selectedItem}
              slotKey={slotKey}
            />
          )}
        </EtroPaper>
      </div>
      {relicFormDialogVisible && (
        <RelicForm
          isOpen={relicFormDialogVisible}
          toggle={() => {
            toggleRelicFormDialog();
            setRelicItem(null);
            setBaseRelicItem(null);
          }}
          selectedRow={relicItem}
          defaultBaseItem={baseRelicItem}
          itemLevelSync={gearset.itemLevelSync}
        />
      )}
    </>
  );
};

const actions = {
  setGearset: gearsetsCreators.setGearset,
  readRelic: relicCreators.read,
  resetRelicWatches: relicCreators.resetWatches
};

const mapStateToProps = (state, props) => {
  const defaultList = [],
    currentJob = state.jobs.currentJob,
    itemLevelRange = state.gearsets.itemLevelRange,
    {slot} = props;

  let options = [];

  if (slot.name === 'Food') {
    options = filterOptionBySecondary(
      currentJob,
      state.food.listResult,
      true,
      state.gearsets.gearset.food
    );
  } else if (slot.name === 'Medicine') {
    options = filterOptionBySecondary(currentJob, state.medicine.listResult);
  } else if (currentJob) {
    const baseOptions =
        state.equipment[currentJob.abbrev][slot.equipKey] || defaultList,
      customOptions =
        state.relic[currentJob.abbrev][slot.equipKey] || defaultList;

    options = orderBy(
      baseOptions
        .concat(customOptions)
        .filter(
          ({id, itemLevel}) =>
            (itemLevel >= itemLevelRange[0] &&
              itemLevel <= itemLevelRange[1]) ||
            (specialEquipment[currentJob.abbrev] &&
              specialEquipment[currentJob.abbrev].includes(id))
        ),
      ...ORDER_KEY
    );
  }

  return {
    currentJob,
    options,
    gearset: state.gearsets.gearset,
    isAuthenticated: state.auth.isAuthenticated,
    relicCreateResult: state.relic.createResult,
    relicUpdateResult: state.relic.updateResult,
    relicReadResult: state.relic.readResult
  };
};

export const GearsetCard = connect(
  mapStateToProps,
  actions
)(GearsetCardComponent);
