import React from 'react';
import {Title, TitleProps} from '@mantine/core';

export interface EtroTitleProps extends TitleProps {}

const defaultProps: Partial<EtroTitleProps> = {};

export const EtroTitle: React.FC<EtroTitleProps> = props => {
  return <Title {...defaultProps} {...props} />;
};
