import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'typeface-open-sans';
import {App} from './App';
// import {unregister} from './serviceWorker';
import './i18n';

// CSS
import './styles/index.scss';
import '../node_modules/normalize.css/normalize.css';

// App.js is the entry point
ReactDOM.render(
  <Suspense fallback="">
    <App />
  </Suspense>,
  document.getElementById('root')
);
/* 
  Causes Security Exception in FF private modes. 
  Think was only used to remove the old SW (~2019) if existed
*/
// unregister();
