import React, {useCallback} from 'react';
import {NumberInput, NumberInputProps} from '@mantine/core';
import {EtroCloseButton} from '../EtroCloseButton';

export interface EtroNumberInputProps extends NumberInputProps {
  clearable?: boolean;
}

const defaultProps: Partial<EtroNumberInputProps> = {
  labelProps: {size: 'sm'},
  size: 'xs'
};

export const EtroNumberInput: React.FC<EtroNumberInputProps> = ({
  clearable,
  disabled,
  onChange,
  rightSection,
  size,
  value,
  ...rest
}) => {
  const onClearClick = useCallback(() => {
    onChange?.('');
  }, [onChange]);

  return (
    <NumberInput
      disabled={disabled}
      onChange={onChange}
      rightSectionWidth={30}
      size={size}
      // null is not handled properly
      value={value ?? ''}
      {...defaultProps}
      {...rest}
      rightSection={
        <>
          {rightSection}
          {clearable && (
            <EtroCloseButton
              disabled={disabled || !value}
              onClick={onClearClick}
              size={size}
              variant="subtle"
            />
          )}
        </>
      }
    />
  );
};
