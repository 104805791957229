import React from 'react';
import {Button, Dialog, Intent} from '@blueprintjs/core';
import {
  EtroDataGrid,
  IconCellRenderer,
  SortingAlgorithms
} from '~/components/EtroDataGrid';
import {EtroBox} from '~/components/EtroBox';
import {RemoteSVG} from '~/components/EtroIcon/RemoteSVG';

const columns = [
  {
    name: 'Materia',
    key: 'name',
    sortable: true,
    sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive,
    formatter: ({row}) => (
      <IconCellRenderer
        height={'24px'}
        width={'24px'}
        iconPath={row.iconPath}
        value={row.name}
        justify="-start"
      />
    )
  },
  {
    name: 'Total',
    key: 'total',
    sortable: true,
    sortingAlgorithm: SortingAlgorithms.number
  }
  // {name: 'Stat Value', key: 'sum'}
];

export const MateriaTotalsDialog = ({materiaTotals, isOpen, toggle}) => {
  return (
    <Dialog
      title={'Materia Totals'}
      icon={
        <RemoteSVG iconPath="/slots/materia.svg" className="etro-icon-small" />
      }
      isOpen={isOpen}
      onClose={toggle}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      backdropClassName={''}
    >
      <EtroBox
        sx={{
          '.rdg': {
            gridTemplateColumns: `auto 80px !important`
          }
        }}
        p="lg"
      >
        <EtroDataGrid
          disableHeader
          disableFiltering
          className="etro-param-table"
          rows={materiaTotals}
          columns={columns}
          size="fill"
        />
      </EtroBox>
      <div className="etro-dialog-footer">
        <div className="etro-dialog-footer-actions">
          <Button
            onClick={toggle}
            intent={Intent.PRIMARY}
            text="Ok"
            outlined={true}
          />
        </div>
      </div>
    </Dialog>
  );
};
