import React, {useEffect, useState} from 'react';
import {EtroDialog} from '~/components';
import {Button} from '@blueprintjs/core';

export const ConsentBanner: React.FC = () => {
  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    if (window?.nitroAds?.loaded && window?.__tcfapi) {
      setBannerVisible(true);
    } else {
      document.addEventListener('nitroAds.loaded', () => {
        if (window?.__tcfapi) {
          setBannerVisible(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (window['__cmp']) {
      window['__cmp']('addConsentLink');
    }
  }, []);

  return (
    <EtroDialog
      opened={bannerVisible}
      withCloseButton
      onClose={() => setBannerVisible(false)}
      size="md"
      radius="md"
      shadow="md"
    >
      <Button
        text="Manage Cookie Preferences"
        outlined
        icon="cog"
        onClick={() => {
          if (window['__cmp']) {
            window['__cmp']('showModal');
          }
        }}
      />
    </EtroDialog>
  );
};
