import React, {useMemo, useEffect} from 'react';
import {sortBy} from 'lodash';
import {StatsAndTiersTable} from './StatsAndTiersTable';
import {AdditionalStatsTable} from './AdditionalStatsTable';
import {jobParamList} from '~/constants';
import {
  calcAllGCDTiers,
  calcCriticalHitRate,
  calcCriticalMultiplier,
  calcDetMultiplier,
  calcDefMitigation,
  calcDirectHitRate,
  calcGCD,
  calcGCDTiers,
  calcHP,
  calcMDefMitigation,
  calcMPRefresh,
  calcSpeedMultiplier,
  calcTenDamageMultiplier,
  calcTenMitigation,
  calculateItemLevel,
  calculateTiers,
  checkIfHealer,
  checkIfTank,
  convertMultiplier,
  getParam,
  getWeaponDMGObject,
  calcMainStatMultiplier,
  calcWeaponDMGMultiplier,
  calcExpectedDMG,
  calcExpectedCRTMod,
  calcExpectedDHMod
} from './GearsetHelpers';

export const SidebarTables = ({
  params,
  gearset,
  currentJob,
  additionalParams,
  setAdditionalParams,
  isCraftOrGatherJob,
  levelBaseParams
}) => {
  const mainStat = useMemo(
    () => getParam(params, jobParamList[currentJob.abbrev].main[0]),
    [currentJob.abbrev, params]
  );
  const gearsetItemLevel = useMemo(
      () => calculateItemLevel(gearset, currentJob, isCraftOrGatherJob),
      [gearset, currentJob, isCraftOrGatherJob]
    ),
    usesSKS = useMemo(
      () => jobParamList[currentJob.abbrev].secondary.includes('SKS'),
      [currentJob]
    ),
    isTank = checkIfTank(currentJob.abbrev),
    isHealer = isTank ? false : checkIfHealer(currentJob.abbrev),
    DH = useMemo(() => getParam(params, 'DH'), [params]),
    CRT = useMemo(() => getParam(params, 'CRT'), [params]),
    DET = useMemo(() => getParam(params, 'DET'), [params]),
    TEN = useMemo(() => getParam(params, 'TEN'), [params]),
    PIE = useMemo(() => getParam(params, 'PIE'), [params]),
    VIT = useMemo(() => getParam(params, 'VIT'), [params]),
    DEF = useMemo(() => getParam(params, 'DEF'), [params]),
    MDEF = useMemo(() => getParam(params, 'MDEF'), [params]),
    SPD = useMemo(
      () => (usesSKS ? getParam(params, 'SKS') : getParam(params, 'SPS')),
      [params, usesSKS]
    ),
    HP = useMemo(() => calcHP(VIT, currentJob, isTank, levelBaseParams), [
      VIT,
      currentJob,
      isTank,
      levelBaseParams
    ]),
    directHitRate = useMemo(() => calcDirectHitRate(DH, levelBaseParams), [
      DH,
      levelBaseParams
    ]),
    criticalHitRate = useMemo(() => calcCriticalHitRate(CRT, levelBaseParams), [
      CRT,
      levelBaseParams
    ]),
    crtMultiplier = useMemo(
      () => calcCriticalMultiplier(CRT, levelBaseParams),
      [CRT, levelBaseParams]
    ),
    defMitigation = useMemo(() => calcDefMitigation(DEF, levelBaseParams), [
      DEF,
      levelBaseParams
    ]),
    mdefMitigation = useMemo(() => calcMDefMitigation(MDEF, levelBaseParams), [
      MDEF,
      levelBaseParams
    ]),
    detMultiplier = useMemo(() => calcDetMultiplier(DET, levelBaseParams), [
      DET,
      levelBaseParams
    ]),
    spdMultiplier = useMemo(
      () => calcSpeedMultiplier(SPD, usesSKS, levelBaseParams),
      [SPD, usesSKS, levelBaseParams]
    ),
    tenDamageMultiplier = useMemo(
      () => calcTenDamageMultiplier(TEN, levelBaseParams),
      [TEN, levelBaseParams]
    ),
    tenMitigation = useMemo(() => calcTenMitigation(TEN, levelBaseParams), [
      TEN,
      levelBaseParams
    ]),
    mpRefresh = useMemo(() => calcMPRefresh(PIE, levelBaseParams), [
      PIE,
      levelBaseParams
    ]),
    mainStatMultiplier = useMemo(
      () => calcMainStatMultiplier(mainStat, levelBaseParams, isTank),
      [isTank, levelBaseParams, mainStat]
    ),
    weaponDMGMultiplier = useMemo(
      () =>
        calcWeaponDMGMultiplier({
          WD: getWeaponDMGObject(gearset.weapon)?.value,
          job: currentJob,
          levelBaseParams
        }),
      [currentJob, gearset, levelBaseParams]
    ),
    expectedDMG = useMemo(
      () =>
        calcExpectedDMG({
          dmg: weaponDMGMultiplier,
          main: mainStatMultiplier,
          crt: calcExpectedCRTMod({criticalHitRate, crtMultiplier}),
          det: detMultiplier,
          dh: calcExpectedDHMod({directHitRate}),
          ten: tenDamageMultiplier,
          trait: jobParamList[currentJob.abbrev].damageTrait
        }),
      [
        criticalHitRate,
        crtMultiplier,
        currentJob,
        detMultiplier,
        directHitRate,
        mainStatMultiplier,
        tenDamageMultiplier,
        weaponDMGMultiplier
      ]
    ),
    vitMultiplier = useMemo(
      () => calcMainStatMultiplier(VIT, levelBaseParams, isTank),
      [VIT, isTank, levelBaseParams]
    ),
    paramsAndTiers = useMemo(
      () =>
        calculateTiers(
          params,
          {
            detMultiplier,
            crtMultiplier,
            directHitRate,
            mpRefresh,
            spdMultiplier,
            tenMultiplier: tenDamageMultiplier,
            defMitigation,
            mdefMitigation,
            mainStatMultiplier,
            vitMultiplier
          },
          usesSKS,
          levelBaseParams,
          isTank
        ),
      [
        params,
        detMultiplier,
        crtMultiplier,
        directHitRate,
        mpRefresh,
        spdMultiplier,
        tenDamageMultiplier,
        defMitigation,
        mdefMitigation,
        mainStatMultiplier,
        vitMultiplier,
        usesSKS,
        levelBaseParams,
        isTank
      ]
    ),
    gcd = useMemo(
      () => calcGCD(SPD, usesSKS, currentJob, levelBaseParams, gearset.level),
      [SPD, usesSKS, currentJob, levelBaseParams, gearset]
    ),
    allGCDTiers = useMemo(
      () =>
        !isCraftOrGatherJob &&
        calcAllGCDTiers(usesSKS, currentJob, levelBaseParams, gearset.level),
      [usesSKS, currentJob, isCraftOrGatherJob, levelBaseParams, gearset]
    ),
    gcdTiers = useMemo(
      () =>
        !isCraftOrGatherJob &&
        calcGCDTiers(allGCDTiers, gcd, SPD?.value, currentJob, gearset.level),
      [isCraftOrGatherJob, allGCDTiers, gcd, SPD, currentJob, gearset]
    ),
    paramsAndTiersRows = useMemo(() => {
      return isCraftOrGatherJob
        ? paramsAndTiers
        : paramsAndTiers.concat(gcdTiers);
    }, [gcdTiers, isCraftOrGatherJob, paramsAndTiers]);

  useEffect(() => {
    let newParams = [{name: 'Average Item Level', value: gearsetItemLevel}];

    if (!isCraftOrGatherJob) {
      newParams = newParams
        .concat([
          {name: 'Critical Hit Rate', value: criticalHitRate, units: '%'},
          {
            name: 'Critical Hit Multiplier',
            value: convertMultiplier(crtMultiplier),
            units: '%'
          },
          {
            name: 'Determination Multiplier',
            value: convertMultiplier(detMultiplier),
            units: '%'
          },
          {name: 'Direct Hit Rate', value: directHitRate, units: '%'},
          {
            name: `${usesSKS ? 'Skill Speed' : 'Spell Speed'} Multiplier`,
            value: convertMultiplier(spdMultiplier),
            units: '%'
          },
          {name: 'HP', value: HP},
          {
            name: 'Defense Mitigation',
            value: defMitigation.converted,
            units: '%'
          },
          {
            name: 'Magic Defense Mitigation',
            value: mdefMitigation.converted,
            units: '%'
          },
          {
            name: `${mainStat.name} Multiplier`,
            value: convertMultiplier(mainStatMultiplier),
            units: '%'
          },
          {
            name: 'Weapon DMG Multiplier',
            value: convertMultiplier(weaponDMGMultiplier),
            units: '%'
          },
          {
            name: '100p Action (Expected)',
            value: expectedDMG,
            helpText:
              'Expected damage from a 100 potency action. See gearset help (next to save) for more information.'
          }
        ])
        .filter(Boolean);

      if (isTank) {
        newParams.push({
          name: 'Tenacity Multiplier',
          value: convertMultiplier(tenDamageMultiplier),
          units: '%'
        });
        newParams.push({
          name: 'Tenacity Mitigation',
          value: tenMitigation.converted,
          units: '%'
        });
      } else if (isHealer) {
        newParams.push({name: 'MP per Tick', value: mpRefresh});
      }
    }

    setAdditionalParams(sortBy(newParams, ({name}) => name.toLowerCase()));
  }, [
    setAdditionalParams,
    gearsetItemLevel,
    directHitRate,
    criticalHitRate,
    crtMultiplier,
    detMultiplier,
    spdMultiplier,
    usesSKS,
    isTank,
    isHealer,
    isCraftOrGatherJob,
    params,
    currentJob,
    HP,
    levelBaseParams,
    gcd,
    tenDamageMultiplier,
    mpRefresh,
    defMitigation,
    mainStat,
    mainStatMultiplier,
    gcdTiers,
    weaponDMGMultiplier,
    expectedDMG,
    mdefMitigation,
    tenMitigation
  ]);

  return (
    <>
      <StatsAndTiersTable
        rows={paramsAndTiersRows}
        isCraftOrGatherJob={isCraftOrGatherJob}
      />
      <AdditionalStatsTable additionalParams={additionalParams} />
    </>
  );
};
