import React, {useState} from 'react';
import {Provider} from 'react-redux';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';
import {FocusStyleManager} from '@blueprintjs/core';
import {ErrorBoundary} from 'react-error-boundary';
import {Header} from './components/Header';
import {ValidateToken} from './components/ValidateToken/ValidateToken';
import {TitleHandler} from './components/TitleHandler/TitleHandler';
import {NotFound} from './views/NotFound/NotFound';
import {VerifyEmail} from './views/VerifyEmail/VerifyEmail';
import {DiscordCallback} from './views/DiscordCallback/DiscordCallback';
import {Gearset} from './views/Gearset/Gearset';
import {ErrorFallback} from './components/EtroErrorBoundary';
import {NewVersionDialog} from './views/NewVersionDialog/NewVersionDialog';
import {store} from './store';
import {EmbedGearset} from './views/Embed/EmbedGearset';
import {EmbedWrapper} from './views/Embed/EmbedWrapper';
import {EmbedNotFound} from './views/Embed/EmbedNotFound';
import {EmbedLoadingOverlay} from './views/Embed/EmbedLoadingOverlay';
import {EmbedDocs} from './views/Embed/EmbedDocs';
import {ThemeProvider} from '~/components/ThemeProvider';
import {DndProvider} from 'react-dnd';
import {TouchBackend} from 'react-dnd-touch-backend';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {isMobile} from 'react-device-detect';
import {NitroPayCallback} from './views/NitroPayCallback';
import {Privacy} from './views/Privacy';
import {Contact} from './views/Contact';
import {PrivateRoute} from './routes/PrivateRoute';
import {Home} from './views/Home';
import {Updates} from './views/Updates';
import {Inventory} from './views/Inventory/Inventory';
import {Footer} from './components/Footer';
import {ScrollToTop} from './components/ScrollToTop';
import {EtroLoadingOverlay} from '~/components';
import {Account} from './views/Account';
import {PasswordReset} from './views/PasswordReset';
import {AppShell} from '@mantine/core';
import {ModalsProvider} from '@mantine/modals';
import {Notifications} from '@mantine/notifications';

// This turns off focus styles on checkboxes/switches since they cannot be overwritten???
FocusStyleManager.onlyShowFocusOnTabs();

// The Switch can only contain Routes otherwise NotFound will always render
export const App = () => {
  const [hasErrors, setHasErrors] = useState(false);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <ModalsProvider>
            <Notifications position="top-center" />
            <Switch>
              <Route path="/embed">
                <EmbedWrapper>
                  <EmbedLoadingOverlay />
                  <Switch>
                    <Route
                      exact
                      path="/embed/gearset/:id"
                      component={EmbedGearset}
                    />
                    <Route component={EmbedNotFound} />
                  </Switch>
                </EmbedWrapper>
              </Route>
              <Route path="/">
                <AppShell fixed={false} header={<Header />} footer={<Footer />}>
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={() => setHasErrors(true)}
                    onReset={() => {
                      setHasErrors(false);
                    }}
                    resetKeys={[hasErrors]}
                  >
                    <ValidateToken />
                    <EtroLoadingOverlay />
                    <NewVersionDialog />
                    <TitleHandler />
                    <ScrollToTop />
                    <DndProvider
                      backend={isMobile ? TouchBackend : HTML5Backend}
                    >
                      <Switch>
                        <PrivateRoute
                          exact
                          path="/account"
                          component={Account}
                        />
                        {/* Changed route in #1061, have redirect for safety */}
                        <Redirect from="/updates" to="/changelog" />
                        <Route exact path="/" component={Home} />
                        <Route exact path="/gearset" component={Gearset} />
                        <Route exact path="/gearset/:id" component={Gearset} />
                        <Route exact path="/inventory" component={Inventory} />
                        <Route exact path="/changelog" component={Updates} />
                        <Route exact path="/docs/embed" component={EmbedDocs} />
                        <Route
                          path="/verify-email/:key"
                          component={VerifyEmail}
                        />
                        <Route
                          path="/password-reset/:uid/:token"
                          component={PasswordReset}
                        />
                        <Route
                          path="/discord-callback"
                          component={DiscordCallback}
                        />
                        <Route
                          path="/nitropay-callback"
                          component={NitroPayCallback}
                        />
                        <Route path="/privacy" component={Privacy} />
                        <Route path="/contact" component={Contact} />
                        <Route component={NotFound} />
                      </Switch>
                    </DndProvider>
                  </ErrorBoundary>
                </AppShell>
              </Route>
            </Switch>
          </ModalsProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};
