import React from 'react';
import {createPolymorphicComponent, Button, ButtonProps} from '@mantine/core';
import {EtroButtonGroup} from './EtroButtonGroup';

export interface EtroButtonProps
  extends ButtonProps,
    Omit<
      React.HTMLProps<HTMLButtonElement>,
      'ref' | 'color' | 'size' | 'type'
    > {}

const defaultProps: Partial<EtroButtonProps> = {
  size: 'xs',
  variant: 'outline'
};

const _EtroButton = React.forwardRef<HTMLButtonElement, EtroButtonProps>(
  (props, ref) => {
    return <Button ref={ref} {...defaultProps} {...props} />;
  }
) as any;

_EtroButton.Group = EtroButtonGroup;

export const EtroButton = createPolymorphicComponent<
  'button',
  EtroButtonProps,
  {Group: typeof EtroButtonGroup}
>(_EtroButton);
