import React from 'react';
import {SimpleGrid, SimpleGridProps} from '@mantine/core';

export interface EtroSimpleGridProps extends SimpleGridProps {}

const defaultProps: Partial<EtroSimpleGridProps> = {};

export const EtroSimpleGrid: React.FC<EtroSimpleGridProps> = props => {
  return <SimpleGrid {...defaultProps} {...props} />;
};
