import React from 'react';
import {Burger, BurgerProps} from '@mantine/core';

export interface EtroBurgerProps extends BurgerProps {}

const defaultProps: Partial<EtroBurgerProps> = {};

export const EtroBurger = React.forwardRef<HTMLButtonElement, EtroBurgerProps>(
  (props, ref) => {
    return <Burger ref={ref} {...defaultProps} {...props} />;
  }
);
