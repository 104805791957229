import {createReducers} from 'redux-arc';
import {sortBy, trimStart} from 'lodash';
import {types} from '../actions/materia';
import * as utils from './utils';

export const INITIAL_STATE = {
  materiaSelectOptions: [],
  materiaIdMap: {},
  listResult: [],
  listIsLoading: false,
  listError: null,
  readResult: null,
  readIsLoading: false,
  readError: null
};

export const onMateriaListResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      listIsLoading: false,
      listError:
        (action.payload.response && action.payload.response.data) || true,
      listResult: [],
      materiaSelectOptions: []
    };
  }

  const mappedOptions = action.payload?.data
    ?.reduce((acc, materiaType) => {
      for (var p in materiaType) {
        // Have to check materiaType[p] because some tiers can be null
        if (materiaType[p] && typeof materiaType[p] === 'object') {
          acc.push({
            ...materiaType[p],
            materiaType: materiaType.id,
            param: materiaType.param,
            paramValue: materiaType[`${p}Value`],
            tier: Number(trimStart(p, 'tier'))
          });
        }
      }
      return acc;
    }, [])
    .map(x => {
      return {
        ...x,
        value: x.id,
        label: x.name
      };
    });

  const idMap = mappedOptions?.reduce((acc, materia) => {
    acc[materia.id] = materia;

    return acc;
  }, {});

  return {
    ...state,
    listIsLoading: false,
    listResult: action.payload.data,
    materiaSelectOptions: sortBy(mappedOptions, [
      // sort by name without roman numeral
      o => o.name.split('Materia')[0],
      'tier'
    ]),
    materiaIdMap: idMap ?? {}
  };
};

const HANDLERS = {
  [types.READ.REQUEST]: utils.onReadRequest,
  [types.READ.RESPONSE]: utils.onReadResponse,
  [types.LIST.REQUEST]: utils.onListRequest,
  [types.LIST.RESPONSE]: onMateriaListResponse
};

export const materia = createReducers(INITIAL_STATE, HANDLERS);
