import {createReducers} from 'redux-arc';
import {reduce, sortBy} from 'lodash';
import {types} from '../actions/gearsets';
import {types as equipmentTypes} from '~/actions/equipment';
import {types as relicTypes} from '~/actions/relic';
import {
  defaultMinItemLevel,
  defaultMateriaRange,
  gearsetSlotKeys,
  maxItemLevel,
  defaultMaxLevel,
  partyBonus
} from '~/constants';
import * as utils from './utils';
import {combineProps} from './relic';

const INITIAL_GEARSET = {
  weapon: null,
  offHand: null,
  head: null,
  body: null,
  hands: null,
  legs: null,
  feet: null,
  ears: null,
  neck: null,
  wrists: null,
  fingerL: null,
  fingerR: null,
  food: null,
  medicine: null,
  materia: null,
  buffs: null,
  level: defaultMaxLevel,
  itemLevelSync: undefined,
  partyBonus: partyBonus['Off']
};

export const INITIAL_STATE = {
  gearset: INITIAL_GEARSET,
  itemLevelRange: [defaultMinItemLevel, maxItemLevel],
  materiaTierRange: defaultMateriaRange,
  ariyalaImportError: null,
  ariyalaImportIsLoading: false,
  cloneResult: null,
  cloneIsLoading: false,
  cloneError: null,
  createResult: null,
  createIsLoading: false,
  createError: null,
  deleteResult: null,
  deleteIsLoading: false,
  deleteError: null,
  importResult: null,
  itemLevelSyncChanged: false,
  listCached: false,
  listResult: [],
  listIsLoading: false,
  listError: null,
  lodestoneImportError: null,
  lodestoneImportIsLoading: false,
  readResult: null,
  readIsLoading: false,
  readError: null,
  updateResult: null,
  updateIsLoading: false,
  updateError: null,
  xivgearImportError: null,
  xivgearImportIsLoading: false
};

const SORT_KEY = ['jobAbbrev', 'name'];

const onResetGearset = state => {
  return {
    ...state,
    cloneResult: null,
    createResult: null,
    importResult: null,
    readResult: null,
    updateResult: null,
    gearset: INITIAL_GEARSET
  };
};

const onSetGearset = (state, action) => {
  return {
    ...state,
    gearset: {...state.gearset, ...action.payload}
  };
};

const onSetGearsetMateria = (state, action) => {
  const {itemId, slot, materia} = action.payload,
    {gearset} = state;

  return {
    ...state,
    gearset: {
      ...gearset,
      materia: {
        ...gearset.materia,
        [itemId]:
          gearset.materia && gearset.materia[itemId]
            ? {...gearset.materia[itemId], [slot]: materia}
            : {[slot]: materia}
      }
    }
  };
};

const onFilterItemLevel = (state, action) => {
  const {gearset} = state,
    {itemLevelRange, jobAbbrev} = action.payload;

  const cleanedGearset = reduce(
    gearset,
    (r, v, k) => {
      if (
        // only want to filter actual items
        !gearsetSlotKeys.includes(k) ||
        (jobAbbrev === 'FSH' && k === 'offHand') ||
        (jobAbbrev === 'BLU' && k === 'weapon')
      ) {
        r[k] = v;
      } else {
        r[k] =
          v &&
          v.itemLevel >= itemLevelRange[0] &&
          v.itemLevel <= itemLevelRange[1]
            ? v
            : null;
      }
      return r;
    },
    {}
  );

  return {
    ...state,
    gearset: cleanedGearset
  };
};

const handleImportResponse = (state, action, importType) => {
  const importedGearset = action.payload.data;

  return {
    ...state,
    [`${importType}ImportIsLoading`]: false,
    importResult: importedGearset,
    listResult: sortBy(state.listResult.concat(importedGearset), SORT_KEY)
  };
};

const onAriyalaImportResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      ariyalaImportIsLoading: false,
      ariyalaImportError: utils.mapFieldError(
        action?.payload?.response?.data?.ariyalaID?.[0],
        'Ariyala Gearset ID'
      )
    };
  }

  return handleImportResponse(state, action, 'ariyala');
};

const onLodestoneImportResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      lodestoneImportIsLoading: false,
      lodestoneImportError: utils.mapFieldError(
        action?.payload?.response?.data?.lodestoneID?.[0],
        'Lodestone Character ID'
      )
    };
  }

  return handleImportResponse(state, action, 'lodestone');
};

const onXivgearImportResponse = (state, action) => {
  if (action.error) {
    return {
      ...state,
      xivgearImportIsLoading: false,
      xivgearImportError: utils.mapFieldError(
        action?.payload?.response?.data?.xivgearURL?.[0],
        'xivgear Export URL'
      )
    };
  }

  return handleImportResponse(state, action, 'xivgear');
};

const onResetImportError = state => {
  return {
    ...state,
    ariyalaImportError: null,
    lodestoneImportError: null,
    xivgearImportError: null
  };
};

const onSetItemLevelRange = (state, action) => {
  return {
    ...state,
    itemLevelRange: action.payload
  };
};

const onSetMateriaTierRange = (state, action) => {
  return {
    ...state,
    materiaTierRange: action.payload
  };
};

const onJobEquipmentListResponse = (state, action) => {
  if (!state.itemLevelSyncChanged) {
    return state;
  }

  if (action.error) {
    return {
      ...state,
      itemLevelSyncChanged: false
    };
  }

  const {gearset} = state;
  const items = action.payload.data;

  const syncedGearset = gearsetSlotKeys.reduce((acc, slot) => {
    if (gearset[slot] && typeof gearset[slot].id === 'number') {
      const item = items.find(({id}) => id === gearset[slot].id);

      // Check for item incase the ilevel range changed
      if (item) {
        // Add label and value for EtroSelect
        acc[slot] = {...item, label: item.name, value: item.id};
      }
    }

    return acc;
  }, {});

  return {...state, gearset: {...state.gearset, ...syncedGearset}};
};

const onSetItemLevelSync = (state, action) => {
  if (
    state.gearset.itemLevelSync !== action.payload ||
    // forceUpdate otherwise relics can be out of sync
    action.meta?.forceUpdate
  ) {
    return {
      ...state,
      gearset: {...state.gearset, itemLevelSync: action.payload},
      itemLevelSyncChanged: true
    };
  }

  return state;
};

const onRelicListResponse = (state, action) => {
  if (!state.itemLevelSyncChanged) {
    return state;
  }

  if (action.error) {
    return {
      ...state,
      itemLevelSyncChanged: false
    };
  }

  const {gearset} = state;
  const items = action.payload.data;

  const syncedGearset = gearsetSlotKeys.reduce((acc, slot) => {
    if (gearset[slot] && typeof gearset[slot].id === 'string') {
      let item = items.find(({id}) => id === gearset[slot].id);

      // Check for item incase the ilevel range changed
      if (item) {
        item = combineProps(item);
        // Add label and value for EtroSelect
        acc[slot] = {...item, label: item.name, value: item.id};
      }
    }

    return acc;
  }, {});

  return {...state, gearset: {...state.gearset, ...syncedGearset}};
};

const onRelicReadResponse = (state, action) => {
  if (!state.itemLevelSyncChanged || action.meta.skipSetGearset) {
    return state;
  }

  if (action.error) {
    return {
      ...state,
      itemLevelSyncChanged: false
    };
  }

  const {gearset} = state;
  const relic = action.payload.data;
  const relicSlot = relic.baseItem.slotName;

  if (gearset[relicSlot] && gearset[relicSlot].id === relic.id) {
    return {
      ...state,
      gearset: {
        ...state.gearset,
        [relicSlot]: {
          ...combineProps(relic),
          label: relic.name,
          value: relic.id
        }
      }
    };
  }

  return state;
};

const HANDLERS = {
  [types.SET_GEARSET]: onSetGearset,
  [types.SET_GEARSET_MATERIA]: onSetGearsetMateria,
  [types.RESET_GEARSET]: onResetGearset,
  [types.RESET_RESULTS]: utils.onResetResults,
  [types.FILTER_ITEM_LEVEL]: onFilterItemLevel,
  [types.READ.REQUEST]: utils.onReadRequest,
  [types.READ.RESPONSE]: (state, action) =>
    utils.onReadResponse(state, action, SORT_KEY),
  [types.LIST.REQUEST]: utils.onListRequest,
  [types.LIST.RESPONSE]: utils.onListResponse,
  [types.CREATE.REQUEST]: utils.onCreateRequest,
  [types.CREATE.RESPONSE]: (state, action) =>
    utils.onCreateResponse(state, action, SORT_KEY),
  [types.CLONE.REQUEST]: utils.onCloneRequest,
  [types.CLONE.RESPONSE]: (state, action) =>
    utils.onCloneResponse(state, action, SORT_KEY),
  [types.UPDATE.REQUEST]: utils.onUpdateRequest,
  [types.UPDATE.RESPONSE]: (state, action) =>
    utils.onUpdateResponse(state, action, SORT_KEY),
  [types.DELETE.REQUEST]: utils.onDeleteRequest,
  [types.DELETE.RESPONSE]: utils.onDeleteResponse,
  [types.ARIYALA_IMPORT.REQUEST]: state =>
    utils.onGenericRequest(state, 'ariyalaImport'),
  [types.ARIYALA_IMPORT.RESPONSE]: onAriyalaImportResponse,
  [types.RESET_IMPORT_ERROR]: onResetImportError,
  [types.LODESTONE_IMPORT.REQUEST]: state =>
    utils.onGenericRequest(state, 'lodestoneImport'),
  [types.LODESTONE_IMPORT.RESPONSE]: onLodestoneImportResponse,
  [types.SET_ITEM_LEVEL_RANGE]: onSetItemLevelRange,
  [types.SET_MATERIA_TIER_RANGE]: onSetMateriaTierRange,
  [types.SET_ITEM_LEVEL_SYNC]: onSetItemLevelSync,
  [equipmentTypes.LIST.RESPONSE]: onJobEquipmentListResponse,
  [relicTypes.LIST.RESPONSE]: onRelicListResponse,
  [relicTypes.READ.RESPONSE]: onRelicReadResponse,
  [types.XIVGEAR_IMPORT.REQUEST]: state =>
    utils.onGenericRequest(state, 'xivgearImport'),
  [types.XIVGEAR_IMPORT.RESPONSE]: onXivgearImportResponse
};

export const gearsets = createReducers(INITIAL_STATE, HANDLERS);
