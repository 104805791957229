import {Toaster, Position, Intent} from '@blueprintjs/core';

export const AppToaster = Toaster.create({
  position: Position.TOP
});

export const onSuccess = (message, intent = 'success', icon = 'tick') => {
  AppToaster.show({
    message,
    intent,
    icon
  });
};

export const onFailure = (message, intent = 'danger', icon = 'issue') => {
  AppToaster.show({
    message,
    intent,
    icon
  });
};

export const onWarning = ({
  message,
  icon = 'warning-sign',
  timeout = 5000,
  onDismiss
}) => {
  AppToaster.show({
    message,
    intent: Intent.WARNING,
    icon,
    timeout,
    onDismiss: onDismiss ? onDismiss : null
  });
};
