import React from 'react';
import {EmbedGearsetCard} from './EmbedGearsetCard';
import {EmbedGearsetContainer} from './EmbedGearsetContainer';
import {EmbedNotFound} from './EmbedNotFound';

export const EmbedGearset: React.FC = () => {
  return (
    <EmbedGearsetContainer>
      {({
        gearset,
        materiaMap,
        paramNames,
        readError,
        secondaryParams,
        slots
      }) => {
        return (
          <>
            {readError ? (
              <EmbedNotFound entity={'Gearset'} />
            ) : (
              <EmbedGearsetCard
                gearset={gearset}
                materiaMap={materiaMap}
                paramNames={paramNames}
                secondaryParams={secondaryParams}
                slots={slots}
              />
            )}
          </>
        );
      }}
    </EmbedGearsetContainer>
  );
};
