import React from 'react';
import {Divider, DividerProps} from '@mantine/core';

export interface EtroDividerProps extends DividerProps {}

const defaultProps: Partial<EtroDividerProps> = {
  size: 'xs',
  mt: 'sm',
  mb: 'sm'
};

export const EtroDivider: React.FC<EtroDividerProps> = props => {
  return <Divider {...defaultProps} {...props} />;
};
