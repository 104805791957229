import {etroApi} from '.';
import {Rotation} from '~/types';
import {concatToList, updateInList, deleteFromList} from './utils';

const ROTATION_BASE = 'rotations/';
const sortKey = ['jobAbbrev', 'name'];

const rotationsApi = etroApi.injectEndpoints({
  endpoints: ({query, mutation}) => ({
    readRotation: query<Rotation, string>({
      query: id => `${ROTATION_BASE}${id}`
    }),
    listRotations: query<Rotation[], void>({
      query: () => ROTATION_BASE
    }),
    createRotation: mutation<Rotation, Partial<Rotation>>({
      query: body => {
        return {
          url: ROTATION_BASE,
          method: 'POST',
          body
        };
      },
      onQueryStarted: async (body, {dispatch, queryFulfilled}) => {
        try {
          const {data: created} = await queryFulfilled;

          dispatch(
            rotationsApi.util.updateQueryData(
              'listRotations',
              undefined,
              draft => concatToList({draft, sortKey, created})
            )
          );
        } catch {}
      }
    }),
    updateRotation: mutation<Rotation, Partial<Rotation>>({
      query: ({id, ...body}) => {
        return {
          url: `${ROTATION_BASE}${id}/`,
          method: 'PUT',
          body
        };
      },
      onQueryStarted: async ({id}, {dispatch, queryFulfilled}) => {
        try {
          const {data: updated} = await queryFulfilled;

          dispatch(
            rotationsApi.util.updateQueryData(
              'listRotations',
              undefined,
              draft => updateInList({draft, sortKey, updated})
            )
          );
        } catch {}
      }
    }),
    deleteRotation: mutation<void, string>({
      query: id => {
        return {url: `${ROTATION_BASE}${id}`, method: 'DELETE'};
      },
      onQueryStarted: async (id, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;

          dispatch(
            rotationsApi.util.updateQueryData(
              'listRotations',
              undefined,
              draft => deleteFromList({draft, sortKey, id})
            )
          );
        } catch {}
      }
    }),
    cloneRotation: query<Rotation, string>({
      query: id => `${ROTATION_BASE}${id}/clone/`,
      onQueryStarted: async (body, {dispatch, queryFulfilled}) => {
        try {
          const {data: created} = await queryFulfilled;

          dispatch(
            rotationsApi.util.updateQueryData(
              'listRotations',
              undefined,
              draft => concatToList({draft, sortKey, created})
            )
          );
        } catch {}
      }
    })
  })
});

export const {
  useCloneRotationQuery,
  useCreateRotationMutation,
  useDeleteRotationMutation,
  useLazyCloneRotationQuery,
  useLazyListRotationsQuery,
  useLazyReadRotationQuery,
  useListRotationsQuery,
  useReadRotationQuery,
  useUpdateRotationMutation
} = rotationsApi;
