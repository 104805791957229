import {createStyles, useMantineTheme} from '@mantine/core';
import {useMediaQuery} from '@mantine/hooks';

export function useMobileMediaQuery() {
  const theme = useMantineTheme();
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return matches;
}

export const useMediaQueryClasses = createStyles(theme => ({
  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },
  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  }
}));
