import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  EtroAnchor,
  EtroContainer,
  EtroGroup,
  EtroText,
  EtroTitle
} from '~/components';
import {DiscordInviteButton} from '~/components/DiscordButton/DiscordInviteButton';

export const Contact: React.FC = () => {
  const {t} = useTranslation();

  return (
    <EtroContainer>
      <EtroTitle color="etro" order={1} align="center" mb="xl">
        {t('contact')}
      </EtroTitle>
      <EtroGroup position="center" sx={{alignItems: 'flex-end'}}>
        <EtroText size="sm">
          {
            'The fastest way to get help or report issues is to join the Etro Discord'
          }
        </EtroText>
        <DiscordInviteButton
          text={'Discord'}
          outlined
          className={'etro-discord-button'}
        />
      </EtroGroup>
      <EtroText size="sm" mt="sm">
        {
          'If you prefer not to use Discord for help or issues, send an email to '
        }
        <EtroAnchor
          size="sm"
          className="etro-inline-link"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:support@etro.gg"
        >
          {'support@etro.gg'}
        </EtroAnchor>
        {'.'}
      </EtroText>
      <EtroText size="sm" mt="sm">
        {'For business inquires, email us at '}
        <EtroAnchor
          size="sm"
          className="etro-inline-link"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:info@etro.gg"
        >
          {'info@etro.gg'}
        </EtroAnchor>
        {'.'}
      </EtroText>
    </EtroContainer>
  );
};
