import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

interface GearsetReducer {
  materiaEditorVisibleCount: number;
}

const initialState: GearsetReducer = {materiaEditorVisibleCount: 0};

export const gearsetSlice = createSlice({
  name: 'gearset',
  initialState,
  reducers: {
    adjustMateriaEditorVisibleCount(state, action: PayloadAction<number>) {
      state.materiaEditorVisibleCount += action.payload;
    }
  }
});

export const {adjustMateriaEditorVisibleCount} = gearsetSlice.actions;
