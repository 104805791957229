import React, {useState, useEffect} from 'react';
import {Spinner, Overlay, Intent} from '@blueprintjs/core';
import {EtroBox, EtroLogo} from '~/components';
import {useDebounce} from 'react-use';
import {useSelector} from '~/hooks';

export const EmbedLoadingOverlay: React.FC = () => {
  const [overlayVisible, toggleOverlayVisible] = useState(false);
  const {loading} = useSelector(({gearsets, relic}) => {
    return {
      loading: gearsets.readIsLoading || relic.readIsLoading
    };
  });

  useEffect(() => {
    if (!overlayVisible && loading) {
      toggleOverlayVisible(true);
    }
  }, [loading, overlayVisible]);

  // Debounce hiding overlay slightly to stop flashing with multiple API requests
  useDebounce(
    () => {
      if (overlayVisible && !loading) {
        toggleOverlayVisible(false);
      }
    },
    100,
    [loading, overlayVisible, toggleOverlayVisible]
  );

  return (
    <Overlay
      isOpen={overlayVisible}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <EtroBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px'
        }}
      >
        <EtroLogo
          showText
          disableLink
          svgProps={{style: {width: '100%', maxWidth: '250px'}}}
        />
        <Spinner intent={Intent.PRIMARY} size={60} />
      </EtroBox>
    </Overlay>
  );
};
