import React from 'react';
import {
  EtroContainer,
  EtroGroup,
  EtroSimpleGrid,
  EtroStack
} from '~/components';
import {About, HomeNews, GearsetBISList, GearsetList} from '.';
import {NitroPayAd} from '~/components/NitroPayAd';
import {useMantineTheme} from '@mantine/core';
import {useMobileMediaQuery} from '~/hooks';

export const Home: React.FC = () => {
  const {breakpoints} = useMantineTheme();
  const isMobile = useMobileMediaQuery();

  return (
    <>
      <EtroGroup
        align={'flex-start'}
        noWrap
        position="center"
        spacing={isMobile ? 0 : 'xl'}
      >
        <EtroContainer sx={{flexGrow: 1, maxWidth: '1800px'}}>
          <EtroStack align={'center'} spacing={'xl'}>
            <NitroPayAd placementId="etro-ad-home-top" type="bannerLarge" />
            <EtroSimpleGrid
              cols={2}
              spacing="xl"
              breakpoints={[{maxWidth: 1000, cols: 1}]}
            >
              <EtroStack spacing="xl" sx={{maxWidth: breakpoints.md}}>
                <About />
                <HomeNews />
              </EtroStack>
              <EtroStack spacing="xl">
                <GearsetBISList />
                <GearsetList />
              </EtroStack>
            </EtroSimpleGrid>
            <NitroPayAd
              placementId="etro-ad-home-bottom"
              type="bannerMed"
              config={{renderVisibleOnly: true}}
            />
          </EtroStack>
        </EtroContainer>
        <NitroPayAd placementId="etro-ad-home-right" type="anchor" />
      </EtroGroup>
      <NitroPayAd placementId="etro-ad-home-floating" type="floating" />
    </>
  );
};
