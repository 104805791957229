import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedLngs = ['de', 'en', 'fr', 'ja'];

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs,
    interpolation: {
      // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false
    },
    debug: process.env.NODE_ENV !== 'production',
    nonExplicitSupportedLngs: true
  });

// eslint-disable-next-line import/no-default-export
export default i18next;
