import React, {useCallback, useMemo} from 'react';
import {omit} from 'lodash';
import {useHistory} from 'react-router-dom';
import {useMeasure, createStateContext} from 'react-use';
import {
  Filters,
  EtroFilterState,
  EtroFilterColumns,
  nonInputFilters,
  LinkRowRenderer,
  EtroDataGridProps
} from '.';

const FILTER_INITIAL_STATE: Filters = {
  search: '',
  filterColumns: {},
  filterState: {}
};

export const [
  useFilterContext,
  FilterContextProvider,
  FilterContext
] = createStateContext<Filters>(FILTER_INITIAL_STATE);

export const useDGFilterContext = () => {
  const [filters, setFilters] = useFilterContext();
  const clearFilters = useCallback(
    (nextState?: Partial<Filters>) => {
      setFilters(
        // filterColumns is memoized in EtroDG based on columns changing so do not reset.
        nextState
          ? {
              search: '',
              filterState: {},
              ...nextState,
              filterColumns: filters.filterColumns
            }
          : {...FILTER_INITIAL_STATE, filterColumns: filters.filterColumns}
      );
    },
    [filters, setFilters]
  );
  const removeFilter = useCallback(
    (key: string) => {
      setFilters({...filters, filterState: omit(filters.filterState, key)});
    },
    [filters, setFilters]
  );
  const setSearch = useCallback(
    (value: string | null) => {
      setFilters({...filters, search: value ?? ''});
    },
    [filters, setFilters]
  );
  const setFilterState = useCallback(
    (key: string, value: EtroFilterState) => {
      setFilters({
        ...filters,
        filterState: {...filters.filterState, [key]: value}
      });
    },
    [filters, setFilters]
  );
  const setFilterColumns = useCallback(
    (filterColumns: EtroFilterColumns) => {
      setFilters({...filters, filterColumns});
    },
    [filters, setFilters]
  );
  const filterCount = useMemo(() => {
    return Object.values(filters.filterState).reduce(
      (acc, filter) => {
        const {value, operator} = filter;

        if (!!operator && (!!value || nonInputFilters.includes(operator))) {
          return (acc += 1);
        }

        return acc;
      },
      filters.search ? 1 : 0
    );
  }, [filters]);

  return {
    clearFilters,
    filterCount,
    filters,
    removeFilter,
    setFilters,
    setFilterColumns,
    setFilterState,
    setSearch
  };
};

// Have to set column width dynamically because it will extend outside container.
// Use ref to measure parent element
export const useColumnWidth = (numColumns: number) => {
  const [ref, {width}] = useMeasure();
  const columnWidth = useMemo(() => {
    // Subtract a few pixels because of borders
    return Math.floor(width / numColumns);
  }, [numColumns, width]);

  return [ref, columnWidth];
};

export const useLinkOnRowClick = (path: string, rowAccessor: string) => {
  const history = useHistory();
  const callback = useCallback(
    (row: Record<string, any>, column) => {
      history.push(`${path}${row[rowAccessor]}`);
    },
    [history, path, rowAccessor]
  );

  return callback;
};

// https://stackoverflow.com/questions/32308370/what-is-the-syntax-for-typescript-arrow-functions-with-generics
export const useLinkRowRenderer = <R,>(
  path: string,
  rowAccessor: string
): Pick<EtroDataGridProps<R>, 'renderers' | 'enableVirtualization'> => {
  const rowRenderer = useCallback(
    (key, props) => {
      return (
        <LinkRowRenderer
          {...props}
          key={key}
          path={path}
          rowAccessor={rowAccessor}
        />
      );
    },
    [path, rowAccessor]
  );

  /* 
    Disable virtualization automatically because it can cause flickering / render issues
    if the user scrolls too fast
  */
  return {renderers: {rowRenderer}, enableVirtualization: false};
};
