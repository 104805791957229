import React, {useState, useMemo, useCallback, useEffect} from 'react';
import DataGrid, {SortColumn} from 'react-data-grid';
import {useSelector} from '~/hooks';
import {EtroDataGridHeader} from './EtroDataGridHeader';
import {useDGFilterContext} from './hooks';
import {searchFilter} from './Filtering';
import {
  EmptyRowsRenderer,
  EtroDataGridProps,
  EtroDGColumnType,
  EtroDGSize,
  EtroFilterColumns,
  getFilterByTypeAndOperator,
  getSortByName,
  getSortByType,
  nonInputFilters,
  stringCaseInsensitive
} from '.';
import {get, isEmpty} from 'lodash';

export function EtroDataGridComponent<T, SR>(props: EtroDataGridProps<T, SR>) {
  const {
    className,
    columns,
    disableFiltering,
    disableHeader,
    headerActions,
    noDataText = 'No data available.',
    onRowClick,
    rowClass,
    rows,
    size = 'sm',
    style,
    title
  } = props;
  const {darkMode} = useSelector(state => {
    return {
      darkMode: state.user.darkMode
    };
  });
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const columnSortingAlgorithms = useMemo(() => {
    return columns.reduce(
      (acc: Record<string, ReturnType<typeof getSortByName>>, column) => {
        const {sortable, key, sortingAlgorithm, type} = column;
        if (!sortable) {
          return acc;
        }

        if (sortingAlgorithm) {
          acc[key] = getSortByName(sortingAlgorithm);
        } else if (type) {
          acc[key] = getSortByType(type);
        }

        return acc;
      },
      {}
    );
  }, [columns]);
  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;

    const sortedRows = Array.from(rows);
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const {columnKey, direction} = sort;
        const sortAlgorithm = columnSortingAlgorithms[columnKey];
        //@ts-ignore Issue with extending object, generics and sortAlgorithm is narrowing types
        const result = sortAlgorithm(a[columnKey], b[columnKey]);

        if (result !== 0) {
          return direction === 'ASC' ? result : -result;
        }
      }

      return 0;
    });

    return sortedRows;
  }, [columnSortingAlgorithms, rows, sortColumns]);
  const rowClassCallback = useCallback(
    (row: T) => {
      if (!!rowClass) {
        return rowClass(row);
      } else if (!!onRowClick) {
        return 'etro-dg-row-hover';
      }

      return undefined;
    },
    // Plugin tries to add T
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onRowClick, rowClass]
  );

  const {filters, filterCount, setFilterColumns} = useDGFilterContext();

  useEffect(() => {
    if (!disableFiltering && !disableHeader) {
      setFilterColumns(
        columns.reduce((acc: EtroFilterColumns, column) => {
          const {filterable, key, name, type} = column;

          if (!filterable || !type) {
            return acc;
          }

          acc[key] = {
            ...get(filters.filterColumns, key, {}),
            columnKey: key,
            name: typeof name === 'string' ? name : key,
            type
          };

          return acc;
        }, {})
      );
    }
    // Infinite loop if includes setFilterColumns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, disableFiltering, disableHeader]);

  useEffect(
    () => {
      if (!disableFiltering && !disableHeader && rows.length) {
        const selectColumns = Object.keys(filters.filterColumns).filter(
          key => filters.filterColumns[key].type === EtroDGColumnType.select
        );

        if (selectColumns.length) {
          setFilterColumns({
            ...filters.filterColumns,
            ...selectColumns.reduce((acc: EtroFilterColumns, columnKey) => {
              acc[columnKey] = {
                ...filters.filterColumns[columnKey],
                options: [...new Set(rows.map(row => (row as any)[columnKey]))]
                  .sort(stringCaseInsensitive)
                  .map(x => {
                    return {label: x, value: x};
                  })
              };

              return acc;
            }, {})
          });
        }
      }
    },
    // Infinite loop if includes setFilterColumns
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableFiltering, disableHeader, rows]
  );

  const filteredRows = useMemo(() => {
    if (filterCount === 0) {
      return sortedRows;
    }

    const {filterState, search} = filters;

    const filterFunctions = Object.keys(filterState).reduce(
      (
        acc: Record<string, ReturnType<typeof getFilterByTypeAndOperator>>,
        key
      ) => {
        const {type, operator, value} = filterState[key];

        if (!operator || (!value && !nonInputFilters.includes(operator))) {
          return acc;
        }

        acc[key] = getFilterByTypeAndOperator(type, operator);

        return acc;
      },
      {}
    );

    return sortedRows.filter(row => {
      if (!!search) {
        const searchResult = searchFilter(row as any, search);

        if (!searchResult) {
          return false;
        }
      }

      for (const [key, filter] of Object.entries(filterFunctions)) {
        const filterResult = filter(
          // Type narrowing is wrong
          (row as any)[filterState[key].columnKey],
          filterState[key].value as never
        );

        if (!filterResult) {
          return false;
        }
      }

      return true;
    });
  }, [filterCount, filters, sortedRows]);

  return (
    <>
      {!disableHeader && (
        <EtroDataGridHeader<T>
          disableFiltering={disableFiltering}
          headerActions={headerActions}
          rowLength={filteredRows.length}
          title={title}
        />
      )}
      <DataGrid
        {...props}
        className={`rdg-${
          darkMode ? 'dark etro-dg-dark' : 'light etro-dg-light'
        }${!!className ? ' ' + className : ''}`}
        onSortColumnsChange={setSortColumns}
        rowClass={rowClassCallback}
        sortColumns={sortColumns}
        style={{
          blockSize:
            isEmpty(filteredRows) && EtroDGSize[size] === EtroDGSize.fill
              ? EtroDGSize.empty
              : EtroDGSize[size],
          // Default is strict which stops rendering in FF when using fill
          contain: 'content',
          ...style
        }}
        renderers={{
          noRowsFallback: <EmptyRowsRenderer noDataText={noDataText} />,
          ...props.renderers
        }}
        rows={filteredRows}
      />
    </>
  );
}
