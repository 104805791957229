import React from 'react';
import {Grid, GridProps} from '@mantine/core';
import {EtroGridCol} from './EtroGridCol';

export interface EtroGridProps extends GridProps {}

const defaultProps: Partial<EtroGridProps> = {};

export const EtroGrid: React.FC<EtroGridProps> & {
  Col: typeof EtroGridCol;
} = props => {
  return <Grid {...defaultProps} {...props} />;
};

EtroGrid.Col = EtroGridCol;
