import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import queryStringParser from 'query-string';
import {Intent} from '@blueprintjs/core';
import {safeGetItem} from '~/utils/localStorage';
import {authCreators} from '~/actions/auth';
import {AppToaster} from '~/components/AppToaster/AppToaster';

const onFailure = () => {
  AppToaster.show({
    message: 'Error with Discord login, please try again.',
    intent: Intent.DANGER,
    icon: 'issue'
  });
};

export const DiscordCallbackComponent = ({
  location,
  discordLogin,
  discordLoginError
}) => {
  const params = queryStringParser.parse(location.search),
    code = params && params.code;

  useEffect(() => {
    if (code && !discordLoginError) {
      discordLogin({code}).then(r => {
        if (r && r.status !== 200) {
          onFailure();
        }
      });
    }
  }, [discordLogin, code, discordLoginError]);

  return <Redirect to={safeGetItem('discordCallbackFrom') ?? '/'} />;
};

const actions = {discordLogin: authCreators.discordLogin};

const mapStateToProps = state => {
  return {
    discordLoginError: state.auth.discordLoginError
  };
};

export const DiscordCallback = connect(
  mapStateToProps,
  actions
)(DiscordCallbackComponent);
