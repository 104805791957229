import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';
// @ts-ignore
import {createAsyncMiddleware} from 'redux-arc';
import {reduxArcReducers} from '~/reducers';
import {asyncTask} from './middleware/api';
import {etroApi} from './api';
import {sliceReducers} from './slices';

const asyncMiddleware = createAsyncMiddleware(asyncTask);

export const store = configureStore({
  // Alphabetize reducers so they're in order in Redux DevTools.
  reducer: {
    ...reduxArcReducers,
    ...sliceReducers,
    [etroApi.reducerPath]: etroApi.reducer
  },
  middleware: getDefaultMiddleware =>
    //https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false
    }).concat([asyncMiddleware, etroApi.middleware]),
  devTools: process.env.NODE_ENV !== 'production'
});

setupListeners(store.dispatch);

// Inferred types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
