import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {Intent} from '@blueprintjs/core';
import {
  useUnmount,
  useDebounce,
  useToggle,
  usePrevious,
  useMap
} from 'react-use';
import {useHistory, useParams} from 'react-router-dom';
import {AppToaster} from '~/components/AppToaster/AppToaster';
import {
  clansCreators,
  equipmentCreators,
  gearsetsCreators,
  jobsCreators,
  relicCreators
} from '~/actions';
import {GearsetCard} from './GearsetCard';
import {GearsetSidebarWrapper} from './GearsetSidebarWrapper';
import {ConfirmDialog} from '~/components/ConfirmDialog/ConfirmDialog';
import {SaveWarning} from '~/components/AppToaster/SaveWarning';
import {
  useFoodList,
  useJobEquipment,
  useMateriaOptions,
  useMedicineList,
  useSelector,
  useMobileMediaQuery
} from '~/hooks';
import {
  calculateParamTotals,
  sumJobClanBase,
  filterMateriaOptions,
  getSlotObjects,
  getLevelBaseParams
} from './GearsetHelpers';
import {
  defaultCraftGatherMinItemLevel,
  maxItemLevel,
  defaultMinItemLevel,
  currentPatch,
  specialEquipment,
  bluMaxLevel,
  defaultBLUMinItemLevel,
  defaultBLUMaxItemLevel,
  defaultBLUMaxMateriaTier,
  defaultMateriaRange,
  defaultMaxLevel
} from '~/constants';
import {
  Clan,
  EquipmentActions,
  Gearset as IGearset,
  GearsetActions,
  GearsetForm,
  Job,
  LookupActions,
  ReduxArcError,
  Relic,
  RelicActions,
  RootState,
  SpecialEquipmentJobs
} from '~/types';
import {JobAbbrevEnum} from '~/types/enums';
import {FormikProps} from 'formik';
import {
  EtroGroup,
  EtroPaper,
  EtroRTE,
  EtroStack,
  EtroTitle,
  NitroPayAd
} from '~/components';
import {useStyles} from './Gearset.styles';
import {useTranslation} from 'react-i18next';

interface GearsetProps {
  clanList: Clan[];
  createResult: IGearset | null;
  currentClan: Clan | null;
  currentJob: Job | null;
  gearset: RootState['gearsets']['gearset'];
  gearsetList: IGearset[];
  isAuthenticated: boolean;
  isCraftOrGatherJob: boolean;
  itemLevelRange: number[];
  itemLevelSync: number | undefined;
  jobList: Job[];
  listEquipment: EquipmentActions['listEquipment'];
  listError: ReduxArcError;
  listIsLoading: boolean;
  listRelic: RelicActions['listRelic'];
  loginIsLoading: boolean;
  materiaTierRange: number[];
  readEquipment: EquipmentActions['readEquipment'];
  readError: ReduxArcError;
  readGearset: GearsetActions['readGearset'];
  readIsLoading: boolean;
  readRelic: RelicActions['readRelic'];
  relicList: Relic[];
  relicListCached: boolean;
  relicListCachedItemLevel: number | null;
  relicListLoading: boolean;
  resetCurrentClan: LookupActions['resetCurrentClan'];
  resetCurrentJob: LookupActions['resetCurrentJob'];
  resetGearset: GearsetActions['resetGearset'];
  setCurrentClan: LookupActions['setCurrentClan'];
  setCurrentJob: LookupActions['setCurrentJob'];
  setGearset: GearsetActions['setGearset'];
  setItemLevelRange: GearsetActions['setItemLevelRange'];
  setItemLevelSync: GearsetActions['setItemLevelSync'];
  setMateriaTierRange: GearsetActions['setMateriaTierRange'];
}

const gearsetPath = '/gearset';

export const GearsetComponent: React.FC<GearsetProps> = ({
  clanList,
  createResult,
  currentClan,
  currentJob,
  gearset,
  gearsetList,
  isAuthenticated,
  isCraftOrGatherJob,
  itemLevelRange,
  itemLevelSync,
  jobList,
  listEquipment,
  listError,
  listIsLoading,
  listRelic,
  loginIsLoading,
  materiaTierRange,
  readEquipment,
  readError,
  readGearset,
  readIsLoading,
  readRelic,
  relicList,
  relicListCached,
  relicListCachedItemLevel,
  relicListLoading,
  resetCurrentClan,
  resetCurrentJob,
  resetGearset,
  setCurrentClan,
  setCurrentJob,
  setGearset,
  setItemLevelRange,
  setItemLevelSync,
  setMateriaTierRange
}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {id: gearsetId} = useParams<{id: string}>();
  const materiaList = useMateriaOptions();
  const listResult = useJobEquipment();
  const {isOwner, notes} = gearset;
  const [internalNote, setInternalNote] = useState(notes);
  const [allMateriaEditorVisible, toggleAllMateriaEditorVisible] = useToggle(
    false
  );
  const {
    materiaEditorVisibleCount,
    userDefaultItemLevelRange,
    userDefaultPartyBonus
  } = useSelector(({gearset, auth}) => {
    return {
      materiaEditorVisibleCount: gearset.materiaEditorVisibleCount,
      userDefaultItemLevelRange: auth.user?.defaultItemLevelRange,
      userDefaultPartyBonus: auth.user?.defaultPartyBonus
    };
  });
  const prevItemLevelSync = usePrevious(itemLevelSync);
  // Track loading state per read
  const [relicReadLoading, {set: setRelicReadLoading}] = useMap<
    Record<string, boolean>
  >({});
  const isMobile = useMobileMediaQuery();
  const {classes} = useStyles();
  const readOnly = useMemo(() => {
    return (
      !isAuthenticated ||
      readIsLoading ||
      (!isOwner && !!gearsetId) ||
      isEmpty(currentJob)
    );
  }, [currentJob, gearsetId, isAuthenticated, isOwner, readIsLoading]);

  useFoodList();
  useMedicineList();

  useUnmount(() => {
    resetGearset();
    resetCurrentJob();
    resetCurrentClan();
  });

  useDebounce(
    () => {
      if (internalNote !== notes) {
        setGearset({notes: internalNote});
      }
    },
    500,
    [internalNote]
  );

  const levelBaseParams = useMemo(
      () => getLevelBaseParams(currentJob, gearset.level),
      [currentJob, gearset]
    ),
    jobClanBaseSum = useMemo(
      () => sumJobClanBase(currentJob, currentClan, levelBaseParams),
      [currentJob, currentClan, levelBaseParams]
    ),
    paramTotals = useMemo(
      () =>
        calculateParamTotals(
          gearset,
          jobClanBaseSum,
          materiaList,
          isCraftOrGatherJob,
          levelBaseParams,
          currentJob
        ),
      [
        gearset,
        jobClanBaseSum,
        materiaList,
        isCraftOrGatherJob,
        levelBaseParams,
        currentJob
      ]
    ),
    params = paramTotals[0],
    foodValues = paramTotals[1],
    materiaTotals = paramTotals[2],
    medicineValues = paramTotals[3],
    filteredMateriaOptions = useMemo(
      () => filterMateriaOptions(currentJob, materiaList, materiaTierRange),
      [currentJob, materiaTierRange, materiaList]
    ),
    [clearDialogVisible, toggleClearDialog] = useState(false),
    handleClearDialogToggle = () => toggleClearDialog(!clearDialogVisible),
    [additionalParams, setAdditionalParams] = useState([]),
    formRef = useRef<FormikProps<GearsetForm>>(),
    slotObjects = useMemo(() => getSlotObjects(currentJob), [currentJob]);

  // On Job change, reset Gearset and setup item level for fetching
  const handleJobChange = useCallback(
    (job: Job | null) => {
      if (job && job?.id !== currentJob?.id) {
        resetGearset();
        setInternalNote(null);
        toggleAllMateriaEditorVisible(false);

        if (gearsetId) {
          history.replace(gearsetPath);
        }

        if (job.isCrafting || job.isGathering) {
          setItemLevelRange([defaultCraftGatherMinItemLevel, maxItemLevel]);
          setMateriaTierRange(defaultMateriaRange);
          setGearset({level: defaultMaxLevel});
        } else if (job.abbrev === JobAbbrevEnum.BLU) {
          setItemLevelRange([defaultBLUMinItemLevel, defaultBLUMaxItemLevel]);
          setMateriaTierRange([
            defaultBLUMaxMateriaTier,
            defaultBLUMaxMateriaTier
          ]);
          setGearset({level: bluMaxLevel});
        } else {
          setItemLevelRange(
            userDefaultItemLevelRange
              ? userDefaultItemLevelRange
              : [defaultMinItemLevel, maxItemLevel]
          );
          setMateriaTierRange(defaultMateriaRange);
          setGearset({
            level: defaultMaxLevel,
            ...(userDefaultPartyBonus
              ? {partyBonus: userDefaultPartyBonus}
              : {})
          });
        }
      }
    },
    [
      currentJob,
      gearsetId,
      history,
      resetGearset,
      setGearset,
      setItemLevelRange,
      setMateriaTierRange,
      toggleAllMateriaEditorVisible,
      userDefaultItemLevelRange,
      userDefaultPartyBonus
    ]
  );

  // currentJob forces wait until a gearset is loaded and incase sync
  useEffect(() => {
    if (
      isAuthenticated &&
      !isEmpty(currentJob) &&
      !relicListLoading &&
      (!relicListCached ||
        prevItemLevelSync !== itemLevelSync ||
        (relicListCachedItemLevel &&
          relicListCachedItemLevel !== itemLevelSync))
    ) {
      listRelic({itemLevelSync});
    }
  }, [
    currentJob,
    isAuthenticated,
    itemLevelSync,
    listRelic,
    prevItemLevelSync,
    relicListCached,
    relicListCachedItemLevel,
    relicListLoading
  ]);

  useEffect(() => {
    if (gearset.relics && !gearset.isOwner) {
      Object.values(gearset.relics).forEach(relicId => {
        const relic = relicList.find(({id}) => id === relicId);

        if (
          !relicReadLoading[relicId] &&
          (!relic || (relic && prevItemLevelSync !== itemLevelSync))
        ) {
          setRelicReadLoading(relicId, true);
          readRelic({itemLevelSync}, {id: relicId}).then(resp => {
            if (resp.status !== 200) {
              // If not found leave in loading state so that it is not requested again.
              AppToaster.show({
                message: 'Relic not found.',
                intent: Intent.DANGER,
                icon: 'issue'
              });
            } else {
              setRelicReadLoading(relicId, false);
            }
          });
        }
      });
    }
  }, [
    gearset,
    itemLevelSync,
    relicReadLoading,
    readRelic,
    relicList,
    setRelicReadLoading,
    prevItemLevelSync
  ]);

  useEffect(() => {
    const {
      itemLevelRange: cachedItemLevelRange,
      itemLevelSync: cachedItemLevelSync
    } = listResult;

    // If the currentJob or itemLevelRange changes, determine if we need to fetch again
    if (
      currentJob &&
      !isEmpty(currentJob) &&
      !listIsLoading &&
      !listError &&
      (isEmpty(listResult) ||
        itemLevelRange[0] < cachedItemLevelRange[0] ||
        itemLevelRange[1] > cachedItemLevelRange[1] ||
        prevItemLevelSync !== itemLevelSync ||
        cachedItemLevelSync !== itemLevelSync)
    ) {
      const {abbrev} = currentJob;

      listEquipment({
        [abbrev]: true,
        minItemLevel: itemLevelRange[0],
        maxItemLevel: itemLevelRange[1],
        maxLevel: abbrev === JobAbbrevEnum.BLU ? bluMaxLevel : undefined,
        itemLevelSync
      }).then(async r => {
        // This is .then because we want to use the list state,
        // and if it happens to finish before list we cannot keep
        // old state in the list reducer due to how ilevels work
        if (specialEquipment.hasOwnProperty(abbrev)) {
          specialEquipment[abbrev as SpecialEquipmentJobs].forEach(id =>
            readEquipment({itemLevelSync}, {id, jobAbbrev: abbrev})
          );
        }
      });
    }
  }, [
    currentJob,
    itemLevelRange,
    itemLevelSync,
    listEquipment,
    listError,
    listIsLoading,
    listResult,
    prevItemLevelSync,
    readEquipment,
    setItemLevelRange
  ]);

  useEffect(() => {
    if (gearsetId && gearsetId !== createResult?.id) {
      let nextGearset = gearsetList.find(({id}) => id === gearsetId);

      /* 
        Read can get in a race condition with Login if the user
        logged in on a gearset they own and isOwner can be false.
        The effect will refire once login has finished and read the GS.
      */
      if (!nextGearset && !readIsLoading && !loginIsLoading) {
        readGearset(null, {id: gearsetId}).then(r => {
          if (r?.status === 200) {
            nextGearset = r.data;
          }
        });

        if (readError || !isEmpty(readError)) {
          history.replace(gearsetPath);
          AppToaster.show({
            message: 'Gearset not found',
            intent: Intent.DANGER,
            icon: 'issue'
          });
        }
      }

      if (!!nextGearset && !isEmpty(jobList) && !isEmpty(clanList)) {
        const {
          job,
          clan,
          relics,
          minMateriaTier,
          maxMateriaTier,
          minItemLevel,
          maxItemLevel,
          name,
          itemLevelSync,
          notes
        } = nextGearset;

        const nextJob = jobList.find(({id}) => id === job);

        setCurrentJob({
          ...nextJob,
          value: nextJob?.id,
          label: nextJob?.name
        });

        // Gearsets aren't required to have a clan
        if (!!clan) {
          const nextClan = clanList.find(({id}) => id === clan);
          setCurrentClan({
            ...nextClan,
            value: nextClan?.id,
            label: nextClan?.name
          });
        }
        setInternalNote(notes);
        setGearset({...nextGearset, ...relics});
        setItemLevelRange([minItemLevel, maxItemLevel]);
        setMateriaTierRange([minMateriaTier, maxMateriaTier]);
        // forceUpdate otherwise relics can be out of sync
        setItemLevelSync(itemLevelSync, {forceUpdate: !!itemLevelSync});

        formRef.current?.setFieldValue('name', name);
        formRef.current?.setFieldValue('job', job);
        formRef.current?.setFieldValue('clan', clan);
      }
    }
    /* 
      Since gearsetList changes on updateGearset (and other API calls),
      it will cause this effect to rerun and setGearset again.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clanList,
    createResult,
    gearsetId,
    // gearsetList,
    history,
    jobList,
    loginIsLoading,
    readError,
    readGearset,
    readIsLoading,
    setCurrentClan,
    setCurrentJob,
    setGearset,
    setItemLevelRange,
    setMateriaTierRange
  ]);

  // Reset allVisible if the cards were all closed by component state,
  // otherwise the toggle button has to be hit twice.
  useEffect(() => {
    if (allMateriaEditorVisible && materiaEditorVisibleCount === 0) {
      toggleAllMateriaEditorVisible(false);
    }
    // Will auto close all editors if full deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materiaEditorVisibleCount]);

  return (
    <>
      <EtroGroup
        align={'flex-start'}
        noWrap
        position={isMobile ? 'center' : undefined}
        spacing={isMobile ? 0 : 'xl'}
        // Negative margin so that sidebar goes into main padding
        ml={isMobile ? undefined : '-md'}
      >
        <GearsetSidebarWrapper
          additionalParams={additionalParams}
          allMateriaEditorVisible={allMateriaEditorVisible}
          formRef={formRef}
          gearset={gearset}
          gearsetId={gearsetId}
          handleClearDialogToggle={handleClearDialogToggle}
          handleJobChange={handleJobChange}
          isCraftOrGatherJob={isCraftOrGatherJob}
          levelBaseParams={levelBaseParams}
          listResult={listResult}
          materiaTotals={materiaTotals}
          params={params}
          setAdditionalParams={setAdditionalParams}
          toggleAllMateriaEditorVisible={toggleAllMateriaEditorVisible}
        />
        <EtroStack
          align={'center'}
          spacing={'xl'}
          sx={{flexGrow: isMobile ? undefined : 1, width: '100%'}}
        >
          <NitroPayAd placementId="etro-ad-gearset-top" type="bannerLarge" />
          <EtroTitle color="etro" order={1} align="center">
            {t('gearset')}
          </EtroTitle>
          <div className={classes.grid}>
            {slotObjects.map((s, i) => (
              <GearsetCard
                allMateriaEditorVisible={allMateriaEditorVisible}
                filteredMateriaOptions={filteredMateriaOptions}
                foodValues={foodValues}
                isCraftOrGatherJob={isCraftOrGatherJob}
                key={i}
                medicineValues={medicineValues}
                slot={s}
              />
            ))}
            <EtroPaper
              sx={{
                gridColumn: '1 / -1',
                '@media(min-width: 1400px)': {gridColumn: 'span 2'}
              }}
            >
              <EtroTitle order={3} mb={'xs'} align="left">
                {'Notes'}
              </EtroTitle>
              <EtroRTE
                value={internalNote}
                onChange={setInternalNote}
                readOnly={readOnly}
                placeholder={
                  readOnly ? 'No gearset note.' : 'Enter a gearset note...'
                }
                sx={{
                  maxHeight: '400px',
                  overflowY: 'auto'
                }}
              />
            </EtroPaper>
          </div>
          <NitroPayAd
            placementId="etro-ad-gearset-bottom"
            type="bannerMed"
            config={{renderVisibleOnly: true}}
          />
        </EtroStack>
        <NitroPayAd placementId="etro-ad-gearset-right" type="anchor" />
      </EtroGroup>
      <NitroPayAd placementId="etro-ad-gearset-floating" type="floating" />
      <ConfirmDialog
        isOpen={clearDialogVisible}
        toggle={handleClearDialogToggle}
        confirmContent={'Are you sure you want to clear the current gearset?'}
        onConfirm={() => {
          if (gearsetId) {
            history.replace(gearsetPath);
          }
          resetGearset();
          resetCurrentJob();
          resetCurrentClan();
          setMateriaTierRange(defaultMateriaRange);
          setInternalNote(null);
          toggleAllMateriaEditorVisible(false);

          return formRef.current?.resetForm({
            name: '',
            job: null,
            clan: null,
            patch: currentPatch
          });
        }}
      />
      <SaveWarning
        warningPath={gearsetPath}
        message={'Please login to enable saving gearsets.'}
      />
    </>
  );
};

const actions = {
  listEquipment: equipmentCreators.list,
  listRelic: relicCreators.list,
  readEquipment: equipmentCreators.read,
  readGearset: gearsetsCreators.read,
  readRelic: relicCreators.read,
  resetCurrentClan: clansCreators.resetCurrentClan,
  resetCurrentJob: jobsCreators.resetCurrentJob,
  resetGearset: gearsetsCreators.resetGearset,
  setCurrentClan: clansCreators.setCurrentClan,
  setCurrentJob: jobsCreators.setCurrentJob,
  setGearset: gearsetsCreators.setGearset,
  setItemLevelRange: gearsetsCreators.setItemLevelRange,
  setItemLevelSync: gearsetsCreators.setItemLevelSync,
  setMateriaTierRange: gearsetsCreators.setMateriaTierRange
};

const mapStateToProps = (state: RootState) => {
  const currentJob = state.jobs.currentJob,
    isCraftOrGatherJob =
      (!isEmpty(currentJob) &&
        (currentJob?.isCrafting || currentJob?.isGathering)) ??
      false;

  return {
    clanList: state.clans.listResult,
    createResult: state.gearsets.createResult,
    currentClan: state.clans.currentClan,
    currentJob,
    gearset: state.gearsets.gearset,
    gearsetList: state.gearsets.listResult,
    isAuthenticated: state.auth.isAuthenticated,
    isCraftOrGatherJob,
    itemLevelRange: state.gearsets.itemLevelRange,
    itemLevelSync: state.gearsets.gearset.itemLevelSync,
    jobList: state.jobs.listResult,
    listError: state.equipment.listError,
    listIsLoading: state.equipment.listIsLoading,
    loginIsLoading:
      state.auth.loginIsLoading || state.auth.discordLoginIsLoading,
    materiaTierRange: state.gearsets.materiaTierRange,
    readError: state.gearsets.readError,
    readIsLoading: state.gearsets.readIsLoading,
    relicList: state.relic.listResult,
    relicListCached: state.relic.listCached,
    relicListCachedItemLevel: state.relic.listItemLevelSync,
    relicListLoading: state.relic.listIsLoading
  };
};

export const Gearset = connect(mapStateToProps, actions)(GearsetComponent);
