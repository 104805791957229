import React, {useCallback} from 'react';
import {Navbar} from '@mantine/core';
import {
  EtroButton,
  EtroDivider,
  EtroDrawer,
  EtroScrollArea,
  EtroStack,
  EtroText,
  RemoteSVG
} from '~/components';
import {Link} from 'react-router-dom';
import {FooterLinks} from '../Footer/FooterLinks';
import {useStyles} from '../Footer/Footer.styles';
import {useTranslation} from 'react-i18next';
import {HEADER_HEIGHT} from '.';

interface NavbarDrawerProps {
  drawerOpened: boolean;
  toggleDrawer: (nextState?: boolean) => void;
  classes: Record<string, string>;
}

export const NavbarDrawer: React.FC<NavbarDrawerProps> = ({
  drawerOpened,
  toggleDrawer,
  classes
}) => {
  const {t} = useTranslation();
  const {classes: footerClasses} = useStyles();
  const handleNavbarClick = useCallback(() => {
    toggleDrawer();
  }, [toggleDrawer]);

  return (
    <EtroDrawer
      className={classes.hiddenDesktop}
      onClose={toggleDrawer}
      opened={drawerOpened}
      position="bottom"
      size={`calc(100vh - ${HEADER_HEIGHT}px)`}
      styles={() => {
        return {
          body: {padding: 'unset'},
          inner: {padding: 'unset !important'}
        };
      }}
      transitionProps={{transition: 'scale-y'}}
      withCloseButton={false}
      withOverlay={false}
      zIndex={1000000}
    >
      <Navbar fixed top={0} height={'100%'}>
        {/* ScrollArea sets px="sm" so it accounts for scroll bar size */}
        <Navbar.Section pt="xl" grow component={EtroScrollArea}>
          <EtroStack spacing={'xl'} justify="flex-start">
            <EtroButton
              component={Link}
              leftIcon={
                <RemoteSVG iconPath="/ui/gearset.svg" className="etro-icon" />
              }
              onClick={handleNavbarClick}
              size="md"
              to="/gearset"
            >
              <EtroText size="sm">{t('gearset')}</EtroText>
            </EtroButton>
            <EtroButton
              component={Link}
              leftIcon={
                <RemoteSVG iconPath="/ui/inventory.svg" className="etro-icon" />
              }
              onClick={handleNavbarClick}
              size="md"
              to="/inventory"
            >
              <EtroText size="sm">{t('inventory')}</EtroText>
            </EtroButton>
          </EtroStack>
        </Navbar.Section>
        <EtroDivider />
        <Navbar.Section pb="xl" px="sm">
          <FooterLinks
            className={footerClasses.navbarLinks}
            onLinkClick={handleNavbarClick}
          />
        </Navbar.Section>
      </Navbar>
    </EtroDrawer>
  );
};
