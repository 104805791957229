import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDebounce} from 'react-use';
import {useDGFilterContext, FilteringPopover, EtroDataGridHeaderProps} from '.';
import {EtroInput} from '../EtroInput';

interface FilteringProps<T>
  extends Pick<EtroDataGridHeaderProps<T>, 'title' | 'rowLength'> {}

export function FilteringToolbar<T>({rowLength, title}: FilteringProps<T>) {
  const {filters, setSearch} = useDGFilterContext();
  const [internalValue, setInternalValue] = useState(filters.search ?? '');
  const {t} = useTranslation();

  useDebounce(
    () => {
      if (internalValue !== filters.search) {
        setSearch(internalValue);
      }
    },
    250,
    [internalValue]
  );

  // Keep internal in sync with events (clear button) which change the context
  useEffect(
    () => {
      setInternalValue(filters.search ?? '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.search]
  );

  return (
    <>
      <FilteringPopover />
      <EtroInput
        className="etro-button-margin"
        onChange={setInternalValue}
        value={internalValue}
        clearable
        leftIcon="search"
        placeholder={`${t('search')}${
          typeof title === 'string' ? ' ' + title : ' Rows'
        }`}
      />
    </>
  );
}
