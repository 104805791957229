import {etroApi} from '.';
import {Action, ActionTraitFilter} from '~/types';

const ACTION_BASE = 'actions/';

const actionsApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readAction: query<Action, number>({
      query: id => `${ACTION_BASE}${id}`
    }),
    listActions: query<Action[], ActionTraitFilter>({
      query: params => {
        return {url: `${ACTION_BASE}`, params};
      }
    })
  })
});

export const {useReadActionQuery, useListActionsQuery} = actionsApi;
