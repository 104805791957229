import React, {useRef} from 'react';
import {Row, RowRendererProps} from 'react-data-grid';
import {Link} from 'react-router-dom';

interface EmptyRowsRendererProps {
  noDataText: string;
}

interface LinkRowRendererProps
  extends RowRendererProps<Record<string, unknown>> {
  path: string;
  rowAccessor: string;
}

export const EmptyRowsRenderer: React.FC<EmptyRowsRendererProps> = ({
  noDataText
}) => {
  return <div className="etro-dg-no-data">{noDataText}</div>;
};

export const LinkRowRenderer: React.FC<LinkRowRendererProps> = ({
  row,
  path,
  rowAccessor,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    // Setting to row className gets all the RDG styles
    <Link className={ref.current?.className} to={`${path}${row[rowAccessor]}`}>
      <Row ref={ref} row={row} {...rest} />
    </Link>
  );
};
