import React, {FC, useMemo} from 'react';
import {EtroList, EtroBox, EtroGrid, EtroLogo} from '~/components';
import {EmbedGearsetProps, Item} from './types';
import {LinkButton} from '~/components/LinkButton/LinkButton';
import {GearsetExpanded, GearsetParam} from '~/types';
import {
  EtroDataGrid,
  EtroDGColumn,
  SortingAlgorithms
} from '~/components/EtroDataGrid';
import {Buffs, MateriaList, RelicParams} from './Renderers';
import {RemoteIcon} from '~/components/EtroIcon';
import {EtroPaper} from '~/components/EtroPaper';
import {EtroDivider} from '~/components/EtroDivider';
import {EtroTitle} from '~/components/EtroTitle';
import {EtroGroup} from '~/components/EtroGroup';
import {EtroText} from '~/components/EtroText';
import {defaultMaxLevel, iLevelSyncIcon} from '~/constants';
import {useTranslation} from 'react-i18next';

const STAT_NAME_COLUMN_WIDTH = 200;
const STAT_VALUE_COLUMN_WIDTH = 80;
const GRID_COLUMNS = 2;

type Column = EtroDGColumn<GearsetParam>;
type Columns = readonly Column[];

export const EmbedGearsetCard: FC<EmbedGearsetProps> = ({
  gearset,
  materiaMap,
  paramNames,
  secondaryParams,
  slots
}) => {
  const {t} = useTranslation();
  const items: Item[] = useMemo(() => {
    if (gearset) {
      return slots.reduce((acc: Item[], slot) => {
        const slotKey = (slot.gearsetKey
          ? slot.gearsetKey
          : slot.equipKey) as keyof GearsetExpanded;

        if (!!gearset[slotKey] && slotKey !== 'buffs') {
          acc.push({...(gearset[slotKey] as Item), slotKey});
        }

        return acc;
      }, []);
    }

    return [];
  }, [gearset, slots]);
  const columns: Columns = useMemo(() => {
    return [
      {
        name: t('stat'),
        key: 'name',
        width: STAT_NAME_COLUMN_WIDTH,
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.stringCaseInsensitive
      },
      {
        name: t('value'),
        key: 'value',
        width: STAT_VALUE_COLUMN_WIDTH,
        sortable: true,
        sortingAlgorithm: SortingAlgorithms.number,
        formatter: ({row}) => (
          <span>{`${row.value}${row.units ? row.units : ''}`}</span>
        )
      }
    ];
  }, [t]);

  if (!gearset) {
    return null;
  }

  return (
    <EtroGrid grow gutter="sm" columns={GRID_COLUMNS}>
      <EtroGrid.Col span={GRID_COLUMNS}>
        <EtroBox sx={{display: 'flex', justifyContent: 'space-between'}}>
          <EtroLogo
            showText
            openNewTab
            svgProps={{width: '100%', style: {maxWidth: '150px'}}}
          />
          <LinkButton
            className="etro-button-hover-primary-fill"
            outlined
            text={`${t('open')} ${t('gearset')}`}
            icon="share"
            to={`/gearset/${gearset.id}`}
            openNewTab
          />
        </EtroBox>
      </EtroGrid.Col>
      <EtroGrid.Col span={GRID_COLUMNS}>
        <EtroPaper
          p="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'left'
          }}
        >
          <EtroBox sx={{display: 'flex'}}>
            <RemoteIcon
              iconPath={gearset.job.iconPath}
              alt={`${gearset.job.name} Icon`}
              height="80px"
              width="80px"
            />
            <EtroBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingLeft: '4px'
              }}
            >
              <EtroTitle order={2}>{gearset.job.name}</EtroTitle>
              <EtroText size="lg">{gearset.name}</EtroText>
              <EtroGroup spacing={'xs'}>
                {gearset.patch && (
                  <EtroText size="xs">{`${t('patch')} ${
                    gearset.patch
                  }`}</EtroText>
                )}
                {gearset.level !== defaultMaxLevel && (
                  <EtroText size="xs">{`${t('level')} ${
                    gearset.level
                  }`}</EtroText>
                )}
                {gearset.itemLevelSync && (
                  <EtroText size="xs" color="yellow.6">
                    {`${iLevelSyncIcon} ${gearset.itemLevelSync}`}
                  </EtroText>
                )}
              </EtroGroup>
            </EtroBox>
          </EtroBox>
        </EtroPaper>
      </EtroGrid.Col>
      <EtroGrid.Col span={1}>
        <EtroPaper
          p="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            minWidth: '45%'
          }}
        >
          <EtroTitle order={2}>{t('equipment')}</EtroTitle>
          <EtroDivider />
          <EtroList spacing="sm" size="sm" center>
            {items.map((row, i) => {
              return (
                <EtroList.Item
                  key={`equipment${i}`}
                  icon={
                    <RemoteIcon
                      iconPath={row.iconPath}
                      height={40}
                      alt={`${row.name}Icon`}
                      renderSkeleton
                    />
                  }
                >
                  <EtroBox>
                    <EtroText size="sm">{row.name}</EtroText>
                    <EtroBox sx={{display: 'flex', flexWrap: 'wrap'}}>
                      {typeof row.id === 'string' ? (
                        <RelicParams
                          row={row}
                          secondaryParams={secondaryParams}
                        />
                      ) : (
                        <MateriaList
                          row={row}
                          gearset={gearset}
                          materiaMap={materiaMap}
                        />
                      )}
                    </EtroBox>
                  </EtroBox>
                </EtroList.Item>
              );
            })}
          </EtroList>
          {gearset?.buffs && <Buffs gearset={gearset} />}
        </EtroPaper>
      </EtroGrid.Col>
      <EtroGrid.Col span={1}>
        <EtroPaper
          p="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left'
          }}
        >
          <EtroTitle order={2}>{t('stat')}</EtroTitle>
          <EtroDivider />
          <EtroBox
            sx={{
              width: `calc(${STAT_NAME_COLUMN_WIDTH}px + ${STAT_VALUE_COLUMN_WIDTH}px + 2px)`,
              margin: 'auto'
            }}
          >
            <EtroDataGrid
              className="etro-param-table"
              columns={columns}
              disableFiltering
              disableHeader
              noDataText={
                'No stats available. The gearset needs to be saved to cache stats.'
              }
              rows={gearset?.totalParams ?? []}
              size="fill"
            />
          </EtroBox>
        </EtroPaper>
      </EtroGrid.Col>
    </EtroGrid>
  );
};
