import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import queryStringParser from 'query-string';
import {useLocation} from 'react-router';
import {onSuccess, onFailure} from '~/components/AppToaster/AppToaster';

export const NitroPayCallback: React.FC = () => {
  const location = useLocation();
  const {success} = queryStringParser.parse(location.search, {
    parseBooleans: true
  });

  // Since the user is reopening the site, getUser has already ran and updated/init Sub information
  useEffect(() => {
    if (success) {
      onSuccess('Thanks for subscribing to Etro!', 'primary', 'heart');
    } else {
      onFailure(
        'Please consider subscribing to Etro to gain benefits and offset development costs.',
        'danger'
      );
    }
  }, [success]);

  return <Redirect to="/" />;
};
