import React from 'react';
import {Code, CodeProps} from '@mantine/core';

export interface EtroCodeProps extends CodeProps {}

const defaultProps: Partial<EtroCodeProps> = {};

export const EtroCode: React.FC<EtroCodeProps> = props => {
  return <Code {...defaultProps} {...props} />;
};
