import React from 'react';
import {Prism, PrismProps} from '@mantine/prism';

export interface EtroPrismProps extends PrismProps {}

const defaultProps: Partial<EtroPrismProps> = {};

export const EtroPrism: React.FC<EtroPrismProps> = props => {
  return <Prism {...defaultProps} {...props} />;
};
