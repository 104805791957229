import React, {useState} from 'react';
import {Dialog, Intent, Button} from '@blueprintjs/core';
import {useMount} from 'react-use';

export const ConfirmDialog = ({
  confirmContent,
  confirmIcon = 'issue',
  confirmIntent = Intent.DANGER,
  confirmText = 'Ok',
  dialogStyle = undefined,
  disableCancel = false,
  disableConfirm = false,
  icon = 'issue',
  isOpen,
  loading = false,
  onConfirm,
  title = 'Confirmation',
  toggle
}) => {
  const [disableConfirmToggle, setDisableConfirmToggle] = useState(false);
  /* 
    If confirm is being used for form submission, let the form
    handle toggle after validation, success, etc. This requires
    <form> to be the first element in confirmContent
  */
  useMount(() => {
    React.Children.forEach(confirmContent, child => {
      if (child.type === 'form') {
        setDisableConfirmToggle(true);
      }
    });
  });

  return (
    <Dialog
      title={title}
      icon={icon}
      isOpen={isOpen}
      onClose={toggle}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      backdropClassName={''}
      style={dialogStyle}
    >
      <div className="etro-dialog-body">{confirmContent}</div>
      <div className="etro-dialog-footer">
        <div className="etro-dialog-footer-actions">
          {!disableCancel && (
            <Button
              type="button"
              onClick={toggle}
              text="Cancel"
              outlined={true}
              icon="cross"
            />
          )}
          <Button
            onClick={async () => {
              await onConfirm();
              if (!disableConfirmToggle) {
                toggle();
              }
            }}
            intent={confirmIntent}
            text={confirmText}
            loading={loading}
            outlined={true}
            icon={confirmIcon}
            disabled={disableConfirm}
          />
        </div>
      </div>
    </Dialog>
  );
};
