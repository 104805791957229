import React, {useState, useMemo, useEffect} from 'react';
import {connect} from 'react-redux';
import {pick, difference, keys} from 'lodash';
import {Card, Icon, Collapse} from '@blueprintjs/core';
import {isEmpty, sortBy} from 'lodash';
import {useUnmount, useUpdateEffect} from 'react-use';
import {MateriaSelector} from './MateriaSelector';
import {
  getMateriaObjects,
  getEquipmentParams,
  sumMateriaListParams,
  sumObjectsByProperty,
  handleRingId
} from './GearsetHelpers';
import {gearsetsCreators} from '~/actions/gearsets';
import {
  paramNames,
  meldableParamIds,
  crafterParamIds,
  gatherParamIds,
  paramSortOrder
} from '~/constants';
import {EtroDataGrid, useColumnWidth} from '~/components/EtroDataGrid';
import {adjustMateriaEditorVisibleCount} from '~/slices/gearset';
import {useDispatch} from '~/hooks';
import {EtroBox} from '~/components/EtroBox';
import {RemoteSVG} from '~/components/EtroIcon/RemoteSVG';
import {EtroGroup, EtroText} from '~/components';

const getMateriaSelectors = (
  selectedItem,
  slotKey,
  filteredMateriaOptions,
  totalParams
) => {
  const materiaSlots = selectedItem.advancedMelding
    ? 5
    : selectedItem.materiaSlotCount;
  var materiaSelectors = [];

  for (var i = 1; i <= materiaSlots; i++) {
    materiaSelectors.push(
      <MateriaSelector
        key={i}
        materiaSlot={i}
        selectedItem={selectedItem}
        slotKey={slotKey}
        filteredMateriaOptions={filteredMateriaOptions}
        totalParams={totalParams}
      />
    );
  }

  return materiaSelectors;
};

const setupMateriaCapTable = (totalParams, itemMaxes) => {
  // BLU weapons have no totalParams
  if (isEmpty(totalParams)) {
    return [[], []];
  }

  let columns = [],
    data = {};

  for (var paramId in totalParams) {
    const idString = `${paramId}`,
      paramValue = totalParams[paramId],
      maxValue = itemMaxes[paramId],
      overCap = paramValue > maxValue,
      exactCap = paramValue === maxValue;

    columns.push({
      name: paramNames[paramId],
      key: idString,
      formatter: ({row}) => (
        <>
          <span
            className={
              overCap
                ? 'etro-materia-overcap'
                : exactCap
                ? 'etro-materia-exactcap'
                : ''
            }
          >
            {row[idString]}
          </span>
        </>
      )
    });

    data[idString] = `${paramValue}/${maxValue}`;
  }

  columns = sortBy(columns, [
    x => {
      return paramSortOrder[Number(x.key)];
    }
  ]);

  return [columns, [data]];
};

export const MateriaCardComponent = ({
  allMateriaEditorVisible,
  currentJob,
  filteredMateriaOptions,
  selectedItem,
  selectedMateria,
  slotKey
}) => {
  const dispatch = useDispatch();
  const [materiaEditorVisible, toggleMateriaEditor] = useState(false),
    itemParams = useMemo(() => getEquipmentParams(selectedItem), [
      selectedItem
    ]),
    itemMateriaParams = useMemo(() => sumMateriaListParams(selectedMateria), [
      selectedMateria
    ]),
    totalParams = useMemo(() => {
      const itemMateriaSum = sumObjectsByProperty(
          pick(itemParams, meldableParamIds),
          itemMateriaParams
        ),
        {isCrafting, isGathering} = currentJob;

      if (isCrafting || isGathering) {
        const temp = {};

        // Add any missing params with a value of 0 so they are in meld table
        difference(
          isCrafting ? crafterParamIds : gatherParamIds,
          keys(itemMateriaSum).map(Number)
        ).forEach(k => (temp[k] = 0));

        return {...itemMateriaSum, ...temp};
      }

      return itemMateriaSum;
    }, [itemParams, itemMateriaParams, currentJob]);

  // Keep state in sync with global
  useEffect(() => {
    toggleMateriaEditor(allMateriaEditorVisible);
  }, [allMateriaEditorVisible]);

  useUpdateEffect(() => {
    dispatch(adjustMateriaEditorVisibleCount(materiaEditorVisible ? 1 : -1));
  }, [dispatch, materiaEditorVisible]);

  // Use unmount effect for cleanup rather than return in UpdateEffect,
  // otherwise multiple actions are dispatched and the count is off.
  useUnmount(() => {
    if (materiaEditorVisible) {
      dispatch(adjustMateriaEditorVisibleCount(-1));
    }
  });

  const tableSetup = useMemo(
      () => setupMateriaCapTable(totalParams, selectedItem.maxParams),
      [totalParams, selectedItem]
    ),
    columns = tableSetup[0],
    data = tableSetup[1];

  const [ref, columnWidth] = useColumnWidth(columns.length ?? 0);

  const cardContent = useMemo(() => {
    if (!isEmpty(selectedMateria) && !materiaEditorVisible) {
      return selectedMateria.map((m, i) => {
        return !isEmpty(m) ? (
          <div key={i} className="etro-materia-item">
            <Icon
              icon={
                <img
                  src={`${process.env.REACT_APP_ICON_BASE_URL}${m.iconPath}`}
                  height="24px"
                  alt={`materia${i}Icon`}
                />
              }
            />
            {`${paramNames[m.param]} +${m.paramValue}`}
          </div>
        ) : null;
      });
    } else if (materiaEditorVisible) {
      return (
        <EtroDataGrid
          disableHeader
          disableFiltering
          className="etro-materia-cap-table"
          noDataText="Add materia below to show meld caps."
          rows={data}
          columns={columns}
          rowHeight={30}
          size="fill"
          style={{flex: 1}}
        />
      );
    } else {
      return (
        <EtroGroup position="center" spacing="xs">
          <RemoteSVG iconPath="/slots/materia.svg" className="etro-icon-xs" />
          <EtroText size={'xs'} color="dimmed">
            {'Click to add materia...'}
          </EtroText>
        </EtroGroup>
      );
    }
  }, [columns, data, materiaEditorVisible, selectedMateria]);

  return (
    <>
      <Card
        className="etro-materia-card"
        style={{marginBottom: materiaEditorVisible ? '8px' : undefined}}
        interactive={true}
        onClick={() => toggleMateriaEditor(!materiaEditorVisible)}
      >
        <div className="etro-materia-card-content">
          <EtroBox
            ref={ref}
            sx={{
              '.rdg': {
                gridTemplateColumns: `repeat(${columns.length}, ${columnWidth}px) !important`,
                overflowX: 'hidden'
              }
            }}
            className="etro-materia-wrapper"
          >
            {cardContent}
          </EtroBox>
          <span className="etro-materia-collapse-icon">
            <Icon
              icon={
                materiaEditorVisible
                  ? 'double-chevron-down'
                  : 'double-chevron-right'
              }
            />
          </span>
        </div>
      </Card>
      <Collapse isOpen={materiaEditorVisible} keepChildrenMounted={true}>
        {getMateriaSelectors(
          selectedItem,
          slotKey,
          filteredMateriaOptions,
          totalParams
        )}
      </Collapse>
    </>
  );
};

const actions = {
  setGearset: gearsetsCreators.setGearset
};

const mapStateToProps = (state, props) => {
  const selectedMateria = getMateriaObjects(
    state.gearsets.gearset,
    handleRingId(props.selectedItem.id, props.slotKey),
    state.materia.materiaSelectOptions
  );

  return {
    selectedMateria
  };
};

export const MateriaCard = connect(
  mapStateToProps,
  actions
)(MateriaCardComponent);
