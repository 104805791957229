import React from 'react';
import {Button, Intent, IButtonProps} from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';
import {RemoteSVG} from '~/components';

interface KofiButtonProps extends IButtonProps {
  showText?: boolean;
}

export const KofiButton: React.FC<KofiButtonProps> = ({
  className,
  minimal,
  outlined,
  showText = false,
  text
}) => {
  const {t} = useTranslation();

  return (
    <a target="_blank" rel="noopener noreferrer" href="https://ko-fi.com/etro">
      <Button
        className={`etro-full-width etro-button-hover-primary-fill ${
          className ? className : ''
        }`}
        outlined={outlined}
        minimal={minimal}
        intent={outlined ? Intent.PRIMARY : undefined}
        icon={<RemoteSVG className="etro-icon-small" iconPath="/ui/kofi.svg" />}
        text={text ? text : showText ? t('support') : undefined}
      />
    </a>
  );
};
