import {CSSProperties} from 'react';
import {Tuple, DefaultMantineColor} from '@mantine/core';
import {
  MantineThemeOverride,
  MantineTheme,
  ColorScheme,
  CSSObject,
  ContextStylesParams
} from '@mantine/styles';
// @ts-ignore
import variables from '~/styles/variables.scss';
import {HEADER_HEIGHT} from '~/components/Header';
import {FOOTER_HEIGHT} from '~/components/Footer';

// https://mantine.dev/theming/colors/#add-custom-colors-types
type ExtendedCustomColors =
  | 'etro'
  | 'etroDark'
  | 'etroLight'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}

type getEtroThemeColorFn = ({
  theme,
  shade,
  isLightTheme
}: {
  theme: MantineTheme;
  shade: number;
  isLightTheme?: boolean;
}) => string;

// Same as Mantine, not exported
interface EtroVariantOutput {
  border: NonNullable<CSSProperties['borderColor']>;
  background: CSSProperties['backgroundColor'];
  color: NonNullable<CSSProperties['color']>;
  hover: CSSProperties['backgroundColor'];
}

type getEtroVariantFn = ({
  theme,
  isLightTheme
}: {
  theme: MantineTheme;
  isLightTheme?: boolean;
}) => EtroVariantOutput;

type getVariantFn = ({
  theme,
  params,
  context
}: {
  theme: MantineTheme;
  params: any;
  context: ContextStylesParams;
}) => EtroVariantOutput;

const {
  darkText,
  darkPrimary,
  darkSecondary,
  darkTertiary,
  darkQuaternary,
  darkQuinary,
  darkShadow,
  darkIndicator,
  lightText,
  lightPrimary,
  lightSecondary,
  lightTertiary,
  lightQuaternary,
  lightQuinary,
  lightShadow,
  lightIndicator
} = variables as Record<string, string>;
const lineHeight = '1.28581';

const getXsFontSize = ({size}: ContextStylesParams) => {
  return size === 'xs' ? '0.875rem' : undefined;
};

const getIsLightTheme = (colorScheme: ColorScheme) => {
  return colorScheme === 'light';
};

const getEtroThemeColor: getEtroThemeColorFn = ({
  theme,
  shade,
  isLightTheme
}) => {
  const {colorScheme, colors} = theme;

  return isLightTheme ?? getIsLightTheme(colorScheme)
    ? colors.etroLight[shade]
    : colors.etroDark[shade];
};

const getEtroVariant: getEtroVariantFn = ({theme, isLightTheme}) => {
  const {colorScheme, colors, black, white} = theme;
  const primaryColor = theme.fn.primaryColor();

  const etroLightVariant: EtroVariantOutput = {
    background: 'transparent',
    border: theme.fn.rgba(black, 0.2),
    color: colors.etroLight[5],
    hover: theme.fn.rgba(primaryColor, 0.05)
  };

  const etroDarkVariant: EtroVariantOutput = {
    background: 'transparent',
    border: theme.fn.rgba(white, 0.4),
    color: colors.etroDark[5],
    hover: theme.fn.rgba(primaryColor, 0.05)
  };

  return isLightTheme ?? getIsLightTheme(colorScheme)
    ? etroLightVariant
    : etroDarkVariant;
};

const getVariant: getVariantFn = ({theme, params, context}) => {
  return (!!params.color
    ? theme.fn.variant({...params, ...context})
    : getEtroVariant({theme})) as EtroVariantOutput;
};

export const defaultTheme: MantineThemeOverride = {
  // light font
  black: lightText, // '#000000',
  colors: {
    etro: [
      // https://omatsuri.app/color-shades-generator
      // Darken 13%, Saturation 0, First 10
      '#FFFEFE',
      '#ECD6E8',
      '#DBB3D4',
      '#CC94C3',
      '#C078B4',
      '#B460A6',
      '#A34D95',
      '#8E4381',
      '#7B3A71',
      '#6B3362'
      // Old
      // Darken 9%, Saturation 10%
      // '#F6D8F1',
      // '#EDBCE5',
      // '#E2A3D8',
      // '#D78ECB',
      // '#CC7CBE',
      // '#C06DB2',
      // '#B460A6',
      // '#B460A6',
      // '#B460A6',
      // '#AF4C9F'
    ],
    dark: [
      // Normal dark theme colors, except 0 to override dark font
      // https://mantine.dev/theming/dark-theme/#colors
      darkText, // '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5C5F66',
      '#373A40',
      '#2C2E33',
      '#25262B',
      '#1A1B1E',
      '#141517',
      '#101113'
    ],
    // Primary theme colors, ordered lightest to darkest
    etroDark: [
      darkQuinary,
      darkQuaternary,
      darkTertiary,
      darkSecondary,
      darkPrimary,
      darkText,
      darkIndicator
    ],
    etroLight: [
      lightQuinary,
      lightQuaternary,
      lightTertiary,
      lightSecondary,
      lightPrimary,
      lightText,
      lightIndicator
    ]
  },
  primaryColor: 'etro',
  primaryShade: 5,
  // From https://img.finalfantasyxiv.com/lds/pc/global/fonts//FFXIV_Lodestone_SSF.woff
  // https://thewakingsands.github.io/ffxiv-axis-font-icons/
  // Adds FFXIV unicode characters
  fontFamily: 'Open Sans, FFXIV',
  headings: {
    // undefined to appease TS
    fontFamily: undefined,
    fontWeight: undefined,
    sizes: {
      // Match BP font sizes and line height
      h1: {fontSize: '2em', lineHeight},
      h2: {fontSize: '1.5em', lineHeight},
      h3: {fontSize: '1.17em', lineHeight},
      h4: {fontSize: '1em', lineHeight},
      h5: {fontSize: '0.83em', lineHeight},
      h6: {fontSize: '0.67em', lineHeight}
    }
  },
  globalStyles: theme => {
    const isLightTheme = getIsLightTheme(theme.colorScheme);
    const {colors} = theme;

    return {
      '.etro-description-replace-': {
        '&black': {
          color: isLightTheme ? colors.violet[6] : colors.violet[4]
        },
        '&blue': {
          color: colors.blue[4]
        },
        '&green': {
          color: colors.green[6]
        },
        '&orange': {
          color: colors.orange[5]
        },
        '&yellow': {
          // yellow (all shades) is hard to read in light theme
          color: isLightTheme ? colors.violet[6] : colors.yellow[3]
        }
      }
    };
  },
  components: {
    ActionIcon: {
      styles: (theme, params, context) => {
        const hasColor = !!params.color;
        const primaryColor = theme.fn.primaryColor();
        const {background, border, color} = getVariant({
          theme,
          params,
          context
        });

        return {
          root: {
            '&:enabled:active': {
              transform: 'unset',
              boxShadow: `0 0 0 2px ${theme.fn.rgba(
                hasColor ? color : primaryColor,
                0.7
              )}`
            },
            '&[data-disabled]': {
              backgroundColor: background,
              color: theme.fn.rgba(color, 0.6),
              border,
              cursor: 'not-allowed',
              pointerEvents: 'all'
            }
          }
        };
      }
    },
    AppShell: {
      styles: theme => {
        // Spacing is for mt and mb on footer and header
        const layoutSize = HEADER_HEIGHT + FOOTER_HEIGHT;

        return {
          body: {
            // Need minHeight set to place footer on bottom for views with sparse content
            minHeight: `calc(100vh - (${layoutSize}px + ${theme.spacing.xl} * 2))`
          }
        };
      }
    },
    Button: {
      styles: (theme, params, context) => {
        const hasColor = !!params.color;
        const primaryColor = theme.fn.primaryColor();
        const {variant} = context;
        // Make the default button similar to BP where it is theme based
        const {background, border, color, hover} = getVariant({
          theme,
          params,
          context
        });

        // Styles for all buttons
        const baseRootStyles: CSSObject = {
          fontSize: getXsFontSize(context),
          fontWeight: 'initial',
          color,
          '&:hover': {
            backgroundColor: hasColor ? hover : theme.fn.rgba(primaryColor, 0.2)
          },
          '&:not([data-disabled]):active': {
            transform: 'unset',
            boxShadow: `0 0 0 2px ${theme.fn.rgba(
              hasColor ? color : primaryColor,
              0.7
            )}`
          }
        };

        if (variant !== 'outline') {
          return {
            root: baseRootStyles
          };
        }

        return {
          root: {
            ...baseRootStyles,
            borderColor: border,
            backgroundColor: background,
            color,
            // Tabler icons auto fill based on color
            '.mantine-Button-leftIcon svg:not(.tabler-icon)': {fill: color},
            '.mantine-Button-rightIcon svg:not(.tabler-icon)': {fill: color},
            '&:hover': {
              backgroundColor: hover,
              ...(hasColor
                ? {}
                : {
                    color: primaryColor,
                    borderColor: primaryColor,
                    '.mantine-Button-leftIcon svg:not(.tabler-icon)': {
                      fill: primaryColor
                    },
                    '.mantine-Button-rightIcon svg:not(.tabler-icon)': {
                      fill: primaryColor
                    }
                  })
            },
            '&[data-disabled]': {
              backgroundColor: background,
              color: theme.fn.rgba(color, 0.6),
              borderColor: theme.fn.rgba(border, 0.2),
              cursor: 'not-allowed',
              pointerEvents: 'all'
            }
          }
        };
      }
    },
    // Match BP styles
    Card: {
      styles: theme => {
        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 3})
          }
        };
      }
    },
    Code: {
      styles: theme => {
        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 2})
          }
        };
      }
    },
    Container: {
      styles: () => {
        return {
          root: {
            // Leave padding to view specific setting
            padding: 'unset'
          }
        };
      }
    },
    Dialog: {
      styles: theme => {
        const isLightTheme = getIsLightTheme(theme.colorScheme);

        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 3, isLightTheme})
          },
          closeButton: {
            color: isLightTheme ? lightText : darkText,
            '&:hover': {
              // Not sure where BP is getting the button hover colors
              backgroundColor: isLightTheme
                ? 'rgba(167, 182, 194, 0.3)'
                : 'rgba(138, 155, 168, 0.15)'
            }
          }
        };
      }
    },
    Divider: {
      styles: ({colorScheme, colors}) => {
        const isLightTheme = getIsLightTheme(colorScheme);

        return {
          root: {
            borderTopColor: isLightTheme
              ? 'initial'
              : `${colors.dark[3]} !important`
          }
        };
      }
    },
    Footer: {
      styles: (theme, params) => {
        const isLightTheme = getIsLightTheme(theme.colorScheme);
        const shadowOpacity = isLightTheme ? 0.1 : 0.2;

        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 3, isLightTheme}),
            boxShadow: `0px -1px 0px 0px rgba(0,0,0,${shadowOpacity})`,
            borderTop: 'unset'
          }
        };
      }
    },
    Header: {
      styles: (theme, params) => {
        const isLightTheme = getIsLightTheme(theme.colorScheme);
        const shadowOpacity = isLightTheme ? 0.1 : 0.2;

        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 3, isLightTheme}),
            boxShadow: `0 1px 0 0 rgba(0,0,0,${shadowOpacity})`,
            borderBottom: 'unset',
            position: 'sticky'
          }
        };
      }
    },
    Input: {
      styles: (theme, params, context) => {
        return {
          input: {
            backgroundColor: getEtroThemeColor({theme, shade: 2}),
            fontSize: getXsFontSize(context),
            '&[data-disabled]': {
              backgroundColor: getEtroThemeColor({theme, shade: 4})
            }
          }
        };
      }
    },
    Menu: {
      styles: theme => {
        return {
          dropdown: {
            backgroundColor: getEtroThemeColor({theme, shade: 2}),
            padding: `8px 4px !important`
          }
        };
      }
    },
    Modal: {
      styles: theme => {
        const backgroundColor = getEtroThemeColor({theme, shade: 4});
        const title = theme.headings.sizes.h2;

        return {
          content: {backgroundColor},
          header: {backgroundColor},
          inner: {
            '@media (max-width: 400px)': {
              padding: `${theme.spacing.lg} 4px`
            }
          },
          title: {
            // Match Title H2 styles
            fontSize: title.fontSize,
            lineHeight: title.lineHeight,
            fontWeight: 'bold'
          }
        };
      }
    },
    Notification: {
      styles: theme => {
        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 2}),
            textAlign: 'left'
          },
          title: {
            color: getEtroThemeColor({theme, shade: 5})
          }
        };
      }
    },
    // Match BP styles
    Paper: {
      styles: theme => {
        const isLightTheme = getIsLightTheme(theme.colorScheme);

        return {
          root: {
            backgroundColor: getEtroThemeColor({theme, shade: 3, isLightTheme}),
            boxShadow: isLightTheme ? lightShadow : darkShadow
          }
        };
      }
    },
    Popover: {
      styles: theme => {
        return {
          dropdown: {
            border: 'unset'
          }
        };
      }
    },
    Select: {
      styles: (theme, params, context) => {
        const xsFont = getXsFontSize(context);

        return {
          dropdown: {
            background: getEtroThemeColor({theme, shade: 2}),
            top: 'unset !important'
          },
          input: {
            fontSize: xsFont
          },
          item: {
            fontSize: xsFont,
            border: '1px solid transparent',
            padding: '4px 8px',
            '&[data-hovered]&:not([data-selected])': {
              background: 'initial',
              border: `1px solid ${theme.fn.primaryColor()}`
            }
          },
          label: {
            fontSize: xsFont
          }
        };
      }
    },
    Slider: {
      styles: theme => {
        const isLightTheme = getIsLightTheme(theme.colorScheme);
        const background = getEtroThemeColor({theme, shade: 2, isLightTheme});
        const color = getEtroThemeColor({theme, shade: 5, isLightTheme});

        return {
          dragging: {
            '&:active': {
              // Keep thumb above the other when dragging
              zIndex: 4
            }
          },
          label: {
            background,
            color
          },
          mark: {
            borderColor: 'transparent',
            background: 'transparent'
          },
          markLabel: {
            fontSize: theme.fontSizes.xs
          },
          root: {
            '&:focus': {
              // First slider click was showing focus outline in Account settings
              outline: 'none'
            }
          },
          thumb: {
            border: `2px solid ${theme.fn.primaryColor()}`,
            background: color
          },
          track: {'::before': {background}}
        };
      }
    },
    RichTextEditor: {
      styles: theme => {
        const {colorScheme, colors} = theme;
        const isLightTheme = getIsLightTheme(colorScheme);
        const backgroundColor = getEtroThemeColor({
          theme,
          shade: 2,
          isLightTheme
        });

        return {
          root: {
            // Need to reset center on body for align buttons to work
            textAlign: 'initial',
            backgroundColor,
            border: 'unset',
            boxShadow: isLightTheme ? lightShadow : darkShadow
          },
          content: {
            backgroundColor,
            fontSize: '0.875rem',
            p: {
              code: {
                backgroundColor: isLightTheme ? colors.gray[3] : colors.dark[5],
                border: 'unset',
                // match Code component
                padding: '2px calc(5px)'
              }
            },
            // placeholder styles
            '.is-editor-empty::before': {
              color: 'hsl(0, 0%, 50%) !important'
            }
          },
          toolbar: {
            backgroundColor: getEtroThemeColor({theme, shade: 3, isLightTheme})
          },
          control: {
            backgroundColor
          }
        };
      }
    },
    ScrollArea: {
      styles: theme => {
        return {
          thumb: {
            backgroundColor: theme.fn.rgba(theme.fn.primaryColor(), 0.8)
          }
        };
      }
    },
    Switch: {
      styles: () => {
        return {
          body: {alignItems: 'center'},
          track: {
            cursor: 'pointer'
          }
        };
      }
    }
  }
};
