import {
  EtroDGColumnType,
  EtroDGColumnTypeUnion,
  OperatorOption,
  StringFilter,
  NumberFilter,
  Operators
} from '.';
import {NumberOperators, SelectOperators, StringOperators} from '~/types/enums';
import {isNaN} from 'lodash';

const escapeRegExp = (value: string) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const notImplemented = () => true;

export const searchFilter = (row: Record<string, any>, searchText: string) => {
  const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

  return Object.keys(row).some((field: string) => {
    return searchRegex.test(row[field]?.toString());
  });
};

export const emptyFilter = (value: number | string | null) =>
  !value && value !== 0;

export const notEmptyFilter = (value: number | string | null) =>
  !!value || value === 0;

export const validateNumbers = (
  value: number | string | null,
  filterValue: number
) => {
  if (!filterValue && filterValue !== 0) {
    return null;
  }

  if (!value && value !== 0) {
    return null;
  }

  const _value = Number(value);
  const _filterValue = Number(filterValue);

  if (isNaN(_value) || isNaN(_filterValue)) {
    return null;
  }

  return {value: _value, filterValue: _filterValue};
};

export const numberEqualFilter: NumberFilter = (value, filterValue) => {
  const validated = validateNumbers(value, filterValue);

  if (!validated) {
    return false;
  }

  return validated.value === validated.filterValue;
};

export const numberNotEqualFilter: NumberFilter = (value, filterValue) => {
  const validated = validateNumbers(value, filterValue);

  if (!validated) {
    return false;
  }

  return validated.value !== validated.filterValue;
};

export const numberGTFilter: NumberFilter = (value, filterValue) => {
  const validated = validateNumbers(value, filterValue);

  if (!validated) {
    return false;
  }

  return validated.value > validated.filterValue;
};

export const numberGTEFilter: NumberFilter = (value, filterValue) => {
  const validated = validateNumbers(value, filterValue);

  if (!validated) {
    return false;
  }

  return validated.value >= validated.filterValue;
};

export const numberLTFilter: NumberFilter = (value, filterValue) => {
  const validated = validateNumbers(value, filterValue);

  if (!validated) {
    return false;
  }

  return validated.value < validated.filterValue;
};

export const numberLTEFilter: NumberFilter = (value, filterValue) => {
  const validated = validateNumbers(value, filterValue);

  if (!validated) {
    return false;
  }

  return validated.value <= validated.filterValue;
};

export const getNumberFilterByOperator = (op: NumberOperators) => {
  switch (op) {
    case NumberOperators.equal:
      return numberEqualFilter;
    case NumberOperators.notEqual:
      return numberNotEqualFilter;
    case NumberOperators.gt:
      return numberGTFilter;
    case NumberOperators.gte:
      return numberGTEFilter;
    case NumberOperators.lt:
      return numberLTFilter;
    case NumberOperators.lte:
      return numberLTEFilter;
    case NumberOperators.empty:
      return emptyFilter;
    case NumberOperators.notEmpty:
      return notEmptyFilter;
    default:
      return notImplemented;
  }
};

export const stringContainsFilter: StringFilter = (value, filterValue) => {
  if (value) {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  }

  return false;
};
export const stringEqualsFilter: StringFilter = (value, filterValue) => {
  if (value) {
    return value.toLowerCase() === filterValue.toLowerCase();
  }

  return false;
};
export const stringStartsWithFilter: StringFilter = (value, filterValue) => {
  if (value) {
    return value.toLowerCase().startsWith(filterValue.toLowerCase());
  }

  return false;
};
export const stringEndsWithFilter: StringFilter = (value, filterValue) => {
  if (value) {
    return value.toLowerCase().endsWith(filterValue.toLowerCase());
  }

  return false;
};

export const getStringFilterByOperator = (op: StringOperators) => {
  switch (op) {
    case StringOperators.contains:
      return stringContainsFilter;
    case StringOperators.equals:
      return stringEqualsFilter;
    case StringOperators.startsWith:
      return stringStartsWithFilter;
    case StringOperators.endsWith:
      return stringEndsWithFilter;
    case StringOperators.empty:
      return emptyFilter;
    case StringOperators.notEmpty:
      return notEmptyFilter;
    default:
      return notImplemented;
  }
};

export const selectIsFilter: StringFilter = (value, filterValue) => {
  if (value) {
    return value === filterValue;
  }

  return false;
};

export const selectIsNotFilter: StringFilter = (value, filterValue) => {
  if (value) {
    return value !== filterValue;
  }

  return false;
};

export const getSelectFilterByOperator = (op: SelectOperators) => {
  switch (op) {
    case SelectOperators.is:
      return selectIsFilter;
    case SelectOperators.isNot:
      return selectIsNotFilter;
    case SelectOperators.empty:
      return emptyFilter;
    case SelectOperators.notEmpty:
      return notEmptyFilter;
    default:
      return notImplemented;
  }
};

export const stringOperatorOptions = [
  {value: StringOperators.contains, label: 'Contains'},
  {value: StringOperators.equals, label: 'Equals'},
  {value: StringOperators.startsWith, label: 'Starts With'},
  {value: StringOperators.endsWith, label: 'Ends With'},
  {value: StringOperators.empty, label: 'Empty'},
  {value: StringOperators.notEmpty, label: 'Not Empty'}
];

export const numberOperatorOptions = [
  {value: NumberOperators.equal, label: NumberOperators.equal},
  {value: NumberOperators.notEqual, label: NumberOperators.notEqual},
  {value: NumberOperators.gt, label: NumberOperators.gt},
  {value: NumberOperators.gte, label: NumberOperators.gte},
  {value: NumberOperators.lt, label: NumberOperators.lt},
  {value: NumberOperators.lte, label: NumberOperators.lte},
  {value: NumberOperators.empty, label: 'Empty'},
  {value: NumberOperators.notEmpty, label: 'Not Empty'}
];

export const selectOperatorOptions = [
  {value: SelectOperators.is, label: 'Is'},
  {value: SelectOperators.isNot, label: 'Is Not'},
  {value: SelectOperators.empty, label: 'Empty'},
  {value: SelectOperators.notEmpty, label: 'Not Empty'}
];

export const getOperatorsByFilterType = (
  type: EtroDGColumnTypeUnion
): OperatorOption[] => {
  switch (type) {
    case EtroDGColumnType.number:
      return numberOperatorOptions;
    case EtroDGColumnType.string:
      return stringOperatorOptions;
    case EtroDGColumnType.select:
      return selectOperatorOptions;
    default:
      return [];
  }
};

export const nonInputFilters: Operators[] = [
  StringOperators.empty,
  StringOperators.notEmpty,
  NumberOperators.empty,
  NumberOperators.notEmpty,
  SelectOperators.empty,
  SelectOperators.notEmpty
];

export const getFilterByTypeAndOperator = (
  type: EtroDGColumnTypeUnion,
  operator: Operators
) => {
  switch (type) {
    case EtroDGColumnType.string:
      return getStringFilterByOperator(operator as StringOperators);
    case EtroDGColumnType.number:
      return getNumberFilterByOperator(operator as NumberOperators);
    case EtroDGColumnType.select:
      return getSelectFilterByOperator(operator as SelectOperators);
    default:
      return notImplemented;
  }
};
