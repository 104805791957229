import React, {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {isEmpty, isEqual} from 'lodash';
import {useDebounce} from 'react-use';
import {gearsetsCreators} from '~/actions/gearsets';
import {
  minItemLevel,
  maxItemLevel,
  baseParamLevels,
  iLevelSyncIcon,
  partyBonusOptions
} from '~/constants';
import {EtroRadioGroup} from '~/components/Form/EtroRadioGroup';
import {
  EtroNumberInput,
  EtroPaper,
  EtroRangeSlider,
  EtroStack,
  EtroText
} from '~/components';

const FilterSidebarComponent = ({
  currentJob,
  filterItemLevel,
  gearsetLevel,
  gearsetPartyBonus,
  itemLevelRange,
  itemLevelSync,
  isCraftOrGatherJob,
  materiaTierRange,
  setGearset,
  setItemLevelRange,
  setItemLevelSync,
  setMateriaTierRange
}) => {
  const [internalItemLevel, setInternalItemLevel] = useState(itemLevelRange);
  const [internalItemLevelSync, setInternalItemLevelSync] = useState(
    itemLevelSync
  );
  const levelOptions = useMemo(() => {
    return baseParamLevels.map(level => ({label: level, value: level}));
  }, []);
  // Settings are reset if no job, creating weird UX
  const disableSliders = useMemo(() => isEmpty(currentJob), [currentJob]);
  const disableSync = useMemo(() => isEmpty(currentJob) || isCraftOrGatherJob, [
    currentJob,
    isCraftOrGatherJob
  ]);

  // Fires the function when the dep array stops changing after 1000ms
  // This will cause the fetch equipment effect in Gearset to fire
  useDebounce(
    () => {
      // Check for equal because the effect below can trigger this.
      if (!isEqual(itemLevelRange, internalItemLevel)) {
        setItemLevelRange(internalItemLevel);
        filterItemLevel({
          itemLevelRange: internalItemLevel,
          jobAbbrev: currentJob?.abbrev
        });
      }
    },
    1000,
    [internalItemLevel]
  );

  // Keep internal in sync with Redux when it changes outside this component
  // Redux updates on JobChange and internal does not reflect the correct value on the slider
  useEffect(() => {
    setInternalItemLevel(itemLevelRange);
  }, [itemLevelRange]);

  useDebounce(
    () => {
      if (internalItemLevelSync !== itemLevelSync) {
        setItemLevelSync(internalItemLevelSync ? internalItemLevelSync : null);
      }
    },
    1000,
    [internalItemLevelSync]
  );

  // Keep internal in sync with Redux when it changes outside this component
  useEffect(() => {
    setInternalItemLevelSync(itemLevelSync);
  }, [itemLevelSync]);

  return (
    <EtroPaper className="etro-gearset-sidebar">
      <EtroStack spacing={'xl'}>
        <EtroRangeSlider
          disabled={disableSliders}
          markAmount={6}
          max={maxItemLevel}
          min={minItemLevel}
          onChange={range => {
            setInternalItemLevel(range);
          }}
          step={5}
          value={internalItemLevel}
          wrapperProps={{label: 'Item Level Range'}}
        />
        <EtroRangeSlider
          disabled={disableSliders}
          markAmount={12}
          max={12}
          min={1}
          onChange={range => setMateriaTierRange(range)}
          step={1}
          value={materiaTierRange}
          wrapperProps={{label: 'Materia Tier Range'}}
        />
        <EtroRadioGroup
          disabled={disableSync}
          label="Party Bonus"
          onChange={e => setGearset({partyBonus: Number(e.target.value)})}
          options={partyBonusOptions}
          requireValue
          selectedValue={gearsetPartyBonus}
        />
        <EtroRadioGroup
          disabled={disableSync}
          label="Level Sync"
          onChange={e => setGearset({level: Number(e.target.value)})}
          options={levelOptions}
          requireValue
          selectedValue={gearsetLevel}
        />
        <EtroNumberInput
          clearable
          disabled={disableSync}
          hideControls
          label="Item Level Sync"
          max={maxItemLevel}
          mb="md"
          min={0}
          onChange={setInternalItemLevelSync}
          value={internalItemLevelSync}
          icon={
            <EtroText size="sm" color={disableSync ? 'dimmed' : 'yellow.6'}>
              {iLevelSyncIcon}
            </EtroText>
          }
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </EtroStack>
    </EtroPaper>
  );
};

const actions = {
  filterItemLevel: gearsetsCreators.filterItemLevel,
  setGearset: gearsetsCreators.setGearset,
  setItemLevelRange: gearsetsCreators.setItemLevelRange,
  setItemLevelSync: gearsetsCreators.setItemLevelSync,
  setMateriaTierRange: gearsetsCreators.setMateriaTierRange
};

const mapStateToProps = state => {
  return {
    currentJob: state.jobs.currentJob,
    gearsetLevel: state.gearsets.gearset.level,
    gearsetPartyBonus: state.gearsets.gearset.partyBonus,
    itemLevelRange: state.gearsets.itemLevelRange,
    itemLevelSync: state.gearsets.gearset.itemLevelSync,
    materiaTierRange: state.gearsets.materiaTierRange
  };
};

export const FilterSidebar = connect(
  mapStateToProps,
  actions
)(FilterSidebarComponent);
