import {createActions} from 'redux-arc';
import {GET} from './types';

const clansApiBase = 'clans/';

export const {types, creators: clansCreators} = createActions('clans', {
  read: {url: `${clansApiBase}:id/`, method: GET},
  list: {url: clansApiBase, method: GET},
  setCurrentClan: null,
  resetCurrentClan: null
});
