import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useTitle} from 'react-use';

const TitleHandlerComponent = ({gearsetName}) => {
  useTitle(gearsetName ? `${gearsetName} · Etro` : 'Etro');

  return <></>;
};

const mapStateToProps = state => {
  return {
    gearsetName: state.gearsets.gearset.name
  };
};

export const TitleHandler = withRouter(
  connect(mapStateToProps)(TitleHandlerComponent)
);
