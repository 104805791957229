import React from 'react';
import {Drawer, DrawerProps} from '@mantine/core';
import {EtroDrawerBody} from './EtroDrawerBody';
import {EtroDrawerCloseButton} from './EtroDrawerCloseButton';
import {EtroDrawerContent} from './EtroDrawerContent';
import {EtroDrawerHeader} from './EtroDrawerHeader';
import {EtroDrawerOverlay} from './EtroDrawerOverlay';
import {EtroDrawerRoot} from './EtroDrawerRoot';
import {EtroDrawerTitle} from './EtroDrawerTitle';

export interface EtroDrawerProps extends DrawerProps {}
export type EtroDrawerComponents = {
  Body: typeof EtroDrawerBody;
  CloseButton: typeof EtroDrawerCloseButton;
  Content: typeof EtroDrawerContent;
  Header: typeof EtroDrawerHeader;
  Overlay: typeof EtroDrawerOverlay;
  Root: typeof EtroDrawerRoot;
  Title: typeof EtroDrawerTitle;
};

const defaultProps: Partial<EtroDrawerProps> = {};

export const EtroDrawer: React.FC<EtroDrawerProps> &
  EtroDrawerComponents = props => {
  return <Drawer {...defaultProps} {...props} />;
};

EtroDrawer.Body = EtroDrawerBody;
EtroDrawer.CloseButton = EtroDrawerCloseButton;
EtroDrawer.Content = EtroDrawerContent;
EtroDrawer.Header = EtroDrawerHeader;
EtroDrawer.Overlay = EtroDrawerOverlay;
EtroDrawer.Root = EtroDrawerRoot;
EtroDrawer.Title = EtroDrawerTitle;
