import React from 'react';
import {openConfirmModal} from '@mantine/modals';
import {merge} from 'lodash';
import {IconAlertOctagon, IconX} from '@tabler/icons-react';
import {EtroText} from '~/components';

export type EtroConfirmModalProps = Parameters<typeof openConfirmModal>[0];

export const openEtroConfirmModal: typeof openConfirmModal = ({
  title,
  ...rest
}) => {
  const defaultProps: Partial<EtroConfirmModalProps> = {
    cancelProps: {
      leftIcon: <IconX />,
      size: 'xs',
      variant: 'outline'
    },
    centered: true,
    confirmProps: {
      color: 'red',
      leftIcon: <IconAlertOctagon />,
      size: 'xs',
      variant: 'outline'
    },
    labels: {confirm: ' Submit', cancel: 'Cancel'},
    title:
      typeof title === 'string' ? (
        <EtroText color="red" sx={{lineHeight: 'inherit'}}>
          {title}
        </EtroText>
      ) : (
        title
      )
  };

  return openConfirmModal(merge(rest, defaultProps));
};
