import {etroApi} from '.';
import {PVPRank} from '~/types';

const PVPRANK_BASE = 'pvprank/';

const pvpRanksApi = etroApi.injectEndpoints({
  endpoints: ({query}) => ({
    readPVPRank: query<PVPRank, number>({
      query: id => `${PVPRANK_BASE}${id}`
    }),
    listPVPRanks: query<PVPRank[], void>({
      query: () => PVPRANK_BASE
    })
  })
});

export const {useReadPVPRankQuery, useListPVPRanksQuery} = pvpRanksApi;
