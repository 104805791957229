import React, {useState, useCallback} from 'react';
import {
  Button,
  Menu,
  MenuItem,
  IMenuItemProps,
  Position,
  Intent,
  IButtonProps
} from '@blueprintjs/core';
import {PopoverButton} from '../PopoverButton/PopoverButton';

export type SplitButtonMenuOption = IMenuItemProps & {
  hidden?: boolean;
  type?: IButtonProps['type'];
};

interface SplitButtonProps {
  buttonProps?: IButtonProps & {style: React.CSSProperties};
  disabled?: boolean;
  intent?: Intent;
  menuOptions: SplitButtonMenuOption[];
  option?: SplitButtonMenuOption;
  onChange?: (item: IMenuItemProps) => void;
}

export const SplitButton: React.FC<SplitButtonProps> = ({
  buttonProps,
  disabled,
  intent,
  menuOptions,
  option,
  onChange
}) => {
  const [_currentOption, _setCurrentOption] = useState(menuOptions[0]),
    handleMenuItemClick = useCallback(
      (item: IMenuItemProps) => onChange?.(item) ?? _setCurrentOption(item),
      [onChange]
    ),
    currentOption = option ?? _currentOption;

  return (
    <span className="etro-split-button-group">
      <Button
        outlined
        intent={intent}
        disabled={disabled || currentOption.disabled}
        {...buttonProps}
        className="etro-split-button-first"
        text={currentOption.text}
        icon={currentOption.icon}
        type={currentOption.type}
        onClick={currentOption.onClick}
      />
      <PopoverButton
        disabled={disabled || buttonProps?.loading}
        buttonProps={{
          icon: 'chevron-down',
          className: 'etro-split-button-last',
          outlined: true,
          intent
        }}
        popoverProps={{
          usePortal: false,
          position: Position.BOTTOM,
          boundary: 'viewport'
        }}
        content={
          <Menu>
            {menuOptions.map((option, i) => {
              return (
                <MenuItem
                  {...option}
                  key={`split-button-option-${i}`}
                  onClick={() => {
                    handleMenuItemClick(option);
                  }}
                />
              );
            })}
          </Menu>
        }
      />
    </span>
  );
};
