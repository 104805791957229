import React from 'react';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCheck
} from '@tabler/icons-react';
import {showNotification} from '@mantine/notifications';

type showEtroNotificationFn = typeof showNotification;
export type EtroNotificationProps = Parameters<showEtroNotificationFn>[0];

export const showEtroNotification: showEtroNotificationFn = props => {
  return showNotification(props);
};

const successProps: Partial<EtroNotificationProps> = {
  color: 'green',
  icon: <IconCheck />
};

export const showEtroSuccessNotification: showEtroNotificationFn = props => {
  return showEtroNotification({...successProps, ...props});
};

const failureProps: Partial<EtroNotificationProps> = {
  color: 'red',
  icon: <IconAlertOctagon />
};

export const showEtroFailureNotification: showEtroNotificationFn = props => {
  return showEtroNotification({...failureProps, ...props});
};

const warningProps: Partial<EtroNotificationProps> = {
  color: 'yellow',
  icon: <IconAlertTriangle />
};

export const showEtroWarningNotification: showEtroNotificationFn = props => {
  return showEtroNotification({...warningProps, ...props});
};
