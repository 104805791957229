import React from 'react';
import {EtroDataGridComponent} from './EtroDataGrid';
import {EtroDataGridProps} from './types';
import {FilterContextProvider} from './hooks';

export * from './CellRenderers';
export * from './EtroDataGrid';
export * from './EtroDataGridAction';
export * from './EtroDataGridHeader';
export * from './Filter';
export * from './Filtering';
export * from './FilteringPopover';
export * from './FilteringToolbar';
export * from './hooks';
export * from './RowRenderers';
export * from './Sorting';
export * from './types';
export * from 'react-data-grid';

export const EtroDataGrid = <T extends object, SR = unknown>(
  props: EtroDataGridProps<T, SR>
) => {
  return (
    <FilterContextProvider>
      <EtroDataGridComponent<T, SR> {...props} />
    </FilterContextProvider>
  );
};
