import React from 'react';
import {Table, TableProps} from '@mantine/core';

export interface EtroTableProps extends TableProps {}

const defaultProps: Partial<EtroTableProps> = {};

export const EtroTable: React.FC<EtroTableProps> = props => {
  return <Table {...defaultProps} {...props} />;
};
